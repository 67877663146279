import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { DunsAbbr } from 'react-common/components';
import { Util } from 'react-common/services';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';
import get from 'lodash/get';
import './ScreenModal.css';

class ScreenModal extends Component {
  renderShareholderInput = ({ fields }) => (
    <>
      <button
        className="icon-ic_plus btn Btn-add-shareholder"
        type="button"
        onClick={() => fields.push('')}
      >
        Add shareholder
      </button>
      {fields.map((field, index) => (
        <Fragment key={index}>
          <Field
            type="text"
            className="form-control AddShareholder-input"
            component="input"
            placeholder="Full Name"
            name={field}
          />
          <i
            className="AddShareholder-btn-delete icon-ic_close"
            type="button"
            onClick={() => fields.remove(index)}
          />
        </Fragment>
      ))}
    </>
  );

  render() {
    const {
      show,
      handleClose,
      supplier,
      onSubmit,
      formValues: { shareholders = [] } = {},
      user
    } = this.props;

    if (!supplier) {
      return null;
    }

    const cfrReport = user.getComplianceEnabledReport();

    return (
      <Modal className="Modal-screen" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="ModalTitle">Screen company</Modal.Title>
          <i
            className="icon-ic_close pull-right Close-modal-icon"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="Info-text">
            <p>
              You are about to screen the company{' '}
              <strong>
                <var>{supplier.getCompanyName()}</var>,{' '}
                {Util.capitalize(supplier.getCountry())} (<DunsAbbr /> number:{' '}
                <var>{supplier.getDunsNumber()}</var>)
              </strong>
              {supplier.getShareholders().length > 0 && (
                <>
                  {' '}
                  and <strong>shareholders</strong> from the list below
                </>
              )}
              {'.'}
            </p>
            <p>
              If you want to include more shareholders add them now. Adding
              shareholders later on is possible only as a new screening.{' '}
            </p>
          </div>
          <div className="Shareholders-list">
            {supplier
              .getShareholders()
              .map((shareholder, index) => <p key={index}>{shareholder}</p>)}
          </div>

          <div className="AddShareholderRow">
            <FieldArray
              name="shareholders"
              component={this.renderShareholderInput}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="pull-right">
            <button
              type="button"
              className="Btn-default-small Btn-default-neutral WorkflowsCreate-btn-cancel"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="Btn-default-small Btn-default-success WorkflowsCreate-btn-cancel"
              onClick={() => {
                handleClose();
                onSubmit(shareholders.filter(Boolean), cfrReport);
              }}
            >
              Screen
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('screening-shareholders')(state),
  user: get(state, 'user.data.user')
});

const AddShareholderForm = reduxForm({
  form: 'screening-shareholders'
})(ScreenModal);

export default connect(mapStateToProps)(AddShareholderForm);
