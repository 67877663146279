import React, { Fragment } from 'react';
import { Table, Modal } from 'react-bootstrap';

import './RatingsModal.css';

const RatingsModal = ({ show, handleClose, workflow }) => (
  <Modal className="Modal-ratings" show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className="ModalTitle">Ratings</Modal.Title>
      <i
        className="icon-ic_close pull-right Close-modal-icon"
        onClick={handleClose}
      />
    </Modal.Header>
    <Modal.Body>
      <h5 className="Modal-paragraph-title">Financial strength indicator</h5>
      <p className="Financial-description">
        The Financial Strength Indicator is calculated using either the Net
        Worth or Issued Capital of the Subject Company. If both figures are
        available, then the Net Worth is always used to calculate the Financial
        Strength.
      </p>

      <h5 className="Modal-table-heading">Based on Net Worth</h5>

      <Table responsive className="Modal-table-ratings">
        <thead />
        <tbody>
          <tr>
            <td>
              <div className="Square-badge Bg-deep-blue">5A</div>
            </td>
            <td>
              Financial Strength of<br /> 60 + million
            </td>
            <td>
              <div className="Square-badge Bg-mint">4A</div>
            </td>
            <td>
              Financial Strength of<br /> 25 - 60 million
            </td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-navy-blue">3A</div>
            </td>
            <td>
              Financial Strength of <br />12 - 25 million
            </td>
            <td>
              <div className="Square-badge Bg-sky-blue">2A</div>
            </td>
            <td>
              Financial Strength of <br />2.5 - 12 million
            </td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-violet">1A</div>
            </td>
            <td>
              Financial Strength of <br />1.2 - 2.5 million
            </td>
            <td>
              <div className="Square-badge Bg-purple">A</div>
            </td>
            <td>
              Financial Strength of <br />600,000 - 1.2 million
            </td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-yellow">B</div>
            </td>
            <td>
              Financial Strength of<br /> 345,000 - 600,000
            </td>
            <td>
              <div className="Square-badge Bg-light-orange">C</div>
            </td>
            <td>
              Financial Strength of <br />175,000 - 345,000
            </td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-pink">D</div>
            </td>
            <td>
              Financial Strength of <br />120,000 - 175,000
            </td>
            <td>
              <div className="Square-badge Bg-deep-pink">E</div>
            </td>
            <td>
              Financial Strength of<br /> 60,000 - 120,000
            </td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-dark-orange">F</div>
            </td>
            <td>
              Financial Strength of <br />35,000 - 60,000
            </td>
            <td>
              <div className="Square-badge Bg-coral">G</div>
            </td>
            <td>
              Financial Strength of <br />15,000 - 35,000
            </td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-rosewood">H</div>
            </td>
            <td>
              Financial Strength of <br />0 - 15,000
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </Table>

      <p>
        Other codes used:<br />
        N - Financial Strength is negative <br />
        O - Financial Strength is undisclosed<br />
        NB - New Business less than 2 years old<br />
        NQ - Ceased Trading<br />
        UTL - Unable To Locate
      </p>

      <hr />

      {!workflow.isRafOrRacReport() && (
        <Fragment>
          <h5 className="Modal-paragraph-title">
            The Condition Code or Risk Indicator
          </h5>
          <p>
            This is calculated by taking into account key items within the
            Business Information report which are used to predict the likelihood
            of a business failure.
          </p>

          <Table responsive className="Modal-table-Risk">
            <thead>
              <tr>
                <th className="Modal-table-heading">
                  Risk <br />Indicator
                </th>
                <th className="Modal-table-heading">
                  Probability <br />of failure
                </th>
                <th className="Modal-table-heading">
                  Guide to <br />interpretation
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-deep-green">1</div>
                </td>
                <td>Minimal risk</td>
                <td>Credit Grant - offer more enlarged terms if necessary</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-green">2</div>
                </td>
                <td>Low risk</td>
                <td>Credit Grant</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-yellow">3</div>
                </td>
                <td>Slightly greater than the average risk</td>
                <td>Credit Grant, but monitored</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-coral">4</div>
                </td>
                <td>Meaningful risk</td>
                <td>
                  Examine each case before granting credit, get more market
                  information, and analyze other suppliers. Ask for assurance if
                  necessary due to the high risk.
                </td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-deep-blue">S</div>
                </td>
                <td>Risk not assigned due to business nature</td>
                <td>
                  Companies that do not request credit in their normal
                  activities (Banks, Brokerages, Insurance Co., Schools,
                  Associations, Government, etc...)
                </td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-light-grey">-</div>
                </td>
                <td>Unknown risk</td>
                <td>Insufficient Information</td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      )}

      {workflow.isRafOrRacReport() && (
        <Fragment>
          <h5 className="Modal-paragraph-title">Risk Indicator</h5>
          <p>
            To calculate the {workflow.getReportType().toUpperCase()}, we use
            the D&B Rating, the automatic 12 key areas evaluation and other
            financial, tax and register information of the company to make sure
            that the risk level reflects the real situation of the company.
          </p>
          <Table responsive className="Modal-table-Risk">
            <thead>
              <tr>
                <th className="Modal-table-heading heading-center">
                  Risk <br />Indicator
                </th>
                <th className="Modal-table-heading">
                  Probability <br />of failure
                </th>
                <th className="Modal-table-heading">
                  Guide to <br />interpretation
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-deep-green">1</div>
                </td>
                <td>Minimal risk</td>
                <td>Proceed & Extend Terms</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-green">2</div>
                </td>
                <td>Low risk</td>
                <td>Proceed & Extend Terms</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-yellow">3</div>
                </td>
                <td>Medium risk</td>
                <td>Proceed & Extend Terms</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-yellow">4</div>
                </td>
                <td>Risk above average</td>
                <td>Proceed, but monitor</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-coral">5</div>
                </td>
                <td>Significant risk</td>
                <td>Seek Guarantees</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-rosewood">6</div>
                </td>
                <td>High risk</td>
                <td>Seek Guarantees</td>
              </tr>

              <tr>
                <td className="Risk-indicator">
                  <div className="Square-badge Bg-light-grey">-</div>
                </td>
                <td>Unknown risk</td>
                <td>Insufficient Information</td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      )}
    </Modal.Body>
  </Modal>
);

export default RatingsModal;
