import React from 'react';

const InternationalMexicoInfo = ({ taxId }) => (
  <>
    <div>
      <label>Bank:</label>
      <p notranslate="true">BBVA Bancomer, S.A</p>
    </div>
    <div>
      <label>Address:</label>
      <p notranslate="true">
        Durango 263, 5th floor, Colonia Roma Norte, Zip Code: 06700
      </p>
    </div>
    <div notranslate="true">
      <label>SWIFT:</label>
      <p>BCMRMXMMPYM</p>
    </div>
    <div>
      <label>Account:</label>
      <p notranslate="true">0443349721</p>
    </div>
    <div>
      <label>Recipient:</label>
      <p notranslate="true">Dun & Bradstreet de México, S.A. de C.V.</p>
    </div>
    <div>
      <label>Branch Name:</label>
      <p notranslate="true">Banca de Empresa Condesa</p>
    </div>
    <div>
      <label>Branch Number:</label>
      <p notranslate="true">0088</p>
    </div>
    <div>
      <label>Reference Number:</label>
      <p notranslate="true">{taxId}</p>
    </div>
  </>
);

export default InternationalMexicoInfo;
