import React from 'react';

import serverErrorImage from '../../_images/server-error.svg';
import './ErrorFallback.css';

const ErrorFallback = () => (
  <div className="ErrorFallback">
    <img src={serverErrorImage} alt="Error" />
    <p>Oops! An error has occurred.</p>
    <p>
      Please reload the page and try again. If the error persists,{' '}
      <a href="/contact" className="Contact-link">
        contact us.
      </a>
    </p>
  </div>
);

export default ErrorFallback;
