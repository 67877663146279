import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MomentDate } from 'react-common/components';
import { languages } from 'react-common/constants';
import { Localize } from 'react-common/services';
import includes from 'lodash/includes';

import { recentActivityTypes } from '../../../../_constants/workflows';

const recentActivityLink = (type, workflowId, supplierId) => {
  if (
    includes(
      [
        recentActivityTypes.REPORT_STARTED,
        recentActivityTypes.REPORT_FINISHED,
        recentActivityTypes.QUESTIONNAIRE_FINISHED,
        recentActivityTypes.SCORE_FINISHED
      ],
      type
    )
  ) {
    return `/workflows/${workflowId}/suppliers/${supplierId}`;
  }
  return `/workflows/${workflowId}/suppliers`;
};

const StatisticsRecentActivityItem = ({
  activity: {
    type,
    supplier: { workflow_id, supplier_id, supplier_company_name },
    created_at
  }
}) => (
  <Link to={recentActivityLink(type, workflow_id, supplier_id)}>
    <div className="RecentActivity-notification">
      {type === recentActivityTypes.ONBOARDING_STARTED && (
        <>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}
          </span>
          <span className="RecentActivity-notification-message">
            {' '}
            has started{' '}
          </span>
          <span className="RecentActivity-type"> Onboarding process.</span>
        </>
      )}
      {type === recentActivityTypes.ONBOARDING_FINISHED && (
        <>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}
          </span>
          <span className="RecentActivity-notification-message">
            {' '}
            has finished{' '}
          </span>
          <span className="RecentActivity-type"> Onboarding process.</span>
        </>
      )}
      {type === recentActivityTypes.QUESTIONNAIRE_FINISHED && (
        <>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}
          </span>
          <span className="RecentActivity-notification-message">
            {' '}
            has finished{' '}
          </span>
          <span className="RecentActivity-type"> Questionnaire.</span>
        </>
      )}

      {type === recentActivityTypes.REPORT_STARTED && (
        <>
          <span className="RecentActivity-type"> Investigation </span>
          <span className="RecentActivity-notification-message"> on </span>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}
          </span>
          <span className="RecentActivity-notification-message">
            {' '}
            has begun.
          </span>
        </>
      )}
      {type === recentActivityTypes.REPORT_FINISHED && (
        <>
          <span className="RecentActivity-type"> Report </span>
          <span className="RecentActivity-notification-message">
            {' '}
            is now available for{' '}
          </span>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}.
          </span>
        </>
      )}

      {type === recentActivityTypes.SCORE_STARTED && (
        <>
          <span className="RecentActivity-type"> Score View </span>
          <span className="RecentActivity-notification-message">
            {' '}
            investigation on{' '}
          </span>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}
          </span>
          <span className="RecentActivity-notification-message">
            {' '}
            has begun.
          </span>
        </>
      )}
      {type === recentActivityTypes.SCORE_FINISHED && (
        <>
          <span className="RecentActivity-type"> Score View </span>
          <span className="RecentActivity-notification-message">
            {' '}
            is now available for{' '}
          </span>
          <span className="RecentActivity-company" notranslate="true">
            {supplier_company_name}.
          </span>
        </>
      )}
      <p className="RecentActivity-time">
        <MomentDate
          format="MMMM DD, YYYY"
          locale={languages[Localize.getLanguage()].uploadcare}
          date={created_at}
        />
      </p>
    </div>
  </Link>
);

StatisticsRecentActivityItem.propTypes = {
  activity: PropTypes.shape({
    type: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    supplier: PropTypes.shape({
      workflow_id: PropTypes.string,
      supplier_id: PropTypes.string,
      supplier_company_name: PropTypes.string
    })
  })
};

export default StatisticsRecentActivityItem;
