import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { download, setSuppliersFilters } from '../../../_actions';

import WorkflowsDetailsSuppliers from './WorkflowsDetailsSuppliers';

class WorkflowsDetailsSuppliersContainer extends Component {
  state = {
    supplierStatus: 'all',
    searchQuery: ''
  };

  onChangeSupplierStatus = supplierStatus =>
    this.setState({ supplierStatus, searchQuery: '' });

  onChangeSearchQuery = searchQuery => this.setState({ searchQuery });

  downloadAllReports = () => {
    const { dispatchDownload, workflow, isDownloading } = this.props;

    if (isDownloading) {
      return;
    }
    const url = `/api/workflows/${workflow.getId()}/download`;
    const fileName = workflow.getTitle() + '_reports';
    return dispatchDownload(url, fileName);
  };

  downloadExcel = () => {
    const { dispatchDownload, workflow, isDownloading } = this.props;

    if (isDownloading) {
      return;
    }
    const url = `/api/workflows/${workflow.getId()}/download-excel`;
    const fileName =
      workflow.getTitle() +
      '-suppliers-' +
      moment(new Date()).format('DD-MM-YYYY');
    return dispatchDownload(url, fileName);
  };

  downloadQuestionnaire = () => {
    const { dispatchDownload, workflow, isDownloading } = this.props;

    if (isDownloading) {
      return;
    }
    const url = `/api/workflows/${workflow.getId()}/download-questionnaire`;
    const fileName =
      workflow.getTitle() +
      '-suppliers-questionnaire-' +
      moment(new Date()).format('DD-MM-YYYY');
    return dispatchDownload(url, fileName);
  };

  render() {
    const { workflow, user, suppliers, activeFilters } = this.props;
    const { supplierStatus, searchQuery } = this.state;

    return (
      <WorkflowsDetailsSuppliers
        downloadAllReports={this.downloadAllReports}
        downloadExcel={this.downloadExcel}
        downloadQuestionnaire={this.downloadQuestionnaire}
        onChangeSupplierStatus={this.onChangeSupplierStatus}
        onChangeSearchQuery={this.onChangeSearchQuery}
        suppliers={suppliers}
        searchQuery={searchQuery}
        supplierStatus={supplierStatus}
        showAddSuppliersButton={user.isCompanyAdmin()}
        workflow={workflow}
        user={user}
        activeFilters={activeFilters}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    workflows,
    user: { data: { user } },
    ui: { suppliersFilters }
  } = state;
  const { workflow } = props;
  const { isDownloading } = workflows;

  const suppliers = workflow.suppliers.map(
    supplierId => workflows.suppliers.getById[supplierId]
  );

  return {
    workflow,
    user,
    suppliers,
    isDownloading,
    activeFilters: suppliersFilters
  };
};

const mapDispatchToProps = {
  dispatchDownload: download,
  dispatchSetSuppliersFilters: setSuppliersFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsDetailsSuppliersContainer
);
