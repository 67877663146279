import React from 'react';
import { Field, reduxForm } from 'redux-form';

const ResetPasswordForm = ({
  handleSubmit,
  submitting,
  resetPassword: { success, error }
}) => (
  <div className="ResetPassword">
    <h2 className="Title-table">Reset password</h2>

    <div className="Content-table-last">
      <form onSubmit={handleSubmit}>
        <label htmlFor="oldPassword">Old Password</label>
        <Field
          type="password"
          name="oldPassword"
          className="form-control"
          component="input"
          placeholder="Enter your old Password"
        />

        <label htmlFor="newPassword">New Password</label>
        <Field
          type="password"
          name="newPassword"
          className="form-control"
          component="input"
          placeholder="7+ characters"
        />

        <label htmlFor="confirmPassword">Confirm New Password</label>
        <Field
          type="password"
          name="confirmPassword"
          className="form-control"
          component="input"
          placeholder="7+ characters"
        />

        <button
          type="submit"
          disabled={submitting}
          className="Btn-default Btn-default-primary"
        >
          Change
        </button>

        {success && (
          <div className="alert alert-success" role="alert">
            Your password has been successfully reset
          </div>
        )}

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </form>
    </div>
  </div>
);

const ResetPassword = reduxForm({
  form: 'settings-reset-password'
})(ResetPasswordForm);

export default ResetPassword;
