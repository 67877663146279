import React, { Component } from 'react';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { languages } from 'react-common/constants';
import { Localize } from 'react-common/services';
import Drift from '../../_services/Drift';

import './Header.css';

class PublicHeader extends Component {
  state = {
    currentLanguage: Localize.getLanguage()
  };

  setLanguage = language => {
    Localize.setLanguage(language);
    this.setState({ currentLanguage: language });
    Drift.activateClientPlaybook(language);
  };

  render() {
    const isLoginPage = window.location.pathname === '/login';
    const { className = 'col-md-9 col-xs-12' } = this.props;

    return (
      <Navbar className={`Header Header-public ${className}`}>
        <Nav className="Header-nav Public-navbar pull-right">
          <NavDropdown
            id="basic-nav-dropdown"
            className="Language-selector"
            eventKey={1}
            title={languages[this.state.currentLanguage].short}
            onSelect={this.setLanguage}
          >
            {Object.keys(languages)
              .filter(key => languages[key].enabled)
              .map(key => (
                <MenuItem eventKey={key} key={key}>
                  {languages[key].full}
                </MenuItem>
              ))}
          </NavDropdown>
          {isLoginPage && (
            <LinkContainer to="/contact">
              <NavItem eventKey={2}>
                <span>Don't have an account?</span>
                <span className="Public-header-contact">Contact us</span>
              </NavItem>
            </LinkContainer>
          )}
          {!isLoginPage && (
            <LinkContainer to="/login">
              <NavItem eventKey={2} className="Public-header-login">
                Login
              </NavItem>
            </LinkContainer>
          )}
        </Nav>
      </Navbar>
    );
  }
}

export default PublicHeader;
