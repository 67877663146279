import React from 'react';

const InternationalCcsInfo = ({ taxId }) => (
  <>
    <div>
      <label>Bank:</label>
      <p notranslate="true">JP Morgan Chase</p>
    </div>
    <div>
      <label>Address:</label>
      <p notranslate="true">349 5th Ave. New York, NY 11105</p>
    </div>
    <div notranslate="true">
      <label>ABA:</label>
      <p>2100021</p>
    </div>
    <div notranslate="true">
      <label>SWIFT:</label>
      <p>CHASUS33</p>
    </div>
    <div>
      <label>Account:</label>
      <p notranslate="true">251786122</p>
    </div>
    <div>
      <label>Recipient:</label>
      <p notranslate="true">CIAL D&B SERVICES INC</p>
    </div>
    <div>
      <label>Reference Number:</label>
      <p notranslate="true">{taxId}</p>
    </div>
  </>
);

export default InternationalCcsInfo;
