import React from 'react';

import OnboardingSupplierPaymentReference from './OnboardingSupplierPaymentReference';
import OnboardingSupplierPaymentProof from './OnboardingSupplierPaymentProof';

import './OnboardingSupplierPayment.css';

const OnboardingSupplierPayment = props => (
  <div className="OnboardingSupplierPayment">
    {!props.showPaymentReference && (
      <OnboardingSupplierPaymentReference {...props} />
    )}
    {props.showPaymentReference && (
      <OnboardingSupplierPaymentProof {...props} />
    )}
  </div>
);

export default OnboardingSupplierPayment;
