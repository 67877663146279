import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { createWorkflow } from '../../_actions';

import NavigationMenu from './NavigationMenu';

class NavigationMenuContainer extends Component {
  onCreateWorkflowClick = () => {
    const { dispatchCreateWorkflow, user } = this.props;
    return dispatchCreateWorkflow({}, user);
  };

  render() {
    const { className, user, pathname, workflow } = this.props;

    if (!user) {
      return null;
    }

    return (
      <NavigationMenu
        className={className}
        isUserCompanyAdmin={user.isCompanyAdmin()}
        onCreateWorkflowClick={this.onCreateWorkflowClick}
        pathname={pathname}
        workflow={workflow}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    user: { data: { user } },
    workflows = {},
    router: { location: { pathname } = {} } = {}
  } = state;

  const urlRegex = /\/workflows\/([0-9a-f]{24})\/.*/;
  const match = pathname.match(urlRegex);
  const workflowId = match && match[1];
  const workflow =
    workflowId &&
    workflows &&
    workflows.getById &&
    workflows.getById[workflowId];

  return {
    user,
    pathname,
    workflow
  };
};

const mapDispatchToProps = {
  dispatchCreateWorkflow: createWorkflow
};

NavigationMenu.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string.isRequired
      })
    )
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(
  NavigationMenuContainer
);
