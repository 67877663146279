import nextIdentifyConstants from '../_constants/nextIdentify';

export function nextIdentify(
  state = {
    nextIdentify: null
  },
  action
) {
  switch (action.type) {
    case nextIdentifyConstants.NEXT_IDENTIFY_VALIDATION: {
      const currentDateTime = new Date();
      return {
        ...state,
        nextIdentify: currentDateTime.setMinutes(
          currentDateTime.getMinutes() + 30
        )
      };
    }
    default:
      return state;
  }
}
