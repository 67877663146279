import React from 'react';
import { Sidebar } from 'react-common/components';
import { Field, reduxForm } from 'redux-form';

import s360Logo from '../_images/logo_side-menu.svg';
import PublicHeader from '../_components/Header/PublicHeader';

import './ForgotPassword.css';

const ForgotPasswordForm = ({
  handleSubmit,
  submitting,
  formValues: { email },
  forgotPassword: { success, error }
}) => (
  <div>
    <Sidebar
      logo={s360Logo}
      logoAlt="Supplier360"
      className="col-md-3 hidden-sm hidden-xs"
      headerText="Forgot password"
      descriptionText="Enter your email address and we will help you create a new password"
    />
    <div className="col-md-9">
      <div className="row">
        <PublicHeader />
        <div className="col-md-5 col-md-offset-3">
          <form onSubmit={handleSubmit} className="ForgotPassword-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="text"
                className="form-control"
                name="email"
                component="input"
                placeholder="Enter your email"
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={submitting || !email}
                className="Btn-default Btn-default-primary form-control"
              >
                {(!success && 'Request reset link') || 'Resend link'}
              </button>
            </div>
          </form>
          <div>
            {error && (
              <div className="alert fade in alert-danger" role="alert">
                <p>There is no account with this email in our platform.</p>
              </div>
            )}
          </div>

          <div>
            {success && (
              <div className="alert alert-success" role="alert">
                <p>
                  A link to reset your password has been sent to{' '}
                  <var>{email}</var>. Please check your inbox.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ForgotPassword = reduxForm({
  form: 'forgot-password'
})(ForgotPasswordForm);

export default ForgotPassword;
