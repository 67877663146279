import { Download } from 'react-common/services';
import downloadConstants from '../_constants/download';
import { handleError } from './handler';

function getDownloadRequest() {
  return {
    type: downloadConstants.DOWNLOAD_REQUEST
  };
}

function getDownloadSuccess() {
  return {
    type: downloadConstants.DOWNLOAD_SUCCESS
  };
}

function getDownloadFailure(error) {
  return {
    type: downloadConstants.DOWNLOAD_FAILURE,
    error
  };
}

export function download(url, fileName) {
  return dispatch => {
    dispatch(getDownloadRequest());
    document.getElementsByTagName('body')[0].classList.add('loading');
    // remove extension from file name
    let extension;
    if (fileName.indexOf('.') !== -1) {
      extension = fileName.split('.').pop();
      fileName = fileName.slice(0, fileName.lastIndexOf('.'));
    }

    return Download.downloadFile(url, fileName, extension)
      .then(() => dispatch(getDownloadSuccess()))
      .catch(handleError(dispatch, getDownloadFailure))
      .then(() =>
        document.getElementsByTagName('body')[0].classList.remove('loading')
      );
  };
}
