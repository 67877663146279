import React from 'react';

const LocalPeruInfo = () => (
  <>
    <p>
      Invoices for payments received after the 26<sup>th</sup> day of each month
      will be generated with the following month's date.
    </p>
    <p>
      Always remember to reference your RFC number when making a transfer or
      deposit in order to identify the payment.
    </p>
  </>
);

export default LocalPeruInfo;
