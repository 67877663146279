import { push } from 'connected-react-router';
import find from 'lodash/find';
import {
  supplierStatuses,
  workflowStatuses,
  workflowProducts,
  workflowReportTypes,
  workflowPayment,
  questionTypes
} from 'react-common/constants';
import { reset } from 'redux-form';

import workflowsConstants from '../_constants/workflows';
import { handleError } from './handler';
import normalize from './normalize';
import { Supplier } from '../_models/supplier';
import WorkflowsService from '../_services/Workflows';
import Toast from '../_services/Toast';
import { reloadUser } from './user';

function getAllWorkflowsRequest() {
  return {
    type: workflowsConstants.GET_ALL_REQUEST
  };
}

function getAllWorkflowsSuccess(response) {
  return {
    type: workflowsConstants.GET_ALL_SUCCESS,
    response
  };
}

function getAllWorkflowsFailure(error) {
  return {
    type: workflowsConstants.GET_ALL_FAILURE,
    error
  };
}

export function getAllWorkflows(status = workflowStatuses.ACTIVE) {
  return dispatch => {
    dispatch(getAllWorkflowsRequest());
    return WorkflowsService.getWorkflows(status)
      .then(workflows => {
        dispatch(getAllWorkflowsSuccess(normalize(workflows)));
      })
      .catch(handleError(dispatch, getAllWorkflowsFailure));
  };
}

function getWorkflowRequest() {
  return {
    type: workflowsConstants.GET_REQUEST
  };
}

function getWorkflowSuccess(response) {
  return {
    type: workflowsConstants.GET_SUCCESS,
    response
  };
}

function getWorkflowFailure(error) {
  return {
    type: workflowsConstants.GET_FAILURE,
    error
  };
}

export function getWorkflow(workflowId) {
  return dispatch => {
    dispatch(getWorkflowRequest());
    return WorkflowsService.getWorkflow(workflowId)
      .then(workflow => {
        dispatch(getWorkflowSuccess(normalize(workflow)));
      })
      .catch(handleError(dispatch, getWorkflowFailure));
  };
}

function createWorkflowRequest() {
  return {
    type: workflowsConstants.CREATE_REQUEST
  };
}

function createWorkflowFailure(error) {
  return {
    type: workflowsConstants.CREATE_FAILURE,
    error
  };
}

function createWorkflowSuccess(response) {
  return {
    type: workflowsConstants.CREATE_SUCCESS,
    response
  };
}

export function createWorkflow(workflow, user) {
  return dispatch => {
    dispatch(createWorkflowRequest());
    if (!workflow.info) {
      workflow.info = {};
    }
    workflow.info.company_name = user.getCompanyLegalName() || '';

    const {
      workflow_type,
      workflow_product,
      price_per_supplier,
      workflow_payment
    } = user.getSupplierFeature();
    workflow.type = workflow_type;
    workflow.product = workflow_product;
    // if hybrid is selected, set client as default workflow payment
    workflow.payment =
      workflow_payment === workflowPayment.HYBRID
        ? workflowPayment.CLIENT
        : workflow_payment;

    // set price per supplier for supplier and hybrid payments
    if (workflow_payment !== workflowPayment.CLIENT) {
      workflow.price_per_supplier = price_per_supplier;
    }

    const reportTypes = user.getEnabledReportTypes();
    // default report type for score/vale flow is BIR
    if (workflow.product === workflowProducts.SCORE) {
      workflow.report_type = workflowReportTypes.BIR;
    } else {
      workflow.report_type = reportTypes[0] || workflowReportTypes.BIR;
    }

    return WorkflowsService.createWorkflow(workflow)
      .then(workflow => {
        dispatch(createWorkflowSuccess(normalize(workflow)));
        dispatch(push('/workflows/' + workflow._id + '/edit'));
      })
      .catch(handleError(dispatch, createWorkflowFailure));
  };
}

export function cloneWorkflow(workflow) {
  return dispatch => {
    dispatch(createWorkflowRequest());
    const clonedWorkflow = {
      info: workflow.info,
      type: workflow.type,
      product: workflow.product,
      payment: workflow.payment,
      report_type: workflow.report_type,
      questionnaire: workflow.questionnaire || []
    };

    if (workflow.isSupplierPaying()) {
      clonedWorkflow.price_per_supplier = workflow.price_per_supplier;
    }

    return WorkflowsService.createWorkflow(clonedWorkflow)
      .then(newWorkflow => {
        dispatch(createWorkflowSuccess(normalize(newWorkflow)));
        dispatch(push('/workflows/' + newWorkflow._id + '/edit'));
      })
      .catch(handleError(dispatch, createWorkflowFailure));
  };
}

function updateWorkflowRequest(showLoader) {
  return {
    type: workflowsConstants.UPDATE_REQUEST,
    showLoader
  };
}

function updateWorkflowFailure(error) {
  return {
    type: workflowsConstants.UPDATE_FAILURE,
    error
  };
}

function updateWorkflowSuccess(response) {
  return {
    type: workflowsConstants.UPDATE_SUCCESS,
    response
  };
}

export function updateWorkflow(workflow, showLoader = false) {
  return dispatch => {
    dispatch(updateWorkflowRequest(showLoader));
    workflow.suppliers = workflow.suppliers.map(supplier => {
      if (!supplier || !supplier.invite) {
        return supplier;
      }
      supplier = WorkflowsService.parseSupplierEmails(supplier);
      if (
        WorkflowsService.isCkrInvestigation(workflow) ||
        WorkflowsService.isCcrInvestigation(workflow)
      ) {
        supplier.invite.country = 'brazil';
      }
      return supplier;
    });

    const attachmentsToUpload = workflow.questionnaire.filter(
      (question, index) => {
        question.index = index;
        return !question._id && question.type === questionTypes.ATTACHMENT;
      }
    );

    return WorkflowsService.updateWorkflow(workflow)
      .then(async updatedWorkflow => {
        for (const attachment of attachmentsToUpload) {
          let question = updatedWorkflow.questionnaire[attachment.index];
          if (question && question._id) {
            let data = new FormData();
            data.append('attachment', attachment.file);

            updatedWorkflow = await WorkflowsService.addAttachmentToQuestion(
              workflow._id,
              question._id,
              data
            );
          }
        }

        dispatch(updateWorkflowSuccess(normalize(updatedWorkflow)));
      })
      .catch(handleError(dispatch, updateWorkflowFailure));
  };
}

function notifySuppliersRequest() {
  return {
    type: workflowsConstants.NOTIFY_SUPPLIERS_REQUEST
  };
}

function notifySuppliersFailure(error) {
  return {
    type: workflowsConstants.NOTIFY_SUPPLIERS_FAILURE,
    error
  };
}

function notifySuppliersSuccess() {
  return {
    type: workflowsConstants.NOTIFY_SUPPLIERS_SUCCESS
  };
}

export function notifySuppliers(workflowId) {
  return dispatch => {
    dispatch(notifySuppliersRequest());
    return WorkflowsService.sendReminderToAllSuppliers(workflowId)
      .then(() => dispatch(notifySuppliersSuccess()))
      .catch(handleError(dispatch, notifySuppliersFailure));
  };
}

function activateWorkflowRequest() {
  return {
    type: workflowsConstants.ACTIVATE_REQUEST
  };
}

function activateWorkflowFailure(error) {
  return {
    type: workflowsConstants.ACTIVATE_FAILURE,
    error
  };
}

function activateWorkflowSuccess(response) {
  return {
    type: workflowsConstants.ACTIVATE_SUCCESS,
    response
  };
}

export function activateWorkflow(workflow) {
  return dispatch => {
    dispatch(activateWorkflowRequest());
    return WorkflowsService.updateWorkflow(workflow)
      .then(() => {
        return WorkflowsService.activateWorkflow(workflow._id);
      })
      .then(updatedWorkflow => {
        dispatch(activateWorkflowSuccess(normalize(updatedWorkflow)));
        dispatch(push('/workflows/' + updatedWorkflow._id + '/results'));
        dispatch(reloadUser());
      })
      .catch(handleError(dispatch, activateWorkflowFailure));
  };
}

function deleteDraftRequest() {
  return {
    type: workflowsConstants.DELETE_DRAFT_REQUEST
  };
}

function deleteDraftFailure(error) {
  return {
    type: workflowsConstants.DELETE_DRAFT_FAILURE,
    error
  };
}

function deleteDraftSuccess(workflowId) {
  return {
    type: workflowsConstants.DELETE_DRAFT_SUCCESS,
    workflowId
  };
}

export function deleteDraft(workflowId) {
  return dispatch => {
    dispatch(deleteDraftRequest());
    return WorkflowsService.deleteDraft(workflowId)
      .then(() => {
        dispatch(deleteDraftSuccess(workflowId));
        dispatch(push('/workflows/draft'));
      })
      .catch(handleError(dispatch, deleteDraftFailure));
  };
}

function closeWorkflowRequest() {
  return {
    type: workflowsConstants.CLOSE_WORKFLOW_REQUEST
  };
}

function closeWorkflowSuccess(response) {
  return {
    type: workflowsConstants.CLOSE_WORKFLOW_SUCCESS,
    response
  };
}

function closeWorkflowFailure(error) {
  return {
    type: workflowsConstants.CLOSE_WORKFLOW_FAILURE,
    error
  };
}

export function closeWorkflow(workflowId) {
  return dispatch => {
    dispatch(closeWorkflowRequest());
    return WorkflowsService.closeWorkflow(workflowId)
      .then(updatedWorkflow => {
        dispatch(closeWorkflowSuccess(normalize(updatedWorkflow)));
        dispatch(push('/workflows/completed'));
      })
      .catch(handleError(dispatch, closeWorkflowFailure));
  };
}

function statisticsRequest(showLoader) {
  return {
    type: workflowsConstants.WORKFLOW_STATISTICS_REQUEST,
    showLoader
  };
}

function statisticsSuccess(statistics, activities) {
  return {
    type: workflowsConstants.WORKFLOW_STATISTICS_SUCCESS,
    statistics,
    activities
  };
}

function statisticsFailure(error) {
  return {
    type: workflowsConstants.WORKFLOW_STATISTICS_FAILURE,
    error
  };
}

export function getStatistics(workflowIds, showLoader = false) {
  return dispatch => {
    dispatch(statisticsRequest(showLoader));
    return Promise.all([
      WorkflowsService.getStatistics(workflowIds),
      WorkflowsService.getActivities(workflowIds)
    ])
      .then(results => {
        const [statistics, activities] = results;
        WorkflowsService.prepopulateCommercialScoreStatistics(statistics);
        WorkflowsService.prepopulateRacRiskIndicatorStatistics(statistics);
        dispatch(statisticsSuccess(statistics, activities));
      })
      .catch(handleError(dispatch, statisticsFailure));
  };
}

function addSupplierRequest() {
  return {
    type: workflowsConstants.ADD_SUPPLIER_REQUEST
  };
}

function addSupplierSuccess(response) {
  return {
    type: workflowsConstants.ADD_SUPPLIER_SUCCESS,
    response
  };
}

export function addSupplierFailure(error) {
  return {
    type: workflowsConstants.ADD_SUPPLIER_FAILURE,
    error
  };
}

export function addSupplier(workflowId, supplier) {
  return dispatch => {
    dispatch(addSupplierRequest());

    return WorkflowsService.addSupplier(
      workflowId,
      WorkflowsService.parseSupplierEmails(supplier)
    )
      .then(updatedWorkflow => {
        dispatch(addSupplierSuccess(normalize(updatedWorkflow)));
        dispatch(reset('add-suppliers-single'));
        dispatch(reloadUser());
      })
      .catch(handleError(dispatch, addSupplierFailure));
  };
}

function addSuppliersBulkRequest() {
  return {
    type: workflowsConstants.ADD_SUPPLIERS_BULK_REQUEST
  };
}

function addSuppliersBulkSuccess(response) {
  return {
    type: workflowsConstants.ADD_SUPPLIERS_BULK_SUCCESS,
    response
  };
}

export function addSuppliersBulkFailure(error) {
  return {
    type: workflowsConstants.ADD_SUPPLIERS_BULK_FAILURE,
    error
  };
}

export function addSuppliersBulk(workflowId, suppliers) {
  return dispatch => {
    dispatch(addSuppliersBulkRequest());

    suppliers.forEach(supplier =>
      WorkflowsService.parseSupplierEmails(supplier)
    );
    return WorkflowsService.addSuppliersBulk(workflowId, suppliers)
      .then(updatedWorkflow => {
        dispatch(addSuppliersBulkSuccess(normalize(updatedWorkflow)));
        dispatch(reset('add-suppliers-bulk'));
        dispatch(reloadUser());
      })
      .catch(handleError(dispatch, addSuppliersBulkFailure));
  };
}

function requestBirReportRequest() {
  return {
    type: workflowsConstants.REQUEST_BIR_REPORT_REQUEST
  };
}

function requestBirReportSuccess(supplier, birRequest) {
  return {
    type: workflowsConstants.REQUEST_BIR_REPORT_SUCCESS,
    supplier,
    birRequest
  };
}

export function requestBirReportFailure(error) {
  return {
    type: workflowsConstants.REQUEST_BIR_REPORT_FAILURE,
    error
  };
}

export function requestBirReport(workflowId, supplier) {
  return dispatch => {
    dispatch(requestBirReportRequest());
    return WorkflowsService.requestBirReport(workflowId, supplier)
      .then(birRequest => {
        const supplierCopy = new Supplier({ ...supplier });
        dispatch(requestBirReportSuccess(supplierCopy, birRequest));
        Toast.showInfo('BIR requested.');
        dispatch(reloadUser());
      })
      .catch(handleError(dispatch, requestBirReportFailure));
  };
}

function requestCcrReportRequest() {
  return {
    type: workflowsConstants.REQUEST_CCR_REPORT_REQUEST
  };
}

function requestCcrReportSuccess(response) {
  return {
    type: workflowsConstants.REQUEST_CCR_REPORT_SUCCESS,
    response
  };
}

export function requestCcrReportFailure(error) {
  return {
    type: workflowsConstants.REQUEST_CCR_REPORT_FAILURE,
    error
  };
}

export function requestCcrReport(workflowId, supplierId) {
  return dispatch => {
    dispatch(requestCcrReportRequest());
    return WorkflowsService.requestCcrReport(workflowId, supplierId)
      .then(updatedWorkflow => {
        dispatch(requestCcrReportSuccess(normalize(updatedWorkflow)));
        Toast.showInfo('CCR requested.');
        dispatch(reloadUser());
      })
      .catch(handleError(dispatch, requestCcrReportFailure));
  };
}

function changeSupplierStatusRequest() {
  return {
    type: workflowsConstants.CHANGE_SUPPLIER_STATUS_REQUEST
  };
}

function changeSupplierStatusSuccess(supplier) {
  return {
    type: workflowsConstants.CHANGE_SUPPLIER_STATUS_SUCCESS,
    supplier
  };
}

export function changeSupplierStatusFailure(error) {
  return {
    type: workflowsConstants.CHANGE_SUPPLIER_STATUS_FAILURE,
    error
  };
}

export function changeStatusForSupplier(workflowId, supplier, newStatus) {
  return dispatch => {
    if (!newStatus) {
      newStatus = supplier.isCompleted()
        ? supplierStatuses.ARCHIVED
        : supplierStatuses.COMPLETED;
    }

    dispatch(changeSupplierStatusRequest());
    return WorkflowsService.changeSupplierStatus(
      workflowId,
      supplier.getId(),
      newStatus
    )
      .then(workflow => {
        const newSupplier = new Supplier(
          find(workflow.suppliers, s => s._id === supplier.getId())
        );
        newSupplier.setWorkflow(workflow);
        dispatch(changeSupplierStatusSuccess(newSupplier));
        Toast.showInfo(
          newStatus === supplierStatuses.ARCHIVED
            ? 'Supplier archived.'
            : 'Supplier unarchived.'
        );
      })
      .catch(handleError(dispatch, changeSupplierStatusFailure));
  };
}

function sendSupplierReminderRequest() {
  return {
    type: workflowsConstants.SEND_SUPPLIER_REMINDER_REQUEST
  };
}

function sendSupplierReminderSuccess(supplier) {
  return {
    type: workflowsConstants.SEND_SUPPLIER_REMINDER_SUCCESS,
    supplier
  };
}

export function sendSupplierReminderFailure(error) {
  return {
    type: workflowsConstants.SEND_SUPPLIER_REMINDER_FAILURE,
    error
  };
}

export function sendReminderToSupplier(workflowId, supplierId) {
  return dispatch => {
    dispatch(sendSupplierReminderRequest());
    return WorkflowsService.sendSupplierReminder(workflowId, supplierId)
      .then(workflow => {
        const supplier = new Supplier(
          find(workflow.suppliers, s => s._id === supplierId)
        );
        supplier.setWorkflow(workflow);
        dispatch(sendSupplierReminderSuccess(supplier));
      })
      .catch(handleError(dispatch, sendSupplierReminderFailure));
  };
}

function deleteSupplierRequest() {
  return {
    type: workflowsConstants.DELETE_SUPPLIER_REQUEST
  };
}

function deleteSupplierSuccess(workflowId, supplierId) {
  return {
    type: workflowsConstants.DELETE_SUPPLIER_SUCCESS,
    workflowId,
    supplierId
  };
}

export function deleteSupplierFailure(error) {
  return {
    type: workflowsConstants.DELETE_SUPPLIER_FAILURE,
    error
  };
}

export function deleteSupplier(workflowId, supplierId) {
  return dispatch => {
    dispatch(deleteSupplierRequest());
    return WorkflowsService.deleteSupplier(workflowId, supplierId)
      .then(() => dispatch(deleteSupplierSuccess(workflowId, supplierId)))
      .catch(handleError(dispatch, deleteSupplierFailure));
  };
}

export function setTagsToSupplier(workflowId, supplier, tags) {
  return dispatch => {
    dispatch({
      type: workflowsConstants.SET_TAGS_TO_SUPPLIER,
      supplier: new Supplier({ ...supplier, tags })
    });
    dispatch(updateSupplier(workflowId, supplier.getId(), { tags }));
  };
}

function updateSupplierRequest() {
  return {
    type: workflowsConstants.UPDATE_SUPPLIER_REQUEST
  };
}

function updateSupplierSuccess(supplier) {
  return {
    type: workflowsConstants.UPDATE_SUPPLIER_SUCCESS,
    supplier
  };
}

function updateSupplierFailure(error) {
  return {
    type: workflowsConstants.UPDATE_SUPPLIER_FAILURE,
    error
  };
}

export function updateSupplier(workflowId, supplierId, supplierData) {
  return dispatch => {
    dispatch(updateSupplierRequest());
    return WorkflowsService.updateSupplier(workflowId, supplierId, supplierData)
      .then(workflow => {
        const supplier = new Supplier(
          find(workflow.suppliers, s => s._id === supplierId)
        );
        supplier.setWorkflow(workflow);
        dispatch(updateSupplierSuccess(supplier));
      })
      .catch(handleError(dispatch, updateSupplierFailure));
  };
}

function addAttachmentToQuestionRequest(questionIds) {
  return {
    type: workflowsConstants.ADD_ATTACHMENT_QUESTION_REQUEST,
    files: questionIds
  };
}

function addAttachmentToQuestionSuccess(response, questionIds) {
  return {
    type: workflowsConstants.ADD_ATTACHMENT_QUESTION_SUCCESS,
    response,
    files: questionIds
  };
}

function addAttachmentToQuestionFailure(questionIds) {
  return error => ({
    type: workflowsConstants.ADD_ATTACHMENT_QUESTION_FAILURE,
    error,
    files: questionIds
  });
}

export function addAttachmentToQuestion(workflowId, questionId, attachment) {
  return dispatch => {
    const questionIds = [questionId];
    const data = new FormData();
    data.append('attachment', attachment);

    dispatch(addAttachmentToQuestionRequest(questionIds));
    return WorkflowsService.addAttachmentToQuestion(
      workflowId,
      questionId,
      data
    )
      .then(updatedWorkflow => {
        dispatch(
          addAttachmentToQuestionSuccess(
            normalize(updatedWorkflow),
            questionIds
          )
        );
        return updatedWorkflow.questionnaire.find(
          question => question._id === questionId
        );
      })
      .catch(
        handleError(dispatch, addAttachmentToQuestionFailure(questionIds))
      );
  };
}

function addFileToSupplierRequest(supplierId) {
  return {
    type: workflowsConstants.ADD_FILE_SUPPLIER_REQUEST,
    files: [supplierId]
  };
}

function addFileToSupplierSuccess(response, supplierId) {
  return {
    type: workflowsConstants.ADD_FILE_SUPPLIER_SUCCESS,
    response,
    files: [supplierId]
  };
}

function addFileToSupplierFailure(supplierId) {
  return error => ({
    type: workflowsConstants.ADD_FILE_SUPPLIER_FAILURE,
    error,
    files: [supplierId]
  });
}

export function addFileToSupplier(workflowId, supplierId, file) {
  return dispatch => {
    const data = new FormData();
    data.append('file', file);

    dispatch(addFileToSupplierRequest(supplierId));
    return WorkflowsService.addFileToSupplier(workflowId, supplierId, data)
      .then(updatedWorkflow =>
        dispatch(
          addFileToSupplierSuccess(normalize(updatedWorkflow), supplierId)
        )
      )
      .catch(handleError(dispatch, addFileToSupplierFailure()));
  };
}

function deleteFileFromSupplierRequest() {
  return {
    type: workflowsConstants.DELETE_FILE_SUPPLIER_REQUEST
  };
}

function deleteFileFromSupplierSuccess(response) {
  return {
    type: workflowsConstants.DELETE_FILE_SUPPLIER_SUCCESS,
    response
  };
}

function deleteFileFromSupplierFailure() {
  return error => ({
    type: workflowsConstants.DELETE_FILE_SUPPLIER_FAILURE,
    error
  });
}

export function deleteFileFromSupplier(workflowId, supplierId, fileId) {
  return dispatch => {
    dispatch(deleteFileFromSupplierRequest(supplierId));
    return WorkflowsService.deleteFileFromSupplier(
      workflowId,
      supplierId,
      fileId
    )
      .then(updatedWorkflow =>
        dispatch(
          deleteFileFromSupplierSuccess(normalize(updatedWorkflow), supplierId)
        )
      )
      .catch(handleError(dispatch, deleteFileFromSupplierFailure()));
  };
}

function screenSupplierRequest(supplier) {
  return {
    type: workflowsConstants.SCREEN_SUPPLIER_REQUEST,
    supplier
  };
}

function screenSupplierSuccess(response) {
  return {
    type: workflowsConstants.SCREEN_SUPPLIER_SUCCESS,
    response
  };
}

function screenSupplierFailure(supplier) {
  return error => ({
    type: workflowsConstants.SCREEN_SUPPLIER_FAILURE,
    error,
    supplier
  });
}

export function screenSupplier(workflowId, supplier, shareholders = []) {
  return dispatch => {
    const newSupplier = new Supplier(supplier);
    newSupplier.compliance = (newSupplier.compliance || []).concat({
      request: { requested_at: new Date() }
    });
    dispatch(screenSupplierRequest(newSupplier));
    return WorkflowsService.screenSupplier(
      workflowId,
      newSupplier.getId(),
      shareholders
    )
      .then(updatedWorkflow => {
        dispatch(screenSupplierSuccess(normalize(updatedWorkflow)));
        dispatch(reloadUser());
      })
      .catch(error => {
        Toast.showWarning('An error occurred while screening company');
        handleError(dispatch, screenSupplierFailure(supplier))(error);
      });
  };
}
