import React from 'react';
import PropTypes from 'prop-types';

import './FieldValidationMessage.css';

function FieldValidationMessage({ touched, error, warning }) {
  if (!touched || (!error && !warning)) {
    return null;
  }

  return (
    touched &&
    ((error && (
      <span className="FieldValidationMessage FieldValidationMessage--error">
        {error}
      </span>
    )) ||
      (warning && (
        <span className="FieldValidationMessage FieldValidationMessage--warning">
          {warning}
        </span>
      )))
  );
}

FieldValidationMessage.propTypes = {
  touched: PropTypes.bool.isRequired,
  error: PropTypes.string,
  warning: PropTypes.string
};

export default FieldValidationMessage;
