import React from 'react';
import { Localize } from 'react-common/services';
import { onboardingLinks } from '../../../_constants/onboarding';

import cialdnblogo from '../../../_images/Cial_D&B_Logo-White.svg';
import linkedin from '../../../_images/linkedin.png';
import facebook from '../../../_images/facebook.png';

import './OnboardingFooter.css';

const OnboardingFooter = () => (
  <div className="container-fluid Onboarding-footer">
    <div className="Onboarding-footer-content">
      <img src={cialdnblogo} alt="Supplier360" className="Logo-cialdnb" />
      <div className="Links">
        <span>Cial Dun & Bradstreet, Inc.</span>
        <a
          href={onboardingLinks.privacy[Localize.getLanguage()]}
          className="footer-link"
        >
          Privacy policy
        </a>
        {' | '}
        <a
          href={onboardingLinks.terms[Localize.getLanguage()]}
          className="footer-link-no-margin"
        >
          Terms of use
        </a>
      </div>

      <div className="Social">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/cial-dun-&-bradstreet/"
        >
          <img src={linkedin} className="media-icons" alt="LinkedIn" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/cialdnb/"
        >
          <img src={facebook} className="media-icons" alt="Facebook" />
        </a>
      </div>
    </div>
  </div>
);

export default OnboardingFooter;
