import React from 'react';
import { Sidebar } from 'react-common/components';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import s360Logo from '../_images/logo_side-menu.svg';
import PublicHeader from '../_components/Header/PublicHeader';
import './Login.css';

const LoginForm = ({ handleSubmit, submitting, login: { error } }) => (
  <div>
    <Sidebar
      logo={s360Logo}
      logoAlt="Supplier360"
      className="col-md-3 hidden-sm hidden-xs"
      headerText="Login to your account"
    />
    <div className="col-md-9">
      <div className="row">
        <PublicHeader />
        <div className="col-md-5 col-md-offset-3">
          <form onSubmit={handleSubmit} className="Login-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                className="form-control Login-input"
                name="email"
                component="input"
                placeholder="example@cialdnb.com"
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field
                type="password"
                className="form-control Login-input"
                name="password"
                component="input"
                placeholder="Enter Password"
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <Link
                to="/forgot-password"
                className="btn-forgot-pass pull-right"
              >
                Forgot password?
              </Link>
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={submitting}
                className="Btn-default Btn-default-primary form-control"
              >
                Login
              </button>
            </div>
            {error && (
              <div
                className="alert alert-danger alert-dismissible"
                role="alert"
              >
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  </div>
);

const Login = reduxForm({
  form: 'login'
})(LoginForm);

export default Login;
