import React, { Component } from 'react';
import { ComponentWithLoader } from 'react-common/components';
import { connect } from 'react-redux';

import {
  addFileToSupplier,
  download,
  deleteFileFromSupplier,
  getWorkflow,
  requestBirReport,
  requestCcrReport,
  updateHeaderBreadcrumbs,
  changeStatusForSupplier,
  screenSupplier
} from '../../_actions';
import WorkflowsService from '../../_services/Workflows';

import PageNotFound from '../../PageNotFound/PageNotFound';
import WorkflowsSupplierDetails from './WorkflowsSupplierDetails';

class WorkflowsSupplierDetailsContainer extends Component {
  componentDidMount() {
    const {
      dispatchGetWorkflow,
      dispatchUpdateHeaderBreadcrumbs,
      workflow,
      workflowId,
      headerTitles
    } = this.props;

    if (!workflow) {
      dispatchGetWorkflow(workflowId);
    }

    dispatchUpdateHeaderBreadcrumbs(headerTitles);
  }

  componentDidUpdate(prevProps) {
    const { dispatchUpdateHeaderBreadcrumbs, headerTitles } = this.props;

    if (headerTitles.length !== prevProps.headerTitles.length) {
      dispatchUpdateHeaderBreadcrumbs(headerTitles);
    }
  }

  downloadAllFiles = () => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    const url = `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/results`;
    const fileName = supplier.getCompanyName() + '_report';

    return dispatchDownload(url, fileName);
  };

  downloadBirFile = (fileId, fileName) => () => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    const url = `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/bir/${fileId}`;
    return dispatchDownload(url, fileName);
  };

  downloadAdditionalFile = (fileId, fileName) => () => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    const url = `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/additional/${fileId}`;
    return dispatchDownload(url, fileName);
  };

  downloadFileInput = (questionId, fileName) => () => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    return dispatchDownload(
      `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/question/${questionId}`,
      fileName
    );
  };

  requestBir = () => {
    const { dispatchRequestBirReport, workflowId, supplier } = this.props;
    return dispatchRequestBirReport(workflowId, supplier);
  };

  requestCcr = () => {
    const { dispatchRequestCcrReport, workflowId, supplier } = this.props;
    return dispatchRequestCcrReport(workflowId, supplier.getId());
  };

  uploadSupplierFile = file => {
    const { dispatchAddFileToSupplier, workflowId, supplier } = this.props;
    return dispatchAddFileToSupplier(workflowId, supplier.getId(), file);
  };

  deleteSupplierFile = fileId => {
    const { dispatchDeleteFileFromSupplier, workflowId, supplier } = this.props;
    return dispatchDeleteFileFromSupplier(workflowId, supplier.getId(), fileId);
  };

  downloadSupplierFile = (fileId, fileName) => () => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    const url = `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/files/${fileId}`;
    return dispatchDownload(url, fileName);
  };

  changeSupplierStatus = () => {
    const { dispatchChangeStatusForSupplier, workflow, supplier } = this.props;
    return dispatchChangeStatusForSupplier(workflow.getId(), supplier);
  };

  screenSupplier = (shareholders = []) => {
    const { dispatchScreenSupplier, workflowId, supplier } = this.props;
    return dispatchScreenSupplier(workflowId, supplier, shareholders);
  };

  downloadCfrReport = (reportId, reportType = 'html') => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    const url = `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/compliance/${reportId}?type=${reportType}`;
    return dispatchDownload(
      url,
      `CFR - ${supplier.getCompanyName()}.${reportType}`
    );
  };

  downloadReportFiles = () => {
    const {
      dispatchDownload,
      isDownloading,
      workflowId,
      supplier
    } = this.props;

    if (isDownloading) {
      return;
    }

    const url = `/api/workflows/${workflowId}/suppliers/${supplier.getId()}/reports`;
    return dispatchDownload(url, `${supplier.getCompanyName()} - Report`);
  };

  render() {
    const { showPageNotFound, showLoader } = this.props;
    if (showPageNotFound) {
      return <PageNotFound className="row" />;
    }

    return (
      <ComponentWithLoader showLoader={showLoader}>
        <WorkflowsSupplierDetails
          {...this.props}
          requestBir={this.requestBir}
          requestCcr={this.requestCcr}
          downloadAllFiles={this.downloadAllFiles}
          downloadBirFile={this.downloadBirFile}
          downloadFileInput={this.downloadFileInput}
          downloadAdditionalFile={this.downloadAdditionalFile}
          uploadSupplierFile={this.uploadSupplierFile}
          deleteSupplierFile={this.deleteSupplierFile}
          downloadSupplierFile={this.downloadSupplierFile}
          screenSupplier={this.screenSupplier}
          changeSupplierStatus={this.changeSupplierStatus}
          downloadCfrReport={this.downloadCfrReport}
          downloadReportFiles={this.downloadReportFiles}
        />
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    workflows: {
      getById,
      suppliers,
      isFetching,
      isDownloading,
      uploadingFiles
    },
    user: { data: { user } }
  } = state;
  const {
    workflow_id: workflowId,
    supplier_id: supplierId
  } = props.match.params;
  const workflow = getById[workflowId];
  const supplier = workflow && suppliers.getById[supplierId];
  let headerTitles = [];

  const questionnaire =
    WorkflowsService.getQuestionnaireAnswers(workflow, supplier) || [];

  if (workflow && supplier) {
    const category = WorkflowsService.getWorkflowCategoryForStatus(
      workflow.getStatus()
    );
    headerTitles = [
      {
        title: category.headerTitle,
        path: category.path
      },
      {
        title: workflow.getTitle(),
        path: `/workflows/${workflow._id}/suppliers`,
        notranslate: true
      },
      {
        title: supplier.getCompanyName(),
        notranslate: true
      }
    ];
  }

  return {
    workflowId,
    workflow,
    user,
    supplier,
    questionnaire,
    headerTitles,
    showPageNotFound: !supplier && !isFetching,
    showLoader: !supplier || isFetching,
    isDownloading,
    uploadingFiles
  };
};

const mapDispatchToProps = {
  dispatchGetWorkflow: getWorkflow,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchDownload: download,
  dispatchRequestBirReport: requestBirReport,
  dispatchRequestCcrReport: requestCcrReport,
  dispatchAddFileToSupplier: addFileToSupplier,
  dispatchDeleteFileFromSupplier: deleteFileFromSupplier,
  dispatchChangeStatusForSupplier: changeStatusForSupplier,
  dispatchScreenSupplier: screenSupplier
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsSupplierDetailsContainer
);
