import has from 'lodash/has';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import moment from 'moment';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {
  DunsAbbr,
  MomentDate,
  SingleModal,
  Tooltip
} from 'react-common/components';
import {
  financialStrengthOrder,
  languages,
  racRiskIndicators,
  racRiskIndicatorsOrder,
  riskIndicators,
  riskIndicatorsOrder,
  supplierStatuses,
  workflowReportTypes,
  workflowTypes,
  supplierStatusUpdateTypes,
  statusUpdateTypeToText,
  statusUpdateTypesOrder,
  financialStrengthClasses,
  riskIndicatorsClasses,
  racRiskIndicatorsClasses
} from 'react-common/constants';
import { Localize, Util } from 'react-common/services';
import OutsideClickHandler from 'react-outside-click-handler';
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  ccrScoreClasses,
  paydexGroupClasses,
  supplierColumns,
  supplierColumnsText
} from '../../_constants/workflows';
import TagsDropdown from '../TagsDropdown/TagsDropdown';
import TagsManagementDropdown from '../TagsManagementDropdown/TagsManagementDropdown';
import WorkflowsService from '../../_services/Workflows';
import RatingsModal from '../RatingsModal/RatingsModal';
import CcrModal from '../CcrModal/CcrModal';
import PaydexModal from '../PaydexModal/PaydexModal';
import StatusModal from '../StatusModal/StatusModal';
import ScreenModal from '../ScreenModal/ScreenModal';
import {
  changeStatusForSupplier,
  deleteSupplier as deletePendingSupplier,
  deleteSupplierTag,
  download,
  requestBirReport,
  requestCcrReport,
  screenSupplier,
  sendReminderToSupplier,
  setSupplierTags,
  setTagsToSupplier,
  updateSupplier
} from '../../_actions';
import { connect } from 'react-redux';
import { User } from '../../_models/user';

import './SuppliersTable.css';

class SuppliersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRatingsModal: false,
      showPaydexModal: false,
      showCcrModal: false,
      showStatusModal: false,
      showDeleteSupplierModal: false,
      pendingSupplierToDelete: undefined,
      showScreenModal: false,
      supplierToScreen: undefined
    };
  }

  statusToText = {
    all: 'All',
    [supplierStatuses.PENDING]: 'Pending',
    [supplierStatuses.IN_PROGRESS]: 'In Progress',
    [supplierStatuses.COMPLETED]: 'Completed',
    [supplierStatuses.ARCHIVED]: 'Archived'
  };

  requestCcrReport = supplier => () =>
    this.props.dispatchRequestCcrReport(
      supplier.getWorkflowId(),
      supplier.getId()
    );

  downloadReportForSupplier = supplier => () => {
    const { dispatchDownload, isDownloading } = this.props;

    if (isDownloading || !supplier.hasAnyFilesForDownload()) {
      return;
    }

    const url = `/api/workflows/${supplier.getWorkflowId()}/suppliers/${supplier.getId()}/results`;
    return dispatchDownload(url, supplier.getCompanyName() + '_report');
  };

  changeSupplierStatus = supplier => () => {
    const { dispatchChangeStatusForSupplier } = this.props;
    return dispatchChangeStatusForSupplier(supplier.getWorkflowId(), supplier);
  };

  sendReminder = supplier => () => {
    const { dispatchSendReminderToSupplier } = this.props;

    return dispatchSendReminderToSupplier(
      supplier.getWorkflowId(),
      supplier.getId()
    );
  };

  setTagsToSupplier = (supplier, tags) => {
    const { dispatchSetTagsToSupplier } = this.props;
    return dispatchSetTagsToSupplier(
      supplier.getWorkflowId(),
      supplier,
      tags.map(tag => tag.value)
    );
  };

  createTagForSupplier = (supplier, tag) => {
    const {
      user,
      dispatchSetSupplierTags,
      dispatchUpdateSupplier
    } = this.props;

    return dispatchSetSupplierTags(
      user,
      user.getSupplierTags().concat({ name: tag })
    ).then(({ user }) => {
      const updatedTags = new User(user).getSupplierTags();
      const tagObj = updatedTags.find(t => t.name === tag);
      return dispatchUpdateSupplier(
        supplier.getWorkflowId(),
        supplier.getId(),
        {
          tags: supplier.getTags().concat(tagObj._id)
        }
      );
    });
  };

  updateTag = (tagId, updatedTag) => {
    const { user, dispatchSetSupplierTags } = this.props;

    const tags = [...user.getSupplierTags()];
    const tagIndex = tags.findIndex(tag => tag._id === tagId);

    if (tagIndex === -1) {
      return;
    }

    tags[tagIndex] = updatedTag;

    return dispatchSetSupplierTags(user, tags);
  };

  createUntitledTag = () => {
    const { user, dispatchSetSupplierTags } = this.props;
    const tags = [
      ...user.getSupplierTags(),
      { name: Localize.translate('Untitled') }
    ];
    return dispatchSetSupplierTags(user, tags);
  };

  deleteTag = tagId => {
    const { user, dispatchDeleteSupplierTag } = this.props;
    return dispatchDeleteSupplierTag(user, tagId);
  };

  requestBirReport = supplier => () => {
    const { dispatchRequestBirReport } = this.props;
    return dispatchRequestBirReport(supplier.getWorkflowId(), supplier);
  };

  screenSupplier = supplier => (shareholders = []) => {
    const { dispatchScreenSupplier } = this.props;
    return dispatchScreenSupplier(
      supplier.getWorkflowId(),
      supplier,
      shareholders
    );
  };

  deleteSupplier = (workflowId, supplierId) => {
    const { dispatchDeletePendingSupplier } = this.props;

    return dispatchDeletePendingSupplier(workflowId, supplierId);
  };

  handleHeaderClick = onClick => event => {
    event.stopPropagation();
    onClick(event);
  };

  hideRatingsModal = () => this.setState({ showRatingsModal: false });
  showRatingsModal = () => this.setState({ showRatingsModal: true });

  hidePaydexModal = () => this.setState({ showPaydexModal: false });
  showPaydexModal = () => this.setState({ showPaydexModal: true });

  hideStatusModal = () => this.setState({ showStatusModal: false });
  showStatusModal = () => this.setState({ showStatusModal: true });

  hideCcrModal = () => this.setState({ showCcrModal: false });
  showCcrModal = () => this.setState({ showCcrModal: true });

  hideScreenModal = () =>
    this.setState({ showScreenModal: false, supplierToScreen: undefined });
  showScreenModal = supplierToScreen =>
    this.setState({ showScreenModal: true, supplierToScreen });

  hideDeleteSupplierModal = () =>
    this.setState({
      showDeleteSupplierModal: false,
      pendingSupplierToDelete: undefined
    });

  showDeleteSupplierModal = pendingSupplierToDelete =>
    this.setState({
      showDeleteSupplierModal: true,
      pendingSupplierToDelete
    });

  emptyTableMessage = (supplierStatus, searchQuery, isFilterActive) => {
    if (searchQuery) {
      return 'There are no suppliers that match your search query';
    }
    if (isFilterActive) {
      return 'There are no suppliers that match your filter criteria';
    }
    switch (supplierStatus) {
      case supplierStatuses.PENDING:
        return 'There are currently no suppliers with status pending';
      case supplierStatuses.IN_PROGRESS:
        return 'There are currently no suppliers with status in progress';
      case supplierStatuses.COMPLETED:
        return 'There are currently no suppliers with status completed';
      case supplierStatuses.ARCHIVED:
        return 'There are currently no archived suppliers';
      default:
        return 'There are currently no suppliers';
    }
  };

  noTranslate = () => ({ notranslate: 'true' });

  title = title => ({ title });

  titleAndNoTranslate = title => ({ title, notranslate: 'true' });

  capitalized = () => 'capitalized';

  textCenter = () => 'text-center';

  renderCompanyName = (_, supplier) => (
    <Link
      to={`/workflows/${supplier.getWorkflowId()}/suppliers/${supplier.getId()}`}
      className="Table-link"
    >
      {supplier.getCompanyName() || Localize.translate('Untitled')}
    </Link>
  );

  renderWorkflowTitle = (_, supplier) => (
    <Link
      to={`/workflows/${supplier.getWorkflowId()}/suppliers`}
      className="Table-link"
    >
      {supplier.getWorkflowTitle()}
    </Link>
  );

  renderStatus = (_, supplier) => {
    const status = supplier.getLastStatusUpdate();
    if (!status) {
      return null;
    }

    let supplierStatus = supplier.getStatus();

    // handle Vale flow
    if (
      supplierStatus === supplierStatuses.COMPLETED &&
      status.name === supplierStatusUpdateTypes.CREATING_REPORT
    ) {
      supplierStatus = supplierStatuses.IN_PROGRESS;
    }

    const supplierStatusToClass = {
      [supplierStatuses.PENDING]: 'Bg-grey',
      [supplierStatuses.IN_PROGRESS]: 'Bg-light-orange',
      [supplierStatuses.COMPLETED]: 'Bg-deep-mint',
      [supplierStatuses.ARCHIVED]: 'Bg-deep-mint'
    };

    return (
      <Tooltip
        text={
          <MomentDate
            format="MMM DD, YYYY"
            locale={languages[Localize.getLanguage()].uploadcare}
            date={status.created_at}
          />
        }
        position="bottom"
        id="status-update-tooltip"
      >
        <span className={`Badge ${supplierStatusToClass[supplierStatus]}`}>
          {statusUpdateTypeToText[status.name]}
        </span>
      </Tooltip>
    );
  };

  renderStatusHeader = (column, colIndex, { sortElement }) => (
    <>
      {!this.props.multipleWorkflows && (
        <i
          className="icon-ic_question Icon-question"
          onClick={this.handleHeaderClick(this.showStatusModal)}
        />
      )}

      <span>Status</span>
      {sortElement}
    </>
  );

  renderTags = (_, supplier) => {
    const { user } = this.props;
    const tags = supplier.getTags();

    if (!tags || !tags.length) {
      return null;
    }

    const tagsById = user.getSupplierTagsMap();

    return tags.filter(tag => tagsById[tag]).map((tag, index) => (
      <span
        key={index}
        className="Badge Tags-badge"
        style={{ backgroundColor: tagsById[tag].color }}
      >
        {tagsById[tag].name}
      </span>
    ));
  };

  renderEditTags = (editorProps, value, supplier) => {
    const { user } = this.props;
    const tagsById = user.getSupplierTagsMap();
    const supplierTags = supplier
      .getTags()
      .filter(tag => tagsById[tag])
      .map(tag => tagsById[tag]);
    return (
      <OutsideClickHandler
        onOutsideClick={() => editorProps.onUpdate(supplier.getTags())}
      >
        <TagsDropdown
          availableTags={user.getSupplierTags()}
          selectedTags={supplierTags}
          onChange={tags => this.setTagsToSupplier(supplier, tags)}
          onCreate={tag => this.createTagForSupplier(supplier, tag)}
        />
      </OutsideClickHandler>
    );
  };

  renderTagsHeader = () => {
    const { user } = this.props;
    const tags = user.getSupplierTags();

    return (
      <>
        <span>Labels</span>
        <TagsManagementDropdown
          tags={tags}
          onCreate={this.createUntitledTag}
          onDelete={this.deleteTag}
          onUpdate={this.updateTag}
        />
      </>
    );
  };

  renderFinancialStrength = (_, supplier) => {
    const financialStrength = supplier.getFinancialStrength();
    if (!financialStrength) {
      return null;
    }

    return (
      <div
        className={`Square-badge Bg-${financialStrengthClasses[
          financialStrength
        ] || 'grey'}`}
      >
        {financialStrength}
      </div>
    );
  };

  renderRiskIndicator = (_, supplier) => {
    const riskIndicator = supplier.getRiskIndicator();
    if (!riskIndicator) {
      return null;
    }
    return (
      <Tooltip
        text={riskIndicators[riskIndicator]}
        position="bottom"
        id="risk-indicator-tooltip"
      >
        <div
          className={`Square-badge Bg-${riskIndicatorsClasses[riskIndicator] ||
            'light-grey'}`}
        >
          {riskIndicator}
        </div>
      </Tooltip>
    );
  };

  renderRacRiskIndicator = (_, supplier) => {
    if (!supplier.isCompleted()) {
      return null;
    }
    let riskIndicator = supplier.getRacRiskIndicator();

    const racRiskIndicatorClass = riskIndicator
      ? racRiskIndicatorsClasses[supplier.getRacRiskIndicator()] ||
        racRiskIndicatorsClasses['-']
      : 'light-grey';

    return (
      <Tooltip
        text={racRiskIndicators[riskIndicator]}
        position="bottom"
        id="rac-risk-indicator-tooltip"
      >
        <div className={`Square-badge Bg-${racRiskIndicatorClass}`}>
          {riskIndicator}
        </div>
      </Tooltip>
    );
  };

  renderDnbRating = (value, supplier) => {
    const financialStrength = supplier.getFinancialStrength();
    const dnbRating = supplier.getDnbRating();
    if (!dnbRating) {
      return null;
    }
    return (
      <div
        className={`Square-badge Bg-${financialStrengthClasses[
          financialStrength
        ] || 'grey'}`}
      >
        {dnbRating}
      </div>
    );
  };

  renderDate = date =>
    date ? (
      <MomentDate
        format="MMMM DD, YYYY"
        locale={languages[Localize.getLanguage()].uploadcare}
        date={date}
        timezone="UTC"
      />
    ) : null;

  renderWebsite = (_, supplier) => {
    const website = supplier.getWebsite();
    if (!website) {
      return null;
    }
    return (
      <a
        href={Util.wrapWithHttp(website)}
        className="Website"
        title={website}
        target="_blank"
        rel="noopener noreferrer"
      >
        {website}
      </a>
    );
  };

  renderScreenStatus = (_, supplier) => {
    const screening = supplier.getLastScreening();
    const hasData = has(screening, 'data');

    if (!screening) {
      return null;
    }

    if (!hasData) {
      return <span className="Badge Bg-deep-mint">In progress...</span>;
    }

    return (
      <span
        className={`Badge ${
          supplier.isMatch() ? 'Bg-light-orange' : 'Bg-grey'
        }`}
      >
        {supplier.isMatch() ? 'Match' : 'No Match'}
      </span>
    );
  };

  renderPaydex = (_, supplier) => {
    const paydex = supplier.getPaydex();
    if (!paydex) {
      return null;
    }
    return (
      <Tooltip
        text={WorkflowsService.getPaydexDescription(paydex)}
        position="bottom"
        id="paydex-tooltip"
      >
        <div
          className={`Square-badge Bg-${
            paydexGroupClasses[WorkflowsService.getPaydexGroup(paydex)]
          }`}
        >
          {WorkflowsService.isValidPaydex(paydex) ? paydex : 'UN'}
        </div>
      </Tooltip>
    );
  };

  renderPaydexHeader = (column, colIndex, { sortElement }) => (
    <>
      <i
        className="icon-ic_question Icon-question"
        onClick={this.handleHeaderClick(this.showPaydexModal)}
      />
      <span>Paydex</span>
      {sortElement}
    </>
  );

  renderCcr = (_, supplier) => {
    const ccrScore = supplier.getCcrScore();

    if (!ccrScore) {
      return null;
    }

    return (
      <div
        className={
          ccrScoreClasses[ccrScore]
            ? `Square-badge Bg-${ccrScoreClasses[ccrScore]}`
            : ''
        }
        notranslate="true"
      >
        {ccrScore}
      </div>
    );
  };

  renderCcrStatus = (_, supplier) => {
    if (!supplier.isCcrRequested()) {
      return <div className="Badge Bg-grey">Not requested</div>;
    }

    const ccrScore = supplier.getCcrScore();

    if (!ccrScore) {
      return (
        <Tooltip
          text={
            <MomentDate
              format="MMM DD, YYYY"
              locale={languages[Localize.getLanguage()].uploadcare}
              date={supplier.getCcrRequestedDate()}
            />
          }
          position="bottom"
          id={`ccr-pending-date-${supplier.getId()}`}
        >
          <div className="Badge Bg-light-orange">Pending</div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        text={
          <MomentDate
            format="MMM DD, YYYY"
            locale={languages[Localize.getLanguage()].uploadcare}
            date={supplier.getCcrDeliveredDate()}
          />
        }
        position="bottom"
        id={`ccr-delivered-date-${supplier.getId()}`}
      >
        <div className="Badge Bg-deep-mint">Delivered</div>;
      </Tooltip>
    );
  };

  renderCcrScoreHeader = (column, colIndex, { sortElement }) => (
    <>
      <i
        className="icon-ic_question Icon-question"
        onClick={this.handleHeaderClick(this.showCcrModal)}
      />
      <span>CCR score</span>
      {sortElement}
    </>
  );

  renderQuestionnaireStatus = (_, supplier) => {
    const workflow = supplier.getWorkflow();

    if (!workflow.hasQuestionnaire()) {
      return null;
    }

    if (!supplier.getQuestionnaireStartedDate()) {
      return <div className="Badge Bg-grey">Not started</div>;
    }

    if (!supplier.getQuestionnaireCompletedDate()) {
      return (
        <Tooltip
          text={
            <MomentDate
              format="MMM DD, YYYY"
              locale={languages[Localize.getLanguage()].uploadcare}
              date={supplier.getQuestionnaireStartedDate()}
            />
          }
          position="bottom"
          id={`questionnaire-date-${supplier.getId()}`}
        >
          <div className="Badge Bg-light-orange">Started</div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        text={
          <MomentDate
            format="MMM DD, YYYY"
            locale={languages[Localize.getLanguage()].uploadcare}
            date={supplier.getQuestionnaireCompletedDate()}
          />
        }
        position="bottom"
        id={`questionnaire-date-${supplier.getId()}`}
      >
        <div className="Badge Bg-deep-mint">Completed</div>
      </Tooltip>
    );
  };

  renderRecords = records => {
    if (!records) {
      return null;
    }
    return (
      <Tooltip
        text="Lawsuits / Legal Issues"
        position="bottom"
        id="record-tooltip"
      >
        <span>{records}</span>
      </Tooltip>
    );
  };

  renderDownload = supplier => {
    const { isDownloading } = this.props;
    const workflow = supplier.getWorkflow();
    return (
      <Tooltip
        id={`download-${supplier.getId()}-tooltip`}
        text={
          workflow.getType() === workflowTypes.INVESTIGATING
            ? 'Download report'
            : 'Download results'
        }
        position="bottom"
      >
        <div className="BtnIconsTable">
          <i
            className={
              'icon-ic_download Download-icon' +
              (isDownloading || !supplier.hasAnyFilesForDownload()
                ? ' disabled'
                : '')
            }
            onClick={this.downloadReportForSupplier(supplier)}
          />
        </div>
      </Tooltip>
    );
  };

  renderScreen = supplier => (
    <Tooltip
      id={`screen-${supplier.getId()}-tooltip`}
      text={supplier.hasScreening() ? 'Re-Screen Company' : 'Screen Company'}
      position="bottom"
    >
      <div className="BtnIconsTable">
        <i
          className={
            'icon-ic_screen Screen-icon' +
            (supplier.isScreeningInProgress() ? ' disabled' : '')
          }
          onClick={() =>
            !supplier.isScreeningInProgress() && this.showScreenModal(supplier)
          }
        />
      </div>
    </Tooltip>
  );

  renderReminder = supplier => {
    const now = moment(new Date());

    const lastReminder = supplier.getLatestReminder();

    const isSupplierReminded = lastReminder
      ? now.diff(moment(lastReminder.reminded_at), 'hours') < 24
      : false;

    return (
      <Tooltip
        id={
          (isSupplierReminded ? 'reminder-sent-' : 'reminder-') +
          `${supplier.getId()}-tooltip`
        }
        text={
          isSupplierReminded
            ? 'Reminder sent! Next available after 24 hours'
            : 'Remind'
        }
        position="bottom"
      >
        <div className="BtnIconsTable">
          <i
            className={
              'icon-ic_bell Reminder-icon' +
              (isSupplierReminded ? ' disabled' : '')
            }
            onClick={isSupplierReminded ? null : this.sendReminder(supplier)}
          />
        </div>
      </Tooltip>
    );
  };

  renderDeleteSupplier = supplier => (
    <Tooltip
      id={`delete-supplier-${supplier.getId()}-tooltip`}
      text="Delete Supplier"
      position="bottom"
    >
      <div className="BtnIconsTable">
        <i
          className="icon-ic_close Delete-supplier-btn"
          onClick={() => this.showDeleteSupplierModal(supplier)}
        />
      </div>
    </Tooltip>
  );

  renderPendingActions = supplier => {
    return (
      <>
        {this.renderReminder(supplier)}
        {this.renderDeleteSupplier(supplier)}
      </>
    );
  };

  renderCcrActions = supplier => {
    const { user } = this.props;
    const ccrProduct = user.getCcrProduct();
    const ccrLimitReached = ccrProduct.used >= ccrProduct.total;

    if (supplier.getCcrScore()) {
      return null;
    }

    if (supplier.isCcrRequested()) {
      return (
        <Tooltip
          text="CCR requested"
          position="bottom"
          id={`ccr-requested-${supplier.getId()}-tooltip`}
        >
          <div className="BtnIconsTable">
            <i className="icon-ic_bir Request-btn disabled" />
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        id={`request-ccr-${supplier.getId()}-tooltip`}
        text={
          ccrLimitReached
            ? 'You reached product limit. Please contact support for upgrade.'
            : 'Request CCR'
        }
        position="bottom"
      >
        <div className="BtnIconsTable">
          <i
            className={
              'icon-ic_bir Request-btn' + (ccrLimitReached ? ' disabled' : '')
            }
            onClick={
              ccrLimitReached ? undefined : this.requestCcrReport(supplier)
            }
          />
        </div>
      </Tooltip>
    );
  };

  renderRequestBirOrDownload = supplier => {
    const { user } = this.props;

    const workflow = supplier.getWorkflow();
    const productCount = user.getProduct(workflowReportTypes.BIR);
    const productLimitReached = productCount.used >= productCount.total;

    if (workflow.isOnboarding()) {
      return this.renderDownload(supplier);
    }

    if (!supplier.getBirRequestId()) {
      if (user.isCompanyAdmin()) {
        return (
          <Tooltip
            id={`request-bir-${supplier.getId()}-tooltip`}
            text={
              productLimitReached
                ? 'You reached product limit. Please contact support for upgrade.'
                : 'Request BIR'
            }
            position="bottom"
          >
            <div className="BtnIconsTable">
              <i
                className={
                  'icon-ic_bir Request-btn' +
                  (productLimitReached ? ' disabled' : '')
                }
                onClick={
                  productLimitReached
                    ? undefined
                    : this.requestBirReport(supplier)
                }
              />
            </div>
          </Tooltip>
        );
      }
    } else if (supplier.hasBirFiles()) {
      return this.renderDownload(supplier);
    }

    return (
      <Tooltip
        text="BIR requested"
        position="bottom"
        id={`bir-requested-${supplier.getId()}-tooltip`}
      >
        <div className="BtnIconsTable">
          <i className="icon-ic_bir Request-btn disabled" />
        </div>
      </Tooltip>
    );
  };

  renderArchive = supplier => {
    const { user } = this.props;
    if (!user.isCompanyAdmin()) {
      return null;
    }

    return (
      <Tooltip
        id={`archive-supplier-${supplier.getId()}-tooltip`}
        text={
          supplier.getStatus() === supplierStatuses.COMPLETED
            ? 'Archive'
            : 'Unarchive'
        }
        position="bottom"
      >
        <div className="BtnIconsTable">
          <i
            className="icon-ic_archive Archive-icon"
            onClick={this.changeSupplierStatus(supplier)}
          />
        </div>
      </Tooltip>
    );
  };

  renderCompletedActions = supplier => {
    const { user } = this.props;
    const workflow = supplier.getWorkflow();

    return (
      <>
        {!workflow.isCfrReport() && (
          <>
            {user.isCcrProductEnabled() && this.renderCcrActions(supplier)}
            {this.renderRequestBirOrDownload(supplier)}
          </>
        )}
        {user.isComplianceEnabled() && this.renderScreen(supplier)}
        {this.renderArchive(supplier)}
      </>
    );
  };

  renderActions = (_, supplier) => {
    const workflow = supplier.getWorkflow();
    const supplierStatus = supplier.getStatus();

    if (
      supplierStatus === supplierStatuses.IN_PROGRESS ||
      (workflow.getType() === workflowTypes.INVESTIGATING &&
        supplierStatus === supplierStatuses.PENDING)
    ) {
      return null;
    }

    if (supplierStatus === supplierStatuses.PENDING) {
      return this.renderPendingActions(supplier);
    }

    if (
      supplierStatus === supplierStatuses.COMPLETED ||
      supplierStatus === supplierStatuses.ARCHIVED
    ) {
      return this.renderCompletedActions(supplier);
    }
  };

  renderRatingsInfoHeader = headerTitle => (
    column,
    colIndex,
    { sortElement }
  ) => (
    <>
      {!this.props.multipleWorkflows && (
        <i
          className="icon-ic_question Icon-question"
          onClick={this.handleHeaderClick(this.showRatingsModal)}
        />
      )}
      <span>{headerTitle}</span>
      {sortElement}
    </>
  );

  renderScreenInfoHeader = headerTitle => (
    column,
    colIndex,
    { sortElement }
  ) => (
    <>
      <Tooltip
        text={
          'Screen or Re-Screen Company from the actions column. If at least 1 match is found status is "Match"'
        }
        position="bottom"
        id="screen-status-tooltip"
      >
        <i className="icon-ic_question Icon-question" />
      </Tooltip>

      <span>{headerTitle}</span>
      {sortElement}
    </>
  );

  sortFinancialStrength = Util.sortByArrayIndex(financialStrengthOrder);

  sortRiskIndicator = Util.sortByArrayIndex(riskIndicatorsOrder);

  sortRacRiskIndicator = Util.sortByArrayIndex(racRiskIndicatorsOrder);

  sortDnbRating = isRacRating =>
    Util.sortDnbRating(
      financialStrengthOrder,
      isRacRating ? racRiskIndicatorsOrder : riskIndicatorsOrder
    );
  sortStatusUpdates = Util.sortByArrayIndex(statusUpdateTypesOrder);

  financialStrengthSort = (a, b, order) =>
    order === 'asc'
      ? this.sortFinancialStrength(a, b)
      : this.sortFinancialStrength(b, a);

  riskIndicatorSort = (a, b, order) =>
    order === 'asc'
      ? this.sortRiskIndicator(a, b)
      : this.sortRiskIndicator(b, a);

  racRiskIndicatorSort = (a, b, order) =>
    order === 'asc'
      ? this.sortRacRiskIndicator(a, b)
      : this.sortRacRiskIndicator(b, a);

  dnbRatingSort = (a, b, order) => {
    const {
      financial_strength: financialStrengthA,
      risk_indicator: riskIndicatorA,
      rac_risk_indicator: racRiskIndicatorA
    } = a;
    const {
      financial_strength: financialStrengthB,
      risk_indicator: riskIndicatorB,
      rac_risk_indicator: racRiskIndicatorB
    } = b;
    const dnbRatingA =
      financialStrengthA + (riskIndicatorA || racRiskIndicatorA);
    const dnbRatingB =
      financialStrengthB + (riskIndicatorB || racRiskIndicatorB);
    const isRacRating = racRiskIndicatorA && racRiskIndicatorB;

    return order === 'asc'
      ? this.sortDnbRating(isRacRating)(dnbRatingA, dnbRatingB)
      : this.sortDnbRating(isRacRating)(dnbRatingB, dnbRatingA);
  };

  statusUpdatesSort = (a, b, order) => {
    const { name: lastA } = last(a) || {};
    const { name: lastB } = last(b) || {};
    return order === 'asc'
      ? this.sortStatusUpdates(lastA, lastB)
      : this.sortStatusUpdates(lastB, lastA);
  };

  getColumns = () => {
    const { user } = this.props;
    const isScoreProduct = user.isScoreWorkflowProduct();
    return {
      [supplierColumns.COMPANY_NAME]: {
        dataField: 'invite.company_name',
        text: supplierColumnsText[supplierColumns.COMPANY_NAME],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(supplier.getCompanyName()),
        sort: true,
        headerStyle: () => ({ width: '200px' }),
        formatter: this.renderCompanyName,
        editable: false
      },
      [supplierColumns.WORKFLOW_TITLE]: {
        dataField: 'workflow.info.title',
        text: supplierColumnsText[supplierColumns.WORKFLOW_TITLE],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(supplier.getCompanyName()),
        sort: true,
        headerStyle: () => ({ width: '200px' }),
        formatter: this.renderWorkflowTitle,
        editable: false
      },
      [supplierColumns.STATUS]: {
        dataField: 'status_updates',
        text: supplierColumnsText[supplierColumns.STATUS],
        sort: true,
        editable: false,
        sortFunc: this.statusUpdatesSort,
        headerClasses: this.textCenter,
        headerFormatter: this.renderStatusHeader,
        headerStyle: () => ({ width: '250px' }),
        classes: this.textCenter,
        formatter: this.renderStatus
      },
      [supplierColumns.TAGS]: {
        dataField: 'tags',
        text: supplierColumnsText[supplierColumns.TAGS],
        attrs: this.noTranslate,
        sort: false,
        headerStyle: () => ({ width: '250px' }),
        classes: () => 'Tags',
        headerFormatter: this.renderTagsHeader,
        formatter: this.renderTags,
        editorRenderer: this.renderEditTags,
        editCellStyle: () => ({ overflow: 'visible' })
      },
      [supplierColumns.FULL_NAME]: {
        dataField: 'invite.full_name',
        text: supplierColumnsText[supplierColumns.FULL_NAME],
        attrs: this.titleAndNoTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '160px' })
      },
      [supplierColumns.EMAIL]: {
        dataField: 'invite.email',
        text: supplierColumnsText[supplierColumns.EMAIL],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(supplier.getAllEmails().join(', ')),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '160px' }),
        formatter: (_, supplier) => supplier.getAllEmails().join(', ')
      },
      [supplierColumns.COUNTRY]: {
        dataField: 'invite.country',
        text: supplierColumnsText[supplierColumns.COUNTRY],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(Util.capitalize(supplier.getCountry())),
        formatter: Util.capitalize,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '140px' })
      },
      [supplierColumns.ADDRESS]: {
        dataField: 'invite.address',
        text: supplierColumnsText[supplierColumns.ADDRESS],
        attrs: (_, supplier) => this.titleAndNoTranslate(supplier.getAddress()),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' }),
        formatter: (_, supplier) => supplier.getAddress()
      },
      [supplierColumns.SIC]: {
        dataField: 'invite.sic',
        text: supplierColumnsText[supplierColumns.SIC],
        attrs: (_, supplier) => this.titleAndNoTranslate(supplier.getSic()),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '80px' }),
        formatter: (_, supplier) => supplier.getSic()
      },
      [supplierColumns.TAX_ID]: {
        dataField: 'invite.tax_id',
        text: supplierColumnsText[supplierColumns.TAX_ID],
        attrs: (_, supplier) => this.titleAndNoTranslate(supplier.getTaxId()),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '200px' }),
        formatter: (_, supplier) => supplier.getTaxId()
      },
      [supplierColumns.PHONE]: {
        dataField: 'invite.phone',
        text: supplierColumnsText[supplierColumns.PHONE],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(supplier.getPhones().join(', ')),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' }),
        formatter: (_, supplier) => supplier.getPhones().join(', ')
      },
      [supplierColumns.FINANCIAL_STRENGTH]: {
        dataField:
          (isScoreProduct ? 'score' : 'report') + '.financial_strength',
        text: supplierColumnsText[supplierColumns.FINANCIAL_STRENGTH],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        formatter: this.renderFinancialStrength,
        headerFormatter: this.renderRatingsInfoHeader('Financial strength'),
        headerClasses: this.textCenter,
        sortFunc: this.financialStrengthSort,
        headerStyle: () => ({ width: '300px' })
      },
      [supplierColumns.COMMERCIAL_SCORE]: {
        dataField: 'report.commercial_score',
        text: supplierColumnsText[supplierColumns.COMMERCIAL_SCORE],
        attrs: this.noTranslate,
        headerClasses: this.textCenter,
        classes: this.textCenter,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.RISK_INDICATOR]: {
        dataField: (isScoreProduct ? 'score' : 'report') + '.risk_indicator',
        text: supplierColumnsText[supplierColumns.RISK_INDICATOR],
        sort: true,
        editable: false,
        formatter: this.renderRiskIndicator,
        headerFormatter: this.renderRatingsInfoHeader('Risk indicator'),
        headerClasses: this.textCenter,
        classes: this.textCenter,
        sortFunc: this.riskIndicatorSort,
        headerStyle: () => ({ width: '230px' })
      },
      [supplierColumns.RAC_RISK_INDICATOR]: {
        dataField: 'report.rac_risk_indicator',
        text: supplierColumnsText[supplierColumns.RAC_RISK_INDICATOR],
        sort: true,
        editable: false,
        formatter: this.renderRacRiskIndicator,
        headerFormatter: this.renderRatingsInfoHeader(
          supplierColumnsText[supplierColumns.RAC_RISK_INDICATOR]
        ),
        headerClasses: this.textCenter,
        classes: this.textCenter,
        sortFunc: this.racRiskIndicatorSort,
        headerStyle: () => ({ width: '230px' })
      },
      [supplierColumns.DNB_RATING]: {
        dataField: (isScoreProduct ? 'score' : 'report') + '.dnb_rating',
        text: supplierColumnsText[supplierColumns.DNB_RATING],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        formatter: this.renderDnbRating,
        headerFormatter: this.renderRatingsInfoHeader('D&B rating'),
        headerClasses: this.textCenter,
        classes: this.textCenter,
        sortFunc: this.dnbRatingSort,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.DELIVERY_DATE]: {
        dataField: (isScoreProduct ? 'score' : 'report') + '.delivery_date',
        text: supplierColumnsText[supplierColumns.DELIVERY_DATE],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        formatter: (_, supplier) => this.renderDate(supplier.getDeliveryDate()),
        headerClasses: this.textCenter,
        headerStyle: () => ({ width: '180px' }),
        classes: this.textCenter
      },
      [supplierColumns.BALANCE_SHEET_DATE]: {
        dataField:
          (isScoreProduct ? 'score' : 'report') + '.balance_sheet_date',
        text: supplierColumnsText[supplierColumns.BALANCE_SHEET_DATE],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        formatter: (_, supplier) =>
          this.renderDate(supplier.getBalanceSheetDateDate()),
        headerClasses: this.textCenter,
        classes: this.textCenter,
        headerStyle: () => ({ width: '180px' })
      },
      [supplierColumns.INDUSTRY]: {
        dataField: (isScoreProduct ? 'score' : 'report') + '.industry',
        text: supplierColumnsText[supplierColumns.INDUSTRY],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(supplier.getIndustry()),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' }),
        formatter: (_, supplier) => supplier.getIndustry()
      },
      [supplierColumns.DUNS_NUMBER]: {
        dataField: 'report.duns_number',
        text: supplierColumnsText[supplierColumns.DUNS_NUMBER],
        attrs: this.titleAndNoTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' }),
        headerFormatter: () => (
          <>
            <DunsAbbr /> number
          </>
        ),
        formatter: (_, supplier) => supplier.getDunsNumber()
      },
      [supplierColumns.EMMA_SCORE]: {
        dataField: 'report.emma_score',
        text: supplierColumnsText[supplierColumns.EMMA_SCORE],
        attrs: this.titleAndNoTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '120px' })
      },
      [supplierColumns.PAYDEX]: {
        dataField: (isScoreProduct ? 'score' : 'report') + '.paydex',
        text: supplierColumnsText[supplierColumns.PAYDEX],
        attrs: this.noTranslate,
        headerClasses: this.textCenter,
        classes: this.textCenter,
        sort: true,
        editable: false,
        sortFunc: (a, b, order) => (order === 'asc' ? b - a : a - b),
        formatter: this.renderPaydex,
        headerFormatter: this.renderPaydexHeader,
        headerStyle: () => ({ width: '110px' })
      },
      [supplierColumns.NUMBER_OF_SUITS]: {
        dataField: 'report.number_of_suits',
        text: supplierColumnsText[supplierColumns.NUMBER_OF_SUITS],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerClasses: this.textCenter,
        classes: this.textCenter,
        formatter: this.renderRecords,
        headerStyle: () => ({ width: '110px' })
      },
      [supplierColumns.WEBSITE]: {
        dataField: (isScoreProduct ? 'score' : 'report') + '.website',
        text: supplierColumnsText[supplierColumns.WEBSITE],
        attrs: (_, supplier) => this.titleAndNoTranslate(supplier.getWebsite()),
        formatter: this.renderWebsite,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.SHAREHOLDERS]: {
        dataField: 'report.shareholders',
        text: supplierColumnsText[supplierColumns.SHAREHOLDERS],
        attrs: (_, supplier) =>
          this.titleAndNoTranslate(supplier.getShareholders()),
        formatter: (_, supplier) => supplier.getShareholders().join(', '),
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '200px' })
      },
      [supplierColumns.REVENUE]: {
        dataField: 'report.revenue',
        text: supplierColumnsText[supplierColumns.REVENUE],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.NUMBER_OF_EMPLOYEES]: {
        dataField: 'report.number_of_employees',
        text: supplierColumnsText[supplierColumns.NUMBER_OF_EMPLOYEES],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.SHORT_TERM_DEBIT]: {
        dataField: 'report.short_term_debit',
        text: supplierColumnsText[supplierColumns.SHORT_TERM_DEBIT],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.LONG_TERM_DEBIT]: {
        dataField: 'report.long_term_debit',
        text: supplierColumnsText[supplierColumns.LONG_TERM_DEBIT],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.EBIT]: {
        dataField: 'report.ebit',
        text: supplierColumnsText[supplierColumns.EBIT],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.FINANCIAL_EXPENSES]: {
        dataField: 'report.financial_expenses',
        text: supplierColumnsText[supplierColumns.FINANCIAL_EXPENSES],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '170px' })
      },
      [supplierColumns.CURRENT_ASSETS]: {
        dataField: 'report.current_assets',
        text: supplierColumnsText[supplierColumns.CURRENT_ASSETS],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.LONG_TERM_ASSETS]: {
        dataField: 'report.long_term_assets',
        text: supplierColumnsText[supplierColumns.LONG_TERM_ASSETS],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.CURRENT_LIABILITIES]: {
        dataField: 'report.current_liabilities',
        text: supplierColumnsText[supplierColumns.CURRENT_LIABILITIES],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.LONG_TERM_LIABILITIES]: {
        dataField: 'report.long_term_liabilities',
        text: supplierColumnsText[supplierColumns.LONG_TERM_LIABILITIES],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '170px' })
      },
      [supplierColumns.ACCOUNTS_RECEIVABLE]: {
        dataField: 'report.accounts_receivable',
        text: supplierColumnsText[supplierColumns.ACCOUNTS_RECEIVABLE],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '170px' })
      },
      [supplierColumns.ACCOUNTS_PAYABLE]: {
        dataField: 'report.accounts_payable',
        text: supplierColumnsText[supplierColumns.ACCOUNTS_PAYABLE],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '170px' })
      },
      [supplierColumns.INVENTORY]: {
        dataField: 'report.inventory',
        text: supplierColumnsText[supplierColumns.INVENTORY],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '110px' })
      },
      [supplierColumns.GLOBAL_ULTIMATE_DUNS_NUMBER]: {
        dataField: 'report.global_ultimate_duns_number',
        text: supplierColumnsText[supplierColumns.GLOBAL_ULTIMATE_DUNS_NUMBER],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '300px' })
      },
      [supplierColumns.GLOBAL_ULTIMATE_COMPANY_NAME]: {
        dataField: 'report.global_ultimate_company_name',
        text: supplierColumnsText[supplierColumns.GLOBAL_ULTIMATE_COMPANY_NAME],
        attrs: this.noTranslate,
        sort: true,
        editable: false,
        headerStyle: () => ({ width: '300px' })
      },
      [supplierColumns.SCREEN_STATUS]: {
        dataField: 'screen_status',
        text: supplierColumnsText[supplierColumns.SCREEN_STATUS],
        attrs: this.noTranslate,
        formatter: this.renderScreenStatus,
        headerFormatter: this.renderScreenInfoHeader('Screen status'),
        headerClasses: this.textCenter,
        classes: this.textCenter,
        editable: false,
        headerStyle: () => ({ width: '180px' })
      },
      [supplierColumns.CCR_SCORE]: {
        dataField: 'report.ccr_score',
        text: supplierColumnsText[supplierColumns.CCR_SCORE],
        headerClasses: this.textCenter,
        classes: this.textCenter,
        sort: true,
        editable: false,
        formatter: this.renderCcr,
        headerFormatter: this.renderCcrScoreHeader,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.CCR_STATUS]: {
        dataField: 'ccr_status',
        text: supplierColumnsText[supplierColumns.CCR_STATUS],
        headerClasses: this.textCenter,
        classes: this.textCenter,
        editable: false,
        formatter: this.renderCcrStatus,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.QUESTIONNAIRE_STATUS]: {
        dataField: 'questionnaire_status',
        text: supplierColumnsText[supplierColumns.QUESTIONNAIRE_STATUS],
        headerClasses: this.textCenter,
        classes: this.textCenter,
        editable: false,
        formatter: this.renderQuestionnaireStatus,
        headerStyle: () => ({ width: '150px' })
      },
      [supplierColumns.ACTIONS]: {
        dataField: 'actions',
        isDummyField: true,
        text: supplierColumnsText[supplierColumns.ACTIONS],
        formatter: this.renderActions,
        headerClasses: this.textCenter,
        classes: 'text-center td-actions',
        headerStyle: () => ({ width: '220px', paddingRight: '40px' }),
        editable: false
      }
    };
  };

  render() {
    const {
      workflows,
      user,
      suppliersByStatus,
      supplierStatus,
      searchQuery,
      activeFilters,
      multipleWorkflows,
      onChangeSupplierStatus
    } = this.props;

    const {
      supplierToScreen,
      showDeleteSupplierModal,
      pendingSupplierToDelete
    } = this.state;

    let filteredSuppliers = suppliersByStatus[supplierStatus];

    if (WorkflowsService.isFilterActive(activeFilters)) {
      filteredSuppliers = WorkflowsService.filterSuppliers(
        suppliersByStatus['all'],
        activeFilters
      );
    }

    if (searchQuery) {
      filteredSuppliers = filteredSuppliers.filter(
        WorkflowsService.searchSuppliers(searchQuery)
      );
    }

    const columns = this.getColumns();
    const isFilterActive = WorkflowsService.isFilterActive(activeFilters);

    this.selectedColumns = ['company_name'];
    if (multipleWorkflows) {
      this.selectedColumns.push(['workflow_title']);
    }
    workflows.forEach(workflow => {
      this.selectedColumns = this.selectedColumns.concat(
        WorkflowsService.getSelectedColumnsForWorkflow(
          workflow,
          user,
          supplierStatus
        )
      );
    });
    this.selectedColumns = uniq(this.selectedColumns);
    return (
      <div className="SuppliersTable">
        <div className="Supplier-status-tabs">
          {(isFilterActive || searchQuery) && (
            <div id="supplier-status-tabs-tab-filter">
              Filtered results
              <var className="Suppliers-count" notranslate="true">
                ({filteredSuppliers.length})
              </var>
            </div>
          )}
          {!isFilterActive &&
            !searchQuery && (
              <Tabs
                onSelect={onChangeSupplierStatus}
                activeKey={supplierStatus}
                id="supplier-status-tabs"
              >
                {Object.keys(this.statusToText).map(status => (
                  <Tab
                    eventKey={status}
                    key={status}
                    title={
                      <>
                        {this.statusToText[status]}
                        <var className="Suppliers-count" notranslate="true">
                          ({suppliersByStatus[status].length})
                        </var>
                      </>
                    }
                  />
                ))}
              </Tabs>
            )}
        </div>
        <div className="table-responsive">
          <BootstrapTable
            keyField="_id"
            data={filteredSuppliers}
            columns={this.selectedColumns.map(column => columns[column])}
            bordered={false}
            defaultSortDirection="asc"
            noDataIndication={this.emptyTableMessage(
              supplierStatus,
              searchQuery,
              isFilterActive
            )}
            cellEdit={cellEditFactory({ mode: 'click' })}
          />

          {!multipleWorkflows && (
            <RatingsModal
              show={this.state.showRatingsModal}
              handleClose={this.hideRatingsModal}
              workflow={workflows[0]}
            />
          )}

          <CcrModal
            show={this.state.showCcrModal}
            handleClose={this.hideCcrModal}
          />

          <PaydexModal
            show={this.state.showPaydexModal}
            handleClose={this.hidePaydexModal}
          />

          {!multipleWorkflows && (
            <StatusModal
              show={this.state.showStatusModal}
              handleClose={this.hideStatusModal}
              workflow={workflows[0]}
            />
          )}

          <ScreenModal
            show={this.state.showScreenModal}
            handleClose={this.hideScreenModal}
            supplier={supplierToScreen}
            onSubmit={this.screenSupplier(supplierToScreen)}
          />

          <SingleModal
            title="Delete Supplier"
            body="Are you sure you want to delete this supplier?"
            primaryButtonClassName="Btn-default-danger"
            show={showDeleteSupplierModal}
            handleClose={this.hideDeleteSupplierModal}
            handleSubmit={() => {
              this.hideDeleteSupplierModal();
              this.deleteSupplier(
                pendingSupplierToDelete.getWorkflowId(),
                pendingSupplierToDelete.getId()
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const suppliersByStatus = {
    all: [],
    [supplierStatuses.PENDING]: [],
    [supplierStatuses.IN_PROGRESS]: [],
    [supplierStatuses.COMPLETED]: [],
    [supplierStatuses.ARCHIVED]: []
  };

  props.suppliers.forEach(supplier => {
    if (supplier && supplier.getStatus()) {
      suppliersByStatus.all.push(supplier);
      suppliersByStatus[supplier.status].push(supplier);
    }
  });

  return {
    suppliersByStatus
  };
};

const mapDispatchToProps = {
  dispatchChangeStatusForSupplier: changeStatusForSupplier,
  dispatchDownload: download,
  dispatchRequestCcrReport: requestCcrReport,
  dispatchSendReminderToSupplier: sendReminderToSupplier,
  dispatchSetTagsToSupplier: setTagsToSupplier,
  dispatchDeleteSupplierTag: deleteSupplierTag,
  dispatchSetSupplierTags: setSupplierTags,
  dispatchRequestBirReport: requestBirReport,
  dispatchUpdateSupplier: updateSupplier,
  dispatchScreenSupplier: screenSupplier,
  dispatchDeletePendingSupplier: deletePendingSupplier
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersTable);
