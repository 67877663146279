import React from 'react';
import { Field } from 'redux-form';
import CountryDropdown from '../../../_components/CountryDropdown/CountryDropdown';

const OnboardingSupplierCialAccountRegisterForm = ({
  supplierId,
  handleSubmit,
  submitting,
  formError,
  showLoginForm
}) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="full_name">Full Name</label>
      <Field
        type="text"
        className="form-control Login-input"
        name="full_name"
        component="input"
        placeholder="Enter Full Name"
        autoComplete="off"
      />
    </div>
    {!supplierId && (
      <div className="form-group">
        <label htmlFor="company_name">Company Name</label>
        <Field
          type="text"
          className="form-control Login-input"
          name="company_name"
          component="input"
          placeholder="Enter Company Name"
          autoComplete="off"
        />
      </div>
    )}

    <div className="form-group">
      <label htmlFor="email">Work Email</label>
      <Field
        type="email"
        className="form-control Login-input"
        name="email"
        component="input"
        placeholder="example@cialdnb.com"
        autoComplete="off"
      />
    </div>
    <div className="form-group">
      <label htmlFor="password">Password</label>
      <Field
        type="password"
        className="form-control Login-input"
        name="password"
        component="input"
        placeholder="Enter Password"
        autoComplete="off"
      />
    </div>
    <div className="form-group">
      <label htmlFor="country">Your Company's Country</label>
      <Field
        type="text"
        className="form-control Login-input"
        name="country"
        component={CountryDropdown}
        placeholder="Enter Country"
        autoComplete="off"
      />
    </div>
    <div className="form-group">
      <label htmlFor="phone">Contact Phone</label>
      <Field
        type="text"
        className="form-control Login-input"
        name="phone"
        component="input"
        placeholder="Enter Phone"
        autoComplete="off"
      />
    </div>
    <div className="form-group">
      <button
        type="submit"
        disabled={submitting}
        className="Btn-default Btn-default-primary form-control OnboardingSupplierCial-btn-create-account"
      >
        Create an account
      </button>
    </div>
    {formError && (
      <div className="alert alert-danger alert-dismissible" role="alert">
        {formError}
      </div>
    )}
    <div className="OnboardingSupplierCial-footer" onClick={showLoginForm}>
      <span>Already have an account? </span>&nbsp;
      <span className="OnboardingSupplierCial-show-form-button Btn-link">
        Click here to login
      </span>
    </div>
  </form>
);

export default OnboardingSupplierCialAccountRegisterForm;
