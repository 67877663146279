import { workflowReportTypes, workflowStatuses } from 'react-common/constants';

const workflowsConstants = {
  GET_ALL_REQUEST: 'WORKFLOWS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'WORKFLOWS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'WORKFLOWS_GET_ALL_FAILURE',

  GET_REQUEST: 'WORKFLOWS_GET_REQUEST',
  GET_SUCCESS: 'WORKFLOWS_GET_SUCCESS',
  GET_FAILURE: 'WORKFLOWS_GET_FAILURE',

  CREATE_REQUEST: 'WORKFLOWS_CREATE_REQUEST',
  CREATE_SUCCESS: 'WORKFLOWS_CREATE_SUCCESS',
  CREATE_FAILURE: 'WORKFLOWS_CREATE_FAILURE',

  UPDATE_REQUEST: 'WORKFLOWS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'WORKFLOWS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'WORKFLOWS_UPDATE_FAILURE',

  ACTIVATE_REQUEST: 'WORKFLOWS_ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'WORKFLOWS_ACTIVATE_SUCCESS',
  ACTIVATE_FAILURE: 'WORKFLOWS_ACTIVATE_FAILURE',

  DELETE_DRAFT_REQUEST: 'WORKFLOWS_DELETE_DRAFT_REQUEST',
  DELETE_DRAFT_SUCCESS: 'WORKFLOWS_DELETE_DRAFT_SUCCESS',
  DELETE_DRAFT_FAILURE: 'WORKFLOWS_DELETE_DRAFT_FAILURE',

  CLOSE_WORKFLOW_REQUEST: 'CLOSE_WORKFLOW_REQUEST',
  CLOSE_WORKFLOW_SUCCESS: 'CLOSE_WORKFLOW_SUCCESS',
  CLOSE_WORKFLOW_FAILURE: 'CLOSE_WORKFLOW_FAILURE',

  WORKFLOW_STATISTICS_REQUEST: 'WORKFLOW_STATISTICS_REQUEST',
  WORKFLOW_STATISTICS_SUCCESS: 'WORKFLOW_STATISTICS_SUCCESS',
  WORKFLOW_STATISTICS_FAILURE: 'WORKFLOW_STATISTICS_FAILURE',

  ADD_SUPPLIER_REQUEST: 'ADD_SUPPLIER_REQUEST',
  ADD_SUPPLIER_SUCCESS: 'ADD_SUPPLIER_SUCCESS',
  ADD_SUPPLIER_FAILURE: 'ADD_SUPPLIER_FAILURE',

  ADD_SUPPLIERS_BULK_REQUEST: 'ADD_SUPPLIERS_BULK_REQUEST',
  ADD_SUPPLIERS_BULK_SUCCESS: 'ADD_SUPPLIERS_BULK_SUCCESS',
  ADD_SUPPLIERS_BULK_FAILURE: 'ADD_SUPPLIERS_BULK_FAILURE',

  REQUEST_BIR_REPORT_REQUEST: 'REQUEST_BIR_REPORT_REQUEST',
  REQUEST_BIR_REPORT_SUCCESS: 'REQUEST_BIR_REPORT_SUCCESS',
  REQUEST_BIR_REPORT_FAILURE: 'REQUEST_BIR_REPORT_FAILURE',

  REQUEST_CCR_REPORT_REQUEST: 'REQUEST_CCR_REPORT_REQUEST',
  REQUEST_CCR_REPORT_SUCCESS: 'REQUEST_CCR_REPORT_SUCCESS',
  REQUEST_CCR_REPORT_FAILURE: 'REQUEST_CCR_REPORT_FAILURE',

  CHANGE_SUPPLIER_STATUS_REQUEST: 'CHANGE_SUPPLIER_STATUS_REQUEST',
  CHANGE_SUPPLIER_STATUS_SUCCESS: 'CHANGE_SUPPLIER_STATUS_SUCCESS',
  CHANGE_SUPPLIER_STATUS_FAILURE: 'CHANGE_SUPPLIER_STATUS_FAILURE',

  SEND_SUPPLIER_REMINDER_REQUEST: 'SEND_SUPPLIER_REMINDER_REQUEST',
  SEND_SUPPLIER_REMINDER_SUCCESS: 'SEND_SUPPLIER_REMINDER_SUCCESS',
  SEND_SUPPLIER_REMINDER_FAILURE: 'SEND_SUPPLIER_REMINDER_FAILURE',

  DELETE_SUPPLIER_REQUEST: 'DELETE_SUPPLIER_REQUEST',
  DELETE_SUPPLIER_SUCCESS: 'DELETE_SUPPLIER_SUCCESS',
  DELETE_SUPPLIER_FAILURE: 'DELETE_SUPPLIER_FAILURE',

  UPDATE_SUPPLIER_REQUEST: 'UPDATE_SUPPLIER_REQUEST',
  UPDATE_SUPPLIER_SUCCESS: 'UPDATE_SUPPLIER_SUCCESS',
  UPDATE_SUPPLIER_FAILURE: 'UPDATE_SUPPLIER_FAILURE',

  ADD_ATTACHMENT_QUESTION_REQUEST: 'WORKFLOWS_ADD_ATTACHMENT_QUESTION_REQUEST',
  ADD_ATTACHMENT_QUESTION_SUCCESS: 'WORKFLOWS_ADD_ATTACHMENT_QUESTION_SUCCESS',
  ADD_ATTACHMENT_QUESTION_FAILURE: 'WORKFLOWS_ADD_ATTACHMENT_QUESTION_FAILURE',

  NOTIFY_SUPPLIERS_REQUEST: 'NOTIFY_SUPPLIERS_REQUEST',
  NOTIFY_SUPPLIERS_SUCCESS: 'NOTIFY_SUPPLIERS_SUCCESS',
  NOTIFY_SUPPLIERS_FAILURE: 'NOTIFY_SUPPLIERS_FAILURE',

  ADD_FILE_SUPPLIER_REQUEST: 'WORKFLOWS_ADD_FILE_SUPPLIER_REQUEST',
  ADD_FILE_SUPPLIER_SUCCESS: 'WORKFLOWS_ADD_FILE_SUPPLIER_SUCCESS',
  ADD_FILE_SUPPLIER_FAILURE: 'WORKFLOWS_ADD_FILE_SUPPLIER_FAILURE',

  DELETE_FILE_SUPPLIER_REQUEST: 'WORKFLOWS_DELETE_FILE_SUPPLIER_REQUEST',
  DELETE_FILE_SUPPLIER_SUCCESS: 'WORKFLOWS_DELETE_FILE_SUPPLIER_SUCCESS',
  DELETE_FILE_SUPPLIER_FAILURE: 'WORKFLOWS_DELETE_FILE_SUPPLIER_FAILURE',

  SET_TAGS_TO_SUPPLIER: 'SET_TAGS_TO_SUPPLIER',

  SCREEN_SUPPLIER_REQUEST: 'SCREEN_SUPPLIER_REQUEST',
  SCREEN_SUPPLIER_SUCCESS: 'SCREEN_SUPPLIER_SUCCESS',
  SCREEN_SUPPLIER_FAILURE: 'SCREEN_SUPPLIER_FAILURE'
};

export const workflowCategories = [
  {
    type: workflowStatuses.ACTIVE,
    path: '/workflows',
    headerTitle: 'Active'
  },
  {
    type: workflowStatuses.DRAFT,
    path: '/workflows/draft',
    headerTitle: 'Draft'
  },
  {
    type: workflowStatuses.CLOSED,
    path: '/workflows/completed',
    headerTitle: 'Completed'
  }
];

export const recentActivityTypes = {
  ONBOARDING_STARTED: 'onboarding.started',
  ONBOARDING_FINISHED: 'onboarding.finished',
  QUESTIONNAIRE_FINISHED: 'questionnaire.finished',
  REPORT_STARTED: 'bir.report.requested',
  REPORT_FINISHED: 'bir.report.finished',
  SCORE_STARTED: 'score.request.started',
  SCORE_FINISHED: 'score.request.finished'
};

export const emptyFilters = {
  status: undefined,
  country: [],
  financialStrength: [],
  industry: [],
  riskIndicator: [],
  commercialScore: [],
  tags: []
};

export const commercialScoreGroups = {
  GREAT: 'great',
  GOOD: 'good',
  REGULAR: 'regular',
  TO_MONITOR: 'to_monitor',
  ALERT: 'alert'
};

export const commercialScoreLabels = {
  [commercialScoreGroups.GREAT]: 'Great',
  [commercialScoreGroups.GOOD]: 'Good',
  [commercialScoreGroups.REGULAR]: 'Regular',
  [commercialScoreGroups.TO_MONITOR]: 'To Monitor',
  [commercialScoreGroups.ALERT]: 'Alert'
};

export const commercialScoreOrder = [
  commercialScoreGroups.GREAT,
  commercialScoreGroups.GOOD,
  commercialScoreGroups.REGULAR,
  commercialScoreGroups.TO_MONITOR,
  commercialScoreGroups.ALERT
];

export const paydexGroups = {
  GOOD: 'good',
  OK: 'ok',
  BAD: 'bad',
  UNAVAILABLE: 'unavailable'
};

export const paydexGroupClasses = {
  [paydexGroups.GOOD]: 'green',
  [paydexGroups.OK]: 'yellow',
  [paydexGroups.BAD]: 'coral',
  [paydexGroups.UNAVAILABLE]: 'grey'
};

export const ccrScoreClasses = {
  A: 'mint',
  B: 'mint',
  C: 'mint',
  D: 'coral',
  E: 'coral'
};

export const INFORMATION_UNAVAILABLE = 'N/A';

export const reportTypeToName = {
  [workflowReportTypes.BIR]: 'BIR',
  [workflowReportTypes.RAC]: 'RAC',
  [workflowReportTypes.RAF]: 'RAF',
  [workflowReportTypes.SHORT_FORM]: 'CKR',
  [workflowReportTypes.SHORT_FORM_PLUS]: 'CKR Plus',
  [workflowReportTypes.SQR]: 'SQR',
  [workflowReportTypes.CAR]: 'CAR',
  [workflowReportTypes.CCR]: 'CCR',
  [workflowReportTypes.HOM_9_ASPECTS]: 'Hom 9 Aspects',
  [workflowReportTypes.HOM_8_ASPECTS]: 'Hom 8 Aspects',
  [workflowReportTypes.HOM_7_ASPECTS]: 'Hom 7 Aspects',
  [workflowReportTypes.HOM_FALLLABELLLA]: 'Hom Fallabella',
  [workflowReportTypes.HOM_FALLLABELLLA_MID]:
    'Hom Fallabella mid-sized companies',
  [workflowReportTypes.CFR]: 'CFR'
};

export const supplierColumns = {
  COMPANY_NAME: 'company_name',
  WORKFLOW_TITLE: 'workflow_title',
  STATUS: 'status',
  TAGS: 'tags',
  FULL_NAME: 'full_name',
  EMAIL: 'email',
  COUNTRY: 'country',
  ADDRESS: 'address',
  SIC: 'sic',
  TAX_ID: 'tax_id',
  PHONE: 'phone',
  FINANCIAL_STRENGTH: 'financial_strength',
  COMMERCIAL_SCORE: 'commercial_score',
  RISK_INDICATOR: 'risk_indicator',
  RAC_RISK_INDICATOR: 'rac_risk_indicator',
  DNB_RATING: 'dnb_rating',
  DELIVERY_DATE: 'delivery_date',
  BALANCE_SHEET_DATE: 'balance_sheet_date',
  INDUSTRY: 'industry',
  DUNS_NUMBER: 'duns_number',
  EMMA_SCORE: 'emma_score',
  PAYDEX: 'paydex',
  NUMBER_OF_SUITS: 'number_of_suits',
  WEBSITE: 'website',
  SHAREHOLDERS: 'shareholders',
  REVENUE: 'revenue',
  NUMBER_OF_EMPLOYEES: 'number_of_employees',
  SHORT_TERM_DEBIT: 'short_term_debit',
  LONG_TERM_DEBIT: 'long_term_debit',
  EBIT: 'ebit',
  FINANCIAL_EXPENSES: 'financial_expenses',
  CURRENT_ASSETS: 'current_assets',
  LONG_TERM_ASSETS: 'long_term_assets',
  CURRENT_LIABILITIES: 'current_liabilities',
  LONG_TERM_LIABILITIES: 'long_term_liabilities',
  ACCOUNTS_RECEIVABLE: 'accounts_receivable',
  ACCOUNTS_PAYABLE: 'accounts_payable',
  INVENTORY: 'inventory',
  SCREEN_STATUS: 'screen_status',
  CCR_SCORE: 'ccr_score',
  CCR_STATUS: 'ccr_status',
  QUESTIONNAIRE_STATUS: 'questionnaire_status',
  ACTIONS: 'actions',
  GLOBAL_ULTIMATE_DUNS_NUMBER: 'global_ultimate_duns_number',
  GLOBAL_ULTIMATE_COMPANY_NAME: 'global_ultimate_company_name'
};

export const supplierColumnsText = {
  [supplierColumns.COMPANY_NAME]: 'Company name',
  [supplierColumns.WORKFLOW_TITLE]: 'Workflow',
  [supplierColumns.STATUS]: 'Status',
  [supplierColumns.TAGS]: 'Labels',
  [supplierColumns.FULL_NAME]: 'Contact person',
  [supplierColumns.EMAIL]: 'Contact emails',
  [supplierColumns.COUNTRY]: 'Country',
  [supplierColumns.ADDRESS]: 'Address',
  [supplierColumns.SIC]: 'SIC',
  [supplierColumns.TAX_ID]: 'Tax ID',
  [supplierColumns.PHONE]: 'Phone',
  [supplierColumns.FINANCIAL_STRENGTH]: 'Financial strength',
  [supplierColumns.COMMERCIAL_SCORE]: 'Commercial score',
  [supplierColumns.RISK_INDICATOR]: 'Risk indicator',
  [supplierColumns.RAC_RISK_INDICATOR]: 'SQR risk',
  [supplierColumns.DNB_RATING]: 'D&B rating',
  [supplierColumns.DELIVERY_DATE]: 'Delivery date',
  [supplierColumns.BALANCE_SHEET_DATE]: 'Balance sheet date',
  [supplierColumns.INDUSTRY]: 'Industry',
  [supplierColumns.DUNS_NUMBER]: 'D-U-N-S® number',
  [supplierColumns.EMMA_SCORE]: 'Emma score',
  [supplierColumns.PAYDEX]: 'Paydex',
  [supplierColumns.NUMBER_OF_SUITS]: 'Records',
  [supplierColumns.WEBSITE]: 'Website',
  [supplierColumns.SHAREHOLDERS]: 'Shareholders',
  [supplierColumns.REVENUE]: 'Revenue',
  [supplierColumns.NUMBER_OF_EMPLOYEES]: 'Number of employees',
  [supplierColumns.SHORT_TERM_DEBIT]: 'Short term debit',
  [supplierColumns.LONG_TERM_DEBIT]: 'Long term debit',
  [supplierColumns.EBIT]: 'EBIT',
  [supplierColumns.FINANCIAL_EXPENSES]: 'Financial expenses',
  [supplierColumns.CURRENT_ASSETS]: 'Current assets',
  [supplierColumns.LONG_TERM_ASSETS]: 'Long term assets',
  [supplierColumns.CURRENT_LIABILITIES]: 'Current liabilities',
  [supplierColumns.LONG_TERM_LIABILITIES]: 'Long term liabilities',
  [supplierColumns.ACCOUNTS_RECEIVABLE]: 'Accounts receivable',
  [supplierColumns.ACCOUNTS_PAYABLE]: 'Accounts payable',
  [supplierColumns.INVENTORY]: 'Inventory',
  [supplierColumns.GLOBAL_ULTIMATE_DUNS_NUMBER]: 'Global ultimate DUNS number',
  [supplierColumns.GLOBAL_ULTIMATE_COMPANY_NAME]:
    'Global ultimate company name',
  [supplierColumns.SCREEN_STATUS]: 'Screen status',
  [supplierColumns.CCR_SCORE]: 'CCR score',
  [supplierColumns.CCR_STATUS]: 'CCR status',
  [supplierColumns.QUESTIONNAIRE_STATUS]: 'Questionnaire status',
  [supplierColumns.ACTIONS]: 'Actions'
};

export const workflowSortArray = [
  {
    name: 'Newest last modified',
    field: 'modified_at',
    reverse: true
  },
  {
    name: 'Oldest last modified',
    field: 'modified_at',
    reverse: false
  },
  {
    name: 'Workflow title (A - Z)',
    field: 'info.title',
    reverse: false
  },
  {
    name: 'Workflow title (Z - A)',
    field: 'info.title',
    reverse: true
  },
  {
    name: 'Highest supplier number',
    field: 'suppliers.length',
    reverse: true
  },
  {
    name: 'Lowest supplier number',
    field: 'suppliers.length',
    reverse: false
  },
  {
    name: 'Newest launch date',
    field: 'launched_at',
    reverse: true
  },
  {
    name: 'Oldest launch date',
    field: 'launched_at',
    reverse: false
  }
];

export default workflowsConstants;
