import nextIdentifyConstants from '../_constants/nextIdentify';

function nextIdentify() {
  return {
    type: nextIdentifyConstants.NEXT_IDENTIFY_VALIDATION
  };
}

export function nextIdentifyValidation() {
  return dispatch => {
    return dispatch(nextIdentify());
  };
}
