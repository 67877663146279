import uiConstants from '../_constants/ui';

export function updateHeaderBreadcrumbs(headerBreadcrumbs) {
  return dispatch => {
    dispatch({
      type: uiConstants.UI_HEADER_BREADCRUMBS_UPDATE,
      headerBreadcrumbs
    });
  };
}

export function setSuppliersFilters(suppliersFilters) {
  return dispatch => {
    dispatch({
      type: uiConstants.SUPPLIERS_FILTER_UPDATE,
      suppliersFilters
    });
  };
}
