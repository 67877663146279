import React from 'react';
import { DunsAbbr } from 'react-common/components';
import { Util } from 'react-common/services';
import BootstrapTable from 'react-bootstrap-table-next';

import WorkflowsService from '../../_services/Workflows';

const noTranslate = () => ({ notranslate: 'true' });

const getColumns = workflow =>
  [
    {
      dataField: 'invite.company_name',
      text: 'Company Name',
      attrs: noTranslate,
      headerStyle: () => ({ width: '200px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.full_name',
      text: 'Full Name',
      attrs: noTranslate,
      headerStyle: () => ({ width: '160px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.email',
      text: 'Contact Emails',
      attrs: noTranslate,
      headerStyle: () => ({ width: '200px' }),
      sort: true,
      formatter: (_, supplier) =>
        WorkflowsService.getSupplierEmails(supplier).join(', '),
      show: true
    },
    {
      dataField: 'invite.country',
      text: 'Country',
      formatter: country => Util.capitalize(country),
      headerStyle: () => ({ width: '200px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.tax_id',
      text: 'Tax ID',
      attrs: noTranslate,
      headerStyle: () => ({ width: '100px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.address',
      text: 'Full address',
      attrs: noTranslate,
      headerStyle: () => ({ width: '200px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.sic',
      text: 'SIC',
      attrs: noTranslate,
      headerStyle: () => ({ width: '80px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.phone',
      text: 'Phone',
      attrs: noTranslate,
      headerStyle: () => ({ width: '150px' }),
      sort: true,
      show: true
    },
    {
      dataField: 'invite.duns_number',
      text: 'Duns Number',
      attrs: noTranslate,
      headerStyle: () => ({ width: '150px' }),
      headerFormatter: () => (
        <>
          <DunsAbbr /> number
        </>
      ),
      sort: true,
      show: workflow.isInvestigating() || workflow.isCfrReport()
    },
    {
      dataField: 'invite.shareholders',
      text: 'Shareholders',
      attrs: noTranslate,
      headerStyle: () => ({ width: '150px' }),
      show: workflow.isCfrReport()
    }
  ].filter(column => column.show);

const WorkflowsSuppliersAddTable = ({ suppliers, workflow }) => (
  <div className="table-responsive">
    <BootstrapTable
      keyField="_id"
      data={suppliers}
      columns={getColumns(workflow)}
      bordered={false}
    />
  </div>
);

export default WorkflowsSuppliersAddTable;
