import React from 'react';

const LocalMexicoInfo = () => (
  <>
    <div className="PeruForm">
      <p>
        Please make payment on your order to Dun & Bradstreet S.A.C., by check
        or interbank transfer, to one of the following BBVA Continental
        accounts:
      </p>

      <div>
        <label>Current Account in Peruvian Soles:</label>
        <p notranslate="true">0011-0147-0100001543-66</p>
      </div>
      <div>
        <label>C.C.I. Account Peruvian Soles:</label>
        <p notranslate="true">011-147-000100001543-66</p>
      </div>
      <div>
        <label>Current Account in USD:</label>
        <p notranslate="true">0011-0384-0100013664-56</p>
      </div>
      <div>
        <label>C.C.I. Account in USD:</label>
        <p notranslate="true">011-384-000100013664-56</p>
      </div>
    </div>
    <div className="PeruForm">
      <p>
        If the invoice price, including taxes (i.g.v.), is over 700.00 soles, it
        must include a percentage of the total amount invoiced to be deposited
        in a deductions account.
      </p>

      <div>
        <label>
          This transaction is subject to the tax obligations payment system
          (spot) of the Peru Central Government:
        </label>
        <p notranslate="true">12%</p>
      </div>

      <div>
        <label>Type of Operation:</label>
        <p notranslate="true">01</p>
      </div>
      <div>
        <label>Good/service code:</label>
        <p notranslate="true">022</p>
      </div>
      <div>
        <label>Banco De La Nación Account:</label>
        <p notranslate="true">00-000-488860</p>
      </div>
    </div>
  </>
);

export default LocalMexicoInfo;
