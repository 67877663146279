import PropTypes from 'prop-types';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Localize } from 'react-common/services';

import {
  commercialScoreGroups,
  commercialScoreLabels,
  commercialScoreOrder
} from '../../../_constants/workflows';

import './StatisticsCommercialScore.css';
import get from 'lodash/get';

const dataset = {
  labels: [],
  datasets: [
    {
      data: []
    }
  ]
};

const commercialScoreColors = {
  [commercialScoreGroups.GREAT]: '#69C568',
  [commercialScoreGroups.GOOD]: '#B9C552',
  [commercialScoreGroups.REGULAR]: '#FFC341',
  [commercialScoreGroups.TO_MONITOR]: '#F79851',
  [commercialScoreGroups.ALERT]: '#EC6660'
};

const legend = {
  display: true,
  position: 'left',
  fullWidth: false,
  reverse: false,
  labels: {
    fontColor: '#333333',
    fontSize: 14,
    boxWidth: 15,
    fontFamily: "'Open Sans'",
    padding: 16
  }
};

const options = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },
  responsive: true,
  maintainAspectRatio: false
};

const loadDataset = data => {
  dataset.labels = data.map(
    stat => Localize.translate(commercialScoreLabels[stat[0]]) + ' (%)'
  );
  dataset.datasets[0].data = data.map(stat => {
    // Show values between 0 and 1 with first visible digit (i.e. 0.0123 = 0.01, 0.0000567 = 0.00006)
    if (stat[1] < 1) {
      return stat[1].toFixed(1 - Math.floor(Math.log(stat[1]) / Math.log(10)));
    }
    // Show bigger values with 1 decimal place
    return stat[1].toFixed(1);
  });
  dataset.datasets[0].backgroundColor = dataset.datasets[0].hoverBackgroundColor = data.map(
    stat => commercialScoreColors[stat[0]]
  );
};

const loadEmptyDataset = () => {
  dataset.labels = commercialScoreOrder.map(
    score => Localize.translate(commercialScoreLabels[score]) + ' %'
  );
  dataset.datasets[0].data = [0, 0, 0, 0, 0, 100];
  dataset.datasets[0].backgroundColor = dataset.datasets[0].hoverBackgroundColor = commercialScoreOrder.map(
    score => commercialScoreColors[score]
  );
};

const StatisticsCommercialScore = ({ data, className, onClick }) => {
  const isClickable = data && data.length && onClick;

  if (data && data.length) {
    loadDataset(data);
  } else {
    loadEmptyDataset();
  }

  if (isClickable) {
    options.onHover = (event, chartElement) =>
      (event.target.style.cursor = chartElement[0] ? 'pointer' : 'default');
  } else {
    delete options.onHover;
  }

  return (
    <div className={className || 'col-sm-12 col-lg-6'}>
      <div className="Statistics-content Statistics-content-row">
        <h3 className="Statistics-title">By Commercial Score</h3>
        <div className="row">
          <div className="col-xs-12 StatisticsCommercialScore-doughnut">
            <Doughnut
              data={dataset}
              legend={legend}
              options={options}
              width={500}
              height={280}
              onElementsClick={elements => {
                if (isClickable) {
                  const elementIndex = get(elements, '[0]._index');
                  if (elementIndex !== undefined && data[elementIndex]) {
                    onClick(data[elementIndex][0]);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StatisticsCommercialScore.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string
};

export default StatisticsCommercialScore;
