import React from 'react';
import { Link } from 'react-router-dom';

import './PageNotFound.css';
import notFoundPic from '../_images/404.svg';

const PageNotFound = props => (
  <div className={`${props.className} PageNotFound`}>
    <img src={notFoundPic} alt="Page not found" />
    <p>Oops! Page not found.</p>
    <Link to="/home">
      <button className="Btn-default Btn-default-primary ">Go Home</button>
    </Link>
  </div>
);

export default PageNotFound;
