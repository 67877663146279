import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Checkbox } from 'react-common/components';
import includes from 'lodash/includes';

import StatisticsCount from '../../_components/Statistics/StatisticsCount/StatisticsCount';
import Statistics from '../../_components/Statistics/Statistics';

import './DashboardStatistics.css';

const DashboardStatistics = ({
  workflows,
  selectedWorkflows,
  dropdownText,
  statistics,
  user,
  handleWorkflowSelect
}) => (
  <div className="DashboardStatistics">
    <div className="row">
      {workflows.length > 0 && (
        <div className="col-xs-9 col-xs-offset-3 col-sm-offset-2 col-sm-10 text-right">
          <Dropdown
            id="dashboard-workflows-dropdown"
            className="DashboardStatistics-workflows-dropdown"
            pullRight
          >
            <Dropdown.Toggle>
              {selectedWorkflows.length === 0 ? (
                <span>{dropdownText}</span>
              ) : (
                <span notranslate="true">{dropdownText}</span>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="DashboardStatistics-Dropdown">
                <div className="DashboardStatistics-Dropdown-item">
                  <Checkbox
                    key="0"
                    name="all"
                    label="All workflows"
                    checked={selectedWorkflows.length === 0}
                    handleChange={handleWorkflowSelect}
                  />
                </div>
                {workflows.map(workflow => (
                  <div
                    className="DashboardStatistics-Dropdown-item"
                    key={workflow._id}
                    notranslate="true"
                  >
                    <Checkbox
                      name={workflow._id}
                      label={workflow.info.title}
                      checked={includes(selectedWorkflows, workflow._id)}
                      handleChange={handleWorkflowSelect}
                    />
                  </div>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>

    <div className="row">
      <StatisticsCount
        className="col-sm-12 col-lg-6"
        data={statistics.workflows}
        title="Workflows"
      />
      <StatisticsCount
        className="col-sm-12 col-lg-6"
        data={statistics.suppliers.status}
        title="Suppliers"
      />
      <Statistics statistics={statistics} user={user} />
    </div>
  </div>
);

export default DashboardStatistics;
