import React, { Component } from 'react';
import debounce from 'lodash/debounce';

import './SuppliersSearchBar.css';

const debounceSearch = debounce(
  (onChangeSearchQuery, searchQuery) => onChangeSearchQuery(searchQuery),
  300
);

const searchPlaceholder =
  'Search supplier by Company Name / Tax ID / Contact name / Email';

class SuppliersSearchBar extends Component {
  state = {
    searchFocused: false
  };

  setSearchFocus = searchFocused => this.setState({ searchFocused });

  render() {
    const { searchFocused } = this.state;
    const { onChangeSearchQuery } = this.props;
    return (
      <div
        className="Search-bar pull-left"
        style={searchFocused ? { width: '100%' } : null}
      >
        <input
          type="text"
          className="form-control Search-input hidden-xs"
          placeholder={searchPlaceholder}
          onChange={e => debounceSearch(onChangeSearchQuery, e.target.value)}
          onFocus={() => this.setSearchFocus(true)}
          onBlur={() => this.setSearchFocus(false)}
        />
        <input
          type="text"
          className="form-control Search-input visible-xs"
          placeholder={searchPlaceholder}
          onChange={e => debounceSearch(onChangeSearchQuery, e.target.value)}
          onFocus={() => this.setSearchFocus(true)}
          onBlur={() => this.setSearchFocus(false)}
        />
        <i className="icon-ic_search Search-icon form-control-feedback " />
      </div>
    );
  }
}

export default SuppliersSearchBar;
