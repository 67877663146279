import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Loader, PrivateRoute, Toast } from 'react-common/components';
import { Auth, Segment } from 'react-common/services';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { reloadUser, nextIdentifyValidation } from '../_actions';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import ErrorFallback from '../_components/ErrorFallback/ErrorFallback';

import configureStore from '../configureStore';

import Landing from '../Landing/Landing';
import Onboarding from '../Onboarding/Onboarding';
import LoginContainer from '../Login/LoginContainer';
import Dashboard from '../Dashboard/Dashboard';
import Contact from '../Contact/Contact';
import Settings from '../Settings/Settings';
import Workflows from '../Workflows/Workflows';
import ForgotPasswordContainer from '../ForgotPassword/ForgotPasswordContainer';
import ChangePasswordContainer from '../ChangePassword/ChangePasswordContainer';
import SuppliersContainer from '../Suppliers/SuppliersContainer';
import PageNotFound from '../PageNotFound/PageNotFound';
import { initialize as initializePosthog } from '../_services/Posthog';

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();
const store = configureStore(history);

initializePosthog({
  key: process.env.REACT_APP_POSTHOG_KEY,
  url: process.env.REACT_APP_POSTHOG_APP_URL
});

history.listen(() => {
  const currentDateTime = new Date();
  const { nextIdentify: { nextIdentify } } = store.getState();
  if (currentDateTime > nextIdentify || !nextIdentify) {
    store.dispatch(nextIdentifyValidation());
    store.dispatch(reloadUser());
  } else {
    Segment.page();
  }
});

store.dispatch(nextIdentifyValidation());

class App extends Component {
  state = {
    userLoaded: false
  };

  componentDidMount() {
    if (!Auth.isUserAuthenticated()) {
      return;
    }

    store
      .dispatch(reloadUser())
      .then(() => this.setState({ userLoaded: true }));
  }

  render() {
    const { userLoaded } = this.state;
    const { user: { data: { user } } } = store.getState();

    if (userLoaded && !user) {
      return <ErrorFallback />;
    }

    if (Auth.isUserAuthenticated() && !userLoaded) {
      return <Loader loading />;
    }

    return (
      <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={history}>
          <Switch>
            {/*show onboarding page without header*/}
            <Route path="/onboarding" component={Onboarding} />
            <Route
              render={() => (
                <div className="container-fluid">
                  <Toast />
                  <Row>
                    <Switch>
                      <Route exact path="/" component={Landing} />
                      <Route exact path="/login" component={LoginContainer} />
                      <PrivateRoute path="/home" component={Dashboard} />
                      <PrivateRoute path="/workflows" component={Workflows} />
                      <PrivateRoute
                        path="/suppliers"
                        component={SuppliersContainer}
                      />
                      <PrivateRoute
                        exact
                        path="/settings/:section(profile|password)"
                        component={Settings}
                      />
                      <Route exact path="/contact" component={Contact} />
                      <Route
                        exact
                        path="/forgot-password"
                        component={ForgotPasswordContainer}
                      />
                      <Route
                        exact
                        path="/change-password"
                        component={ChangePasswordContainer}
                      />
                      <Route
                        render={() =>
                          Auth.isUserAuthenticated() ? (
                            <AppWrapper>
                              <PageNotFound />
                            </AppWrapper>
                          ) : (
                            <PageNotFound />
                          )
                        }
                      />
                    </Switch>
                  </Row>
                </div>
              )}
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
