import React from 'react';
import { Table, Modal } from 'react-bootstrap';
import {
  supplierStatusUpdateTypes,
  statusUpdateTypeToText,
  statusUpdateTypeToDescription
} from 'react-common/constants';

import './StatusModal.css';

const getStatusTypesForWorkflow = workflow => {
  if (workflow.isOnboarding()) {
    if (workflow.isQuestionnaireReport()) {
      return [
        [supplierStatusUpdateTypes.EMAIL_RECEIVED, 'grey'],
        [supplierStatusUpdateTypes.STARTED_ONBOARDING, 'light-orange'],
        [supplierStatusUpdateTypes.CIAL_ACCOUNT, 'light-orange'],
        [supplierStatusUpdateTypes.PAYMENT_REFERENCE, 'light-orange'],
        [supplierStatusUpdateTypes.PAYMENT_PROOF, 'light-orange'],
        [supplierStatusUpdateTypes.STARTED_QUESTIONNAIRE, 'light-orange'],
        [supplierStatusUpdateTypes.ONBOARDING_COMPLETED, 'deep-mint']
      ];
    }
    return [
      [supplierStatusUpdateTypes.EMAIL_RECEIVED, 'grey'],
      [supplierStatusUpdateTypes.STARTED_ONBOARDING, 'light-orange'],
      [supplierStatusUpdateTypes.CIAL_ACCOUNT, 'light-orange'],
      [supplierStatusUpdateTypes.PAYMENT_REFERENCE, 'light-orange'],
      [supplierStatusUpdateTypes.PAYMENT_PROOF, 'light-orange'],
      [supplierStatusUpdateTypes.STARTED_FORM, 'light-orange'],
      [supplierStatusUpdateTypes.ADDITIONAL_FILES, 'light-orange'],
      [supplierStatusUpdateTypes.STARTED_QUESTIONNAIRE, 'light-orange'],
      [supplierStatusUpdateTypes.DATA_VERIFICATION, 'light-orange'],
      [supplierStatusUpdateTypes.SUPPLIER_UPDATE_DATA, 'light-orange'],
      [supplierStatusUpdateTypes.CREATING_REPORT, 'light-orange'],
      [supplierStatusUpdateTypes.REPORT_CREATED, 'deep-mint']
    ];
  }

  if (workflow.isScoreProduct()) {
    return [
      [supplierStatusUpdateTypes.DATA_VERIFICATION, 'grey'],
      [supplierStatusUpdateTypes.CREATING_SCORE, 'light-orange'],
      [supplierStatusUpdateTypes.SCORE_CREATED, 'deep-mint'],
      [supplierStatusUpdateTypes.CREATING_REPORT, 'light-orange'],
      [supplierStatusUpdateTypes.REPORT_CREATED, 'deep-mint']
    ];
  }
  return [
    [supplierStatusUpdateTypes.DATA_VERIFICATION, 'grey'],
    [supplierStatusUpdateTypes.CREATING_REPORT, 'light-orange'],
    [supplierStatusUpdateTypes.REPORT_CREATED, 'deep-mint']
  ];
};

const StatusModal = ({ show, handleClose, workflow }) => (
  <Modal className='Modal-status' show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className='ModalTitle'>Supplier Status</Modal.Title>
      <i
        className='icon-ic_close pull-right Close-modal-icon'
        onClick={handleClose}
      />
    </Modal.Header>
    <Modal.Body>
      <Table responsive>
        <thead>
          <tr>
            <th>Status</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {getStatusTypesForWorkflow(workflow).map(
            ([statusUpdateType, colorClass], index) => (
              <tr key={index}>
                <td>
                  <span className={`Badge Bg-${colorClass}`}>
                    {statusUpdateTypeToText[statusUpdateType]}
                  </span>
                </td>
                <td>{statusUpdateTypeToDescription[statusUpdateType]}</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>
);

export default StatusModal;
