import PropTypes from 'prop-types';
import React from 'react';
import { Util } from 'react-common/services';

import './StatisticsCountry.css';

const StatisticsCountry = ({ data, className, onClick }) => {
  const isClickable = country => country !== 'other' && onClick;

  return (
    <div className={className || 'col-sm-6 col-lg-3'}>
      <div className="Statistics-content Statistics-content-row">
        <h3 className="Statistics-title">By Country</h3>
        {data &&
          data.map(([country, percent], index) => (
            <div
              key={index}
              className={
                'row StatisticsCountry' +
                (isClickable(country) ? ' clickable' : '')
              }
              onClick={() => isClickable(country) && onClick(country)}
            >
              <div
                className={
                  'StatisticsCountry-square StatisticsCountry-square-level-' +
                  (index + 1)
                }
              >
                <div
                  className="StatisticsCountry-square-content"
                  notranslate="true"
                >
                  {percent === 0 ? '<1%' : Math.round(percent) + '%'}
                </div>
              </div>

              <div
                className={
                  'StatisticsCountry-Country-Name' +
                  (index === 0 ? ' StatisticsCountry-Country-Name-First' : '')
                }
              >
                {Util.capitalize(country)}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

StatisticsCountry.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string
};

export default StatisticsCountry;
