import React from 'react';
import { PrivateRoute } from 'react-common/components';
import { Route, Switch } from 'react-router-dom';

import OnboardingPreview from './OnboardingPreview/OnboardingPreview';
import OnboardingSupplier from './OnboardingSupplier/OnboardingSupplier';
import PageNotFound from '../PageNotFound/PageNotFound';

import './Onboarding.css';

const Onboarding = () => (
  <div>
    <Switch>
      <PrivateRoute
        exact
        path="/onboarding/:workflow_id/:section(introduction|questionnaire)"
        component={OnboardingPreview}
      />
      <Route
        exact
        path="/onboarding/:workflow_id"
        component={OnboardingSupplier}
      />
      <Route
        exact
        path="/onboarding/:workflow_id/suppliers/:supplier_id"
        component={OnboardingSupplier}
      />
      <Route render={() => <PageNotFound className="Onboarding-404" />} />
    </Switch>
  </div>
);

export default Onboarding;
