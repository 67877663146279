import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import './WorkflowsCreateUpdateModal.css';

const WorkflowsCreateUpdateModal = ({
  show,
  onlyUpdateClick,
  updateAndNotifyClick,
  handleClose
}) => (
  <Modal
    className="WorkflowsCreateUpdateModal"
    show={show}
    onHide={handleClose}
  >
    <Modal.Header>
      <Modal.Title>Update changes</Modal.Title>
      <i
        className="icon-ic_close pull-right Close-modal-icon"
        onClick={handleClose}
      />
    </Modal.Header>
    <Modal.Body>
      The workflow will be updated. Do you want to notify all suppliers by
      sending emails?
    </Modal.Body>
    <Modal.Footer>
      <button
        className="Btn-outline-small Btn-outline-secondary pull-left"
        onClick={handleClose}
      >
        Cancel
      </button>
      <button
        className="Btn-default-small Btn-outline-primary"
        onClick={onlyUpdateClick}
      >
        Only update
      </button>
      <button
        className="Btn-default-small Btn-default-success"
        onClick={updateAndNotifyClick}
      >
        Update and notify
      </button>
    </Modal.Footer>
  </Modal>
);

WorkflowsCreateUpdateModal.propTypes = {
  show: PropTypes.bool,
  onlyUpdateClick: PropTypes.func,
  updateAndNotifyClick: PropTypes.func,
  handleClose: PropTypes.func
};

export default WorkflowsCreateUpdateModal;
