import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { UploadcareDialog } from 'react-common/components';
import { Util } from 'react-common/services';
import { languages } from 'react-common/constants';
import { Field, reduxForm } from 'redux-form';

class AccountForm extends Component {
  render() {
    const {
      language,
      handleSubmit,
      change,
      submitting,
      formValues = {},
      accountChange: { success, error }
    } = this.props;

    const { info: { name, image } = {} } = formValues;
    const imageStyle = image ? { backgroundImage: `url(${image})` } : {};

    return (
      <div className="Account">
        <h2 className="Title-table">Profile</h2>
        <div className="Content-table-last">
          <form onSubmit={handleSubmit}>
            <label>User picture</label>

            <div
              className="Image-container"
              style={imageStyle}
              onClick={() => this.uploader.openDialog()}
            >
              {!image && (
                <div className="Colored-square">
                  <var>{Util.getInitials(name)}</var>
                </div>
              )}
              <div className="Image-overlay">
                <div className="Overlay-text">
                  {(image && 'Change') || 'Upload'}
                </div>
              </div>
              <UploadcareDialog
                value={image}
                ref={uploader => (this.uploader = uploader)}
                onUploadComplete={info => change('info.image', info.cdnUrl)}
                language={language}
              />
            </div>

            <label htmlFor="info.name">Full Name</label>
            <Field
              type="text"
              name="info.name"
              className="form-control"
              component="input"
              placeholder="Full Name"
            />

            <label htmlFor="info.email">Email</label>
            <Field
              type="text"
              name="info.email"
              className="form-control"
              component="input"
              placeholder="Email"
              disabled={true}
            />

            <div className="row">
              <div className="col-md-7">
                <label htmlFor="info.phone">Phone</label>
                <Field
                  type="text"
                  name="info.phone"
                  className="form-control"
                  component="input"
                  placeholder="Phone"
                />
              </div>

              <div className="col-md-5">
                <label htmlFor="info.phone_ext">Phone Ext.</label>
                <Field
                  type="text"
                  name="info.phone_ext"
                  className="form-control"
                  component="input"
                  placeholder="Phone"
                />
              </div>
            </div>

            <label htmlFor="preferences.language">Preferred language</label>
            <Field
              className="form-control"
              name="preferences.language"
              component={component => (
                <Dropdown
                  id="language-dropdown"
                  className="dropdown form-control Dropdown-form-control LanguageDropdown"
                  onSelect={component.input.onChange}
                >
                  <Dropdown.Toggle className="form-control Dropdown-form-control">
                    {languages[component.input.value || 'en'].full}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(languages)
                      .filter(key => languages[key].enabled)
                      .map(key => (
                        <MenuItem eventKey={key} key={key}>
                          {languages[key].full}
                        </MenuItem>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            />

            <button
              type="submit"
              disabled={submitting}
              className="Btn-default Btn-default-primary"
            >
              Update changes
            </button>

            {success && (
              <div className="alert alert-success" role="alert">
                Your account has been successfully updated
              </div>
            )}

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const Account = reduxForm({
  form: 'settings-account'
})(AccountForm);

export default Account;
