import React from 'react';
import { supplierStatuses } from 'react-common/constants';

import SuppliersTable from '../_components/SuppliersTable/SuppliersTable';
import SuppliersFilterModal from '../_components/SuppliersFilterModal/SuppliersFilterModal';
import SuppliersColumnSelector from '../_components/SuppliersColumnSelector/SuppliersColumnSelector';
import SuppliersSearchBar from '../_components/SuppliersSearchBar/SuppliersSearchBar';

import './Suppliers.css';

const Suppliers = ({
  workflows,
  suppliers,
  supplierStatus,
  searchQuery,
  onChangeSupplierStatus,
  onChangeSearchQuery,
  user,
  activeFilters
}) => (
  <div className="Suppliers">
    {/*  SEARCH BAR  */}
    <SuppliersSearchBar onChangeSearchQuery={onChangeSearchQuery} />
    {/*  END SEARCH BAR  */}

    <div className="Suppliers-btn-group pull-right">
      {/*  FILTER  */}
      <SuppliersFilterModal
        suppliers={suppliers}
        user={user}
        useRacRiskIndicator={false}
      />
      {/*  END FILTER  */}

      {/*  COLUMNS  */}
      {(supplierStatus === 'all' ||
        supplierStatus === supplierStatuses.COMPLETED ||
        supplierStatus === supplierStatuses.ARCHIVED) && (
        <SuppliersColumnSelector
          user={user}
          workflows={workflows}
          supplierStatus={supplierStatus}
        />
      )}
      {/*  END COLUMNS  */}
    </div>

    <div className="row">
      <SuppliersTable
        user={user}
        workflows={workflows}
        suppliers={suppliers}
        supplierStatus={supplierStatus}
        activeFilters={activeFilters}
        searchQuery={searchQuery}
        multipleWorkflows={true}
        onChangeSupplierStatus={onChangeSupplierStatus}
      />
    </div>
  </div>
);

export default Suppliers;
