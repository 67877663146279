import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Localize } from 'react-common/services';

import { getEnums } from '../../_actions/enums';
import { cialCountries, ccsCountries } from '../../_constants/onboarding';

import './CountryDropdown.css';

class CountryDropdown extends Component {
  componentDidMount() {
    const { countries, dispatchGetEnums } = this.props;
    if (!countries) {
      return dispatchGetEnums();
    }
  }

  render() {
    const { input, placeholder, isDisabled } = this.props;
    const { value, onChange } = input;
    let { countries } = this.props;

    if (!countries.length) {
      countries = cialCountries.concat(ccsCountries);
    }

    // react-select accept values as object in format { label, value }
    const selectedCountry = {
      value
    };

    const countriesOptions = countries
      .map(country => {
        const countryLabel = Localize.translate(country.title);
        const countryValue = country.title.toLowerCase();

        if (countryValue === selectedCountry.value) {
          selectedCountry.label = countryLabel;
        }

        return {
          label: countryLabel,
          value: countryValue
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <Select
        placeholder={placeholder || 'Country'}
        options={countriesOptions}
        value={selectedCountry.value ? selectedCountry : ''}
        onChange={country => country && onChange(country.value)}
        classNamePrefix="CountryDropdown"
        isDisabled={isDisabled}
      />
    );
  }
}

const mapStateToProps = state => {
  const { enums } = state;

  return {
    countries: enums.items.countries || []
  };
};

const mapDispatchToProps = {
  dispatchGetEnums: getEnums
};

CountryDropdown.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool
};

CountryDropdown.defaultProps = {
  isDisabled: false
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryDropdown);
