import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Tab, Tabs } from 'react-bootstrap';

import AppWrapper from '../_components/AppWrapper/AppWrapper';

import {
  changeAccountSettings,
  changeAccountSettingsFailure,
  resetPassword,
  resetPasswordFailure,
  updateHeaderBreadcrumbs
} from '../_actions';
import Account from './Account';
import ResetPassword from './ResetPassword';

import './Settings.css';

class Settings extends Component {
  componentDidMount() {
    const { dispatchUpdateHeaderBreadcrumbs } = this.props;

    dispatchUpdateHeaderBreadcrumbs([{ title: 'My Account' }]);
  }

  submitAccount = ({ info, preferences }) => {
    const {
      dispatchChangeAccountSettingsFailure,
      dispatchChangeAccountSettings
    } = this.props;
    const newAccount = { info: { ...info }, preferences: { ...preferences } };
    const { name, email, phone, phone_ext } = newAccount.info;

    if (!name || !email || !phone) {
      return dispatchChangeAccountSettingsFailure({
        error: 'All fields are required'
      });
    }

    if (phone_ext && phone_ext.length !== 4 && phone_ext.length !== 6) {
      return dispatchChangeAccountSettingsFailure({
        error: 'Phone extension must have 4 or 6 numbers'
      });
    }

    if (phone_ext) {
      newAccount.info.phone = `${phone} ext${phone_ext}`;
    }
    delete newAccount.info.phone_ext;

    return dispatchChangeAccountSettings(newAccount);
  };

  submitResetPassword = passwordData => {
    const { dispatchResetPasswordFailure, dispatchResetPassword } = this.props;
    const { oldPassword, newPassword, confirmPassword } = passwordData;
    const error = message => dispatchResetPasswordFailure({ error: message });

    // check all inputs are filled
    if (!oldPassword || !newPassword || !confirmPassword) {
      return error('All fields are required');
    }

    // check passwords are 7+ characters
    if (newPassword.length < 7) {
      return error(
        'The password you entered is too short. Please enter 7+ characters'
      );
    }

    // check passwords match
    if (newPassword !== confirmPassword) {
      return error('The passwords you entered do not match');
    }

    return dispatchResetPassword(oldPassword, newPassword);
  };

  handleTabSelect = section => {
    const { dispatchPush } = this.props;
    dispatchPush(`/settings/${section}`);
  };

  render() {
    const {
      accountChange,
      resetPassword,
      initialValues,
      formValues,
      match,
      language
    } = this.props;
    return (
      <AppWrapper>
        <div className="Settings">
          <div className="col-sm-10 col-sm-offset-2 col-xs-12">
            <div className="row">
              <div className="col-sm-7 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-5 col-xs-12">
                <Tabs
                  id="settings-tabs"
                  onSelect={this.handleTabSelect}
                  activeKey={match.params.section}
                  className="Settings-tabs"
                >
                  <Tab eventKey="profile" title="Profile">
                    <Account
                      onSubmit={this.submitAccount}
                      accountChange={accountChange}
                      initialValues={initialValues}
                      formValues={formValues}
                      language={language}
                    />
                  </Tab>

                  <Tab eventKey="password" title="Password">
                    <ResetPassword
                      onSubmit={this.submitResetPassword}
                      resetPassword={resetPassword}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { form, user } = state;
  const {
    accountChange,
    resetPassword,
    data: { user: { preferences: { language } = {} } = {} } = {}
  } = user;

  let formValues = Auth.getUser();
  [
    formValues.info.phone,
    formValues.info.phone_ext
  ] = formValues.info.phone.split(/\s*ext\s*/);

  if (form['settings-account'] && form['settings-account'].values) {
    formValues = form['settings-account'].values;
  }

  return {
    accountChange,
    resetPassword,
    initialValues: formValues,
    formValues,
    language
  };
};

const mapDispatchToProps = {
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchChangeAccountSettingsFailure: changeAccountSettingsFailure,
  dispatchChangeAccountSettings: changeAccountSettings,
  dispatchResetPasswordFailure: resetPasswordFailure,
  dispatchResetPassword: resetPassword,
  dispatchPush: push
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
