import React, { Component } from 'react';
import {
  workflowStatuses,
  workflowProducts,
  workflowReportTypes,
  questionTypes
} from 'react-common/constants';
import { ComponentWithLoader } from 'react-common/components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import {
  activateWorkflow,
  notifySuppliers,
  updateWorkflow,
  deleteDraft,
  getEnums,
  getWorkflow,
  updateHeaderBreadcrumbs
} from '../../_actions';
import PageNotFound from '../../PageNotFound/PageNotFound';
import WorkflowsCreate from './WorkflowsCreate';
import WorkflowsService from '../../_services/Workflows';
import Toast from '../../_services/Toast';

class WorkflowsCreateContainer extends Component {
  state = {
    isActivateModalOpen: false,
    isDeleteModalOpen: false,
    isUpdateModalOpen: false,
    showSavedAsDraftToast: true
  };

  componentDidMount() {
    const {
      dispatchGetWorkflow,
      dispatchGetEnums,
      initialValues,
      initialValues: { status = workflowStatuses.DRAFT } = {},
      workflowId,
      countries
    } = this.props;

    if (!initialValues) {
      dispatchGetWorkflow(workflowId);
    }

    if (!countries) {
      dispatchGetEnums();
    }

    this.updateHeaderBreadcrumbs(status);
  }

  componentDidUpdate(prevProps) {
    const { initialValues: currentValues = {} } = this.props;
    const { initialValues: prevValues = {} } = prevProps;
    if (currentValues.status && currentValues.status !== prevValues.status) {
      this.updateHeaderBreadcrumbs(currentValues.status);
    }
  }

  componentWillUnmount() {
    if (
      this.props.formValues &&
      this.props.formValues.status === workflowStatuses.DRAFT &&
      this.state.showSavedAsDraftToast
    ) {
      Toast.showInfo('Workflow is saved as draft.');
    }
  }

  updateHeaderBreadcrumbs = status => {
    const category = WorkflowsService.getWorkflowCategoryForStatus(status);

    this.props.dispatchUpdateHeaderBreadcrumbs([
      {
        title: category.headerTitle,
        path: category.path
      },
      {
        title: 'Edit Workflow'
      }
    ]);
  };

  showActivateModal = () => this.setState({ isActivateModalOpen: true });
  closeActivateModal = () => this.setState({ isActivateModalOpen: false });

  showDeleteModal = () => this.setState({ isDeleteModalOpen: true });
  closeDeleteModal = () => this.setState({ isDeleteModalOpen: false });

  showUpdateModal = () => this.setState({ isUpdateModalOpen: true });
  closeUpdateModal = () => this.setState({ isUpdateModalOpen: false });

  activateWorkflow = workflow => {
    const { dispatchActivateWorkflow } = this.props;
    this.setState({ showSavedAsDraftToast: false });
    return dispatchActivateWorkflow(workflow);
  };

  updateWorkflow = workflow => {
    const { dispatchUpdateWorkflow } = this.props;
    this.setState({ showSavedAsDraftToast: false });
    return dispatchUpdateWorkflow(workflow, true);
  };

  notifyAllSuppliers = () => {
    const { dispatchNotifySuppliers, workflowId } = this.props;
    return dispatchNotifySuppliers(workflowId);
  };

  backToResults = () => {
    const { dispatchPush } = this.props;
    return dispatchPush('results');
  };

  deleteWorkflow = workflowId => {
    const { dispatchDeleteDraft } = this.props;
    this.setState({ showSavedAsDraftToast: false });
    return dispatchDeleteDraft(workflowId);
  };

  render() {
    const {
      showPageNotFound,
      showLoader,
      formValues,
      initialValues = {}
    } = this.props;

    if (showPageNotFound) {
      return <PageNotFound />;
    }

    return (
      <ComponentWithLoader showLoader={showLoader}>
        <WorkflowsCreate
          onSubmit={
            formValues && formValues.status === workflowStatuses.DRAFT
              ? this.activateWorkflow
              : this.updateWorkflow
          }
          deleteWorkflow={this.deleteWorkflow}
          isActivateModalOpen={this.state.isActivateModalOpen}
          showActivateModal={this.showActivateModal}
          closeActivateModal={this.closeActivateModal}
          isDeleteModalOpen={this.state.isDeleteModalOpen}
          showDeleteModal={this.showDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          isUpdateModalOpen={this.state.isUpdateModalOpen}
          showUpdateModal={this.showUpdateModal}
          closeUpdateModal={this.closeUpdateModal}
          notifyAllSuppliers={this.notifyAllSuppliers}
          backToResults={this.backToResults}
          form={`create-workflow-${initialValues._id}`}
          key={`create-workflow-${initialValues._id}`}
          {...this.props}
        />
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    workflows: { uploadingFiles = [], getById, suppliers, isFetching } = {},
    form,
    enums: { items: { countries } },
    user: { data: { user } }
  } = state;
  const { match: { params: { workflow_id: workflowId } = {} } = {} } = props;
  const workflow = getById[workflowId];
  let formValues = {};
  let hasQuestionnaireErrors = false;
  let initialWorkflow;
  let errorText = '';

  // form redux fix, add init suppliers and questionnaire
  if (workflow) {
    initialWorkflow = { ...workflow };
    initialWorkflow.suppliers = workflow.suppliers.map(supplierId => {
      const supplier = suppliers.getById[supplierId] || {
        invite: {}
      };
      supplier.invite.emails = WorkflowsService.getSupplierEmails(supplier);
      return supplier;
    });
    if (!initialWorkflow.suppliers.length) {
      initialWorkflow.suppliers = [{}];
    }

    const formName = `create-workflow-${workflow._id}`;
    if (form[formName] && form[formName].values) {
      formValues = form[formName].values;

      if (
        formValues.suppliers &&
        formValues.suppliers.length &&
        formValues.status === workflowStatuses.DRAFT
      ) {
        const hasSuppliersErrors = formValues.suppliers.some(
          supplier => !WorkflowsService.isSupplierValid(supplier, formValues)
        );

        const taxIdErrors = formValues.suppliers.some(supplier => {
          const { invite: { tax_id, country } = {} } = supplier;
          return (
            tax_id && !WorkflowsService.isTaxIdValidForCountry(tax_id, country)
          );
        });
        if (hasSuppliersErrors) {
          errorText =
            'Please fix suppliers form. Some required fields are empty.';
        } else if (taxIdErrors) {
          errorText = 'Please fix TAX ID. Only valid CNPJ is allowed.';
        } else if (user) {
          let reportType = workflowProducts.SCORE;
          if (formValues.product === workflowProducts.REPORT) {
            reportType = formValues.report_type;
          }

          const productCount = user.getProduct(reportType);

          if (
            productCount.used + formValues.suppliers.length >
            productCount.total
          ) {
            errorText =
              'You reached product limit. Please contact support for upgrade.';
          }
        }
      }

      if (formValues.questionnaire && formValues.questionnaire.length) {
        hasQuestionnaireErrors = formValues.questionnaire.some(
          question =>
            !question.title ||
            !question.type ||
            ((question.type === questionTypes.RADIO ||
              question.type === questionTypes.CHECKBOX) &&
              question.options &&
              question.options.some(option => !option.value)) ||
            (question.type === questionTypes.ATTACHMENT && !question.file)
        );
        if (hasQuestionnaireErrors) {
          errorText = 'Please fix questionnaire form. All fields are required.';
        }

        formValues.questionnaire.forEach((question, index) => {
          if (question.type === questionTypes.ATTACHMENT) {
            if (!question._id && workflow.questionnaire[index]) {
              question._id = workflow.questionnaire[index]._id;
            }
          }
        });
      } else if (formValues.report_type === workflowReportTypes.QUESTIONNAIRE) {
        errorText = 'Questionnaire is empty.';
      }

      if (formValues.info && !formValues.info.title) {
        errorText = 'Please fill in workflow title.';
      }
    }
  }

  return {
    workflowId,
    countries,
    user,
    initialValues: initialWorkflow,
    formValues,
    showPageNotFound:
      !user.isCompanyAdmin() ||
      (!workflow && !isFetching) ||
      (workflow && workflow.status === workflowStatuses.CLOSED),
    showLoader: isFetching,
    hasQuestionnaireErrors,
    errorText,
    reportTypes: user.getEnabledReportTypes(),
    uploadingFiles
  };
};

const mapDispatchToProps = {
  dispatchGetWorkflow: getWorkflow,
  dispatchActivateWorkflow: activateWorkflow,
  dispatchUpdateWorkflow: updateWorkflow,
  dispatchDeleteDraft: deleteDraft,
  dispatchNotifySuppliers: notifySuppliers,
  dispatchGetEnums: getEnums,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchPush: push
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsCreateContainer
);
