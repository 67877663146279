import { logout } from './user';

export function handleError(dispatch, failureFunction) {
  return response => {
    if (response.status === 401) {
      return dispatch(logout());
    }

    if (!response.text) {
      return dispatch(failureFunction({ error: response }));
    }

    return response.text().then(errorMessage => {
      try {
        const error = JSON.parse(errorMessage);
        return dispatch(failureFunction(error));
      } catch (err) {
        return dispatch(failureFunction({ error: errorMessage }));
      }
    });
  };
}
