import find from 'lodash/find';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import { Fetch, Util } from 'react-common/services';
import {
  racRiskIndicators,
  supplierStatuses,
  workflowProducts,
  workflowReportTypes,
  workflowStatuses,
  workflowTypes
} from 'react-common/constants';
import {
  workflowCategories,
  commercialScoreGroups,
  paydexGroups,
  supplierColumns
} from '../_constants/workflows';

class WorkflowsService {
  getWorkflows(status = workflowStatuses.ACTIVE, fields) {
    let query = {};
    if (status) {
      query.status = status;
    }
    if (fields) {
      query.fields = fields.join(',');
    }
    return Fetch.getJSON(`/api/workflows` + Util.queryToString(query));
  }

  getWorkflow(workflowId) {
    return Fetch.getJSON(`/api/workflows/${workflowId}`);
  }

  createWorkflow(workflow) {
    return Fetch.postJSON('/api/workflows', workflow);
  }

  updateWorkflow(workflow) {
    return Fetch.putJSON(`/api/workflows/${workflow._id}`, workflow);
  }

  activateWorkflow(workflowId) {
    return Fetch.putJSON(`/api/workflows/${workflowId}/activate`, {});
  }

  closeWorkflow(workflowId) {
    return Fetch.putJSON(`/api/workflows/${workflowId}/close`, {});
  }

  deleteDraft(workflowId) {
    return Fetch.deleteJSON(`/api/workflows/${workflowId}`);
  }

  getStatistics(workflowIds) {
    if (workflowIds && !(workflowIds instanceof Array)) {
      workflowIds = [workflowIds];
    }
    return Fetch.getJSON(
      '/api/workflows/statistics' +
        (workflowIds ? `?ids=${workflowIds.join(',')}` : '')
    ).then(data => {
      // clean statistics data
      ['riskIndicator', 'racRiskIndicator', 'financialStrength'].forEach(
        supplierData => {
          if (data.suppliers && data.suppliers[supplierData]) {
            Object.keys(data.suppliers[supplierData]).forEach(key => {
              if (!key) {
                delete data.suppliers[supplierData][key];
              }
            });
          }
        }
      );
      return data;
    });
  }

  getActivities(workflowIds) {
    if (workflowIds && !(workflowIds instanceof Array)) {
      workflowIds = [workflowIds];
    }
    return Fetch.getJSON(
      '/api/workflows/activities' +
        (workflowIds ? `?ids=${workflowIds.join(',')}` : '')
    );
  }

  addSupplier(workflowId, supplier) {
    return Fetch.postJSON(`/api/workflows/${workflowId}/suppliers`, supplier);
  }

  addSuppliersBulk(workflowId, suppliers) {
    return Fetch.postJSON(
      `/api/workflows/${workflowId}/suppliers_bulk`,
      suppliers
    );
  }

  deleteSupplier(workflowId, supplierId) {
    return Fetch.deleteJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}`
    );
  }

  updateSupplier(workflowId, supplierId, supplierData) {
    return Fetch.putJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}`,
      {
        _id: supplierId,
        ...supplierData
      }
    );
  }

  requestBirReport(workflowId, supplier) {
    return Fetch.postJSON('/api/bir-requests', {
      supplier: {
        workflow_id: workflowId,
        supplier_id: supplier._id
      }
    }).then(birRequest =>
      Fetch.postJSON(`/api/bir-requests/${birRequest._id}/submit`)
    );
  }

  requestCcrReport(workflowId, supplierId) {
    return Fetch.postJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}/ccr`
    );
  }

  changeSupplierStatus(workflowId, supplierId, status) {
    return Fetch.putJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}`,
      {
        _id: supplierId,
        status
      }
    );
  }

  sendReminderToAllSuppliers(workflowId) {
    return Fetch.postJSON(`/api/workflows/${workflowId}/suppliers/reminders`);
  }

  sendSupplierReminder(workflowId, supplierId) {
    return Fetch.postJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}/reminders`
    );
  }

  addAttachmentToQuestion(workflowId, questionId, attachment) {
    return Fetch.patch(
      `/api/workflows/${workflowId}/questionnaire/${questionId}/attachment`,
      attachment
    );
  }

  addFileToSupplier(workflowId, supplierId, file) {
    return Fetch.patch(
      `/api/workflows/${workflowId}/suppliers/${supplierId}/files`,
      file
    );
  }

  deleteFileFromSupplier(workflowId, supplierId, fileId) {
    return Fetch.deleteJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}/files/${fileId}`
    );
  }

  screenSupplier(workflowId, supplierId, shareholders = []) {
    return Fetch.patchJSON(
      `/api/workflows/${workflowId}/suppliers/${supplierId}/screen`,
      { shareholders }
    );
  }

  getQuestionnaireAnswers(workflow, supplier) {
    if (!workflow || !supplier || !supplier.getAnswers()) {
      return [];
    }

    const answers = supplier.getAnswers();
    return workflow.questionnaire.map(question => ({
      question: question,
      answer: find(answers, answer => answer.question_id === question._id)
    }));
  }

  searchSuppliers(searchQuery) {
    searchQuery = searchQuery.toLowerCase();
    return supplier => {
      const satisfies = field =>
        !!field && field.toLowerCase().indexOf(searchQuery) !== -1;
      return [
        supplier.getCompanyName(),
        supplier.getFullName(),
        supplier.getTaxId(),
        ...supplier.getAllEmails()
      ].some(satisfies);
    };
  }

  parseSuppliersBulk(data, countries = [], workflow) {
    const countriesMap = Util.arrayToObject(
      countries.map(country => country.title.toLowerCase())
    );
    const suppliers = [];
    const errors = [];
    const splitLines = data.split('\n');

    // 100 supplier limit
    if (splitLines.filter(line => line).length > 100) {
      errors.push('Supplier bulk exceeds 100 supplier limit');
    }

    for (let line of splitLines) {
      line = line.trim();

      // skip empty lines
      if (!line) {
        continue;
      }

      // split line by semi-colon, trim all whitespaces
      const parts = line.split(/\s*;\s*/);

      let numberOfElements =
        workflow.type === workflowTypes.INVESTIGATING ? 9 : 8;

      if (this.isCfrReport(workflow)) {
        numberOfElements = 10;
      }

      if (parts.length !== numberOfElements) {
        errors.push(
          `Found record with more or less than <var>${numberOfElements}</var> elements: <var>${line}</var>`
        );
        continue;
      }
      const [
        company_name,
        full_name,
        emails,
        countryInput,
        tax_id,
        address,
        sic,
        phone,
        duns_number,
        shareholders
      ] = parts;
      const country =
        this.isCkrInvestigation(workflow) || this.isCcrInvestigation(workflow)
          ? 'brazil'
          : countryInput.toLowerCase();

      if (this.isCkrInvestigation(workflow) && !tax_id) {
        errors.push('Tax ID is required');
        continue;
      }

      if (this.isCfrReport(workflow) && !duns_number) {
        errors.push('D-U-N-S Number is required');
        continue;
      }

      if (
        !this.isCkrInvestigation(workflow) &&
        !this.isCcr(workflow) &&
        !this.isCfrReport(workflow)
      ) {
        const required_fields = [company_name, full_name, emails, countryInput];
        if (this.isTaxIdRequired(country)) {
          required_fields.push(tax_id);
        }

        if (!required_fields.every(x => !!x)) {
          errors.push(
            this.isTaxIdRequired(country)
              ? 'Company name, Full name, Emails, Country and Tax ID are required'
              : 'Company name, Full name, Emails and Country are required'
          );
        }

        // unknown countries are not allowed
        if (!countriesMap[country]) {
          errors.push(`Found unknown country: <var>${country}</var>`);
          continue;
        }
      }

      // invalid emails are not allowed
      let invalidEmails = false;
      let emailArrays = emails ? emails.split(/\s*,\s*/) : [];

      if (
        (!this.isCkrInvestigation(workflow) &&
          !this.isCcrInvestigation(workflow)) ||
        emails
      ) {
        emailArrays.forEach(email => {
          if (!Util.isValidEmail(email)) {
            invalidEmails = true;
            errors.push(`Found invalid email: <var>${email}</var>`);
          }
        });
      }

      if (invalidEmails) {
        continue;
      }

      if (!this.isTaxIdValidForCountry(tax_id, country)) {
        errors.push(`Found invalid tax id: <var>${tax_id}</var>`);
        continue;
      }

      suppliers.push({
        invite: {
          company_name,
          full_name,
          emails: emailArrays,
          email: emailArrays[0],
          secondary_emails: emailArrays.slice(1),
          country,
          address,
          sic,
          tax_id,
          phone,
          duns_number,
          shareholders: shareholders ? shareholders.split(/\s*,\s*/) : []
        }
      });
    }

    return { suppliers, errors };
  }

  getWorkflowCategoryForStatus = status =>
    find(workflowCategories, category => category.type === status);

  getWorkflowCategoryForPath = path =>
    find(workflowCategories, category => category.path === path);

  isValidPaydex = paydex => {
    paydex = parseInt(paydex, 10);

    return !!paydex && paydex >= 0 && paydex <= 100;
  };

  getPaydexGroup = paydex => {
    paydex = parseInt(paydex, 10);

    // paydex is not an integer number, or out of range
    if (!paydex || paydex < 0 || paydex > 100) {
      return paydexGroups.UNAVAILABLE;
    }

    if (paydex < 50) {
      return paydexGroups.BAD;
    }
    if (paydex < 80) {
      return paydexGroups.OK;
    }
    // paydex <= 100
    return paydexGroups.GOOD;
  };

  getPaydexDescription = paydex => {
    paydex = parseInt(paydex, 10);

    // paydex is not an integer number, or out of range
    if (!paydex || paydex < 0 || paydex > 100) {
      return 'Unavailable';
    }
    if (paydex < 50) {
      return 'Considerable Delay';
    }
    if (paydex < 80) {
      return 'Delayed payment';
    }
    // paydex <= 100
    return 'Punctual payment';
  };

  getSupplierEmails = supplier =>
    [supplier.invite.email]
      .concat(supplier.invite.secondary_emails || [])
      .filter(email => email);

  parseSupplierEmails = supplier => {
    if (supplier && supplier.invite) {
      [supplier.invite.email, ...supplier.invite.secondary_emails] =
        supplier.invite.emails || [];
    }
    return supplier;
  };

  isCkrInvestigation = workflow =>
    workflow.type === workflowTypes.INVESTIGATING &&
    (workflow.report_type === workflowReportTypes.SHORT_FORM ||
      workflow.report_type === workflowReportTypes.SHORT_FORM_PLUS);

  isCcrInvestigation = workflow =>
    workflow.type === workflowTypes.INVESTIGATING &&
    workflow.report_type === workflowReportTypes.CCR;

  isCcr = workflow => workflow.report_type === workflowReportTypes.CCR;
  isCfrReport = workflow => workflow.report_type === workflowReportTypes.CFR;

  isSupplierValid = (supplier, workflow) => {
    if (this.isCkrInvestigation(workflow)) {
      return !!supplier.invite && !!supplier.invite.tax_id;
    }

    if (this.isCfrReport(workflow)) {
      return !!supplier.invite && !!supplier.invite.duns_number;
    }

    return (
      !!supplier.invite &&
      !!supplier.invite.company_name &&
      !!supplier.invite.full_name &&
      !!supplier.invite.emails &&
      !!supplier.invite.emails.length &&
      !!supplier.invite.emails.every(Util.isValidEmail) &&
      !!supplier.invite.country &&
      !!(this.isTaxIdRequired(supplier.invite.country) &&
      workflow.report_type !== workflowReportTypes.CCR
        ? !!supplier.invite.tax_id
        : true)
    );
  };

  isTaxIdRequired = country => country === 'brazil';

  isTaxIdValidForCountry = (taxId = '', country) =>
    country === 'brazil' ? isValidCnpj(taxId, true) : true;

  getCommercialScoreGroup = commercialScore => {
    const scoreNumber = parseInt(commercialScore, 10);
    if (scoreNumber < 21) {
      return commercialScoreGroups.ALERT;
    }
    if (scoreNumber < 41) {
      return commercialScoreGroups.TO_MONITOR;
    }
    if (scoreNumber < 61) {
      return commercialScoreGroups.REGULAR;
    }
    if (scoreNumber < 81) {
      return commercialScoreGroups.GOOD;
    }
    return commercialScoreGroups.GREAT;
  };

  prepopulateCommercialScoreStatistics = statistics => {
    statistics.suppliers.commercialScore = Object.keys(
      statistics.suppliers.commercialScore
    ).reduce((stats, score) => {
      const group = this.getCommercialScoreGroup(score);

      if (!stats[group]) {
        stats[group] = 0;
      }
      stats[group] += statistics.suppliers.commercialScore[score];

      return stats;
    }, {});
  };

  prepopulateRacRiskIndicatorStatistics = statistics => {
    statistics.suppliers.racRiskIndicator = Object.keys(
      statistics.suppliers.racRiskIndicator
    ).reduce((stats, riskIndicator) => {
      const indicator = racRiskIndicators[riskIndicator] ? riskIndicator : '-';
      if (!stats[indicator]) {
        stats[indicator] = 0;
      }
      stats[indicator] += statistics.suppliers.racRiskIndicator[riskIndicator];

      return stats;
    }, {});
  };

  isFilterActive(filters) {
    return (
      filters &&
      (filters.status ||
        (filters.country && filters.country.length > 0) ||
        (filters.financialStrength && filters.financialStrength.length > 0) ||
        (filters.industry && filters.industry.length > 0) ||
        (filters.riskIndicator && filters.riskIndicator.length > 0) ||
        (filters.commercialScore && filters.commercialScore.length > 0) ||
        (filters.tags && filters.tags.length > 0))
    );
  }

  filterSuppliers(suppliers, filters) {
    return suppliers.filter(supplier => {
      if (filters.status && supplier.getStatus() !== filters.status) {
        return false;
      }
      if (
        filters.country.length > 0 &&
        !includes(filters.country, supplier.getCountry())
      ) {
        return false;
      }
      if (
        filters.financialStrength.length > 0 &&
        !includes(filters.financialStrength, supplier.getFinancialStrength())
      ) {
        return false;
      }
      if (
        filters.riskIndicator.length > 0 &&
        !includes(
          filters.riskIndicator,
          supplier.getRacRiskIndicator() || supplier.getRiskIndicator()
        )
      ) {
        return false;
      }
      if (
        filters.industry.length > 0 &&
        !includes(filters.industry, supplier.getIndustry())
      ) {
        return false;
      }
      if (
        filters.commercialScore.length > 0 &&
        (!supplier.getCommercialScore() ||
          !includes(
            filters.commercialScore,
            this.getCommercialScoreGroup(supplier.getCommercialScore())
          ))
      ) {
        return false;
      }
      if (
        filters.tags.length > 0 &&
        isEmpty(intersection(filters.tags, supplier.getTags()))
      ) {
        return false;
      }
      return true;
    });
  }

  getAvailableColumnsForWorkflow(workflow, user, supplierStatus) {
    return [
      supplierColumns.COMPANY_NAME,
      (supplierStatus === 'all' ||
        (workflow.isOnboarding() &&
          supplierStatus === supplierStatuses.IN_PROGRESS)) &&
        supplierColumns.STATUS,
      supplierColumns.FULL_NAME,
      supplierColumns.TAGS,
      supplierColumns.EMAIL,
      supplierColumns.COUNTRY,
      supplierColumns.ADDRESS,
      supplierColumns.SIC,
      supplierColumns.TAX_ID,
      supplierColumns.PHONE,
      !workflow.isCkrReport() &&
        !workflow.isCcrReport() &&
        !workflow.isQuestionnaireReport() &&
        supplierColumns.FINANCIAL_STRENGTH,
      workflow.isCkrReport() && supplierColumns.COMMERCIAL_SCORE,
      !workflow.isCkrReport() &&
        !workflow.isRafOrRacReport() &&
        !workflow.isSqrReport() &&
        !workflow.isCcrReport() &&
        !workflow.isQuestionnaireReport() &&
        supplierColumns.RISK_INDICATOR,
      (workflow.isRafOrRacReport() || workflow.isSqrReport()) &&
        supplierColumns.RAC_RISK_INDICATOR,
      !workflow.isCkrReport() &&
        !workflow.isQuestionnaireReport() &&
        !workflow.isCcrReport() &&
        !workflow.isSqrReport() &&
        supplierColumns.DNB_RATING,
      !workflow.isQuestionnaireReport() && supplierColumns.DELIVERY_DATE,
      !workflow.isCkrReport() &&
        !workflow.isQuestionnaireReport() &&
        !workflow.isCcrReport() &&
        supplierColumns.BALANCE_SHEET_DATE,
      supplierColumns.INDUSTRY,
      !workflow.isQuestionnaireReport() && supplierColumns.DUNS_NUMBER,
      !workflow.isCkrReport() &&
        !workflow.isRafOrRacReport() &&
        !workflow.isCcrReport() &&
        !workflow.isQuestionnaireReport() &&
        supplierColumns.EMMA_SCORE,
      !workflow.isCkrReport() &&
        !workflow.isRafOrRacReport() &&
        !workflow.isCcrReport() &&
        !workflow.isQuestionnaireReport() &&
        supplierColumns.PAYDEX,
      !workflow.isCkrReport() &&
        !workflow.isCcrReport() &&
        !workflow.isQuestionnaireReport() &&
        supplierColumns.NUMBER_OF_SUITS,
      !workflow.isQuestionnaireReport() && supplierColumns.WEBSITE,
      !workflow.isQuestionnaireReport() && supplierColumns.SHAREHOLDERS,
      supplierColumns.REVENUE,
      supplierColumns.NUMBER_OF_EMPLOYEES,
      workflow.isSqrReport() && supplierColumns.SHORT_TERM_DEBIT,
      workflow.isSqrReport() && supplierColumns.LONG_TERM_DEBIT,
      workflow.isSqrReport() && supplierColumns.EBIT,
      workflow.isSqrReport() && supplierColumns.FINANCIAL_EXPENSES,
      workflow.isSqrReport() && supplierColumns.CURRENT_ASSETS,
      workflow.isSqrReport() && supplierColumns.LONG_TERM_ASSETS,
      workflow.isSqrReport() && supplierColumns.CURRENT_LIABILITIES,
      workflow.isSqrReport() && supplierColumns.LONG_TERM_LIABILITIES,
      workflow.isSqrReport() && supplierColumns.ACCOUNTS_RECEIVABLE,
      workflow.isSqrReport() && supplierColumns.ACCOUNTS_PAYABLE,
      workflow.isSqrReport() && supplierColumns.INVENTORY,
      workflow.isSqrReport() && supplierColumns.GLOBAL_ULTIMATE_DUNS_NUMBER,
      workflow.isSqrReport() && supplierColumns.GLOBAL_ULTIMATE_COMPANY_NAME,
      user.isComplianceEnabled() && supplierColumns.SCREEN_STATUS,
      user.isCcrProductEnabled() && supplierColumns.CCR_SCORE,
      user.isCcrProductEnabled() && supplierColumns.CCR_STATUS,
      workflow.hasQuestionnaire() && supplierColumns.QUESTIONNAIRE_STATUS,
      supplierColumns.ACTIONS
    ].filter(Boolean);
  }

  getDefaultColumns = workflow => {
    if (workflow.getProduct() === workflowProducts.SCORE) {
      return [
        supplierColumns.COMPANY_NAME,
        supplierColumns.FULL_NAME,
        supplierColumns.EMAIL,
        supplierColumns.COUNTRY,
        supplierColumns.ADDRESS,
        supplierColumns.SIC,
        supplierColumns.TAX_ID,
        supplierColumns.PHONE,
        supplierColumns.DNB_RATING,
        supplierColumns.ACTIONS
      ];
    }
    if (workflow.isRafOrRacReport()) {
      return [
        supplierColumns.COMPANY_NAME,
        supplierColumns.FULL_NAME,
        supplierColumns.COUNTRY,
        supplierColumns.INDUSTRY,
        supplierColumns.DUNS_NUMBER,
        supplierColumns.NUMBER_OF_SUITS,
        supplierColumns.FINANCIAL_STRENGTH,
        supplierColumns.RAC_RISK_INDICATOR,
        supplierColumns.ACTIONS
      ];
    }
    if (workflow.isCkrReport()) {
      return [
        supplierColumns.COMPANY_NAME,
        supplierColumns.FULL_NAME,
        supplierColumns.EMAIL,
        supplierColumns.COUNTRY,
        supplierColumns.INDUSTRY,
        supplierColumns.DUNS_NUMBER,
        supplierColumns.COMMERCIAL_SCORE,
        supplierColumns.ACTIONS
      ];
    }
    if (workflow.isQuestionnaireReport()) {
      return [
        supplierColumns.COMPANY_NAME,
        supplierColumns.FULL_NAME,
        supplierColumns.EMAIL,
        supplierColumns.COUNTRY,
        supplierColumns.ACTIONS
      ];
    }

    // BIR or SQR
    return [
      supplierColumns.COMPANY_NAME,
      supplierColumns.FULL_NAME,
      supplierColumns.COUNTRY,
      supplierColumns.INDUSTRY,
      supplierColumns.DUNS_NUMBER,
      supplierColumns.NUMBER_OF_SUITS,
      supplierColumns.FINANCIAL_STRENGTH,
      supplierColumns.RISK_INDICATOR,
      supplierColumns.ACTIONS
    ];
  };

  getSelectedColumnsForWorkflow(workflow, user, supplierStatus) {
    let columns;
    if (
      supplierStatus === supplierStatuses.PENDING ||
      supplierStatus === supplierStatuses.IN_PROGRESS
    ) {
      columns = [
        supplierColumns.COMPANY_NAME,
        supplierColumns.FULL_NAME,
        supplierColumns.EMAIL,
        supplierColumns.COUNTRY,
        supplierColumns.ADDRESS,
        supplierColumns.SIC,
        supplierColumns.TAX_ID,
        supplierColumns.PHONE,
        supplierColumns.DUNS_NUMBER,
        workflow.isCfrReport() && supplierColumns.SHAREHOLDERS
      ].filter(Boolean);

      // allow actions only for onboarding workflows and pending suppliers who are company admins
      if (
        workflow.isOnboarding() &&
        supplierStatus === supplierStatuses.PENDING &&
        user.isCompanyAdmin()
      ) {
        columns.push(supplierColumns.ACTIONS);
      }
    } else {
      columns = user.getSupplierColumns();
      if (!columns || !columns.length) {
        columns = this.getDefaultColumns(workflow);
      }
      const availableColumns = this.getAvailableColumnsForWorkflow(
        workflow,
        user,
        supplierStatus
      );
      columns = columns.filter(column => availableColumns.includes(column));
    }

    if (
      columns[1] !== supplierColumns.STATUS &&
      (supplierStatus === 'all' ||
        (workflow.isOnboarding() &&
          supplierStatus === supplierStatuses.IN_PROGRESS))
    ) {
      columns.splice(1, 0, supplierColumns.STATUS);
    }

    return columns;
  }
}

export default new WorkflowsService();
