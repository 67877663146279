import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { workflowStatuses } from 'react-common/constants';
import { NavLink, Link } from 'react-router-dom';
import { Localize } from 'react-common/services';
import { onboardingLinks } from '../../_constants/onboarding';

import { workflowCategories } from '../../_constants/workflows';
import logo from '../../_images/logo_side-menu.svg';
import './NavigationMenu.css';

class NavigationMenu extends Component {
  render() {
    const {
      className = '',
      isUserCompanyAdmin,
      onCreateWorkflowClick,
      pathname,
      workflow
    } = this.props;

    return (
      <div className={`NavigationMenu hidden-xs ${className}`}>
        <ul className="NavigationMenu-list nav nav-pills nav-stacked no-border">
          <li>
            <NavLink to="/home">
              <img
                src={logo}
                alt="Supplier360"
                className="NavigationMenu-logo"
              />
            </NavLink>
          </li>

          {isUserCompanyAdmin && (
            <li>
              <button
                className="Btn-default-small Btn-default-primary Navigation-create-btn"
                onClick={onCreateWorkflowClick}
              >
                <i className="icon-ic_plus" />
                <span>New workflow</span>
              </button>
            </li>
          )}

          <li>
            <NavLink to="/home">
              <i className="icon-ic_dashboard" />
              Dashboard
            </NavLink>
          </li>

          <li>
            <NavLink to="/suppliers">
              <i className="icon-ic_search" />
              Suppliers
            </NavLink>
          </li>

          <li className="NavigationMenu-list-title">
            <span>Workflows</span>
          </li>

          {workflowCategories.map((category, index) => {
            if (
              category.type === workflowStatuses.DRAFT &&
              !isUserCompanyAdmin
            ) {
              return null;
            }
            return (
              <li className="NavigationMenu-list-inner" key={index}>
                <Link
                  to={category.path}
                  className={
                    pathname === category.path ||
                    (workflow && workflow.getStatus() === category.type)
                      ? 'active'
                      : ''
                  }
                >
                  <i className={'icon-ic_workflows-' + category.type} />
                  {category.headerTitle}
                </Link>
              </li>
            );
          })}

          <li className="fixed-bottom">
            <NavLink to="/contact">
              <i className="icon-ic_talk-to-us" />
              Contact us
            </NavLink>
            <div className="tos">
              <a
                href={onboardingLinks.privacy[Localize.getLanguage()]}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <span className="middot">&middot;</span>
              <a
                href={onboardingLinks.terms[Localize.getLanguage()]}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of use
              </a>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

NavigationMenu.propTypes = {
  className: PropTypes.string,
  isUserCompanyAdmin: PropTypes.bool,
  onCreateWorkflowClick: PropTypes.func
};

export default NavigationMenu;
