import React, { Component } from 'react';
import { Sidebar } from 'react-common/components';
import { Field, reduxForm } from 'redux-form';

import s360Logo from '../_images/logo_side-menu.svg';
import PublicHeader from '../_components/Header/PublicHeader';

import './ChangePassword.css';

class ChangePasswordForm extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      changePassword: { success, error }
    } = this.props;

    return (
      <div>
        <Sidebar
          logo={s360Logo}
          logoAlt="Supplier360"
          className="col-md-3 hidden-sm hidden-xs"
          headerText="Create new password"
          descriptionText="Enter and confirm your new password"
        />
        <div className="col-md-9">
          <div className="row">
            <PublicHeader />
            <div className="col-md-5 col-md-offset-3">
              <form onSubmit={handleSubmit} className="ChangePassword-form">
                <div className="form-group">
                  <label htmlFor="password">New Password</label>
                  <Field
                    type="password"
                    className="form-control"
                    name="password"
                    component="input"
                    placeholder="7+ characters"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirm-password">Confirm New Password</label>
                  <Field
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    component="input"
                    placeholder="7+ characters"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={submitting || success}
                    className="Btn-default Btn-default-primary form-control"
                  >
                    Create
                  </button>
                </div>
                <div>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                </div>
                <div>
                  {success && (
                    <div className="alert alert-success" role="alert">
                      Your password has been changed successfully. We'll get you
                      to login in a second..
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ChangePassword = reduxForm({
  form: 'change-password'
})(ChangePasswordForm);

export default ChangePassword;
