import React from 'react';

const LocalCcsInfo = () => (
  <>
    <p>Please make your payment using the following bank information.</p>
    <div>
      <div>
        <label>Bank:</label>
        <p>JP Morgan Chase</p>
      </div>
      <div>
        <label>Address:</label>
        <p>349 5th Ave. New York, NY 11105</p>
      </div>
      <div>
        <label>ABA:</label>
        <p notranslate="true">2100021</p>
      </div>
      <div>
        <label>SWIFT:</label>
        <p notranslate="true">CHASUS33</p>
      </div>
      <div>
        <label>Account:</label>
        <p notranslate="true">251786122</p>
      </div>
      <div>
        <label>Recipient:</label>
        <p>Cial D&B Services Inc</p>
      </div>
    </div>
    <p>Wire fees & taxes are responsibility of the payer.</p>
  </>
);

export default LocalCcsInfo;
