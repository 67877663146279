import PropTypes from 'prop-types';
import React from 'react';

import { workflowReportTypes } from 'react-common/constants';

import StatisticsCountry from './StatisticsCountry/StatisticsCountry';
import StatisticsFinancialStrength from './StatisticsFinancialStrength/StatisticsFinancialStrength';
import StatisticsIndustry from './StatisticsIndustry/StatisticsIndustry';
import StatisticsRiskIndicator from './StatisticsRiskIndicator/StatisticsRiskIndicator';
import StatisticsCommercialScore from './StatisticsCommercialScore/StatisticsCommercialScore';
import StatisticsRecentActivity from './StatisticsRecentActivity/StatisticsRecentActivity';

import './Statistics.css';

const Statistics = ({
  statistics: { suppliers, activities },
  workflow,
  user,
  onClick
}) => {
  const reportTypes = user.getEnabledReportTypes();
  const onlyRacIsEnabled =
    reportTypes.length === 1 &&
    (reportTypes[0] === workflowReportTypes.RAC ||
      reportTypes[0] === workflowReportTypes.SQR ||
      reportTypes[0] === workflowReportTypes.RAF);

  return (
    <>
      {(!workflow || !workflow.isCkrReport()) && (
        <>
          <StatisticsCountry
            className={
              workflow && workflow.isQuestionnaireReport()
                ? 'col-sm-12 col-lg-6'
                : 'col-sm-6 col-lg-3'
            }
            data={suppliers.country}
            onClick={onClick ? onClick('country') : null}
          />
          {(!workflow || !workflow.isQuestionnaireReport()) && (
            <>
              <StatisticsFinancialStrength
                className="col-sm-6 col-lg-3"
                data={suppliers.financialStrength}
                onClick={onClick ? onClick('financialStrength') : null}
              />
              <StatisticsIndustry
                className="col-sm-12 col-lg-6"
                data={suppliers.industry}
                onClick={onClick ? onClick('industry') : null}
              />
              <StatisticsRiskIndicator
                className="col-sm-12 col-lg-6"
                data={
                  (workflow &&
                    (workflow.isRafOrRacReport() || workflow.isSqrReport())) ||
                  onlyRacIsEnabled
                    ? suppliers.racRiskIndicator
                    : suppliers.riskIndicator
                }
                useRacRatings={
                  (workflow &&
                    (workflow.isRafOrRacReport() || workflow.isSqrReport())) ||
                  onlyRacIsEnabled
                }
                onClick={onClick ? onClick('riskIndicator') : null}
              />
            </>
          )}
          <StatisticsRecentActivity
            className="col-sm-12 col-lg-6"
            activities={activities}
          />
        </>
      )}
      {workflow &&
        workflow.isCkrReport() && (
          <>
            <StatisticsCommercialScore
              className="col-sm-12 col-lg-6"
              data={suppliers.commercialScore}
              onClick={onClick ? onClick('commercialScore') : null}
            />
            <StatisticsIndustry
              className="col-sm-12 col-lg-6"
              data={suppliers.industry}
              onClick={onClick ? onClick('industry') : null}
            />
            <StatisticsCountry
              className="col-sm-12 col-lg-6"
              data={suppliers.country}
              onClick={onClick ? onClick('country') : null}
            />
            <StatisticsRecentActivity
              className="col-sm-12 col-lg-6"
              activities={activities}
            />
          </>
        )}
    </>
  );
};

Statistics.propTypes = {
  statistics: PropTypes.shape({
    suppliers: PropTypes.shape({
      country: PropTypes.array,
      financialStrength: PropTypes.array,
      industry: PropTypes.array,
      riskIndicator: PropTypes.array
    })
  }),
  workflow: PropTypes.any,
  user: PropTypes.any
};

export default Statistics;
