import React from 'react';
import { Table } from 'react-bootstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { MultiSelect } from 'react-common/components';
import { Util } from 'react-common/services';

import CountryDropdown from '../../_components/CountryDropdown/CountryDropdown';
import WorkflowsService from '../../_services/Workflows';
import { connect } from 'react-redux';
import get from 'lodash/get';

const getPlaceholder = (placeholder, workflow) =>
  placeholder +
  (WorkflowsService.isCkrInvestigation(workflow) ||
  WorkflowsService.isCcrInvestigation(workflow) ||
  WorkflowsService.isCfrReport(workflow)
    ? ''
    : ' (*)');

const WorkflowsSuppliersAddSingleForm = ({
  handleSubmit,
  workflow,
  submitting,
  formValues
}) => (
  <form onSubmit={handleSubmit} className="WorkflowsSuppliersAddSingleForm">
    <Table id="WorkflowsSuppliersAdd-input-fields">
      <tbody>
        <tr className="no-border">
          <td className="Field-company-name">
            <Field
              type="text"
              name="invite.company_name"
              className="form-control"
              component="input"
              placeholder={getPlaceholder('Company Name', workflow)}
            />
          </td>
          <td className="Field-full-name">
            <Field
              type="text"
              name="invite.full_name"
              className="form-control"
              component="input"
              placeholder={getPlaceholder('Full Name', workflow)}
            />
          </td>
          <td className="Field-email">
            <Field
              type="text"
              name="invite.emails"
              className="form-control"
              component={props => (
                <MultiSelect {...props} validate={Util.isValidEmail} />
              )}
              placeholder={getPlaceholder('Contact Emails', workflow)}
            />
          </td>
        </tr>
        <tr>
          <td className="Field-country">
            <Field
              className="form-control"
              name="invite.country"
              placeholder={getPlaceholder('Country', workflow)}
              component={CountryDropdown}
              isDisabled={
                WorkflowsService.isCkrInvestigation(workflow) ||
                WorkflowsService.isCcrInvestigation(workflow)
              }
            />
          </td>
          <td className="Field-taxID">
            <Field
              type="text"
              className="form-control"
              name="invite.tax_id"
              component="input"
              placeholder={
                WorkflowsService.isTaxIdRequired(
                  get(formValues, 'invite.country', '')
                ) && !workflow.isCcrReport()
                  ? 'ID fiscal (CNPJ) (*)'
                  : 'Tax ID'
              }
            />
          </td>
          <td className="Field-address">
            <Field
              type="text"
              className="form-control"
              name="invite.address"
              component="input"
              placeholder="Full address"
            />
          </td>
        </tr>
        <tr>
          <td className="Field-sic">
            <Field
              type="text"
              className="form-control"
              name="invite.sic"
              component="input"
              placeholder="SIC code"
            />
          </td>
          <td className="Field-phone">
            <Field
              type="text"
              className="form-control"
              name="invite.phone"
              component="input"
              placeholder="Phone number"
            />
          </td>

          {(workflow.isInvestigating() || workflow.isCfrReport()) && (
            <td className="Field-duns-number">
              <Field
                type="text"
                className="form-control"
                name="invite.duns_number"
                component="input"
                placeholder={`D-U-N-S Number${
                  workflow.isCfrReport() ? ' (*)' : ''
                }`}
              />
            </td>
          )}
        </tr>
        {workflow.isCfrReport() && (
          <tr className="no-border">
            <td className="Field-shareholders">
              <Field
                type="text"
                name="invite.shareholders"
                className="form-control"
                component={props => <MultiSelect {...props} />}
                placeholder="Shareholders"
              />
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="4">
            <button
              className="Btn-default-small Btn-default-primary"
              type="submit"
              disabled={submitting}
            >
              Add
            </button>
          </td>
        </tr>
      </tbody>
    </Table>
  </form>
);

const mapStateToProps = state => ({
  formValues: getFormValues('add-suppliers-single')(state)
});

const WorkflowsSuppliersAddSingle = reduxForm({
  form: 'add-suppliers-single',
  initialValues: {
    invite: {
      emails: []
    }
  }
})(WorkflowsSuppliersAddSingleForm);

export default connect(mapStateToProps)(WorkflowsSuppliersAddSingle);
