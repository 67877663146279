import React from 'react';

const LocalArgentinaInfo = () => (
  <>
    <p>Please make your payment using the following bank information.</p>
    <div>
      <div>
        <label>Bank:</label>
        <p>
          Industrial and Commercial Bank of China (Argentina) S.A Casa Central
        </p>
      </div>
      <div>
        <label>Account:</label>
        <p notranslate="true">0931/02000804/32</p>
      </div>
      <div>
        <label>Recipient:</label>
        <p>Cial D&B Services Inc</p>
      </div>
      <div>
        <label>CBU:</label>
        <p notranslate="true">0150931502000000804321</p>
      </div>
    </div>
    <p>In case of withholding, these must be attached with the receipt.</p>
  </>
);

export default LocalArgentinaInfo;
