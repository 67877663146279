import React, { Component } from 'react';
import { ComponentWithLoader } from 'react-common/components';
import { MenuItem, Dropdown } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';

import WorkflowsListItem from './WorkflowsListItem/WorkflowsListItem';
import WorkflowsListEmpty from './WorkflowsListEmpty/WorkflowsListEmpty';

import { workflowSortArray } from '../../_constants/workflows';

import './WorkflowsList.css';

class WorkflowsList extends Component {
  state = {
    sort: workflowSortArray[0]
  };

  render() {
    const {
      showLoader,
      selectedCategory,
      goToWorkflowDetails,
      isDownloading,
      workflows,
      suppliersById,
      downloadAll
    } = this.props;

    const sortedWorkflows = orderBy(
      workflows,
      [this.state.sort.field],
      this.state.sort.reverse ? ['desc'] : ['asc']
    );

    return (
      <div className="WorkflowsList">
        <div className="List-btn-group">
          {workflows.length > 0 && (
            <>
              <button
                className="WorkflowsList-download-btn pull-right Btn-default-small Btn-default-success hidden-xs"
                disabled={isDownloading}
                onClick={downloadAll}
              >
                <i className="icon-ic_downloads" />
                <span>Export all in Excel</span>
              </button>
            </>
          )}
        </div>
        <Dropdown
          id="workflow-sort-dropdown"
          className="WorkflowsList-sort-btn"
        >
          <Dropdown.Toggle>Sort</Dropdown.Toggle>
          <Dropdown.Menu>
            {workflowSortArray.map((sort, index) => (
              <MenuItem
                key={index}
                active={sort === this.state.sort}
                onClick={() => this.setState({ sort })}
              >
                {sort.name}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <ComponentWithLoader showLoader={showLoader}>
          {workflows.length === 0 && (
            <WorkflowsListEmpty category={selectedCategory} />
          )}

          {workflows.length > 0 && (
            <div className="WorkflowsList-content">
              {sortedWorkflows.map(workflow => (
                <WorkflowsListItem
                  workflow={workflow}
                  suppliersById={suppliersById}
                  key={workflow.getId()}
                  onClickHandler={goToWorkflowDetails}
                />
              ))}
            </div>
          )}
        </ComponentWithLoader>
      </div>
    );
  }
}

export default WorkflowsList;
