import React from 'react';

import { BirFormContainer } from 'react-common/components';

import { adminMyCialDomains } from '../../../_constants/onboarding';

import './OnboardingSupplierBir.css';

const OnboardingSupplierBir = props => (
  <div className="OnboardingSupplierBir">
    {props.isOldBirRequestModel && (
      <iframe
        id="oboarding-bir-form"
        title="Report Form"
        width="100%"
        height="800px"
        src={`${
          adminMyCialDomains[process.env.REACT_APP_ENV]
        }onboarding-bir?workflowId=${props.workflow.getId()}&supplierId=${props.supplier.getId()}&supplier=true&language=${
          props.language
        }`}
        frameBorder="0"
        seamless="seamless"
      />
    )}
    {!props.isOldBirRequestModel && (
      <>
        <h1 className="Bir-title"> Fill the form</h1>
        <BirFormContainer {...props} />
      </>
    )}
  </div>
);

export default OnboardingSupplierBir;
