import uiConstants from '../_constants/ui';
import { emptyFilters } from '../_constants/workflows';

export function ui(
  state = {
    headerBreadcrumbs: [],
    suppliersFilters: {
      ...emptyFilters
    }
  },
  action
) {
  switch (action.type) {
    case uiConstants.UI_HEADER_BREADCRUMBS_UPDATE:
      return {
        ...state,
        headerBreadcrumbs: action.headerBreadcrumbs
      };
    case uiConstants.SUPPLIERS_FILTER_UPDATE:
      return {
        ...state,
        suppliersFilters: {
          ...state.suppliersFilters,
          ...action.suppliersFilters,
          updatedAt: new Date()
        }
      };
    default:
      return state;
  }
}
