import React from 'react';
import { FileDescriptor } from 'react-common/components';

const renderReportFile = (file, downloadFile, isDownloading) => (
  <FileDescriptor
    key={file._id}
    file={file}
    onClick={downloadFile(file.file_id, file.name)}
    disabled={isDownloading}
  />
);

const WorkflowsSupplierDetailsReportFiles = ({
  files,
  downloadBirFile,
  downloadAdditionalFile,
  isDownloading
}) => (
  <>
    <div className="WorkflowsSupplierDetails-rectangle">
      <div className="WorkflowsSupplierDetails-title-table">Report Files</div>

      <div className="WorkflowsSupplierDetails-content-table">
        {files.bir.map(file =>
          renderReportFile(file, downloadBirFile, isDownloading)
        )}
      </div>
    </div>

    {files.additional &&
      !!files.additional.length && (
        <div className="WorkflowsSupplierDetails-rectangle">
          <div className="WorkflowsSupplierDetails-title-table">
            Additional Files
          </div>

          <div className="WorkflowsSupplierDetails-content-table">
            {files.additional.map(file =>
              renderReportFile(file, downloadAdditionalFile, isDownloading)
            )}
          </div>
        </div>
      )}
  </>
);

export default WorkflowsSupplierDetailsReportFiles;
