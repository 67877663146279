import React from 'react';
import { financialStrengthClasses, languages } from 'react-common/constants';
import { MomentDate } from 'react-common/components';
import { Localize } from 'react-common/services';

import { INFORMATION_UNAVAILABLE } from '../../../_constants/workflows';

const WorkflowsSupplierDetailsRating = ({
  workflow,
  supplier,
  showRatingsModal
}) => (
  <div className="WorkflowsSupplierDetails-rectangle">
    <div className="WorkflowsSupplierDetails-title-table">Rating</div>
    <div className="WorkflowsSupplierDetails-content-table">
      {!workflow.isCkrReport() && (
        <div className="WorkflowsSupplierDetails-summary-item">
          <span className="WorkflowsSupplierDetails-label-inline">
            D&B rating<i
              className="icon-ic_question"
              onClick={showRatingsModal}
            />:
          </span>
          {supplier.getDnbRating() ? (
            <span
              notranslate="true"
              className={`WorkflowsSupplierDetails-rating Square-badge Bg-${
                financialStrengthClasses[supplier.getFinancialStrength()]
              }`}
            >
              {supplier.getDnbRating()}
            </span>
          ) : (
            <span className="WorkflowsSupplierDetails-info-text-inline">
              {INFORMATION_UNAVAILABLE}
            </span>
          )}
        </div>
      )}

      {workflow.isCkrReport() && (
        <div className="WorkflowsSupplierDetails-summary-item">
          <span className="WorkflowsSupplierDetails-label-inline">
            Commercial Score:
          </span>
          <span
            notranslate="true"
            className="WorkflowsSupplierDetails-info-text-inline"
          >
            {supplier.getCommercialScore() || INFORMATION_UNAVAILABLE}
          </span>
        </div>
      )}

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">
          Delivery date:
        </span>
        <span className="WorkflowsSupplierDetails-info-text-inline">
          {supplier.getDeliveryDate() ? (
            <MomentDate
              format="MMMM DD, YYYY"
              locale={languages[Localize.getLanguage()].uploadcare}
              date={supplier.getDeliveryDate()}
              timezone="UTC"
            />
          ) : (
            INFORMATION_UNAVAILABLE
          )}
        </span>
      </div>

      {!workflow.isCkrReport() && (
        <div className="WorkflowsSupplierDetails-summary-item">
          <span className="WorkflowsSupplierDetails-label-inline">
            Balance sheet date:
          </span>
          <span className="WorkflowsSupplierDetails-info-text-inline">
            {supplier.getBalanceSheetDateDate() ? (
              <MomentDate
                format="MMMM DD, YYYY"
                locale={languages[Localize.getLanguage()].uploadcare}
                date={supplier.getBalanceSheetDateDate()}
                timezone="UTC"
              />
            ) : (
              INFORMATION_UNAVAILABLE
            )}
          </span>
        </div>
      )}
    </div>
  </div>
);

export default WorkflowsSupplierDetailsRating;
