import find from 'lodash/find';
import { accountRoles, workflowTypes } from 'react-common/constants';
import { SubmissionError } from 'redux-form';

import onboardingConstants from '../_constants/onboarding';
import { handleError } from './handler';
import normalize from './normalize';
import OnboardingService from '../_services/Onboarding';
import UserService from '../_services/User';
import { Supplier } from '../_models/supplier';

function getWorkflowForOnboardingRequest() {
  return {
    type: onboardingConstants.GET_WORKFLOW_REQUEST
  };
}

function getWorkflowForOnboardingSuccess(response) {
  return {
    type: onboardingConstants.GET_WORKFLOW_SUCCESS,
    response
  };
}

function getWorkflowForOnboardingFailure(error) {
  return {
    type: onboardingConstants.GET_WORKFLOW_FAILURE,
    error
  };
}

export function getWorkflowForOnboarding(workflowId, supplierId) {
  return dispatch => {
    dispatch(getWorkflowForOnboardingRequest());
    return Promise.resolve()
      .then(() => {
        if (supplierId) {
          return OnboardingService.getWorkflowForSupplier(
            workflowId,
            supplierId
          );
        }

        return OnboardingService.getWorkflow(workflowId);
      })
      .then(workflow => {
        if (workflow.type === workflowTypes.INVESTIGATING) {
          dispatch(
            getWorkflowForOnboardingFailure({
              error:
                'This is an investigating type of workflow, there is no onboarding.'
            })
          );
        } else {
          dispatch(getWorkflowForOnboardingSuccess(normalize(workflow)));
        }
      })
      .catch(handleError(dispatch, getWorkflowForOnboardingFailure));
  };
}

function onboardingGetStartedRequest() {
  return {
    type: onboardingConstants.GET_STARTED_REQUEST
  };
}

function onboardingGetStartedSuccess(response) {
  return {
    type: onboardingConstants.GET_STARTED_SUCCESS,
    response
  };
}

function onboardingGetStartedFailure(error) {
  return {
    type: onboardingConstants.GET_STARTED_FAILURE,
    error
  };
}

export function onboardingGetStarted(workflowId, supplierId) {
  return dispatch => {
    dispatch(onboardingGetStartedRequest());

    return OnboardingService.getStarted(workflowId, supplierId)
      .then(workflow =>
        dispatch(onboardingGetStartedSuccess(normalize(workflow)))
      )
      .catch(handleError(dispatch, onboardingGetStartedFailure));
  };
}

function submitOnboardingQuestionnaireRequest() {
  return {
    type: onboardingConstants.SUBMIT_QUESTIONNAIRE_REQUEST
  };
}

function submitOnboardingQuestionnaireSuccess(response) {
  return {
    type: onboardingConstants.SUBMIT_QUESTIONNAIRE_SUCCESS,
    response
  };
}

export function submitOnboardingQuestionnaireFailure(error) {
  return {
    type: onboardingConstants.SUBMIT_QUESTIONNAIRE_FAILURE,
    error
  };
}

export function submitQuestionnaire(
  workflowId,
  supplierId,
  answers,
  finalSubmit = false
) {
  return dispatch => {
    dispatch(submitOnboardingQuestionnaireRequest());

    return OnboardingService.submitQuestionnaireForSupplier(
      workflowId,
      supplierId,
      answers,
      finalSubmit
    )
      .then(workflow => {
        dispatch(submitOnboardingQuestionnaireSuccess(normalize(workflow)));
      })
      .catch(handleError(dispatch, submitOnboardingQuestionnaireFailure));
  };
}

function submitOnboardingQuestionnaireFilesRequest(questionIds) {
  return {
    type: onboardingConstants.SUBMIT_QUESTIONNAIRE_FILES_REQUEST,
    files: questionIds
  };
}

function submitOnboardingQuestionnaireFilesSuccess(response, questionIds) {
  return {
    type: onboardingConstants.SUBMIT_QUESTIONNAIRE_FILES_SUCCESS,
    response,
    files: questionIds
  };
}

export function submitOnboardingQuestionnaireFilesFailure(questionIds) {
  return error => ({
    type: onboardingConstants.SUBMIT_QUESTIONNAIRE_FILES_FAILURE,
    error,
    files: questionIds
  });
}

export function submitQuestionnaireFiles(workflowId, supplierId, files) {
  return dispatch => {
    let questionIds = Object.keys(files);

    dispatch(submitOnboardingQuestionnaireFilesRequest(questionIds));

    let data = new FormData();

    Object.keys(files).forEach(questionId =>
      data.append(questionId, files[questionId])
    );

    return OnboardingService.submitQuestionnaireFilesForSupplier(
      workflowId,
      supplierId,
      data
    )
      .then(workflow => {
        dispatch(
          submitOnboardingQuestionnaireFilesSuccess(
            normalize(workflow),
            questionIds
          )
        );
      })
      .catch(
        handleError(
          dispatch,
          submitOnboardingQuestionnaireFilesFailure(questionIds)
        )
      );
  };
}

function deleteQuestionnaireAnswerRequest() {
  return {
    type: onboardingConstants.DELETE_QUESTIONNAIRE_ANSWER_REQUEST
  };
}

function deleteQuestionnaireAnswerSuccess(response) {
  return {
    type: onboardingConstants.DELETE_QUESTIONNAIRE_ANSWER_SUCCESS,
    response
  };
}

export function deleteQuestionnaireAnswerFailure(error) {
  return {
    type: onboardingConstants.DELETE_QUESTIONNAIRE_ANSWER_FAILURE,
    error
  };
}

export function deleteQuestionnaireAnswer(workflowId, supplierId, questionId) {
  return dispatch => {
    dispatch(deleteQuestionnaireAnswerRequest());

    return OnboardingService.deleteQuestionnaireAnswer(
      workflowId,
      supplierId,
      questionId
    )
      .then(workflow => {
        dispatch(deleteQuestionnaireAnswerSuccess(normalize(workflow)));
      })
      .catch(handleError(dispatch, deleteQuestionnaireAnswerFailure));
  };
}

function connectAccountWithSupplierRequest() {
  return {
    type: onboardingConstants.CONNECT_ACCOUNT_REQUEST
  };
}

function connectAccountWithSupplierSuccess(response) {
  return {
    type: onboardingConstants.CONNECT_ACCOUNT_SUCCESS,
    response
  };
}

export function connectAccountWithSupplierFailure(error) {
  return {
    type: onboardingConstants.CONNECT_ACCOUNT_FAILURE,
    error
  };
}

export function connectAccountWithSupplier(
  workflow,
  supplier,
  userData,
  useLoginForm,
  countries
) {
  return dispatch => {
    const {
      full_name: fullName,
      email,
      password,
      phone,
      country,
      company_name: companyName
    } = userData;
    let account;
    let token;
    return Promise.resolve()
      .then(() => {
        if (useLoginForm) {
          return UserService.check(email, password);
        }
        return UserService.register(fullName, email, password, phone, country);
      })
      .then(credentials => {
        token = credentials.token;
        account = credentials.user;
        UserService.connectWithPlugins(account);
        if (supplier) {
          return Promise.resolve(supplier);
        }

        const countryObject = countries.find(
          c =>
            c.iso_alpha_3 === account.info.country ||
            c.code === account.info.country
        );

        return OnboardingService.addSupplierToWorkflow(
          workflow.getId(),
          account.info.name,
          email,
          companyName,
          countryObject &&
            countryObject.title &&
            countryObject.title.toLowerCase(),
          account.info.phone
        ).then(
          updatedWorkflow =>
            (supplier = new Supplier(updatedWorkflow.suppliers[0]))
        );
      })
      .then(() => {
        // this will prevent loading screen if login or register form fails
        dispatch(connectAccountWithSupplierRequest());

        // allow non user accounts to access onboarding process
        if (
          supplier.getEmail() &&
          supplier.getEmail() !== account.info.email &&
          account.role === accountRoles.USER
        ) {
          dispatch(
            connectAccountWithSupplierFailure({
              error: 'Onboarding process has been started by another account.'
            })
          );
          return null;
        }

        OnboardingService.authenticateSupplier(
          workflow.getId(),
          supplier.getId(),
          token
        );

        return Promise.resolve()
          .then(() => {
            if (!supplier.getAccountId()) {
              return OnboardingService.connectAccountWithSupplier(
                workflow.getId(),
                supplier.getId(),
                account
              );
            }
            return workflow;
          })
          .then(w => {
            dispatch(connectAccountWithSupplierSuccess(normalize(w)));
            return supplier;
          });
      })
      .catch(err => {
        handleError(dispatch, connectAccountWithSupplierFailure)(err);
        throw new SubmissionError({
          _error: err
        });
      });
  };
}

function changePhoneNumberRequest() {
  return {
    type: onboardingConstants.CHANGE_PHONE_NUMBER_REQUEST
  };
}

function changePhoneNumberSuccess(response) {
  return {
    type: onboardingConstants.CHANGE_PHONE_NUMBER_SUCCESS,
    response
  };
}

export function changePhoneNumberFailure(error) {
  return {
    type: onboardingConstants.CHANGE_PHONE_NUMBER_FAILURE,
    error
  };
}

export function changePhoneNumber(workflowId, supplierId, phone) {
  return dispatch => {
    dispatch(changePhoneNumberRequest());

    return OnboardingService.changePhoneNumber(workflowId, supplierId, phone)
      .then(workflow => dispatch(changePhoneNumberSuccess(normalize(workflow))))
      .catch(handleError(dispatch, changePhoneNumberFailure));
  };
}

function getBirForOnboardingRequest() {
  return {
    type: onboardingConstants.GET_BIR_REQUEST
  };
}

function getBirForOnboardingSuccess(supplier) {
  return {
    type: onboardingConstants.GET_BIR_SUCCESS,
    supplier
  };
}

function getBirForOnboardingFailure(error) {
  return {
    type: onboardingConstants.GET_BIR_FAILURE,
    error
  };
}

export function getBirRequestForSupplier(supplier) {
  return dispatch => {
    dispatch(getBirForOnboardingRequest());
    return OnboardingService.getBirRequests(supplier.account)
      .then(birRequests => {
        const supplierBirRequest = find(
          birRequests,
          bir => bir.supplier && bir.supplier.supplier_id === supplier._id
        );
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...supplierBirRequest }
        });
        dispatch(getBirForOnboardingSuccess(newSupplier));
      })
      .catch(handleError(dispatch, getBirForOnboardingFailure));
  };
}

function updateBirRequestRequest() {
  return {
    type: onboardingConstants.UPDATE_BIR_REQUEST
  };
}

function updateBirRequestSuccess(supplier) {
  return {
    type: onboardingConstants.UPDATE_BIR_SUCCESS,
    supplier
  };
}

function updateBirRequestFailure(error) {
  return {
    type: onboardingConstants.UPDATE_BIR_FAILURE,
    error
  };
}

export function updateBirRequestForSupplier(supplier, changedData) {
  return dispatch => {
    dispatch(updateBirRequestRequest());

    return OnboardingService.updateBirRequest(supplier.birRequest, changedData)
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(updateBirRequestSuccess(newSupplier));
      })
      .catch(handleError(dispatch, updateBirRequestFailure));
  };
}

export function updateBirRequestFieldForSupplier(supplier, changedData) {
  return dispatch => {
    dispatch(updateBirRequestRequest());
    return OnboardingService.updateFieldInBirRequest(
      supplier.birRequest,
      changedData
    )
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(updateBirRequestSuccess(newSupplier));
      })
      .catch(handleError(dispatch, updateBirRequestFailure));
  };
}

function submitBirRequestRequest() {
  return {
    type: onboardingConstants.SUBMIT_BIR_REQUEST
  };
}

function submitBirRequestSuccess(supplier) {
  return {
    type: onboardingConstants.SUBMIT_BIR_SUCCESS,
    supplier
  };
}

function submitBirRequestFailure(error) {
  return {
    type: onboardingConstants.SUBMIT_BIR_FAILURE,
    error
  };
}

export function submitBirRequestForSupplier(workflow, supplier, changedData) {
  return dispatch => {
    dispatch(submitBirRequestRequest());

    return OnboardingService.submitBirRequest(supplier.birRequest, changedData)
      .then(() => {
        dispatch(submitBirRequestSuccess(supplier));
        dispatch(getWorkflowForOnboarding(workflow.getId(), supplier.getId()));
      })
      .catch(handleError(dispatch, submitBirRequestFailure));
  };
}

function uploadBirRequestFileRequest(name) {
  return {
    type: onboardingConstants.UPLOAD_BIR_REQUEST_FILE_REQUEST,
    files: [name]
  };
}

function uploadBirRequestFileSuccess(supplier, name) {
  return {
    type: onboardingConstants.UPLOAD_BIR_REQUEST_FILE_SUCCESS,
    supplier,
    files: [name]
  };
}

function uploadBirRequestFileFailure(name) {
  return error => ({
    type: onboardingConstants.UPLOAD_BIR_REQUEST_FILE_FAILURE,
    error,
    files: [name]
  });
}

export function uploadBirRequestFileForSupplier(supplier, name, file) {
  return dispatch => {
    dispatch(uploadBirRequestFileRequest(name));

    const fileData = new FormData();
    fileData.append(name, file);

    return OnboardingService.uploadFile(supplier.birRequest, fileData)
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(uploadBirRequestFileSuccess(newSupplier, name));
      })
      .catch(handleError(dispatch, uploadBirRequestFileFailure(name)));
  };
}

function deleteBirRequestFileRequest() {
  return {
    type: onboardingConstants.DELETE_BIR_REQUEST_FILE_REQUEST
  };
}

function deleteBirRequestFileSuccess(supplier) {
  return {
    type: onboardingConstants.DELETE_BIR_REQUEST_FILE_SUCCESS,
    supplier
  };
}

function deleteBirRequestFileFailure(error) {
  return {
    type: onboardingConstants.DELETE_BIR_REQUEST_FILE_FAILURE,
    error
  };
}

export function deleteBirRequestFileForSupplier(supplier, fileName) {
  return dispatch => {
    dispatch(deleteBirRequestFileRequest());

    return OnboardingService.deleteFile(supplier.birRequest, fileName)
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(deleteBirRequestFileSuccess(newSupplier));
      })
      .catch(handleError(dispatch, deleteBirRequestFileFailure));
  };
}

function createPaymentReferenceRequest() {
  return {
    type: onboardingConstants.CREATE_PAYMENT_REFERENCE_REQUEST
  };
}

function createPaymentReferenceSuccess(supplier) {
  return {
    type: onboardingConstants.CREATE_PAYMENT_REFERENCE_SUCCESS,
    supplier
  };
}

export function createPaymentReferenceFailure(error) {
  return {
    type: onboardingConstants.CREATE_PAYMENT_REFERENCE_FAILURE,
    error
  };
}

export function createPaymentReferenceForSupplier(supplier, billingInfo) {
  return dispatch => {
    dispatch(createPaymentReferenceRequest());
    return OnboardingService.createPaymentReferenceForBirRequest(
      supplier.birRequest._id,
      billingInfo
    )
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(createPaymentReferenceSuccess(newSupplier));
      })
      .catch(handleError(dispatch, createPaymentReferenceFailure));
  };
}

function initOnlinePaymentRequest() {
  return {
    type: onboardingConstants.INIT_ONLINE_PAYMENT_REQUEST
  };
}

function initOnlinePaymentSuccess(supplier) {
  return {
    type: onboardingConstants.INIT_ONLINE_PAYMENT_SUCCESS,
    supplier
  };
}

function initOnlinePaymentFailure(error) {
  return {
    type: onboardingConstants.INIT_ONLINE_PAYMENT_FAILURE,
    error
  };
}

export function initOnlinePaymentForBirRequest(supplier) {
  return dispatch => {
    dispatch(initOnlinePaymentRequest());
    return OnboardingService.initOnlinePaymentForBirRequest(
      supplier.getBirRequestId()
    )
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(initOnlinePaymentSuccess(newSupplier));
        return newSupplier;
      })
      .catch(handleError(dispatch, initOnlinePaymentFailure));
  };
}

function addPaymentProofRequest(supplierId) {
  return {
    type: onboardingConstants.ADD_PAYMENT_PROOF_REQUEST,
    files: [supplierId]
  };
}

function addPaymentProofSuccess(supplier) {
  return {
    type: onboardingConstants.ADD_PAYMENT_PROOF_SUCCESS,
    supplier,
    files: [supplier.getId()]
  };
}

export function addPaymentProofFailure(supplierId) {
  return error => ({
    type: onboardingConstants.ADD_PAYMENT_PROOF_FAILURE,
    error,
    files: [supplierId]
  });
}

export function addPaymentProofForSupplier(workflowId, supplier, paymentProof) {
  return dispatch => {
    dispatch(addPaymentProofRequest(supplier.getId()));

    let data = new FormData();
    data.append('payment-proof', paymentProof);

    return OnboardingService.addPaymentProof(workflowId, supplier.getId(), data)
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(addPaymentProofSuccess(newSupplier));
      })
      .catch(handleError(dispatch, addPaymentProofFailure(supplier.getId())));
  };
}

function removePaymentProofRequest() {
  return {
    type: onboardingConstants.REMOVE_PAYMENT_PROOF_REQUEST
  };
}

function removePaymentProofSuccess(supplier) {
  return {
    type: onboardingConstants.REMOVE_PAYMENT_PROOF_SUCCESS,
    supplier
  };
}

export function removePaymentProofFailure(error) {
  return {
    type: onboardingConstants.REMOVE_PAYMENT_PROOF_FAILURE,
    error
  };
}

export function removePaymentProofForSupplier(
  workflowId,
  supplier,
  paymentProofId
) {
  return dispatch => {
    dispatch(removePaymentProofRequest());

    return OnboardingService.removePaymentProof(
      workflowId,
      supplier.getId(),
      paymentProofId
    )
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(removePaymentProofSuccess(newSupplier));
      })
      .catch(handleError(dispatch, removePaymentProofFailure));
  };
}

function submitOnboardingPaymentProofRequest() {
  return {
    type: onboardingConstants.SUBMIT_PAYMENT_PROOF_REQUEST
  };
}

function submitOnboardingPaymentProofSuccess(response) {
  return {
    type: onboardingConstants.SUBMIT_PAYMENT_PROOF_SUCCESS,
    response
  };
}

export function submitOnboardingPaymentProofFailure(error) {
  return {
    type: onboardingConstants.SUBMIT_PAYMENT_PROOF_FAILURE,
    error
  };
}

export function submitPaymentProofForSupplier(workflowId, supplierId) {
  return dispatch => {
    dispatch(submitOnboardingPaymentProofRequest());

    return OnboardingService.submitPaymentProof(workflowId, supplierId)
      .then(workflow =>
        dispatch(submitOnboardingPaymentProofSuccess(normalize(workflow)))
      )
      .catch(handleError(dispatch, submitOnboardingPaymentProofFailure));
  };
}

function uploadRfcDocumentRequest() {
  return {
    type: onboardingConstants.UPLOAD_RFC_DOCUMENT_REQUEST,
    files: ['rfc_document']
  };
}

function uploadRfcDocumentSuccess(supplier) {
  return {
    type: onboardingConstants.UPLOAD_RFC_DOCUMENT_SUCCESS,
    supplier,
    files: ['rfc_document']
  };
}

export function uploadRfcDocumentFailure(error) {
  return {
    type: onboardingConstants.UPLOAD_RFC_DOCUMENT_FAILURE,
    error,
    files: ['rfc_document']
  };
}

export function uploadRfcDocumentForSupplier(
  workflowId,
  supplier,
  rfcDocument
) {
  return dispatch => {
    dispatch(uploadRfcDocumentRequest());

    let data = new FormData();
    data.append('rfc-document', rfcDocument);

    return OnboardingService.uploadRfcDocument(
      workflowId,
      supplier.getId(),
      data
    )
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(uploadRfcDocumentSuccess(newSupplier));
      })
      .catch(handleError(dispatch, uploadRfcDocumentFailure));
  };
}

function deleteRfcDocumentRequest() {
  return {
    type: onboardingConstants.DELETE_RFC_DOCUMENT_REQUEST
  };
}

function deleteRfcDocumentSuccess(supplier) {
  return {
    type: onboardingConstants.DELETE_RFC_DOCUMENT_SUCCESS,
    supplier
  };
}

export function deleteRfcDocumentFailure(error) {
  return {
    type: onboardingConstants.DELETE_RFC_DOCUMENT_FAILURE,
    error
  };
}

export function deleteRfcDocumentForSupplier(workflowId, supplier) {
  return dispatch => {
    dispatch(deleteRfcDocumentRequest());

    return OnboardingService.deleteRfcDocument(workflowId, supplier.getId())
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(deleteRfcDocumentSuccess(newSupplier));
      })
      .catch(handleError(dispatch, deleteRfcDocumentFailure));
  };
}

function initBrazilPaymentRequest() {
  return {
    type: onboardingConstants.INIT_BRAZIL_PAYMENT_REQUEST
  };
}

function initBrazilPaymentSuccess(supplier) {
  return {
    type: onboardingConstants.INIT_BRAZIL_PAYMENT_SUCCESS,
    supplier
  };
}

export function initBrazilPaymentFailure(error) {
  return {
    type: onboardingConstants.INIT_BRAZIL_PAYMENT_FAILURE,
    error
  };
}

export function initBrazilPaymentForBirRequest(supplier) {
  return dispatch => {
    dispatch(initBrazilPaymentRequest());

    return OnboardingService.initBrazilPaymentForBirRequest(
      supplier.getBirRequestId()
    )
      .then(birRequest => {
        const newSupplier = new Supplier({
          ...supplier,
          birRequest: { ...birRequest }
        });
        dispatch(initBrazilPaymentSuccess(newSupplier));
        return newSupplier;
      })
      .catch(handleError(dispatch, initBrazilPaymentFailure));
  };
}
