import React, { Component } from 'react';
import { ComponentWithLoader } from 'react-common/components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import {
  getStatistics,
  setSuppliersFilters,
  getWorkflow,
  updateHeaderBreadcrumbs
} from '../../_actions';
import WorkflowsService from '../../_services/Workflows';

import WorkflowsDetails from './WorkflowsDetails';
import PageNotFound from '../../PageNotFound/PageNotFound';
import { emptyFilters } from '../../_constants/workflows';

class WorkflowsDetailsContainer extends Component {
  componentDidMount() {
    const {
      dispatchGetWorkflow,
      dispatchUpdateHeaderBreadcrumbs,
      workflow,
      workflowId,
      headerTitles
    } = this.props;

    if (!workflow) {
      dispatchGetWorkflow(workflowId);
    }

    dispatchUpdateHeaderBreadcrumbs(headerTitles);
  }

  componentDidUpdate(prevProps) {
    const { dispatchUpdateHeaderBreadcrumbs, headerTitles } = this.props;

    if (headerTitles.length !== prevProps.headerTitles.length) {
      dispatchUpdateHeaderBreadcrumbs(headerTitles);
    }
  }

  handleTabSelect = section => {
    const {
      dispatchPush,
      dispatchGetStatistics,
      dispatchSetSuppliersFilters,
      workflowId
    } = this.props;
    dispatchPush(`/workflows/${workflowId}/${section}`);
    if (section === 'results') {
      return dispatchGetStatistics(workflowId, true);
    }
    return dispatchSetSuppliersFilters(emptyFilters);
  };

  render() {
    const { showPageNotFound, showLoader } = this.props;
    if (showPageNotFound) {
      return <PageNotFound className="row" />;
    }
    return (
      <ComponentWithLoader showLoader={showLoader}>
        <WorkflowsDetails
          handleTabSelect={this.handleTabSelect}
          {...this.props}
        />
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workflows, user } = state;
  const { workflow_id: workflowId } = props.match.params;
  const workflow = workflows.getById[workflowId];
  let headerTitles = [];

  if (workflow) {
    const category = WorkflowsService.getWorkflowCategoryForStatus(
      workflow.status
    );
    headerTitles = [
      {
        title: category.headerTitle,
        path: category.path
      },
      {
        title: workflow.info.title,
        notranslate: true
      }
    ];
  }

  return {
    workflow,
    workflowId,
    user: user && user.data && user.data.user,
    headerTitles,
    showPageNotFound: !workflow && !!workflows.error,
    showLoader: !workflow || workflows.isFetching || user.isFetching,
    showStatisticsLoader:
      !workflows.statistics || !!workflows.statistics.isFetching
  };
};

const mapDispatchToProps = {
  dispatchGetWorkflow: getWorkflow,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchGetStatistics: getStatistics,
  dispatchSetSuppliersFilters: setSuppliersFilters,
  dispatchPush: push
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsDetailsContainer
);
