import get from 'lodash/get';
import includes from 'lodash/includes';
import React from 'react';
import { Checkbox, FileDescriptor, RadioButton } from 'react-common/components';
import { Panel, PanelGroup } from 'react-bootstrap';

import { questionTypes } from 'react-common/constants';
import { INFORMATION_UNAVAILABLE } from '../../../_constants/workflows';

const renderInput = ({ answer }) => (
  <div className="WorkflowsSupplierDetails-answer">
    {get(answer, 'answer') || INFORMATION_UNAVAILABLE}
  </div>
);

const renderMultipleChoice = ({ question, answer }) => (
  <div className="WorkflowsSupplierDetails-answer">
    {get(question, 'options', []).map(option => {
      if (question.type === questionTypes.CHECKBOX) {
        return (
          <Checkbox
            key={option._id}
            label={option.value}
            disabled={true}
            checked={includes(get(answer, 'answer'), option._id)}
          />
        );
      }
      return (
        <RadioButton
          key={option._id}
          label={option.value}
          disabled={true}
          checked={get(answer, 'answer') === option._id}
        />
      );
    })}
  </div>
);

const renderQuestionnaireFile = (
  { question, answer },
  downloadFileInput,
  isDownloading
) => {
  let file;
  if (question.type === questionTypes.ATTACHMENT) {
    file = get(question, 'file');
  }
  if (question.type === questionTypes.FILE) {
    file = get(answer, 'answer');
  }

  return (
    <>
      {file && (
        <div className="WorkflowsSupplierDetails-answer">
          <FileDescriptor
            file={file}
            onClick={downloadFileInput(question._id, file.name)}
            disabled={isDownloading}
          />
        </div>
      )}
      {(!file || !file.key) && (
        <div className="WorkflowsSupplierDetails-file">
          {INFORMATION_UNAVAILABLE}
        </div>
      )}
    </>
  );
};

const WorkflowsSupplierDetailsQuestionnaire = ({
  questionnaire,
  downloadFileInput,
  isDownloading
}) => {
  let questionNumber = 0;

  return (
    <PanelGroup
      id="supplier-details-questionnaire-accordion"
      accordion
      defaultActiveKey="1"
    >
      <Panel className="WorkflowsCreate-panel Screen-panel" eventKey="1">
        <Panel.Heading>
          <Panel.Title toggle>
            <div className="WorkflowsSupplierDetails-title-table no-border">
              <span className="WorkflowsSupplierDetails-title-table-heading">
                Questionnaire
              </span>
            </div>
            <span className="pull-right">
              <i className="icon-ic_expand-arrow" />
            </span>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Collapse>
          <Panel.Body>
            <div className="row">
              <div className="WorkflowsSupplierDetails-content-table">
                {questionnaire.map(qa => {
                  if (qa.question.type !== questionTypes.SECTION) {
                    questionNumber += 1;
                  }

                  let answer;

                  switch (qa.question.type) {
                    case questionTypes.INPUT:
                      answer = renderInput(qa);
                      break;
                    case questionTypes.CHECKBOX:
                      answer = renderMultipleChoice(qa);
                      break;
                    case questionTypes.RADIO:
                      answer = renderMultipleChoice(qa);
                      break;
                    case questionTypes.FILE:
                    case questionTypes.ATTACHMENT:
                      answer = renderQuestionnaireFile(
                        qa,
                        downloadFileInput,
                        isDownloading
                      );
                      break;
                    case questionTypes.SECTION:
                    default:
                      answer = null;
                  }

                  return (
                    <div
                      key={qa.question._id}
                      notranslate="true"
                      className="WorkflowsSupplierDetails-question-answer"
                    >
                      <div className="WorkflowsSupplierDetails-question">
                        {qa.question.type === questionTypes.SECTION &&
                          qa.question.title}
                        {qa.question.type !== questionTypes.SECTION &&
                          `${questionNumber}. ${qa.question.title}${
                            qa.question.required ? '*' : ''
                          }`}
                      </div>
                      {answer}
                    </div>
                  );
                })}
              </div>
            </div>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    </PanelGroup>
  );
};

export default WorkflowsSupplierDetailsQuestionnaire;
