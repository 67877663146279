import React from 'react';
import { Table, Modal } from 'react-bootstrap';

import './CcrModal.css';

const CcrModal = ({ show, handleClose }) => (
  <Modal className="Modal-ccr" show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className="ModalTitle">CCR Scores</Modal.Title>
      <i
        className="icon-ic_close pull-right Close-modal-icon"
        onClick={handleClose}
      />
    </Modal.Header>
    <Modal.Body>
      <Table responsive>
        <thead>
          <tr>
            <th>Score</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="5">
              <div className="Square-badge Bg-green">A</div>
            </td>
            <td>Compromising news in media</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Criminal Records</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Civil Actions</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Sanction Lists</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td>
              No negative content found (Laboural actions - CEPIM - CEIS - CENEP
              - Improbity - Unfit and Unsuitable){' '}
            </td>
          </tr>
          <tr>
            <td rowSpan="5">
              <div className="Square-badge Bg-green">B</div>
            </td>
            <td>Compromising news in media</td>
            <td>Indirect Relation</td>
          </tr>
          <tr>
            <td>Criminal Records</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Civil Actions</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Sanction Lists</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td>
              No negative content found (Laboural actions - CEPIM - CEIS - CENEP
              - Improbity - Unfit and Unsuitable){' '}
            </td>
          </tr>

          <tr>
            <td rowSpan="5">
              <div className="Square-badge Bg-green">C</div>
            </td>
            <td>Compromising news in media</td>
            <td>Indirect Relation</td>
          </tr>
          <tr>
            <td>Criminal Records</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Civil Actions</td>
            <td>Relative civil action</td>
          </tr>
          <tr>
            <td>Sanction Lists</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td>
              No negative content found (Laboural actions - CEPIM - CEIS - CENEP
              - Improbity - Unfit and Unsuitable){' '}
            </td>
          </tr>

          <tr>
            <td rowSpan="5">
              <div className="Square-badge Bg-coral">D</div>
            </td>
            <td>Compromising news in media</td>
            <td>Related to huge investigations</td>
          </tr>
          <tr>
            <td>Criminal Records</td>
            <td>Any criminal record</td>
          </tr>
          <tr>
            <td>Civil Actions</td>
            <td>
              Declared Bankruptcy / Receivership / Fraud / Confidence trick /
              Improbity / Improper Practices
            </td>
          </tr>
          <tr>
            <td>Sanction Lists</td>
            <td>Blacklisted</td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td>All liability certificates with Negative effects</td>
          </tr>

          <tr>
            <td rowSpan="5">
              <div className="Square-badge Bg-coral">E</div>
            </td>
            <td>Compromising news in media</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Criminal Records</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Civil Actions</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Sanction Lists</td>
            <td>No negative content found </td>
          </tr>
          <tr>
            <td>Certificates</td>
            <td>
              No negative content found (Laboural actions - CEPIM - CEIS - CENEP
              - Improbity - Unfit and Unsuitable){' '}
            </td>
          </tr>
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>
);

export default CcrModal;
