import React from 'react';

const InternationalArgentinaInfo = ({ taxId }) => (
  <>
    <div>
      <label>Bank:</label>
      <p notranslate="true">
        Industrial and Commercial Bank of China (Argentina) S.A. Buenos Aires
      </p>
    </div>
    <div notranslate="true">
      <label>ABA:</label>
      <p>026009593</p>
    </div>
    <div>
      <label>Account:</label>
      <p notranslate="true">6550-3-16764</p>
    </div>
    <div notranslate="true">
      <label>SWIFT:</label>
      <p>ICBKARBA</p>
    </div>
    <div>
      <label>Recipient:</label>
      <p notranslate="true">DUN Y BRADSTREET SA</p>
    </div>
    <div notranslate="true">
      <label>CBU:</label>
      <p>0150931502000000804321</p>
    </div>
    <div>
      <label>Reference Number:</label>
      <p notranslate="true">{taxId}</p>
    </div>
  </>
);

export default InternationalArgentinaInfo;
