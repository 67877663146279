import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { user } from './user';
import { workflows } from './workflows';
import { enums } from './enums';
import { ui } from './ui';
import { nextIdentify } from './nextIdentify';

const rootReducer = history =>
  combineReducers({
    user,
    workflows,
    enums,
    ui,
    nextIdentify,
    form: formReducer,
    router: connectRouter(history)
  });

export default rootReducer;
