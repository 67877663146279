import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import { workflowReportTypes } from 'react-common/constants';

import OnboardingSupplierBir from './OnboardingSupplierBir';
import {
  submitBirRequestForSupplier,
  updateBirRequestForSupplier,
  updateBirRequestFieldForSupplier,
  uploadBirRequestFileForSupplier,
  deleteBirRequestFileForSupplier,
  getBirRequestForSupplier
} from '../../../_actions';

import { ccsCountries } from '../../../_constants/onboarding';

class OnboardingSupplierBirContainer extends Component {
  componentDidMount() {
    const { supplier, dispatchGetBirRequestForSupplier } = this.props;
    if (!supplier.birRequest) {
      dispatchGetBirRequestForSupplier(supplier);
    }
  }

  updateForm = (name, value, file) => {
    const {
      dispatchUpdateBirRequestFieldForSupplier,
      dispatchUploadBirRequestFileForSupplier,
      dispatchDeleteBirRequestFileForSupplier,
      supplier,
      files
    } = this.props;

    if (!name) {
      return;
    }

    if (file) {
      return dispatchUploadBirRequestFileForSupplier(supplier, name, file);
    }
    if (!value && files[name]) {
      return dispatchDeleteBirRequestFileForSupplier(supplier, name);
    }

    return dispatchUpdateBirRequestFieldForSupplier(supplier, {
      [name]: value
    });
  };

  onStepSubmit = (formData, newStep) => {
    const { dispatchUpdateBirRequestForSupplier, supplier } = this.props;

    return dispatchUpdateBirRequestForSupplier(supplier, {
      company: formData,
      step: newStep
    });
  };

  submitForm = formData => {
    const {
      workflow,
      supplier,
      dispatchSubmitBirRequestForSupplier,
      changeOnboardingStep
    } = this.props;
    return dispatchSubmitBirRequestForSupplier(workflow, supplier, {
      company: formData
    }).then(() => changeOnboardingStep(''));
  };

  render() {
    const {
      company,
      country,
      files,
      step,
      accountId,
      uploadingFiles,
      language,
      workflow,
      supplier,
      isOldBirRequestModel
    } = this.props;

    if (!accountId) {
      return null;
    }

    let initialValues = { ...company };
    for (let key in files) {
      initialValues[key] = files[key];
    }

    let birFormCountry = 'INT';
    if (
      [
        workflowReportTypes.HOM_9_ASPECTS,
        workflowReportTypes.HOM_8_ASPECTS,
        workflowReportTypes.HOM_7_ASPECTS,
        workflowReportTypes.HOM_FALLLABELLLA,
        workflowReportTypes.HOM_FALLLABELLLA_MID
      ].includes(workflow.getReportType())
    ) {
      birFormCountry = workflow.getReportType().toUpperCase();
    } else if (country === 'ARG' && workflow.isSqrReport()) {
      birFormCountry = 'ARG_SQR';
    } else if (country === 'PER' && workflow.isSqrReport()) {
      birFormCountry = 'PER_SQR';
    } else if (['BRA', 'MEX', 'PER', 'ARG'].includes(country)) {
      birFormCountry = country;
    } else if (map(ccsCountries, 'code').includes(country)) {
      birFormCountry = 'CCS';
    }

    return (
      <OnboardingSupplierBir
        initialValues={initialValues}
        country={birFormCountry}
        currentStep={step}
        files={files}
        uploadingFiles={uploadingFiles}
        onChange={this.updateForm}
        onStepSubmit={this.onStepSubmit}
        onSubmit={this.submitForm}
        workflow={workflow}
        supplier={supplier}
        language={language}
        isOldBirRequestModel={isOldBirRequestModel}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workflows: { uploadingFiles = [] } = {} } = state;
  const {
    supplier: {
      birRequest: {
        company = {},
        country,
        files: { _files = [] } = {},
        step,
        account: { account_id: accountId } = {}
      } = {}
    } = {}
  } = props;

  const files = {};
  for (let file of _files) {
    files[file.type] = file;
  }

  return {
    company,
    country,
    files,
    step,
    accountId,
    uploadingFiles,
    isOldBirRequestModel:
      company.general_information && !!company.general_information.legal_name
  };
};

const mapDispatchToProps = {
  dispatchSubmitBirRequestForSupplier: submitBirRequestForSupplier,
  dispatchUpdateBirRequestForSupplier: updateBirRequestForSupplier,
  dispatchUpdateBirRequestFieldForSupplier: updateBirRequestFieldForSupplier,
  dispatchUploadBirRequestFileForSupplier: uploadBirRequestFileForSupplier,
  dispatchDeleteBirRequestFileForSupplier: deleteBirRequestFileForSupplier,
  dispatchGetBirRequestForSupplier: getBirRequestForSupplier
};

export default connect(mapStateToProps, mapDispatchToProps)(
  OnboardingSupplierBirContainer
);
