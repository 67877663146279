import { Fetch } from 'react-common/services';

class OnboardingService {
  getWorkflowForSupplier(workflowId, supplierId) {
    return Fetch.getJSON(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}`
    );
  }

  getWorkflow(workflowId) {
    return Fetch.getJSON(`/onboarding/workflows/${workflowId}`);
  }

  getStarted(workflowId, supplierId) {
    return Fetch.patchJSON(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/get-started`
    );
  }

  submitQuestionnaireForSupplier(
    workflowId,
    supplierId,
    answers,
    finalSubmit = false
  ) {
    return Fetch.patchJSON(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/answers${
        finalSubmit ? '/submit' : ''
      }`,
      answers
    );
  }

  submitQuestionnaireFilesForSupplier(workflowId, supplierId, files) {
    return Fetch.patch(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/answers-files`,
      files
    );
  }

  deleteQuestionnaireAnswer(workflowId, supplierId, questionId) {
    return Fetch.deleteJSON(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/answers/${questionId}`
    );
  }

  connectAccountWithSupplier(workflowId, supplierId, account) {
    return Fetch.patchJSON(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/account`,
      {
        account_id: account._id
      }
    );
  }

  changePhoneNumber(workflowId, supplierId, phone) {
    return Fetch.putJSON(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}`,
      {
        _id: supplierId,
        account: {
          phone
        }
      }
    );
  }

  getBirRequests(supplierAccount = {}) {
    return Fetch.getJSON('/onboarding/bir-requests', {
      headers: { account_id: supplierAccount.account_id }
    });
  }

  updateFieldInBirRequest(birRequest, changedData) {
    return Fetch.patchJSON(
      `/onboarding/bir-requests/${birRequest._id}`,
      changedData,
      { headers: { account_id: birRequest.account.account_id } }
    );
  }

  updateBirRequest(birRequest, changedData) {
    return Fetch.putJSON(
      `/onboarding/bir-requests/${birRequest._id}`,
      changedData,
      { headers: { account_id: birRequest.account.account_id } }
    );
  }

  submitBirRequest(birRequest, changedData) {
    return Fetch.postJSON(
      `/onboarding/bir-requests/${birRequest._id}/submit`,
      changedData,
      { headers: { account_id: birRequest.account.account_id } }
    );
  }

  uploadFile(birRequest, file) {
    return Fetch.patch(
      `/onboarding/bir-requests/${birRequest._id}/files`,
      file
    );
  }

  deleteFile(birRequest, fileName) {
    return Fetch.deleteJSON(
      `/onboarding/bir-requests/${
        birRequest._id
      }/files?fileName=${encodeURIComponent(fileName)}`
    );
  }

  createPaymentReferenceForBirRequest(birRequestId, billingInformation) {
    return Fetch.patchJSON(
      `/onboarding/bir-requests/${birRequestId}/billing`,
      billingInformation
    );
  }

  initOnlinePaymentForBirRequest(birRequestId) {
    return Fetch.postJSON(
      `/onboarding/bir-requests/${birRequestId}/online-payment`
    );
  }

  initBrazilPaymentForBirRequest(birRequestId) {
    return Fetch.postJSON(
      `/onboarding/bir-requests/${birRequestId}/brazil-payment`
    );
  }

  addPaymentProof(workflowId, supplierId, paymentProof) {
    return Fetch.patch(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/payment-proof`,
      paymentProof
    );
  }

  removePaymentProof(workflowId, supplierId, paymentProofId) {
    return Fetch.delete(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/payment-proof/${paymentProofId}`
    );
  }

  submitPaymentProof(workflowId, supplierId) {
    return Fetch.patch(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/payment-proof/submit`
    );
  }

  uploadRfcDocument(workflowId, supplierId, rfcDocument) {
    return Fetch.patch(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/rfc-document`,
      rfcDocument
    );
  }

  deleteRfcDocument(workflowId, supplierId) {
    return Fetch.delete(
      `/onboarding/workflows/${workflowId}/suppliers/${supplierId}/rfc-document`
    );
  }

  authenticateSupplier(workflowId, supplierId, token) {
    localStorage.setItem(`supplier_token_${workflowId}_${supplierId}`, token);
  }

  isSupplierAuthenticated(workflowId, supplierId) {
    return (
      localStorage.getItem(`supplier_token_${workflowId}_${supplierId}`) !==
      null
    );
  }

  addSupplierToWorkflow(
    workflowId,
    fullName,
    email,
    companyName,
    country,
    phone
  ) {
    return Fetch.postJSON(`/onboarding/workflows/${workflowId}/suppliers`, {
      invite: {
        full_name: fullName,
        company_name: companyName,
        email,
        country,
        phone
      }
    });
  }
}

export default new OnboardingService();
