import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { login } from '../_actions';
import Login from './Login';
import UserService from '../_services/User';

class LoginContainer extends Component {
  componentDidMount() {
    const { logAsEmail } = this.props;

    // log out user if log as email exists
    if (logAsEmail) {
      UserService.logout();
    }
  }

  submit = user => {
    const { dispatchLogin, nextUrl, logAsEmail } = this.props;
    return dispatchLogin(user, nextUrl, logAsEmail);
  };

  render() {
    const { logAsEmail } = this.props;

    // redirect to home if already authorized and log as feature is not used
    if (Auth.isUserAuthenticated() && !logAsEmail) {
      return <Redirect to="/home" />;
    }

    return <Login {...this.props} onSubmit={this.submit} />;
  }
}

const mapStateToProps = (state, props) => {
  const { login } = state.user;
  const logAsEmail = queryString.parse(props.location.search).log_as_email;

  return {
    login,
    logAsEmail,
    nextUrl:
      props.location &&
      props.location.state &&
      props.location.state.from &&
      props.location.state.from.pathname
  };
};

const mapDispatchToProps = {
  dispatchLogin: login
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
