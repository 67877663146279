import React from 'react';
import { Table, Modal } from 'react-bootstrap';

import './PaydexModal.css';

const PaydexModal = ({ show, handleClose }) => (
  <Modal className="Modal-paydex" show={show} onHide={handleClose}>
    <Modal.Header>
      <Modal.Title className="ModalTitle">Paydex</Modal.Title>
      <i
        className="icon-ic_close pull-right Close-modal-icon"
        onClick={handleClose}
      />
    </Modal.Header>
    <Modal.Body>
      <Table responsive>
        <thead>
          <tr>
            <th>Score</th>
            <th>Payment</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="Square-badge Bg-green">100</div>
            </td>
            <td>30 days in advance</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-green">90</div>
            </td>
            <td>20 days in advance</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-green">80</div>
            </td>
            <td>Prompt</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-yellow">70</div>
            </td>
            <td>15 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-yellow">60</div>
            </td>
            <td>22 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-yellow">50</div>
            </td>
            <td>30 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-coral">40</div>
            </td>
            <td>60 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-coral">30</div>
            </td>
            <td>90 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-coral">20</div>
            </td>
            <td>120 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-coral">&lt;20</div>
            </td>
            <td>+120 Days Beyond Terms</td>
          </tr>

          <tr>
            <td>
              <div className="Square-badge Bg-grey">UN</div>
            </td>
            <td>Unavailable</td>
          </tr>
        </tbody>
      </Table>
    </Modal.Body>
  </Modal>
);

export default PaydexModal;
