import React from 'react';

import getStartedIcon from '../../_images/ic_workflow_getstarted.svg';
import './DashboardEmpty.css';

const DashboardEmpty = ({ showCreateButton, onCreateWorkflowClick }) => (
  <div className="DashboardEmpty">
    <div>
      <img src={getStartedIcon} alt="Create workflow" />
      <h1>Get Started</h1>
      <p>You haven't created any workflows yet.</p>
      {showCreateButton && (
        <button
          className="Btn-default Btn-default-primary CreateWorkflow-btn"
          onClick={onCreateWorkflowClick}
        >
          Create your workflow
        </button>
      )}
    </div>
  </div>
);

export default DashboardEmpty;
