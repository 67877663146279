import React, { Component } from 'react';
import { UploadcareDialog } from 'react-common/components';
import { Localize } from 'react-common/services';

const getCoverStyle = cover =>
  cover ? { backgroundImage: `url('${cover}')` } : {};

class WorkflowsCreateCover extends Component {
  render() {
    const { cover, onUploadComplete } = this.props;
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <label>Cover photo (optimal width: 1920px)</label>
            <div
              className={
                'WorkflowsCreate-cover img-responsive' +
                (cover ? '' : ' WorkflowsCreate-no-cover-image')
              }
              style={getCoverStyle(cover)}
              onClick={() => this.coverUploader.openDialog()}
            >
              <div className="Cover-overlay">
                <div className="Cover-overlay-text">Change</div>
              </div>
            </div>
            <UploadcareDialog
              value={cover}
              crop="3:1"
              ref={uploader => (this.coverUploader = uploader)}
              onUploadComplete={onUploadComplete}
              language={Localize.getLanguage()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default WorkflowsCreateCover;
