import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { Tooltip, FileUpload, Checkbox } from 'react-common/components';
import { Field, FormSection, reduxForm } from 'redux-form';
import { isValid as isValidCnpj } from '@fnando/cnpj';

import { statesMapping } from '../../../_constants/onboarding';
import { createPaymentReferenceForSupplier } from '../../../_actions/onboarding';
import TextField from '../../../_components/Form/TextField/TextField';
import lockIcon from '../../../_images/lock.svg';

const required = value =>
  value || typeof value === 'number' ? undefined : 'Required';

const isRFC = value =>
  value.match(/^[0-9A-Za-z&]{12,13}$/) ? undefined : 'RFC is not valid';

const isRUC = value =>
  value.match(/^[0-9]{11}$/) ? undefined : 'RUC is not valid';

const isDNI = value =>
  value.match(/^[0-9]{8}$/) ? undefined : 'DNI is not valid';

const isCNPJ = value =>
  isValidCnpj(value, true) ? undefined : 'CNPJ is not valid';

// https://gist.github.com/martinrios95/fd8a35212af3bbbd3477ae59a2e8b1dc
const isCUIT = value => {
  const errorMessage = 'CUIT is not valid';
  const cuitArray = value.split('-');
  if (cuitArray.length !== 3) {
    return errorMessage;
  }
  const verifier = ('' + cuitArray[0] + cuitArray[1]).split('');
  const base = [2, 3, 4, 5, 6, 7];

  // Reverse array
  let indexStart = 0;
  let indexEnd = verifier.length - 1;
  while (indexStart < indexEnd) {
    const auxChar = verifier[indexEnd];
    verifier[indexEnd] = verifier[indexStart];
    verifier[indexStart] = auxChar;
    indexStart++;
    indexEnd--;
  }

  // Get sum from previous array
  let sum = 0;
  for (let i = 0; i < verifier.length; i++) {
    sum += parseInt(verifier[i]) * base[i % base.length];
  }

  // Verify digits
  const sumMod11 = sum % 11;
  let verifierDigit = 11 - sumMod11;
  if (verifierDigit === 11) {
    verifierDigit = 0;
  }

  // Compare verifier digits
  return parseInt(cuitArray[2]) === verifierDigit ? undefined : errorMessage;
};

const mexicoPaymentsMethods = [
  'Cash',
  'Nominal Check',
  'Electronic Funds Transfer',
  'Credit Card',
  'To Define'
];

const peruPaymentMethods = [
  'Cash Deposit',
  'Deposit with Check',
  'Wire transfer'
];

const ccsPaymentMethods = ['Wire transfer', 'PayPal'];

const argentinaPaymentMethods = ['Cash Deposit', 'Deposit with Check'];

const brazilPaymentMethods = ['Boleto', 'Credit Card'];

const internationalPaymentMethods = [
  'Cash',
  'Nominal Check',
  'Electronic Funds Transfer',
  'Credit Card'
];

const paymentMethods = {
  mexico: mexicoPaymentsMethods,
  peru: peruPaymentMethods,
  ccs: ccsPaymentMethods,
  argentina: argentinaPaymentMethods,
  brazil: brazilPaymentMethods,
  international: internationalPaymentMethods
};

const peruTaxTypes = ['ruc', 'dni'];

const cfiOptions = ['P01 (To be defined)', 'G03 (General expenses)'];

const requiredLabel = label => (
  <>
    <span>{label}</span>
    <span notranslate="true">*</span>
  </>
);

const OnboardingSupplierPaymentReferenceForm = ({
  supplier: { birRequest: { files: { _files: files = [] } = {} } = {} },
  handleSubmit,
  submitting,
  createPaymentReference,
  formError,
  formValues: { general = {}, payment = {} } = {},
  paymentRegion,
  uploadRfcDocument,
  deleteRfcDocument,
  uploadingFiles
}) => {
  const rfcDocument = files.find(file => file.type === 'rfc_document');

  return (
    <form onSubmit={handleSubmit(createPaymentReference)}>
      <div>
        <h1> Payment reference information</h1>
        <p>
          Please provide information for creating a payment reference and
          invoice.
        </p>
      </div>
      <div className="row PaymentReference-row">
        <div className="col-md-6">
          <FormSection name="general">
            <h5 className="PaymentReference-form-section">General Data</h5>
            <TextField
              label={requiredLabel('Full Business Name')}
              name="company_name"
              placeholder="Company Name"
              validate={[required]}
            />
            {paymentRegion === 'peru' && (
              <div className="form-group">
                <label htmlFor="tax_id_type">
                  {requiredLabel('Tax ID Type')}
                </label>
                <Field
                  className="form-control"
                  name="tax_id_type"
                  placeholder="Tax ID Type"
                  validate={[required]}
                  component={component => (
                    <Dropdown
                      id="tax-id-type-dropdown"
                      className="dropdown form-control Dropdown-form-control"
                      onSelect={component.input.onChange}
                    >
                      <Dropdown.Toggle className="form-control Dropdown-form-control">
                        {component.input.value.toUpperCase()}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {peruTaxTypes.map(type => (
                          <MenuItem eventKey={type} key={type}>
                            {type.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                />
              </div>
            )}
            {paymentRegion === 'peru' && general.tax_id_type === 'ruc' && (
              <TextField
                label={requiredLabel('RUC')}
                name="tax_id"
                placeholder="RUC (Tax ID)"
                validate={[required, isRUC]}
              />
            )}
            {paymentRegion === 'peru' && general.tax_id_type === 'dni' && (
              <TextField
                label={requiredLabel('DNI')}
                name="tax_id"
                placeholder="DNI (Tax ID)"
                validate={[required, isDNI]}
              />
            )}
            {paymentRegion === 'mexico' && (
              <TextField
                label={requiredLabel('RFC')}
                name="tax_id"
                placeholder="RFC (Tax ID)"
                validate={[required, isRFC]}
              />
            )}
            {(paymentRegion === 'ccs' ||
              paymentRegion === 'chile' ||
              paymentRegion === 'international') && (
              <TextField
                label={requiredLabel('Tax ID')}
                name="tax_id"
                placeholder="Tax ID"
                validate={[required]}
              />
            )}
            {paymentRegion === 'argentina' && (
              <TextField
                label={requiredLabel('CUIT (XX-XXXXXXXX-X)')}
                name="tax_id"
                placeholder="CUIT (Tax ID)"
                validate={[required, isCUIT]}
              />
            )}
            {paymentRegion === 'brazil' && (
              <TextField
                label={requiredLabel('CNPJ')}
                name="tax_id"
                placeholder="CNPJ (Tax ID)"
                validate={[required, isCNPJ]}
              />
            )}
            <TextField
              label={requiredLabel('Street address')}
              name="address"
              placeholder="Address"
              validate={[required]}
            />
            <TextField
              label={requiredLabel('Postal code')}
              name="postal_code"
              placeholder="Postal Code"
              validate={[required]}
            />
            <TextField
              label={requiredLabel('City')}
              name="city"
              placeholder="City"
              validate={[required]}
            />
            {(paymentRegion === 'ccs' || !statesMapping[paymentRegion]) && (
              <TextField
                label={requiredLabel('State')}
                name="state"
                placeholder="State"
                validate={[required]}
              />
            )}
            {paymentRegion !== 'ccs' && statesMapping[paymentRegion] && (
              <div className="form-group">
                <label htmlFor="state">State</label>
                <Field
                  type="text"
                  className="form-control"
                  name="state"
                  placeholder="State"
                  component={component => (
                    <Dropdown
                      id="state-method-dropdown"
                      className="dropdown form-control Dropdown-form-control"
                      onSelect={component.input.onChange}
                    >
                      <Dropdown.Toggle className="form-control Dropdown-form-control">
                        {component.input.value}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{ overflowY: 'scroll', maxHeight: '200px' }}
                      >
                        {statesMapping[paymentRegion].map(state => (
                          <MenuItem eventKey={state} key={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                />
              </div>
            )}
            <TextField
              label={requiredLabel('Telephone')}
              name="phone"
              placeholder="Telephone"
              validate={[required]}
            />
            {paymentRegion !== 'mexico' && (
              <TextField
                label={requiredLabel('Mobile Phone')}
                name="mobile_phone"
                placeholder="Mobile Phone"
                validate={[required]}
              />
            )}
          </FormSection>
        </div>
        <div className="col-md-6">
          <FormSection name="payment">
            <h5 className="PaymentReference-form-section">
              Billing Information
            </h5>
            <TextField
              label={requiredLabel('Person who should receive invoice')}
              name="person_name"
              placeholder="Name"
              validate={[required]}
            />
            <TextField
              label={requiredLabel('Position')}
              name="position"
              placeholder="Position"
              validate={[required]}
            />
            <TextField
              label={requiredLabel('Email')}
              name="email"
              placeholder="Email"
              validate={[required]}
            />
            {paymentRegion === 'mexico' && (
              <div className="form-group">
                <label htmlFor="cfi">
                  Use of CFI
                  <Tooltip
                    text="The use of the CFI code is to understand how the invoice will be used once presented. Please select one of the following options."
                    position="right"
                    id="cfi-info"
                  >
                    <i className="icon-ic_question" />
                  </Tooltip>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="cfi"
                  placeholder="CFI"
                  component={component => (
                    <Dropdown
                      id="cfi-method-dropdown"
                      className="dropdown form-control Dropdown-form-control"
                      onSelect={component.input.onChange}
                    >
                      <Dropdown.Toggle className="form-control Dropdown-form-control">
                        {component.input.value}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {cfiOptions.map(cfi => (
                          <MenuItem eventKey={cfi} key={cfi}>
                            {cfi}
                          </MenuItem>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                />
              </div>
            )}

            {paymentRegion !== 'chile' && (
              <div className="form-group">
                <label htmlFor="method">Method of payment</label>
                <Field
                  className="form-control"
                  name="method"
                  placeholder="Method of payment"
                  validate={[required]}
                  component={component => (
                    <Dropdown
                      id="payment-method-dropdown"
                      className="dropdown form-control Dropdown-form-control"
                      onSelect={component.input.onChange}
                    >
                      <Dropdown.Toggle className="form-control Dropdown-form-control">
                        {component.input.value}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {(paymentMethods[paymentRegion] || []).map(method => (
                          <MenuItem eventKey={method} key={method}>
                            {method}
                          </MenuItem>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                />
              </div>
            )}
          </FormSection>

          {paymentRegion === 'mexico' && (
            <div className="form-group">
              <label htmlFor="rfcDocument">
                {requiredLabel('RFC document')}
              </label>

              <FileUpload
                fieldName="rfcDocument"
                isUploading={includes(uploadingFiles, 'rfc_document')}
                onChange={uploadRfcDocument}
                file={rfcDocument}
                onDelete={deleteRfcDocument}
                buttonClassName="Btn-outline-small Btn-outline-primary"
              />
            </div>
          )}
        </div>
      </div>
      {formError && (
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger alert-dismissible" role="alert">
              {formError}
            </div>
          </div>
        </div>
      )}
      <div className="text-center">
        {paymentRegion === 'brazil' && (
          <div className="Terms-checkbox">
            <Field
              type="checkbox"
              name={'payment.terms_accepted'}
              label={
                <span>
                  In order to attend with the need of my client’s homologation
                  process, I agree to bear with the costs of the evaluation
                  process and to share any documents that might be requested in
                  its duration. The non-participation in this process may affect
                  your relationship with this customer. If you have any
                  questions, please contact us through the online chat (bottom
                  right corner) or the e-mail{' '}
                  <a href="mailto:sacbrazil@cialdnb.com">
                    sacbrazil@cialdnb.com
                  </a>
                </span>
              }
              component={Checkbox}
            />
          </div>
        )}
        <button
          className="Btn-default Btn-default-primary"
          type="submit"
          disabled={submitting}
        >
          Get payment information
        </button>
      </div>

      <p className="SecurityInformation">
        <img src={lockIcon} alt="lock-icon" />
        Your personal information is secure and will not be used for any other
        purpose other than verification.
      </p>
    </form>
  );
};

const updateBillingInfoDebounce = debounce(
  (billingInfo, supplier, dispatch) =>
    dispatch(createPaymentReferenceForSupplier(supplier, billingInfo)),
  1000
);

const OnboardingSupplierPaymentReference = reduxForm({
  form: 'payment-reference',
  onChange: (values, dispatch, props) =>
    updateBillingInfoDebounce(values, props.supplier, dispatch)
})(OnboardingSupplierPaymentReferenceForm);

export default OnboardingSupplierPaymentReference;
