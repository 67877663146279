import React from 'react';
import { onboardingSteps } from 'react-common/constants';

import { appMyCialDomains } from '../../../_constants/onboarding';
import thankyouImg from '../../../_images/thankyou.svg';

import './OnboardingSupplierFinished.css';

const OnboardingSupplierFinished = ({ workflow, changeOnboardingStep }) => (
  <div className="OnboardingSupplierFinished">
    <img src={thankyouImg} alt="Thankyou" />
    <p>Thank you for participating in the onboarding process!</p>
    <p>With this account you can login and explore MyCIAL platform.</p>

    {workflow.hasQuestionnaire() &&
      workflow.isCkrReport() && (
        <>
          <p>
            Update answers from questionnaire or login and explore MyCIAL
            platform with this account.
          </p>
          <button
            className="Btn-default Btn-default-success"
            onClick={() => changeOnboardingStep(onboardingSteps.QUESTIONNAIRE)}
          >
            Update questionnaire
          </button>
        </>
      )}

    <a
      href={appMyCialDomains[process.env.REACT_APP_ENV]}
      className="Btn-default Btn-default-primary"
    >
      Login to MyCIAL
    </a>
  </div>
);

export default OnboardingSupplierFinished;
