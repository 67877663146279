import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { onboardingSteps } from 'react-common/constants';

import confirmationIcon from '../../../_images/confirmation.svg';
import './OnboardingSupplierConfirmation.css';

const OnboardingSupplierConfirmationForm = ({
  workflow,
  supplier,
  inputShown,
  goBackStep,
  showInput,
  hideInput,
  changeOnboardingStep,
  selectGoBackOnboardingStep,
  trackSupplierChangedOnboardingStep,
  formError,
  handleSubmit,
  submitting
}) => (
  <div className="OnboardingSupplierConfirmation">
    <img src={confirmationIcon} alt="Confirmation" />
    <h1>Thank you for finishing the form</h1>
    <p>
      A Cial Dun & Bradstreet representative will be in touch soon for a
      verification call.
      <br />
      You will be contacted at the number below: <br />
    </p>
    <div>
      <p className="OnboardingSupplierConfirmation-phone">
        <var>{supplier.account.phone}</var>
      </p>
    </div>
    <div>
      {!inputShown && (
        <p
          className="OnboardingSupplierConfirmation-change-phone"
          onClick={showInput}
        >
          Edit phone number
        </p>
      )}
      {inputShown && (
        <React.Fragment>
          <hr />
          <form onSubmit={handleSubmit}>
            <label htmlFor="phone">Phone</label>
            <Field
              type="text"
              className="form-control"
              name="phone"
              component="input"
              placeholder="Enter new phone number"
            />
            <button
              type="button"
              onClick={hideInput}
              className="Btn-outline-small Btn-outline-primary"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="Btn-default-small Btn-default-primary"
            >
              Save
            </button>
          </form>
          {formError && (
            <div className="alert alert-danger" role="alert">
              {formError}
            </div>
          )}
        </React.Fragment>
      )}
    </div>

    <div className="Go-back">
      <p className="Go-back-title">
        If you need to update any info, choose information type and click button
        below.
      </p>
      <div className="Steps">
        {workflow.isSupplierPaying() && (
          <div
            className={`Go-back-step ${
              goBackStep === onboardingSteps.PAYMENT ? 'Selected' : ''
            }`}
            onClick={() => selectGoBackOnboardingStep(onboardingSteps.PAYMENT)}
          >
            <div>Payment info</div>
          </div>
        )}

        {!workflow.isCkrReport() &&
          workflow.isSupplierPaying() &&
          supplier.getCountry() !== 'brazil' && (
            <div
              className={`Go-back-step ${
                goBackStep === onboardingSteps.BIR_FORM ? 'Selected' : ''
              }`}
              onClick={() =>
                selectGoBackOnboardingStep(onboardingSteps.BIR_FORM)
              }
            >
              <div>Form</div>
            </div>
          )}

        {workflow.hasQuestionnaire() && (
          <div
            className={`Go-back-step ${
              goBackStep === onboardingSteps.QUESTIONNAIRE ? 'Selected' : ''
            }`}
            onClick={() =>
              selectGoBackOnboardingStep(onboardingSteps.QUESTIONNAIRE)
            }
          >
            <div>Questionnaire</div>
          </div>
        )}

        {workflow.isHomFalabellaReport() &&
          workflow.isSupplierPaying() && (
            <div
              className={`Go-back-step ${
                goBackStep === onboardingSteps.BIR_FORM ? 'Selected' : ''
              }`}
              onClick={() =>
                selectGoBackOnboardingStep(onboardingSteps.BIR_FORM)
              }
            >
              <div>Form</div>
            </div>
          )}
      </div>
    </div>
    <button
      className="Btn-default Btn-default-primary Edit-btn"
      disabled={!goBackStep}
      onClick={() => {
        trackSupplierChangedOnboardingStep();
        changeOnboardingStep(goBackStep);
      }}
    >
      Update changes
    </button>
  </div>
);

const OnboardingSupplierConfirmation = reduxForm({
  form: 'onboarding-confirmation'
})(OnboardingSupplierConfirmationForm);

export default OnboardingSupplierConfirmation;
