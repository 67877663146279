import React, { Component, Fragment } from 'react';
import { Dropdown, MenuItem, Panel } from 'react-bootstrap';
import {
  Checkbox,
  FileUpload,
  RadioButton,
  Toggle,
  Tooltip
} from 'react-common/components';
import { questionTypes, workflowReportTypes } from 'react-common/constants';
import { Container, Draggable } from 'react-smooth-dnd';
import { Field, FieldArray } from 'redux-form';
import includes from 'lodash/includes';

import { addAttachmentToQuestion } from '../../../_actions';

class WorkflowsCreateStepsQuestionnaire extends Component {
  state = {
    activeQuestion: ''
  };

  questionTypeLabel = {
    [questionTypes.INPUT]: 'Insert free text',
    [questionTypes.RADIO]: 'Multiple choice',
    [questionTypes.CHECKBOX]: 'Checkboxes',
    [questionTypes.FILE]: 'Upload file',
    [questionTypes.ATTACHMENT]: 'Attach file'
  };

  renderQuestionTypeList = (component, index) => (
    <Dropdown
      id={`dropdown-question-type-${index}`}
      onSelect={component.input.onChange}
      className="form-control"
    >
      <Dropdown.Toggle className="form-control Dropdown-form-control WorkflowsCreate-dropdown">
        {this.questionTypeLabel[component.input.value] || 'Select answer type'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(this.questionTypeLabel).map(type => (
          <MenuItem eventKey={type} key={type}>
            {this.questionTypeLabel[type]}
          </MenuItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  renderInputQuestion = (
    field,
    questionNumber,
    removeQuestion,
    questionType
  ) => (
    <Fragment>
      <div className="col-xs-12 col-md-6 col-lg-7 question Question-input">
        <div className="Question-number">
          <var>{questionNumber}.</var>
          <i
            className="WorkflowsCreate-btn-drag icon-ic_grip-horizontal"
            type="button"
          />
        </div>
        <Field
          type="text"
          className="form-control"
          name={`${field}.title`}
          component="input"
          placeholder={
            questionType === questionTypes.INPUT
              ? 'Set the question'
              : 'Describe the required file'
          }
        />
      </div>
      <div className="col-xs-9 col-md-6 col-lg-5 question question-type-buttons">
        <Field
          type="text"
          className="form-control"
          name={`${field}.type`}
          component={input =>
            this.renderQuestionTypeList(input, questionNumber)
          }
          placeholder="Question Type"
        />
        <div className="Required-question">
          <span className="Required-label">Required</span>
          <Field
            type="checkbox"
            className="form-control"
            name={`${field}.required`}
            component={Toggle}
          />
        </div>
        <i
          className="WorkflowsCreate-btn-delete icon-ic_trash"
          type="button"
          onClick={removeQuestion}
        />
      </div>
    </Fragment>
  );

  renderSectionQuestion = (field, removeQuestion) => (
    <Fragment>
      <div className="col-xs-12 question Question-input">
        <div className="Question-number">
          <var className="Title-mark">T</var>
          <i
            className="WorkflowsCreate-btn-drag icon-ic_grip-horizontal"
            type="button"
          />
        </div>
        <Field
          type="text"
          className="form-control"
          name={`${field}.title`}
          component="input"
          placeholder="Set the section title"
        />
        <i
          className="WorkflowsCreate-btn-delete icon-ic_trash"
          type="button"
          onClick={removeQuestion}
        />
      </div>
    </Fragment>
  );

  renderCheckbox = ({ fields }) => {
    // start with at least two checkboxes
    if (!fields.length) {
      fields.push({ value: '' });
      fields.push({ value: '' });
    }

    const checkboxInputs = fields.map((option, index) => (
      <div className="row" key={index}>
        <div className="col-xs-9 col-md-7 col-lg-5 Question-options">
          <Checkbox disabled={true} label="&nbsp;" noTranslate={true} />

          <Field
            type="text"
            className="form-control "
            name={`${option}.value`}
            component="input"
            placeholder={`Option ${index + 1}`}
          />
          <i
            className={`WorkflowsCreate-btn-delete icon-ic_trash ${
              index < 2 ? 'disabled' : ''
            }`}
            type="button"
            onClick={() => index >= 2 && fields.remove(index)}
          />
        </div>
      </div>
    ));

    checkboxInputs.push(
      <button
        key={checkboxInputs.length}
        className="icon-ic_checkbox btn WorkflowsCreate-btn-add WorkflowsCreate-btn-add-option"
        type="button"
        onClick={() => fields.push({ value: '' })}
      >
        Add option
      </button>
    );
    return checkboxInputs;
  };

  renderCheckboxQuestion = (field, questionNumber, removeQuestion) => (
    <Fragment>
      <div className="col-xs-12 col-md-6 col-lg-7 question Question-input">
        <div className="Question-number">
          <var>{questionNumber}.</var>
          <i
            className="WorkflowsCreate-btn-drag icon-ic_grip-horizontal"
            type="button"
          />
        </div>
        <Field
          type="text"
          className="form-control"
          name={`${field}.title`}
          component="input"
          placeholder="Set the question title"
        />
      </div>
      <div className="col-xs-12 col-md-6 col-lg-5 question question-type-buttons">
        <Field
          type="text"
          className="form-control"
          name={`${field}.type`}
          component={input =>
            this.renderQuestionTypeList(input, questionNumber)
          }
          placeholder="Question Type"
        />
        <div className="Required-question">
          <span className="Required-label">Required</span>
          <Field
            type="checkbox"
            className="form-control"
            name={`${field}.required`}
            component={Toggle}
          />
        </div>
        <i
          className="WorkflowsCreate-btn-delete icon-ic_trash"
          type="button"
          onClick={removeQuestion}
        />
      </div>
      <div className="col-xs-12">
        <FieldArray name={`${field}.options`} component={this.renderCheckbox} />
      </div>
    </Fragment>
  );

  renderRadioButton = ({ fields }) => {
    // start with at least two radio buttons
    if (!fields.length) {
      fields.push({ value: '' });
      fields.push({ value: '' });
    }

    const radioInputs = fields.map((option, index) => (
      <div className="row" key={index}>
        <div className="col-xs-9 col-md-7 col-lg-5 Question-options">
          <RadioButton disabled={true} label="&nbsp;" noTranslate={true} />
          <Field
            type="text"
            className="form-control"
            name={`${option}.value`}
            component="input"
            placeholder={`Option ${index + 1}`}
          />
          <i
            className={`WorkflowsCreate-btn-delete icon-ic_trash ${
              index < 2 ? 'disabled' : ''
            }`}
            type="button"
            onClick={() => index >= 2 && fields.remove(index)}
          />
        </div>
      </div>
    ));

    radioInputs.push(
      <button
        key={radioInputs.length}
        className="icon-ic_radio btn WorkflowsCreate-btn-add WorkflowsCreate-btn-add-option"
        type="button"
        onClick={() => fields.push({ value: '' })}
      >
        Add option
      </button>
    );
    return radioInputs;
  };

  renderRadioButtonQuestion = (field, questionNumber, removeQuestion) => (
    <Fragment>
      <div className="col-xs-12 col-md-6 col-lg-7 question Question-input">
        <div className="Question-number">
          <var>{questionNumber}.</var>
          <i
            className="WorkflowsCreate-btn-drag icon-ic_grip-horizontal"
            type="button"
          />
        </div>
        <Field
          type="text"
          className="form-control"
          name={`${field}.title`}
          component="input"
          placeholder="Set the question title"
        />
      </div>
      <div className="col-xs-12 col-md-6 col-lg-5 question question-type-buttons">
        <Field
          type="text"
          className="form-control"
          name={`${field}.type`}
          component={input =>
            this.renderQuestionTypeList(input, questionNumber)
          }
          placeholder="Question Type"
        />
        <div className="Required-question">
          <span className="Required-label">Required</span>
          <Field
            type="checkbox"
            className="form-control"
            name={`${field}.required`}
            component={Toggle}
          />
        </div>
        <i
          className="WorkflowsCreate-btn-delete icon-ic_trash"
          type="button"
          onClick={removeQuestion}
        />
      </div>
      <div>
        <FieldArray
          name={`${field}.options`}
          component={this.renderRadioButton}
        />
      </div>
    </Fragment>
  );

  renderAttachmentFile = (field, question) => {
    const { workflowId, uploadingFiles, dispatch, change } = this.props;

    return (
      <FileUpload
        fieldName={question._id}
        isUploading={includes(uploadingFiles, question._id)}
        onChange={file => {
          if (!question._id) {
            return change(`${field}.file`, file);
          }
          dispatch(
            addAttachmentToQuestion(workflowId, question._id, file)
          ).then(question => change(`${field}.file`, question.file));
        }}
        file={question.file}
        onDelete={() => change(`${field}.file`, {})}
        buttonClassName="Btn-default-small Btn-default-primary"
      />
    );
  };

  renderAttachmentQuestion = (
    field,
    questionNumber,
    removeQuestion,
    question
  ) => (
    <Fragment>
      <div className="col-xs-12 col-md-6 col-lg-7 question Question-input">
        <div className="Question-number">
          <var>{questionNumber}.</var>
          <i
            className="WorkflowsCreate-btn-drag icon-ic_grip-horizontal"
            type="button"
          />
        </div>
        <Field
          type="text"
          className="form-control"
          name={`${field}.title`}
          component="input"
          placeholder="Set the attachment title"
        />
      </div>
      <div className="col-xs-12 col-md-6 col-lg-5 question question-type-buttons">
        <Field
          type="text"
          className="form-control"
          name={`${field}.type`}
          component={input =>
            this.renderQuestionTypeList(input, questionNumber)
          }
          placeholder="Question Type"
        />
        <i
          className="WorkflowsCreate-btn-delete icon-ic_trash"
          type="button"
          onClick={removeQuestion}
        />
      </div>
      <div className="col-xs-12 Question-attachment">
        {this.renderAttachmentFile(field, question)}
      </div>
    </Fragment>
  );

  setActiveQuestion = activeQuestion => this.setState({ activeQuestion });

  renderQuestions = ({ fields }) => {
    const { activeQuestion } = this.state;
    let questionNumber = 0;

    const questions = fields.map((field, index) => {
      const question = fields.get(index);
      if (!question || !question.type) {
        return null;
      }

      const { type: questionType } = question;

      if (questionType !== questionTypes.SECTION) {
        questionNumber += 1;
      }

      const removeQuestion = () => fields.remove(index);

      return (
        <Draggable
          key={index}
          className={`row question ${activeQuestion === index ? 'active' : ''}`}
          onDragStart={() => this.setActiveQuestion(index)}
        >
          {(questionType === questionTypes.INPUT ||
            questionType === questionTypes.FILE) &&
            this.renderInputQuestion(
              field,
              questionNumber,
              removeQuestion,
              questionType
            )}
          {questionType === questionTypes.CHECKBOX &&
            this.renderCheckboxQuestion(field, questionNumber, removeQuestion)}
          {questionType === questionTypes.RADIO &&
            this.renderRadioButtonQuestion(
              field,
              questionNumber,
              removeQuestion
            )}
          {questionType === questionTypes.SECTION &&
            this.renderSectionQuestion(field, removeQuestion)}
          {questionType === questionTypes.ATTACHMENT &&
            this.renderAttachmentQuestion(
              field,
              questionNumber,
              removeQuestion,
              question
            )}
        </Draggable>
      );
    });

    return (
      <div>
        <Container
          onDrop={({ removedIndex, addedIndex }) =>
            fields.move(removedIndex, addedIndex)
          }
          lockAxis="y"
          dragHandleSelector={'.WorkflowsCreate-btn-drag'}
        >
          {questions}
        </Container>
        {!questions.length && (
          <div className="col-xs-12 WorkflowsCreate-no-questions">
            To add question, click create new question.
          </div>
        )}
        <div className="col-xs-12">
          <button
            className="icon-ic_plus btn WorkflowsCreate-btn-add"
            type="button"
            onClick={() => {
              fields.push({ type: questionTypes.INPUT, required: true });
            }}
          >
            Add question
          </button>
          <button
            className="icon-ic_plus btn WorkflowsCreate-btn-add WorkflowsCreate-btn-add-title"
            type="button"
            onClick={() => {
              fields.push({ type: questionTypes.SECTION });
            }}
          >
            Add title
          </button>
        </div>
      </div>
    );
  };

  showQuestionnairePreviewPage = () => {
    const { workflowId } = this.props;
    window.open(`/onboarding/${workflowId}/questionnaire`, '_blank');
  };

  render() {
    const { formValues, hasQuestionnaireErrors } = this.props;

    let title = 'Set questionnaire and share files';
    if (formValues.report_type !== workflowReportTypes.QUESTIONNAIRE) {
      title += ' (optional)';
    }

    return (
      <Panel className="WorkflowsCreate-panel" eventKey="3">
        <Panel.Heading>
          <Panel.Title toggle>
            <span>{title}</span>
            <span className="pull-right">
              <Tooltip
                text="Preview"
                position="bottom"
                id="preview-questionnaire"
              >
                <i
                  className="icon-ic_eye  Icon-preview"
                  onClick={this.showQuestionnairePreviewPage}
                />
              </Tooltip>
              <i className="icon-ic_expand-arrow" />
            </span>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <FieldArray name="questionnaire" component={this.renderQuestions} />
            {hasQuestionnaireErrors && (
              <div className="col-xs-12 WorkflowsCreate-error">
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  All questionnaire fields are required.
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default WorkflowsCreateStepsQuestionnaire;
