import includes from 'lodash/includes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { questionTypes } from 'react-common/constants';
import OnboardingSupplierQuestionnaire from './OnboardingSupplierQuestionnaire';
import {
  deleteQuestionnaireAnswer,
  download,
  submitOnboardingQuestionnaireFailure,
  submitQuestionnaire,
  submitQuestionnaireFiles
} from '../../../_actions';
import WorkflowsService from '../../../_services/Workflows';

class OnboardingSupplierQuestionnaireContainer extends Component {
  submitAnswers = (finalSubmit = false) => answers => {
    const {
      dispatchSubmitOnboardingQuestionnaireFailure,
      dispatchSubmitQuestionnaire,
      workflow,
      workflowId,
      supplier,
      supplierId,
      changeOnboardingStep,
      setQuestionnaireFormAsSubmitted
    } = this.props;
    let requiredFieldsError = false;

    if (finalSubmit) {
      // check all required questions have been answered
      workflow.questionnaire.forEach(question => {
        const answer = answers[question._id];

        if (
          question.required &&
          ((includes(
            [questionTypes.INPUT, questionTypes.RADIO, questionTypes.CHECKBOX],
            question.type
          ) &&
            !answer) ||
            (question.type === questionTypes.CHECKBOX &&
              Object.values(answer).every(selected => !selected)))
        ) {
          requiredFieldsError = true;
        }
      });

      // check if all required files are uploaded
      const questionnaire =
        WorkflowsService.getQuestionnaireAnswers(workflow, supplier) || [];
      const notAllFilesUploaded = questionnaire
        .filter(
          qa => qa.question.type === questionTypes.FILE && qa.question.required
        )
        .some(qa => !qa.answer || !qa.answer.answer);

      if (notAllFilesUploaded) {
        requiredFieldsError = true;
      }

      if (requiredFieldsError) {
        return dispatchSubmitOnboardingQuestionnaireFailure(
          'Please answer all required (*) fields'
        );
      }
    }

    const newAnswers = { ...answers };

    // convert checkbox answers to arrays of selected option ids
    workflow.questionnaire
      .filter(q => q.type === questionTypes.CHECKBOX)
      .forEach(question => {
        if (newAnswers[question._id]) {
          const checkboxAnswers = newAnswers[question._id] || {};
          newAnswers[question._id] = Object.keys(checkboxAnswers).filter(
            answer => checkboxAnswers[answer] === true
          );
        }
      });

    return dispatchSubmitQuestionnaire(
      workflowId,
      supplierId,
      newAnswers,
      finalSubmit
    ).then(() => {
      if (finalSubmit) {
        changeOnboardingStep('');
        setQuestionnaireFormAsSubmitted();
      }
    });
  };

  submitFile = file => {
    const {
      dispatchSubmitQuestionnaireFiles,
      workflowId,
      supplierId
    } = this.props;
    return dispatchSubmitQuestionnaireFiles(workflowId, supplierId, file);
  };

  deleteFile = questionId => {
    const {
      dispatchDeleteQuestionnaireAnswer,
      workflowId,
      supplierId
    } = this.props;
    return dispatchDeleteQuestionnaireAnswer(
      workflowId,
      supplierId,
      questionId
    );
  };

  downloadAttachment = question => {
    const { dispatchDownload, workflowId, isDownloading } = this.props;

    if (isDownloading || !question.file.name || !question._id) {
      return;
    }

    const url = `/onboarding/${workflowId}/questionnaire/${question._id}/attachment`;
    return dispatchDownload(url, question.file.name);
  };

  render() {
    const { workflow, supplier } = this.props;
    const initialValues = {};
    const files = {};

    const questionnaire =
      WorkflowsService.getQuestionnaireAnswers(workflow, supplier) || [];

    questionnaire
      .filter(qa => qa.answer && qa.answer.answer)
      .forEach(({ question, answer: { answer } }) => {
        switch (question.type) {
          case questionTypes.INPUT:
          case questionTypes.RADIO: {
            if (answer) {
              initialValues[question._id] = answer;
            }
            break;
          }
          case questionTypes.FILE: {
            files[question._id] = answer;
            break;
          }
          case questionTypes.CHECKBOX: {
            if (!initialValues[question._id]) {
              initialValues[question._id] = {};
            }
            answer.forEach(
              option => (initialValues[question._id][option] = true)
            );
            break;
          }
          default: {
          }
        }
      });

    return (
      <OnboardingSupplierQuestionnaire
        {...this.props}
        submitAnswers={this.submitAnswers}
        submitFile={this.submitFile}
        deleteFile={this.deleteFile}
        downloadAttachment={this.downloadAttachment}
        initialValues={initialValues}
        files={files}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    workflows: { uploadingFiles = [], isDownloading, isSubmitting } = {}
  } = state;

  return {
    uploadingFiles,
    isDownloading,
    isSubmitting
  };
};

const mapDispatchToProps = {
  dispatchSubmitOnboardingQuestionnaireFailure: submitOnboardingQuestionnaireFailure,
  dispatchSubmitQuestionnaire: submitQuestionnaire,
  dispatchSubmitQuestionnaireFiles: submitQuestionnaireFiles,
  dispatchDeleteQuestionnaireAnswer: deleteQuestionnaireAnswer,
  dispatchDownload: download
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingSupplierQuestionnaireContainer);
