import React, { Component } from 'react';
import { PrivateRoute } from 'react-common/components';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import AppWrapper from '../_components/AppWrapper/AppWrapper';
import PageNotFound from '../PageNotFound/PageNotFound';
import WorkflowsListContainer from './WorkflowsList/WorkflowsListContainer';
import WorkflowsCreateContainer from './WorkflowsCreate/WorkflowsCreateContainer';
import WorkflowsDetailsContainer from './WorkflowsDetails/WorkflowsDetailsContainer';
import WorkflowsSupplierDetailsContainer from './WorkflowsSupplierDetails/WorkflowsSupplierDetailsContainer';
import WorkflowsSuppliersAddContainer from './WorkflowsSuppliersAdd/WorkflowsSuppliersAddContainer';

import './Workflows.css';

class Workflows extends Component {
  render() {
    return (
      <AppWrapper className="Workflows">
        <Switch>
          <PrivateRoute
            exact
            path="/workflows(|/draft|/completed)"
            component={WorkflowsListContainer}
          />
          <PrivateRoute
            exact
            path="/workflows/:workflow_id/edit"
            component={WorkflowsCreateContainer}
          />
          <PrivateRoute
            exact
            path="/workflows/:workflow_id/:section(results|suppliers|preview)"
            component={WorkflowsDetailsContainer}
          />
          <PrivateRoute
            exact
            path="/workflows/:workflow_id/suppliers/add"
            component={WorkflowsSuppliersAddContainer}
          />
          <PrivateRoute
            exact
            path="/workflows/:workflow_id/suppliers/:supplier_id"
            component={WorkflowsSupplierDetailsContainer}
          />
          <Route
            render={() => <PageNotFound className="Workflows-page-not-found" />}
          />
        </Switch>
      </AppWrapper>
    );
  }
}

export default connect()(Workflows);
