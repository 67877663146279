import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CreatableSelect from 'react-select/lib/Creatable';

import './TagsDropdown.css';

const BadgeOption = ({ innerProps, data }) => {
  if (data.__isNew__) {
    return (
      <div {...innerProps} className="icon-ic_plus TagsDropdown-create-option">
        Create label <span ignore="true">{data.value}</span>
      </div>
    );
  }

  return (
    <div {...innerProps} className="TagsDropdown-option">
      <div className="Badge" style={{ backgroundColor: data.color }}>
        {data.label}
      </div>
    </div>
  );
};

class TagsDropdown extends Component {
  // Track when this component is unmounted so that this.setState(...) is not called after the component is unmounted,
  // which leads to a warning message.
  // Source: https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component/
  _isMounted = false;

  state = {
    isLoading: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCreate = newTag => {
    const { onCreate } = this.props;

    this.setState({ isLoading: true });
    return onCreate(newTag).finally(() => {
      if (this._isMounted) {
        this.setState({ isLoading: false });
      }
    });
  };

  styles = {
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: data.color
    })
  };

  render() {
    const { availableTags = [], selectedTags = [], onChange } = this.props;
    const { isLoading } = this.state;

    const options = availableTags.map(tag => ({
      label: tag.name,
      value: tag._id,
      color: tag.color
    }));

    const value = selectedTags.map(tag => ({
      label: tag.name,
      value: tag._id,
      color: tag.color
    }));

    return (
      <CreatableSelect
        isClearable={false}
        isMulti={true}
        isDisabled={isLoading}
        isLoading={isLoading}
        options={options}
        value={value}
        onChange={onChange}
        onCreateOption={this.handleCreate}
        classNamePrefix="TagsDropdown"
        styles={this.styles}
        blurInputOnSelect={false}
        autoFocus={true}
        isFocused={true}
        menuIsOpen={true}
        components={{
          Option: BadgeOption
        }}
      />
    );
  }
}

TagsDropdown.propTypes = {
  availableTags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  selectedTags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func
};

export default TagsDropdown;
