import enumsConstants from '../_constants/enums';

export function enums(
  state = {
    isFetching: false,
    items: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case enumsConstants.GET_ALL_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case enumsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.enums
      };
    case enumsConstants.GET_ALL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
}
