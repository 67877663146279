import React, { Component } from 'react';
import { workflowStatuses } from 'react-common/constants';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import PageNotFound from '../../PageNotFound/PageNotFound';

import {
  download,
  getAllWorkflows,
  reloadUser,
  updateHeaderBreadcrumbs
} from '../../_actions';
import WorkflowsService from '../../_services/Workflows';

import WorkflowsList from './WorkflowsList';

class WorkflowsListContainer extends Component {
  componentDidMount() {
    const {
      dispatchGetAllWorkflows,
      dispatchUpdateHeaderBreadcrumbs,
      location,
      categoryType
    } = this.props;
    dispatchGetAllWorkflows(categoryType);

    dispatchUpdateHeaderBreadcrumbs([
      {
        title: WorkflowsService.getWorkflowCategoryForPath(location.pathname)
          .headerTitle
      }
    ]);
  }

  componentDidUpdate(prevProps) {
    const {
      dispatchGetAllWorkflows,
      dispatchUpdateHeaderBreadcrumbs,
      location
    } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      const category = WorkflowsService.getWorkflowCategoryForPath(
        location.pathname
      );
      dispatchGetAllWorkflows(category.type);
      dispatchUpdateHeaderBreadcrumbs([
        {
          title: category.headerTitle
        }
      ]);
    }
  }

  goToWorkflowDetails = workflow => {
    const { dispatchPush } = this.props;
    if (workflow.status === workflowStatuses.DRAFT) {
      return dispatchPush('/workflows/' + workflow._id + '/edit');
    }
    return dispatchPush('/workflows/' + workflow._id + '/results');
  };

  downloadAll = () => {
    const { dispatchDownload, isDownloading } = this.props;

    if (isDownloading) {
      return;
    }

    const url = '/api/workflows/download-excel';
    return dispatchDownload(url, 'Supplier360 - Workflows');
  };

  render() {
    const {
      filteredWorkflows,
      suppliersById,
      showLoader,
      user,
      categoryType
    } = this.props;

    if (!user.isCompanyAdmin() && categoryType === workflowStatuses.DRAFT) {
      return <PageNotFound />;
    }

    return (
      <WorkflowsList
        workflows={filteredWorkflows}
        suppliersById={suppliersById}
        showLoader={showLoader}
        selectedCategory={categoryType}
        goToWorkflowDetails={this.goToWorkflowDetails}
        downloadAll={this.downloadAll}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    workflows,
    workflows: { suppliers: { getById: suppliersById } },
    router,
    user: { data: { user } }
  } = state;
  const category = WorkflowsService.getWorkflowCategoryForPath(
    router.location.pathname
  );
  const categoryType = category ? category.type : workflowStatuses.ACTIVE;
  let filteredWorkflows = [];
  Object.keys(workflows.getById).forEach(workflowId => {
    if (workflows.getById[workflowId].status === categoryType) {
      filteredWorkflows.push(workflows.getById[workflowId]);
    }
  });

  filteredWorkflows = filteredWorkflows.sort(
    (workflowA, workflowB) =>
      new Date(workflowB.modified_at || workflowB.created_at) -
      new Date(workflowA.modified_at || workflowA.created_at)
  );

  return {
    filteredWorkflows,
    suppliersById,
    user,
    categoryType,
    showLoader: workflows.status === 'all' || workflows.isFetching
  };
};

const mapDispatchToProps = {
  dispatchGetAllWorkflows: getAllWorkflows,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchReloadUser: reloadUser,
  dispatchPush: push,
  dispatchDownload: download
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsListContainer
);
