import React, { Component } from 'react';
import { workflowStatuses } from 'react-common/constants';
import { Tooltip } from 'react-common/components';
import { Tab, Tabs } from 'react-bootstrap';
import Clipboard from 'react-clipboard.js';

import SuppliersSearchBar from '../../_components/SuppliersSearchBar/SuppliersSearchBar';
import WorkflowsService from '../../_services/Workflows';

import WorkflowsSuppliersAddTable from './WorkflowsSuppliersAddTable';
import WorkflowsSuppliersAddSingle from './WorkflowsSuppliersAddSingle';
import WorkflowsSuppliersAddBulk from './WorkflowsSuppliersAddBulk';

import './WorkflowsSuppliersAdd.css';

class WorkflowsSuppliersAdd extends Component {
  state = {
    activeTab: 'single',
    searchQuery: '',
    publicLinkCopied: false
  };

  changeTab = tab => {
    this.setState({ activeTab: tab });
    this.props.dispatchAddSupplierFailure('');
    setTimeout(() => this.pageEnd && this.pageEnd.scrollIntoView());
  };

  onChangeSearchQuery = searchQuery => this.setState({ searchQuery });

  componentDidMount() {
    setTimeout(() => this.pageEnd && this.pageEnd.scrollIntoView());
  }

  onSuccess = () => {
    this.setState({ publicLinkCopied: true });
    setTimeout(() => this.setState({ publicLinkCopied: false }), 3500);
  };

  getPublicOnboardingLink = () => {
    const { workflow } = this.props;
    return `${document.location.origin}/onboarding/${workflow.getId()}`;
  };

  render() {
    const {
      workflow,
      user,
      addSupplierSubmit,
      addBulkSubmit,
      error
    } = this.props;
    const { searchQuery } = this.state;
    const errorContent =
      error instanceof Array
        ? error.map((err, index) => (
            <span
              className="Alert-errors"
              key={index}
              dangerouslySetInnerHTML={{
                __html: `<var>${index + 1}.</var> ${err}`
              }}
            />
          ))
        : error;

    let suppliers = this.props.suppliers;
    if (searchQuery) {
      suppliers = suppliers.filter(
        WorkflowsService.searchSuppliers(searchQuery)
      );
    }
    return (
      <div className="WorkflowsSuppliersAdd">
        <SuppliersSearchBar onChangeSearchQuery={this.onChangeSearchQuery} />

        <div className="SupplierAdd row">
          <Tabs
            id="suppliers-add-tabs"
            onSelect={this.changeTab}
            activeKey={this.state.activeTab}
            className="SupplierAdd-tabs"
          >
            {workflow.isOnboarding() &&
              user.isPublicLinkEnabled() && (
                <div className="pull-right Public-link-wrapper">
                  <Tooltip
                    text="Copy to clipboard"
                    position="bottom"
                    id="share-link-tooltip"
                  >
                    <Clipboard
                      data-clipboard-text={this.getPublicOnboardingLink()}
                      className="btn-forgot-pass"
                      onSuccess={this.onSuccess}
                    >
                      Share public link
                    </Clipboard>
                  </Tooltip>
                  <Tooltip
                    text="Share public link allows inviting suppliers without adding their info"
                    position="bottom"
                    id="share-link-info-tooltip"
                  >
                    <i className="icon-ic_question Icon-question" />
                  </Tooltip>

                  <div
                    className={
                      'Link-copied' +
                      (this.state.publicLinkCopied ? ' Fade' : '')
                    }
                  >
                    <i className="icon-ic_toast-success C-deep-mint" />
                    <span>Link copied to clipboard!</span>
                    <div className="Onboarding-public-link">
                      {this.getPublicOnboardingLink()}
                    </div>
                  </div>
                </div>
              )}

            <WorkflowsSuppliersAddTable
              suppliers={suppliers}
              workflow={workflow}
            />

            {workflow.status === workflowStatuses.ACTIVE && [
              <Tab
                eventKey="single"
                title="Add Individually"
                className="Active"
                key="single"
              >
                <WorkflowsSuppliersAddSingle
                  onSubmit={addSupplierSubmit}
                  workflow={workflow}
                  initialValues={
                    WorkflowsService.isCkrInvestigation(workflow) ||
                    WorkflowsService.isCcrInvestigation(workflow)
                      ? { invite: { country: 'brazil' } }
                      : {}
                  }
                />
              </Tab>,
              <Tab eventKey="bulk" title="Add Multiple Suppliers" key="bulk">
                <WorkflowsSuppliersAddBulk
                  onSubmit={addBulkSubmit}
                  workflow={workflow}
                />
              </Tab>
            ]}
          </Tabs>
        </div>

        {errorContent && (
          <div className="alert alert-danger alert-dismissible" role="alert">
            {errorContent}
          </div>
        )}
        <div ref={el => (this.pageEnd = el)} />
      </div>
    );
  }
}

export default WorkflowsSuppliersAdd;
