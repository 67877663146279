import React from 'react';
import { DunsAbbr } from 'react-common/components';
import { Util } from 'react-common/services';

import {
  paydexGroupClasses,
  INFORMATION_UNAVAILABLE
} from '../../../_constants/workflows';
import WorkflowsService from '../../../_services/Workflows';

const WorkflowsSupplierDetailsInvite = ({
  workflow,
  supplier,
  showPaydexModal
}) => (
  <div className="WorkflowsSupplierDetails-rectangle">
    <div className="WorkflowsSupplierDetails-title-table">Company info</div>

    <div className="WorkflowsSupplierDetails-content-table">
      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">
          Company Name:
        </span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getCompanyName()}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">Tax ID:</span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getTaxId() || INFORMATION_UNAVAILABLE}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">SIC:</span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getSic() || INFORMATION_UNAVAILABLE}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">Industry:</span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getIndustry() || INFORMATION_UNAVAILABLE}
        </span>
      </div>

      {workflow.isInvestigating() && (
        <div className="WorkflowsSupplierDetails-summary-item">
          <span className="WorkflowsSupplierDetails-label-inline">
            <DunsAbbr /> number:
          </span>
          <span
            className="WorkflowsSupplierDetails-info-text-inline"
            notranslate="true"
          >
            {supplier.getDunsNumber() || INFORMATION_UNAVAILABLE}
          </span>
        </div>
      )}

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">Country:</span>
        <span className="WorkflowsSupplierDetails-info-text-inline">
          {Util.capitalize(supplier.getCountry())}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">
          Contact Person:
        </span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getFullName()}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">
          Contact Emails:
        </span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getAllEmails().join(', ')}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">Phone:</span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getPhones().join(', ') || INFORMATION_UNAVAILABLE}
        </span>
      </div>

      <div className="WorkflowsSupplierDetails-summary-item">
        <span className="WorkflowsSupplierDetails-label-inline">Address:</span>
        <span
          className="WorkflowsSupplierDetails-info-text-inline"
          notranslate="true"
        >
          {supplier.getAddress() || INFORMATION_UNAVAILABLE}
        </span>
      </div>

      {!workflow.isQuestionnaireReport() && (
        <div className="WorkflowsSupplierDetails-summary-item">
          <span className="WorkflowsSupplierDetails-label-inline">
            Website:
          </span>
          <span
            className="WorkflowsSupplierDetails-info-text-inline"
            notranslate="true"
          >
            {(supplier.getWebsite() && (
              <a
                href={Util.wrapWithHttp(supplier.getWebsite())}
                target="_blank"
                rel="noopener noreferrer"
              >
                {supplier.getWebsite()}
              </a>
            )) ||
              INFORMATION_UNAVAILABLE}
          </span>
        </div>
      )}

      {!workflow.isCkrReport() &&
        !workflow.isQuestionnaireReport() &&
        !workflow.isRafOrRacReport() && (
          <div className="WorkflowsSupplierDetails-summary-item">
            {supplier.getScore() &&
              !supplier.getReport() && (
                <>
                  <span className="WorkflowsSupplierDetails-label-inline">
                    Paydex<i
                      className="icon-ic_question"
                      onClick={showPaydexModal}
                    />
                  </span>
                  {supplier.getPaydex() ? (
                    <div
                      className={
                        'WorkflowsSupplierDetails-rating rating-inline Square-badge Bg-' +
                        paydexGroupClasses[
                          WorkflowsService.getPaydexGroup(supplier.getPaydex())
                        ]
                      }
                    >
                      {WorkflowsService.isValidPaydex(supplier.getPaydex())
                        ? supplier.getPaydex()
                        : 'UN'}
                    </div>
                  ) : (
                    <span
                      className="WorkflowsSupplierDetails-info-text-inline"
                      notranslate="true"
                    >
                      {INFORMATION_UNAVAILABLE}
                    </span>
                  )}
                </>
              )}
          </div>
        )}
    </div>
  </div>
);

export default WorkflowsSupplierDetailsInvite;
