import get from 'lodash/get';
import first from 'lodash/first';
import merge from 'lodash/merge';
import {
  accountRoles,
  companyUserRoles,
  workflowProducts,
  workflowReportTypes
} from 'react-common/constants';

export class User {
  constructor(user = {}) {
    merge(this, user);
  }

  getId() {
    return this._id;
  }

  getName() {
    return get(this, 'info.name') || '';
  }

  getFirstName() {
    return first(this.getName().split(' '));
  }

  getImage() {
    return get(this, 'info.image');
  }

  getEmail() {
    return get(this, 'info.email');
  }

  getTitle() {
    return get(this, 'info.title');
  }

  getAccountRole() {
    return get(this, 'security.role');
  }

  isAdmin() {
    return this.getAccountRole() === accountRoles.ADMIN;
  }

  getLanguage() {
    return get(this, 'preferences.language') || 'en';
  }

  getSupplierColumns() {
    return get(this, 'preferences.supplier360.supplier_columns');
  }

  getCompanies() {
    return this.companies || [];
  }

  getCompany() {
    return first(this.getCompanies());
  }

  getCompanyModel() {
    return get(this.getCompany(), 'company');
  }

  getCompanyId() {
    return get(this.getCompany(), 'company_id');
  }

  getCompanyName() {
    return (
      get(this.getCompanyModel(), 'legal_name') ||
      get(this.getCompanyModel(), 'udm.legal_name') ||
      get(this.getCompanyModel(), 'info.name')
    );
  }

  getCompanyLogo() {
    return get(this.getCompanyModel(), 'info.logo');
  }

  getCompanyLegalName() {
    return get(this.getCompany(), 'company.udm.legal_name');
  }

  getCompanyRole() {
    return get(this.getCompany(), 'role');
  }

  isCompanyRole(role) {
    return this.getCompanyRole() === role;
  }

  isCompanyAdmin() {
    return this.isCompanyRole(companyUserRoles.COMPANY_ADMIN);
  }

  isCompanyUser() {
    return this.isCompanyRole(companyUserRoles.COMPANY_USER);
  }

  getFeatures() {
    return get(this.getCompanyModel(), 'features');
  }

  getSupplierFeature() {
    return get(this.getFeatures(), 'supplier360', {});
  }

  getCcrProduct() {
    return this.getProduct(workflowReportTypes.CCR) || {};
  }

  getCfrProduct() {
    return this.getProduct(workflowReportTypes.CFR) || {};
  }

  isCcrProductEnabled() {
    return !!this.getCcrProduct().enabled;
  }

  getWorkflowProduct() {
    return get(
      this.getSupplierFeature(),
      'workflow_product',
      workflowProducts.REPORT
    );
  }

  isScoreWorkflowProduct() {
    return this.getWorkflowProduct() === workflowProducts.SCORE;
  }

  getProducts() {
    return get(this.getSupplierFeature(), 'products', []);
  }

  getProduct(productType) {
    return this.getProducts().find(product => product.type === productType);
  }

  getEnabledProducts() {
    return this.getProducts().filter(
      product => product.enabled && get(product, 'extras.visible', true)
    );
  }

  getEnabledReportTypes() {
    return this.getEnabledProducts().map(product => product.type);
  }

  getSupplierTags() {
    return get(this.getSupplierFeature(), 'tags', []);
  }

  isPublicLinkEnabled() {
    return get(this.getSupplierFeature(), 'public_onboarding_link', false);
  }

  getSupplierTagsMap() {
    return this.getSupplierTags().reduce((obj, tag) => {
      obj[tag._id] = tag;
      return obj;
    }, {});
  }

  getComplianceEnabledReport() {
    return get(this.getProduct(workflowReportTypes.CFR), 'enabled', false);
  }

  isComplianceEnabled() {
    return this.getComplianceEnabledReport;
  }
}
