import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Checkbox } from 'react-common/components';

import WorkflowsService from '../../_services/Workflows';
import { supplierColumnsText } from '../../_constants/workflows';
import { setSuppliersColumns } from '../../_actions';
import { connect } from 'react-redux';

import './SuppliersColumnSelector.css';

class SuppliersColumnSelector extends Component {
  onColumnSelect = column => {
    const { dispatchSetSuppliersColumns, workflows, user } = this.props;

    let columns = user.getSupplierColumns();

    if (!columns || !columns.length) {
      columns = WorkflowsService.getDefaultColumns(workflows[0]);
    }

    if (includes(columns, column)) {
      columns = columns.filter(col => col !== column);
    } else {
      columns = columns.slice(0, -1).concat([column, 'actions']);
    }

    return dispatchSetSuppliersColumns(columns);
  };

  render() {
    const { workflows, user, supplierStatus } = this.props;

    let availableColumns = [];
    let columns = [];
    workflows.forEach(workflow => {
      availableColumns = availableColumns.concat(
        WorkflowsService.getAvailableColumnsForWorkflow(
          workflow,
          user,
          supplierStatus
        )
      );
      columns = columns.concat(
        WorkflowsService.getSelectedColumnsForWorkflow(
          workflow,
          user,
          supplierStatus
        )
      );
    });

    availableColumns = uniq(availableColumns);
    columns = uniq(columns);

    return (
      <Dropdown
        id="workflows-columns-selector"
        className="SuppliersColumnSelector Btn-default-small Btn-default-neutral"
        pullRight
      >
        <Dropdown.Toggle>
          <i className="icon-ic_columns" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <p className="Dropdown-title-label">Displayed columns</p>
          {availableColumns.map(column => (
            <Checkbox
              key={column}
              name={column}
              label={supplierColumnsText[column]}
              checked={includes(columns, column)}
              disabled={includes(['company_name', 'status', 'actions'], column)}
              handleChange={this.onColumnSelect}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapDispatchToProps = {
  dispatchSetSuppliersColumns: setSuppliersColumns
};

export default connect(null, mapDispatchToProps)(SuppliersColumnSelector);
