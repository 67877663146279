import React, { Component } from 'react';
import { connect } from 'react-redux';
import { workflowStatuses } from 'react-common/constants';
import { ComponentWithLoader } from 'react-common/components';

import {
  getAllWorkflows,
  setSuppliersFilters,
  updateHeaderBreadcrumbs
} from '../_actions';
import AppWrapper from '../_components/AppWrapper/AppWrapper';
import Suppliers from './Suppliers';

class SuppliersContainer extends Component {
  state = {
    supplierStatus: 'all',
    searchQuery: '',
    fetchedSuppliers: false
  };

  componentDidMount() {
    const {
      dispatchUpdateHeaderBreadcrumbs,
      dispatchGetAllWorkflows
    } = this.props;

    dispatchUpdateHeaderBreadcrumbs([
      {
        title: 'Suppliers'
      }
    ]);

    dispatchGetAllWorkflows(workflowStatuses.ACTIVE).then(() =>
      this.setState({ fetchedSuppliers: true })
    );
  }

  onChangeSupplierStatus = supplierStatus =>
    this.setState({ supplierStatus, searchQuery: '' });

  onChangeSearchQuery = searchQuery => this.setState({ searchQuery });

  render() {
    const { user, suppliers, workflows, activeFilters } = this.props;
    const { supplierStatus, searchQuery, fetchedSuppliers } = this.state;

    return (
      <AppWrapper className="Workflows">
        <ComponentWithLoader showLoader={!fetchedSuppliers}>
          <Suppliers
            onChangeSupplierStatus={this.onChangeSupplierStatus}
            onChangeSearchQuery={this.onChangeSearchQuery}
            suppliers={suppliers}
            searchQuery={searchQuery}
            supplierStatus={supplierStatus}
            user={user}
            workflows={workflows}
            activeFilters={activeFilters}
          />
        </ComponentWithLoader>
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => {
  const {
    workflows,
    user: { data: { user } },
    ui: { suppliersFilters }
  } = state;
  const suppliers = [];
  const selectedWorkflows = [];

  Object.keys(workflows.getById).forEach(workflowId => {
    const workflow = workflows.getById[workflowId];
    if (workflow && workflow.isActive()) {
      selectedWorkflows.push(workflow);
      workflow.suppliers.forEach(supplierId =>
        suppliers.push(workflows.suppliers.getById[supplierId])
      );
    }
  });
  return {
    user,
    suppliers,
    workflows: selectedWorkflows,
    activeFilters: suppliersFilters
  };
};

const mapDispatchToProps = {
  dispatchGetAllWorkflows: getAllWorkflows,
  dispatchSetSuppliersFilters: setSuppliersFilters,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersContainer);
