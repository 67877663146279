import React from 'react';

import './WorkflowsListEmpty.css';

const categoriesMessage = {
  active: 'You don’t have any active workflows.',
  draft:
    'You don’t have any saved draft. Saving a draft allows you to edit workflow you aren’t ready to launch yet.',
  closed: 'You don’t have any completed workflows.'
};

const WorkflowsListEmpty = ({ category }) => (
  <div className="WorkflowsListEmpty row">{categoriesMessage[category]}</div>
);

export default WorkflowsListEmpty;
