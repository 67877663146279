import React from 'react';
import { onboardingCialAccountSteps } from 'react-common/constants';
import { reduxForm } from 'redux-form';
import logo from '../../../_images/cialdnblogo.svg';

import OnboardingSupplierCialAccountLoginForm from './OnboardingSupplierCialAccountLoginForm';
import OnboardingSupplierCialAccountRegisterForm from './OnboardingSupplierCialAccountRegisterForm';
import './OnboardingSupplierCialAccount.css';

const OnboardingSupplierCialAccountForm = props => (
  <div>
    <div className="row ">
      <div className="col-xs-12 col-sm-12 col-md-6">
        <h1 className="OnboardingSupplierCial-header">
          {props.step === onboardingCialAccountSteps.LOGIN
            ? 'Login to CIAL Account'
            : 'Create CIAL Account'}
        </h1>
        <p className="OnboardingSupplierCial-subtitle">
          {props.step === onboardingCialAccountSteps.LOGIN
            ? 'Login to your account'
            : 'MyCIAL is the single sign-on portal for Cial Dun & Bradstreet. You will need to create an account to fill out the form.'}
        </p>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6">
        <div className="OnboardingSupplierCial-content">
          <img
            src={logo}
            alt="Supplier360"
            className="OnboardingSupplierCial-logo"
          />
          {props.step === onboardingCialAccountSteps.LOGIN && (
            <OnboardingSupplierCialAccountLoginForm {...props} />
          )}
          {props.step === onboardingCialAccountSteps.REGISTER && (
            <OnboardingSupplierCialAccountRegisterForm {...props} />
          )}
        </div>
      </div>
    </div>
  </div>
);

const OnboardingSupplierCialAccount = reduxForm({
  form: 'cial-account'
})(OnboardingSupplierCialAccountForm);

export default OnboardingSupplierCialAccount;
