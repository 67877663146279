import posthog from 'posthog-js';

export default posthog;

export const initialize = ({ key, url }) => {
  if (key && url) {
    posthog.init(key, {
      api_host: url
    });
  }
};
