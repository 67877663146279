import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import React from 'react';
import {
  Checkbox,
  FileDescriptor,
  FileUpload,
  RadioButton
} from 'react-common/components';
import { questionTypes } from 'react-common/constants';
import { Field, reduxForm } from 'redux-form';

import './OnboardingSupplierQuestionnaire.css';

const OnboardingSupplierQuestionnaireForm = ({
  workflow,
  formError,
  submitAnswers,
  submitFile,
  deleteFile,
  isDownloading,
  isSubmitting,
  downloadAttachment,
  uploadingFiles,
  handleSubmit,
  submitting,
  files,
  showPreview = false
}) => {
  let questionNumber = 0;

  return (
    <div className="OnboardingQuestionnaire">
      <form onSubmit={handleSubmit(submitAnswers(true))}>
        <div className="OnboardingQuestionnaire-header">
          Please answer the following questions
        </div>

        {workflow.questionnaire.map(question => {
          if (question.type !== questionTypes.SECTION) {
            questionNumber += 1;
          }
          return (
            <div key={question._id}>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div
                    className={`form-group ${
                      question.type === questionTypes.CHECKBOX ||
                      question.type === questionTypes.RADIO
                        ? 'OnboardingQuestionaire-input-control'
                        : ''
                    }`}
                  >
                    {question.type === questionTypes.SECTION && (
                      <label
                        className="OnboardingQuestionnaire-section"
                        htmlFor={question._id}
                      >
                        <var>{question.title || ''}</var>
                      </label>
                    )}

                    {question.type !== questionTypes.SECTION && (
                      <label
                        className="OnboardingQuestionnaire-question"
                        htmlFor={question._id}
                      >
                        <var>{`${questionNumber}. ${(question.title || '') +
                          (question.type !== questionTypes.ATTACHMENT &&
                          question.required
                            ? '*'
                            : '')}`}</var>
                      </label>
                    )}

                    {question.type === questionTypes.INPUT && (
                      <Field
                        type="text"
                        className="form-control OnboardingQuestionnaire-free-text"
                        name={question._id}
                        component="textarea"
                        placeholder="Write your answer"
                      />
                    )}

                    {question.type === questionTypes.FILE && (
                      <FileUpload
                        fieldName={question._id}
                        isUploading={includes(uploadingFiles, question._id)}
                        onChange={file => {
                          if (!showPreview) {
                            submitFile({ [question._id]: file });
                          }
                        }}
                        file={files[question._id]}
                        onDelete={() => deleteFile(question._id)}
                        buttonClassName="Btn-outline-small Btn-outline-primary"
                      />
                    )}

                    {question.type === questionTypes.CHECKBOX &&
                      question.options.map((option, index) => (
                        <Field
                          key={option._id}
                          type="checkbox"
                          name={`${question._id}.${option._id}`}
                          value={option._id}
                          label={option.value || `Option ${index + 1}`}
                          noTranslate={true}
                          component={Checkbox}
                        />
                      ))}
                    {question.type === questionTypes.RADIO &&
                      question.options.map((option, index) => (
                        <Field
                          key={option._id}
                          type="radio"
                          name={question._id}
                          value={option._id}
                          label={option.value || `Option ${index + 1}`}
                          noTranslate={true}
                          component={RadioButton}
                        />
                      ))}
                    {question.type === questionTypes.ATTACHMENT &&
                      question.file.name && (
                        <FileDescriptor
                          file={question.file}
                          disabled={isDownloading}
                          onClick={() => downloadAttachment(question)}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div>
          <button
            className="Btn-default Btn-default-primary OnboardingQuestionnaire-button-submit"
            type="submit"
            disabled={
              submitting ||
              uploadingFiles.length > 0 ||
              showPreview ||
              isSubmitting
            }
          >
            Submit questionnaire
          </button>
        </div>
        {formError && (
          <div className="row">
            <div className="col-md-6 OnboardingQuestionnaire-form-error">
              <div
                className="alert alert-danger alert-dismissible"
                role="alert"
              >
                {formError}
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const submitQuestionnaireDebounce = debounce((formValues, _, props) => {
  if (!props.showPreview) {
    props.submitAnswers(false)(formValues);
  }
}, 500);

const OnboardingSupplierQuestionnaire = reduxForm({
  form: 'onboarding',
  onChange: submitQuestionnaireDebounce
})(OnboardingSupplierQuestionnaireForm);

export default OnboardingSupplierQuestionnaire;
