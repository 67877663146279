import PropTypes from 'prop-types';
import React from 'react';

import OnboardingNoCoverImage from '../../../_images/bg-cover-onboarding.jpg';

import './OnboardingCover.css';

const getCoverStyle = cover => {
  if (cover) {
    return {
      background: `url('${cover}') no-repeat center 0px`,
      backgroundSize: 'contain'
    };
  }
  return {
    background: `#d7e0e4 url(${OnboardingNoCoverImage}) no-repeat center`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  };
};

const OnboardingCover = ({ workflow }) => (
  <div className="OnboardingCover">
    <div
      style={getCoverStyle(workflow.info.cover)}
      className="OnboardingCover-cover hidden-xs"
    />
  </div>
);

OnboardingCover.propTypes = {
  workflow: PropTypes.object
};

export default OnboardingCover;
