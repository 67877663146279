import PropTypes from 'prop-types';
import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import './StatisticsIndustry.css';

const EMPTY_STATE = [['', 0], ['', 0], ['', 0], ['', 0]];

const getPercentage = percent => Math.round(percent);

const getTextForPercentage = (data, percent) =>
  data !== EMPTY_STATE && percent === 0 ? '<1' : `${getPercentage(percent)}%`;

const StatisticsIndustry = ({ data, className, onClick }) => {
  // set empty state
  if (!data || !data.length) {
    data = EMPTY_STATE;
  }

  const isClickable = industry =>
    data !== EMPTY_STATE && industry !== 'other' && onClick;

  let gridType;
  switch (data.length) {
    case 1:
      gridType = 'single';
      break;
    case 2:
      gridType = 'half';
      break;
    default:
      gridType = 'quarter';
  }

  return (
    <div className={className || 'col-sm-12 col-lg-6'}>
      <div className="StatisticsIndustry Statistics-content Statistics-content-row">
        <h3 className="Statistics-title">By Industry</h3>
        <div className="row">
          {data.map(([industry, percent], index) => (
            <div
              key={index}
              className={
                (gridType === 'single' ? 'col-xs-12' : 'col-xs-6') +
                ' StatisticsIndustry-padding-' +
                (index % 2 ? 'left' : 'right') +
                (isClickable(industry) ? ' clickable' : '')
              }
              onClick={() => isClickable(industry) && onClick(industry)}
            >
              <div
                className={`text-center StatisticsIndustry-${gridType}-${index +
                  1}`}
              >
                <CircularProgressbar
                  className={
                    'CircularProgressbar CircularProgressbar-Industry-' +
                    (index + 1)
                  }
                  percentage={getPercentage(percent)}
                  text={getTextForPercentage(data, percent)}
                  strokeWidth={2}
                  background={true}
                  initialAnimation={true}
                  styles={{
                    background: {
                      fill: 'white'
                    }
                  }}
                />

                <div
                  className="StatisticsIndustry-Industry-Name"
                  title={industry === 'other' ? 'Other' : industry}
                  alt={industry === 'other' ? 'Other' : industry}
                >
                  {industry === 'other' ? 'Other' : <var>{industry}</var>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

StatisticsIndustry.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string
};

export default StatisticsIndustry;
