import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { RadioButton, Tooltip } from 'react-common/components';
import {
  workflowTypes,
  workflowReportTypes,
  workflowProducts,
  workflowStatuses,
  workflowPayment
} from 'react-common/constants';
import { Field } from 'redux-form';

import WorkflowsCreateCover from './WorkflowsCreateCover';

const reportTypeLabels = {
  [workflowReportTypes.BIR]: 'BIR Report',
  [workflowReportTypes.SHORT_FORM]: 'CKR Report',
  [workflowReportTypes.SHORT_FORM_PLUS]: 'CKR Plus Report',
  [workflowReportTypes.RAC]: 'RAC Report',
  [workflowReportTypes.RAF]: 'SQR Brazil Report',
  [workflowReportTypes.SQR]: 'SQR Report',
  [workflowReportTypes.CAR]: 'CAR Report',
  [workflowReportTypes.CCR]: 'CCR Report',
  [workflowReportTypes.HOM_9_ASPECTS]: 'Hom 9 Aspects',
  [workflowReportTypes.HOM_8_ASPECTS]: 'Hom 8 Aspects',
  [workflowReportTypes.HOM_7_ASPECTS]: 'Hom 7 Aspects',
  [workflowReportTypes.HOM_FALLLABELLLA]: 'Hom Fallabella',
  [workflowReportTypes.HOM_FALLLABELLLA_MID]:
    'Hom Fallabella mid-sized companies',
  [workflowReportTypes.QUESTIONNAIRE]: 'Questionnaire'
};

class WorkflowsCreateStepsInfo extends Component {
  render() {
    const { formValues, change, reportTypes, user } = this.props;
    const { workflow_payment: payment } = user.getSupplierFeature();

    const reportTypesWithoutCFR = reportTypes.filter(
      item =>
        item !== workflowReportTypes.CFR_LEV_1 &&
        item !== workflowReportTypes.CFR_LEV_2 &&
        item !== workflowReportTypes.CFR_LEV_3 &&
        item !== workflowReportTypes.CFR
    );

    return (
      <Panel className="WorkflowsCreate-panel" eventKey="1">
        <Panel.Heading>
          <Panel.Title toggle>
            <span>Workflow info</span>

            <Tooltip
              text="Only you will see this info"
              position="bottom"
              id="workflow-create-info-tooltip"
            >
              <i className="icon-ic_question Icon-question" />
            </Tooltip>

            <span className="pull-right">
              <i className="icon-ic_expand-arrow" />
            </span>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="info.title"
                    component="input"
                    placeholder="Choose a title for your workflow"
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="description">
                    Set the workflow's description
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="info.description"
                    component="textarea"
                    placeholder="Describe the purpose of your workflow"
                  />
                </div>
              </div>
            </div>

            {formValues.type === workflowTypes.INVESTIGATING && (
              <WorkflowsCreateCover
                cover={formValues.info.cover}
                onUploadComplete={info => change('info.cover', info.cdnUrl)}
              />
            )}

            {!!reportTypesWithoutCFR.length &&
              formValues.product === workflowProducts.REPORT &&
              reportTypesWithoutCFR.length > 1 &&
              formValues.status === workflowStatuses.DRAFT && (
                <>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="reportType">
                          Choose type of report for this workflow
                        </label>
                        <div>
                          {reportTypesWithoutCFR.map(type => (
                            <Field
                              key={type}
                              type="radio"
                              name="report_type"
                              value={type}
                              label={reportTypeLabels[type]}
                              component={RadioButton}
                              className="WorkflowsCreate-report-type"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {formValues.status === workflowStatuses.DRAFT &&
              payment === workflowPayment.HYBRID && (
                <>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Choose who is paying</label>
                        <div>
                          <Field
                            type="radio"
                            value="client"
                            label="You"
                            component={RadioButton}
                            name="payment"
                            className="WorkflowsCreate-report-type"
                          />

                          <Field
                            type="radio"
                            value="supplier"
                            label="Supplier"
                            component={RadioButton}
                            name="payment"
                            className="WorkflowsCreate-report-type"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default WorkflowsCreateStepsInfo;
