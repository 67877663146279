import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-common/components';
import { supplierStatuses, workflowStatuses } from 'react-common/constants';

import SuppliersTable from '../../../_components/SuppliersTable/SuppliersTable';
import SuppliersFilterModal from '../../../_components/SuppliersFilterModal/SuppliersFilterModal';
import SuppliersColumnSelector from '../../../_components/SuppliersColumnSelector/SuppliersColumnSelector';
import SuppliersSearchBar from '../../../_components/SuppliersSearchBar/SuppliersSearchBar';

import './WorkflowsDetailsSuppliers.css';

const WorkflowsDetailsSuppliers = ({
  workflow,
  suppliers,
  supplierStatus,
  searchQuery,
  downloadAllReports,
  downloadExcel,
  downloadQuestionnaire,
  onChangeSupplierStatus,
  onChangeSearchQuery,
  showAddSuppliersButton,
  user,
  activeFilters
}) => (
  <div className="WorkflowsDetailsSuppliers">
    {/*  SEARCH BAR  */}
    <SuppliersSearchBar onChangeSearchQuery={onChangeSearchQuery} />
    {/*  END SEARCH BAR  */}

    <div className="WorkflowsDetailsSuppliers-btn-group pull-right">
      {/*  FILTER  */}
      <SuppliersFilterModal
        suppliers={suppliers}
        user={user}
        useRacRiskIndicator={workflow.isRafOrRacReport()}
      />
      {/*  END FILTER  */}

      {/*  COLUMNS  */}
      {(supplierStatus === 'all' ||
        supplierStatus === supplierStatuses.COMPLETED ||
        supplierStatus === supplierStatuses.ARCHIVED) && (
        <SuppliersColumnSelector
          user={user}
          workflows={[workflow]}
          supplierStatus={supplierStatus}
        />
      )}
      {/*  END COLUMNS  */}

      {/*  ADD SUPPLIERS  */}
      {workflow.status === workflowStatuses.ACTIVE && showAddSuppliersButton && (
        <Tooltip
          text="Add Suppliers"
          position="bottom"
          id="add-suppliers-tooltip"
        >
          <Link
            className="Btn-default-small Btn-default-primary Add-suppliers-btn"
            to={`/workflows/${workflow.getId()}/suppliers/add`}
          >
            <i className="icon-ic_plus" />
          </Link>
        </Tooltip>
      )}
      {/*  END ADD SUPPLIERS */}

      {/*  DOWNLOADS  */}
      <Dropdown
        className="Btn-default-small Btn-default-success Download-export-btn"
        id="download-dropdown"
        pullRight
      >
        <Dropdown.Toggle>
          <i className="icon-ic_downloads" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem onClick={downloadExcel}>Export in Excel</MenuItem>
          <MenuItem onClick={downloadAllReports}>Download all results</MenuItem>
          {workflow.hasQuestionnaire() && (
            <MenuItem onClick={downloadQuestionnaire}>
              Download questionnaire
            </MenuItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {/*  END DOWNLOADS  */}
    </div>

    <div className="row">
      <SuppliersTable
        user={user}
        suppliers={suppliers}
        workflows={[workflow]}
        supplierStatus={supplierStatus}
        activeFilters={activeFilters}
        searchQuery={searchQuery}
        multipleWorkflows={false}
        onChangeSupplierStatus={onChangeSupplierStatus}
      />
    </div>
  </div>
);

export default WorkflowsDetailsSuppliers;
