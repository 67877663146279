import React from 'react';

const InternationalPeruInfo = ({ taxId }) => (
  <>
    <div>
      <label>Bank:</label>
      <p notranslate="true">BBVA Continental</p>
    </div>
    <div>
      <label>Address:</label>
      <p notranslate="true">
        AV. REPUBLICA DE PANAMA 3055, LIMA-LIMA-SAN ISIDRO
      </p>
    </div>
    <div>
      <label>Account CTE Dolars:</label>
      <p notranslate="true">0011-0384-0100013664-56</p>
    </div>
    <div notranslate="true">
      <label>SWIFT:</label>
      <p>BCONPEPL</p>
    </div>
    <div>
      <label>Recipient:</label>
      <p notranslate="true">DUN & BRADSTREET S.A.C</p>
    </div>
    <div>
      <label>Recipient address:</label>
      <p notranslate="true">
        AV. LA ENCALADA 1420 OFIC. 802, LIMA-LIMA-SANTIAGO DE SURCO
      </p>
    </div>
    <div>
      <label>Recipient TAX ID:</label>
      <p notranslate="true">20101281371</p>
    </div>
    <div>
      <label>Reference Number:</label>
      <p notranslate="true">{taxId}</p>
    </div>
  </>
);

export default InternationalPeruInfo;
