import React, { Component } from 'react';
import { onboardingCialAccountSteps } from 'react-common/constants';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import OnboardingSupplierCialAccount from './OnboardingSupplierCialAccount';
import {
  connectAccountWithSupplier,
  connectAccountWithSupplierFailure,
  getEnums
} from '../../../_actions';
import { ccsCountries, cialCountries } from '../../../_constants/onboarding';

class OnboardingSupplierCialAccountContainer extends Component {
  state = {
    step: onboardingCialAccountSteps.REGISTER
  };

  componentDidMount() {
    const { supplier, countries, dispatchGetEnums } = this.props;
    // show only account login form if supplier already created account
    if (supplier && supplier.getAccountId()) {
      this.setState({
        step: onboardingCialAccountSteps.LOGIN
      });
    }

    if (!countries || !countries.length) {
      dispatchGetEnums();
    }
  }

  showLoginForm = () =>
    this.setState({ step: onboardingCialAccountSteps.LOGIN });

  showRegisterForm = () =>
    this.setState({ step: onboardingCialAccountSteps.REGISTER });

  delay = async function(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  };

  submit = formData => {
    const { step } = this.state;
    const useLoginForm = step === onboardingCialAccountSteps.LOGIN;
    const {
      dispatchConnectAccountWithSupplierFailure,
      dispatchConnectAccountWithSupplier,
      dispatchPush,
      changeOnboardingStep,
      workflow,
      supplier,
      countries = []
    } = this.props;

    const availableCountries = countries.length
      ? countries
      : cialCountries.concat(ccsCountries);

    if (formData.email) {
      formData.email = formData.email.trim();
    }

    const user = { ...formData };
    const {
      full_name: name,
      email,
      password,
      country: userCountry,
      phone,
      company_name: companyName
    } = user;

    if (!useLoginForm && (!name || !email || !password || !phone)) {
      return dispatchConnectAccountWithSupplierFailure({
        error: 'All fields are required'
      });
    }

    if (useLoginForm && (!email || !password)) {
      return dispatchConnectAccountWithSupplierFailure({
        error: 'All fields are required'
      });
    }

    if (!supplier && !companyName) {
      return dispatchConnectAccountWithSupplierFailure({
        error: 'Company name is required'
      });
    }

    const country = availableCountries.find(
      country => country.title.toLowerCase() === userCountry
    );

    if (country) {
      user.country = country.iso_alpha_3 || country.code;
    } else {
      delete user.country;
    }

    return dispatchConnectAccountWithSupplier(
      workflow,
      supplier,
      user,
      useLoginForm,
      availableCountries
    ).then(updatedSupplier => {
      changeOnboardingStep('');

      if (updatedSupplier) {
        this.delay(3000).then(() => {
          dispatchPush(
            `/onboarding/${workflow.getId()}/suppliers/${updatedSupplier.getId()}`
          );
        });
      }
    });
  };

  render() {
    const { supplier, countries, formError } = this.props;
    const { step } = this.state;

    return (
      <OnboardingSupplierCialAccount
        step={step}
        supplierHasAccount={supplier && !!supplier.getAccountId()}
        supplierId={supplier && supplier.getId()}
        initialValues={supplier ? supplier.invite : {}}
        countries={countries}
        formError={formError}
        showLoginForm={this.showLoginForm}
        showRegisterForm={this.showRegisterForm}
        onSubmit={this.submit}
      />
    );
  }
}

const mapStateToProps = ({ enums: { items: { countries = [] } } }) => ({
  countries
});

const mapDispatchToProps = {
  dispatchConnectAccountWithSupplierFailure: connectAccountWithSupplierFailure,
  dispatchConnectAccountWithSupplier: connectAccountWithSupplier,
  dispatchGetEnums: getEnums,
  dispatchPush: push
};

export default connect(mapStateToProps, mapDispatchToProps)(
  OnboardingSupplierCialAccountContainer
);
