import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthorizedHeader from '../Header/AuthorizedHeader';
import NavigationMenuContainer from '../NavigationMenu/NavigationMenuContainer';

import './AppWrapper.css';

class AppWrapper extends Component {
  state = {
    sidebarOpened: true
  };

  componentDidMount() {
    if (localStorage.getItem('supplier360_sidebar_opened') === 'false') {
      this.setState({ sidebarOpened: false });
    }
  }

  toggleSidebar = () => {
    const newState = !this.state.sidebarOpened;
    this.setState({ sidebarOpened: newState });
    localStorage.setItem('supplier360_sidebar_opened', newState.toString());
  };

  render() {
    const { sidebarOpened } = this.state;
    const { className } = this.props;
    return (
      <div className={className}>
        <NavigationMenuContainer
          className={`col-sm-3 col-lg-2 Navigation-animated ${
            sidebarOpened ? 'Sidebar-visible' : 'Sidebar-hidden'
          }`}
        />
        <div
          className={`col-xs-12 col-sm-12 Container-animated ${
            sidebarOpened
              ? 'col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2'
              : 'col-xs-12 col-sm-12'
          }`}
        >
          <div className="row">
            <AuthorizedHeader
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={sidebarOpened}
            />
            <div className="AppWrapper-content">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

AppWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default AppWrapper;
