import React from 'react';
import { Field } from 'redux-form';

import { appMyCialDomains } from '../../../_constants/onboarding';

const OnboardingSupplierCialAccountFormLoginForm = ({
  supplierId,
  supplierHasAccount,
  handleSubmit,
  submitting,
  formError,
  showRegisterForm
}) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="email">Email</label>
      <Field
        type="email"
        className="form-control Login-input"
        name="email"
        component="input"
        placeholder="example@cialdnb.com"
        autoComplete="off"
      />
    </div>
    <div className="form-group">
      <label htmlFor="password">Password</label>
      <Field
        type="password"
        className="form-control Login-input"
        name="password"
        component="input"
        placeholder="Enter Password"
        autoComplete="off"
      />
    </div>
    {!supplierId && (
      <div className="form-group">
        <label htmlFor="company_name">Company Name</label>
        <Field
          type="text"
          className="form-control Login-input"
          name="company_name"
          component="input"
          placeholder="Enter Company Name"
          autoComplete="off"
        />
      </div>
    )}
    <div className="form-group">
      <a
        href={`${appMyCialDomains[process.env.REACT_APP_ENV]}reset-password`}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-forgot-pass pull-right"
      >
        Forgot password?
      </a>
    </div>
    <div className="form-group">
      <button
        type="submit"
        disabled={submitting}
        className="Btn-default Btn-default-primary form-control OnboardingSupplierCial-btn-create-account"
      >
        Login
      </button>
    </div>

    {formError && (
      <div className="alert alert-danger alert-dismissible" role="alert">
        {formError}
      </div>
    )}
    {!supplierHasAccount && (
      <div className="OnboardingSupplierCial-footer" onClick={showRegisterForm}>
        <span>Don't have an account? </span>&nbsp;<span className="OnboardingSupplierCial-show-form-button Btn-link">
          Click here to create account
        </span>
      </div>
    )}
  </form>
);

export default OnboardingSupplierCialAccountFormLoginForm;
