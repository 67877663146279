import includes from 'lodash/includes';
import values from 'lodash/values';
import React, { Component } from 'react';
import { ComponentWithLoader } from 'react-common/components';
import { workflowStatuses } from 'react-common/constants';
import { connect } from 'react-redux';

import AppWrapper from '../_components/AppWrapper/AppWrapper';
import DashboardStatistics from './DashboardStatistics/DashboardStatistics';
import DashboardEmpty from './DashboardEmpty/DashboardEmpty';

import {
  createWorkflow,
  getAllWorkflows,
  getStatistics,
  updateHeaderBreadcrumbs
} from '../_actions';

import './Dashboard.css';

class Dashboard extends Component {
  state = {
    selectedWorkflows: [],
    dropdownText: 'All workflows'
  };

  componentDidMount() {
    const {
      dispatchGetAllWorkflows,
      dispatchGetStatistics,
      dispatchUpdateHeaderBreadcrumbs
    } = this.props;
    dispatchGetAllWorkflows();
    dispatchGetStatistics(null, true);

    dispatchUpdateHeaderBreadcrumbs([{ title: 'Dashboard' }]);
  }

  onCreateWorkflowClick = () => {
    const { dispatchCreateWorkflow, user } = this.props;
    return dispatchCreateWorkflow({}, user);
  };

  handleWorkflowSelect = selectedWorkflowId => {
    const { selectedWorkflows } = this.state;
    const { dispatchGetStatistics, workflows = [] } = this.props;

    let newSelectedWorkflows = [...selectedWorkflows];

    // clicked checkbox 'All workflows'
    if (selectedWorkflowId === 'all') {
      if (selectedWorkflows.length) {
        newSelectedWorkflows = [];
      } else {
        newSelectedWorkflows = workflows.map(workflow => workflow._id);
      }
    } else {
      // clicked a specific workflow
      if (includes(selectedWorkflows, selectedWorkflowId)) {
        newSelectedWorkflows = selectedWorkflows.filter(
          workflowId => workflowId !== selectedWorkflowId
        );
      } else {
        newSelectedWorkflows = selectedWorkflows.concat(selectedWorkflowId);
      }
    }

    // set the dropdown to comma separated workflow names
    let dropdownText = 'All workflows';

    if (newSelectedWorkflows.length > 0) {
      dropdownText = workflows
        .filter(w => includes(newSelectedWorkflows, w._id))
        .map(w => w.info.title)
        .join(', ');
    }

    this.setState({ selectedWorkflows: newSelectedWorkflows, dropdownText });
    return dispatchGetStatistics(newSelectedWorkflows);
  };

  render() {
    const { showLoader, workflows, statistics, user } = this.props;
    const { selectedWorkflows, dropdownText } = this.state;
    const dashboardEmpty = values(statistics.workflows).every(w => w === 0);

    return (
      <AppWrapper className="Dashboard">
        <ComponentWithLoader showLoader={showLoader}>
          {dashboardEmpty && (
            <DashboardEmpty
              showCreateButton={user.isCompanyAdmin()}
              onCreateWorkflowClick={this.onCreateWorkflowClick}
            />
          )}
          {!dashboardEmpty && (
            <DashboardStatistics
              workflows={workflows}
              selectedWorkflows={selectedWorkflows}
              user={user}
              dropdownText={dropdownText}
              statistics={statistics}
              handleWorkflowSelect={this.handleWorkflowSelect}
            />
          )}
        </ComponentWithLoader>
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { workflows, user: { data: { user } } } = state;
  const { statistics, isFetching } = workflows;

  const activeWorkflows = [];
  Object.keys(workflows.getById).forEach(workflowId => {
    if (workflows.getById[workflowId].status === workflowStatuses.ACTIVE) {
      activeWorkflows.push(workflows.getById[workflowId]);
    }
  });

  return {
    workflows: activeWorkflows,
    user,
    statistics,
    showLoader: isFetching || statistics.isFetching
  };
};

const mapDispatchToProps = {
  dispatchGetAllWorkflows: getAllWorkflows,
  dispatchCreateWorkflow: createWorkflow,
  dispatchGetStatistics: getStatistics,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
