import React, { Component } from 'react';
import get from 'lodash/get';
import { Panel, Table, Tabs, Tab } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import { MultiSelect, Tooltip } from 'react-common/components';
import { Util } from 'react-common/services';
import { workflowTypes, workflowReportTypes } from 'react-common/constants';

import CountryDropdown from '../../../_components/CountryDropdown/CountryDropdown';
import WorkflowsService from '../../../_services/Workflows';

const tabs = {
  SINGLE: 'single',
  BULK: 'bulk'
};

const getPlaceholder = (placeholder, workflow) =>
  placeholder +
  (WorkflowsService.isCkrInvestigation(workflow) ||
  WorkflowsService.isCcrInvestigation(workflow) ||
  WorkflowsService.isCfrReport(workflow)
    ? ''
    : ' (*)');

class WorkflowsCreateStepsSuppliers extends Component {
  state = {
    activeTab: tabs.SINGLE,
    bulkErrors: []
  };

  changeTab = tab => {
    this.setState({ activeTab: tab, bulkErrors: [] });
  };

  addSuppliersBulk = fields => () => {
    const { value } = this.bulkTextarea;
    const { countries, formValues } = this.props;

    const {
      suppliers,
      errors: bulkErrors
    } = WorkflowsService.parseSuppliersBulk(value, countries, formValues);

    if (bulkErrors.length) {
      this.setState({ bulkErrors });
      return;
    }

    suppliers.forEach(supplier => fields.push(supplier));
    this.setState({ bulkErrors: [] });
  };

  renderAddSingle = fields => (
    <tr key={fields.length}>
      <td colSpan="8">
        <button
          className="WorkflowsCreate-btn-add btn icon-ic_plus"
          type="button"
          onClick={() => {
            const supplier = { invite: { emails: [] } };
            if (
              WorkflowsService.isCkrInvestigation(this.props.formValues) ||
              WorkflowsService.isCcrInvestigation(this.props.formValues)
            ) {
              supplier.invite.country = 'brazil';
            }
            fields.push(supplier);
          }}
        >
          Add new supplier
        </button>
      </td>
    </tr>
  );

  renderAddBulk = fields => {
    const { formValues } = this.props;

    let placeholder =
      'Company Name; Full Name; Contact Emails; Country; Tax ID; Full address; SIC; Phone';

    if (WorkflowsService.isCfrReport(formValues)) {
      placeholder += '; D-U-N-S Number; Shareholders';
    } else if (formValues.type === workflowTypes.INVESTIGATING) {
      placeholder += '; D-U-N-S Number';
    }

    return (
      <React.Fragment key={fields.length}>
        <tr className="no-border">
          <td colSpan="8">
            Add multiple suppliers in batch form. Copy and paste values
            separated by semi-colon (;) into the box below to bulk upload (one
            company per line).
            <br />
            Suppliers should match this format: <b>{placeholder}</b>
          </td>
        </tr>
        <tr className="no-border">
          <td colSpan="8">
            <textarea
              name="suppliersData"
              className="form-control WorkflowsCreate-suppliers-bulk-textarea"
              placeholder={placeholder}
              ref={el => (this.bulkTextarea = el)}
            />
          </td>
        </tr>
        <tr className="no-border">
          <td colSpan="4">
            <button
              className="WorkflowsCreate-btn-add btn icon-ic_plus"
              type="button"
              onClick={this.addSuppliersBulk(fields)}
            >
              Bulk add
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  renderSuppliers = data => {
    const fields = data.fields;
    const { formValues } = this.props;

    const suppliers = fields.map((supplier, index) => (
      <React.Fragment key={index}>
        <tr className="no-border">
          <td className="Field-company-name">
            <Field
              type="text"
              className="form-control"
              name={`${supplier}.invite.company_name`}
              component="input"
              placeholder={getPlaceholder('Company Name', formValues)}
            />
          </td>
          <td className="Field-full-name">
            <Field
              type="text"
              className="form-control"
              name={`${supplier}.invite.full_name`}
              component="input"
              placeholder={getPlaceholder('Full Name', formValues)}
            />
          </td>
          <td className="Field-email">
            <Field
              name={`${supplier}.invite.emails`}
              component={props => (
                <MultiSelect {...props} validate={Util.isValidEmail} />
              )}
              placeholder={getPlaceholder('Contact Emails', formValues)}
            />
          </td>
          <td>
            {fields.length !== 1 && (
              <i
                className="WorkflowsCreate-btn-delete icon-ic_trash"
                type="button"
                onClick={() => fields.remove(index)}
              />
            )}
          </td>
        </tr>
        <tr className="no-border">
          <td className="Field-country">
            <Field
              name={`${supplier}.invite.country`}
              placeholder={getPlaceholder('Country', formValues)}
              isDisabled={
                WorkflowsService.isCkrInvestigation(formValues) ||
                WorkflowsService.isCcrInvestigation(formValues)
              }
              component={CountryDropdown}
            />
          </td>

          <td className="Field-taxID">
            <Field
              type="text"
              className="form-control"
              name={`${supplier}.invite.tax_id`}
              component="input"
              placeholder={
                WorkflowsService.isTaxIdRequired(
                  get(formValues, `suppliers.${index}.invite.country`, '')
                ) && formValues.report_type !== workflowReportTypes.CCR
                  ? 'ID fiscal (CNPJ) (*)'
                  : 'Tax ID'
              }
            />
          </td>

          <td className="Field-address">
            <Field
              type="text"
              className="form-control"
              name={`${supplier}.invite.address`}
              component="input"
              placeholder="Full address"
            />
          </td>
        </tr>
        <tr className="no-border">
          <td className="Field-sic">
            <Field
              type="text"
              className="form-control"
              name={`${supplier}.invite.sic`}
              component="input"
              placeholder="SIC code"
            />
          </td>
          <td className="Field-phone">
            <Field
              type="text"
              className="form-control"
              name={`${supplier}.invite.phone`}
              component="input"
              placeholder="Phone number"
            />
          </td>

          {(formValues.type === workflowTypes.INVESTIGATING ||
            WorkflowsService.isCfrReport(formValues)) && (
            <td className="Field-duns-number">
              <Field
                type="text"
                className="form-control"
                name={`${supplier}.invite.duns_number`}
                component="input"
                placeholder={`D-U-N-S Number${
                  WorkflowsService.isCfrReport(formValues) ? ' (*)' : ''
                }`}
              />
            </td>
          )}
        </tr>
        {WorkflowsService.isCfrReport(formValues) && (
          <tr>
            <td className="Field-shareholders">
              <Field
                name={`${supplier}.invite.shareholders`}
                component={props => <MultiSelect {...props} />}
                placeholder="Shareholders"
              />
            </td>
          </tr>
        )}
      </React.Fragment>
    ));

    if (this.state.activeTab === tabs.SINGLE) {
      suppliers.push(this.renderAddSingle(fields));
    } else {
      suppliers.push(this.renderAddBulk(fields));
    }

    return suppliers;
  };

  render() {
    const { bulkErrors } = this.state;

    return (
      <Panel className="WorkflowsCreate-panel" eventKey="4">
        <Panel.Heading>
          <Panel.Title toggle>
            <span>Add and manage suppliers</span>
            <Tooltip
              text="Add at least 1 supplier in order to launch workflow. You can add suppliers later."
              position="bottom"
              id="workflow-create-suppliers-tooltip"
            >
              <i className="icon-ic_question Icon-question" />
            </Tooltip>
            <i className="icon-ic_expand-arrow" />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <Tabs
              id="workflow-create-suppliers"
              onSelect={this.changeTab}
              activeKey={this.state.activeTab}
            >
              <Table responsive className="WorkflowsCreate-table">
                <tbody>
                  {/* HACK teritory - force React to render suppliers array on tab change */}
                  {this.state.activeTab === tabs.SINGLE && (
                    <FieldArray
                      name="suppliers"
                      component={this.renderSuppliers}
                    />
                  )}
                  {this.state.activeTab === tabs.BULK && (
                    <FieldArray
                      name="suppliers"
                      component={this.renderSuppliers}
                    />
                  )}
                  {!!bulkErrors.length && (
                    <tr>
                      <td colSpan="6">
                        <div
                          className="alert alert-danger alert-dismissible"
                          role="alert"
                        >
                          {bulkErrors.map((err, index) => (
                            <span
                              className="Alert-errors"
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: `<var>${index + 1}.</var> ${err}`
                              }}
                            />
                          ))}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <Tab eventKey={tabs.SINGLE} title="Add Individually" />
              <Tab
                eventKey={tabs.BULK}
                title={
                  <React.Fragment>
                    <span className="hidden-xs">Add Multiple Suppliers</span>
                    <span className="visible-xs">Add Multiple</span>
                  </React.Fragment>
                }
              />
            </Tabs>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default WorkflowsCreateStepsSuppliers;
