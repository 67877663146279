import React, { Component } from 'react';
import { Auth } from 'react-common/services';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import { changePassword, changePasswordFailure } from '../_actions';
import ChangePassword from './ChangePassword';

class ChangePasswordContainer extends Component {
  getToken = () => {
    return queryString.parse(this.props.location.search).token;
  };

  submit = formData => {
    const {
      dispatchChangePasswordFailure,
      dispatchChangePassword
    } = this.props;
    const token = this.getToken();
    const { password, confirmPassword } = formData;

    if (!password || !confirmPassword) {
      return dispatchChangePasswordFailure({
        error: 'Both fields are required'
      });
    }

    if (password !== confirmPassword) {
      return dispatchChangePasswordFailure({
        error: 'The passwords you entered do not match'
      });
    }

    if (password.length < 7) {
      return dispatchChangePasswordFailure({
        error:
          'The password you entered is too short. Please enter 7+ characters'
      });
    }

    return dispatchChangePassword(token, password);
  };

  render() {
    if (!this.getToken()) {
      return <Redirect to="/login" />;
    }

    // redirect to home if already authorized
    if (Auth.isUserAuthenticated()) {
      return <Redirect to="/home" />;
    }

    return <ChangePassword {...this.props} onSubmit={this.submit} />;
  }
}

const mapStateToProps = ({ user: { changePassword } }) => ({ changePassword });

const mapDispatchToProps = {
  dispatchChangePasswordFailure: changePasswordFailure,
  dispatchChangePassword: changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ChangePasswordContainer
);
