import React from 'react';

import './OnboardingWorkflowClosed.css';
import notFoundPic from '../../_images/404.svg';

const OnboardingWorkflowClosed = props => (
  <div className="OnboardingWorkflowClosed">
    <img src={notFoundPic} alt="Page not found" />
    <p>
      This evaluation process has expired or was closed by your client{' '}
      {props.workflow.getOwnerCompanyName()}.<br />
      Please contact your client for further information.<br />
      <br />
      Thank you for working with CIAL Dun & Bradstreet,<br />
      Latin America's business information leader.
    </p>
  </div>
);

export default OnboardingWorkflowClosed;
