import userConstants from '../_constants/user';
import { User } from '../_models/user';

const initialUserState = {
  isFetching: false,
  data: {
    user: undefined,
    error: ''
  },
  login: {
    success: false,
    error: ''
  },
  forgotPassword: {
    success: false,
    error: ''
  },
  changePassword: {
    success: false,
    error: ''
  },
  accountChange: {
    success: false,
    error: ''
  },
  resetPassword: {
    success: false,
    error: ''
  }
};

export function user(state = initialUserState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
    case userConstants.FORGOT_PASSWORD_REQUEST:
    case userConstants.CHANGE_PASSWORD_REQUEST:
    case userConstants.CHANGE_ACCOUNT_SETTINGS_REQUEST:
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case userConstants.RELOAD_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case userConstants.RELOAD_SUCCESS:
    case userConstants.SET_SUPPLIER_TAGS_SUCCESS:
    case userConstants.DELETE_SUPPLIER_TAG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          user: new User(action.user),
          error: ''
        }
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        forgotPassword: {
          success: true,
          error: ''
        }
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        login: {
          success: true,
          error: ''
        },
        data: {
          user: new User(action.user),
          error: ''
        }
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        changePassword: {
          success: true,
          error: ''
        }
      };
    case userConstants.CHANGE_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          user: new User(action.user),
          error: ''
        },
        accountChange: {
          success: true,
          error: ''
        }
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetPassword: {
          success: true,
          error: ''
        }
      };
    case userConstants.RELOAD_FAILURE:
    case userConstants.SET_SUPPLIER_TAGS_FAILURE:
    case userConstants.DELETE_SUPPLIER_TAG_FAILURE:
      return {
        ...state,
        user: {
          user: undefined,
          error: action.error.error
        }
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        login: {
          success: false,
          error: action.error.error
        }
      };

    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        forgotPassword: {
          success: false,
          error: action.error.error
        }
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        changePassword: {
          success: false,
          error: action.error.error
        }
      };
    case userConstants.CHANGE_ACCOUNT_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        accountChange: {
          success: false,
          error: action.error.error
        }
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        resetPassword: {
          success: false,
          error: action.error.error
        }
      };
    case userConstants.SUPPLIERS_COLUMNS_REQUEST:
      return {
        ...state,
        data: {
          user: new User({
            ...state.data.user,
            preferences: { supplier360: { supplier_columns: action.columns } }
          }),
          error: ''
        }
      };
    case userConstants.LOGOUT:
      return {
        ...initialUserState
      };

    case userConstants.SET_SUPPLIER_TAGS_REQUEST:
    case userConstants.DELETE_SUPPLIER_TAG_REQUEST:
    case userConstants.SUPPLIERS_COLUMNS_FAILURE:
    case userConstants.SUPPLIERS_COLUMNS_SUCCESS:
    default:
      return state;
  }
}
