import debounce from 'lodash/debounce';
import React from 'react';
import {
  ButtonToolbar,
  DropdownButton,
  MenuItem,
  PanelGroup
} from 'react-bootstrap';
import { SingleModal, Tooltip } from 'react-common/components';
import { workflowStatuses, workflowTypes } from 'react-common/constants';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { updateWorkflow } from '../../_actions';

import StepInfo from './WorkflowsCreateSteps/WorkflowsCreateStepsInfo';
import StepOnboarding from './WorkflowsCreateSteps/WorkflowsCreateStepsOnboarding';
import StepSuppliers from './WorkflowsCreateSteps/WorkflowsCreateStepsSuppliers';
import StepQuestionnaire from './WorkflowsCreateSteps/WorkflowsCreateStepsQuestionnaire';
import WorkflowsCreateUpdateModal from './WorkflowsCreateUpdateModal/WorkflowsCreateUpdateModal';

import './WorkflowCreate.css';

const WorkflowsCreateForm = props => {
  const {
    handleSubmit,
    deleteWorkflow,
    formValues,
    workflowId,
    errorText,
    isActivateModalOpen,
    showActivateModal,
    closeActivateModal,
    isDeleteModalOpen,
    showDeleteModal,
    closeDeleteModal,
    isUpdateModalOpen,
    showUpdateModal,
    closeUpdateModal,
    notifyAllSuppliers,
    backToResults
  } = props;

  const launchDisabled = !!errorText;
  const isDraft = formValues && formValues.status === workflowStatuses.DRAFT;

  return (
    <form onSubmit={handleSubmit} className="WorkflowsCreate">
      <div className="WorkflowsCreate-btn-group pull-right">
        <ButtonToolbar>
          <DropdownButton
            pullRight
            className="icon-ic_dots Dots-icon"
            id="workflow-create-actions"
            title=""
            noCaret={true}
          >
            {isDraft && (
              <>
                <MenuItem
                  eventKey="launch"
                  disabled={launchDisabled}
                  onClick={() => {
                    if (!launchDisabled) {
                      showActivateModal();
                    }
                  }}
                >
                  Launch
                </MenuItem>
                <MenuItem
                  eventKey="launch"
                  disabled={launchDisabled}
                  onClick={() => {
                    if (!launchDisabled) {
                      showActivateModal();
                    }
                  }}
                >
                  Delete
                </MenuItem>
              </>
            )}
            {!isDraft && (
              <>
                <LinkContainer to={`/workflows/${workflowId}/results`}>
                  <MenuItem eventKey="cancel">Cancel</MenuItem>
                </LinkContainer>
                <MenuItem
                  eventKey="update"
                  disabled={launchDisabled}
                  onClick={() => {
                    if (!launchDisabled) {
                      showUpdateModal();
                    }
                  }}
                >
                  Update changes
                </MenuItem>
              </>
            )}
          </DropdownButton>
        </ButtonToolbar>

        {isDraft && (
          <>
            <Tooltip
              text="Delete Draft"
              position="bottom"
              id="delete-workflow-btn"
            >
              <button
                type="button"
                className="Btn-default-small Btn-default-neutral WorkflowCreate-btn-delete"
                onClick={showDeleteModal}
              >
                <i className="icon-ic_trash" />
              </button>
            </Tooltip>

            <SingleModal
              title="Delete Draft"
              body="Are you sure you want to delete this draft?"
              primaryButtonClassName="Btn-default-danger"
              show={isDeleteModalOpen}
              handleClose={closeDeleteModal}
              handleSubmit={() => {
                closeDeleteModal();
                deleteWorkflow(workflowId);
              }}
            />
            <Tooltip
              text={errorText}
              position="bottom"
              id="launch-workflow-tooltip"
            >
              <button
                className={
                  'Btn-default-small Btn-default-success WorkflowsCreate-btn-launch' +
                  (launchDisabled ? ' disabled' : '')
                }
                type="button"
                onClick={launchDisabled ? undefined : showActivateModal}
              >
                Launch
              </button>
            </Tooltip>
            <SingleModal
              title="Launch Workflow"
              body={
                'Once you launch your workflow, you will be unable to edit the details' +
                (formValues.type === workflowTypes.ONBOARDING
                  ? ', welcome emails will be sent to all your suppliers'
                  : '') +
                '. Are you sure you are ready to proceed?'
              }
              buttonText="Launch"
              primaryButtonClassName="Btn-default-success"
              show={isActivateModalOpen}
              handleClose={closeActivateModal}
              handleSubmit={() => {
                closeActivateModal();
                handleSubmit();
              }}
            />
          </>
        )}

        {!isDraft && (
          <>
            <Link to={`/workflows/${workflowId}/results`}>
              <button
                type="button"
                className="Btn-default-small Btn-default-neutral WorkflowsCreate-btn-cancel"
              >
                Cancel
              </button>
            </Link>

            <Tooltip
              text={errorText}
              position="bottom"
              id="launch-workflow-tooltip"
            >
              <button
                className={
                  'Btn-default-small Btn-default-success WorkflowsCreate-btn-update' +
                  (launchDisabled ? ' disabled' : '')
                }
                type="button"
                onClick={() => {
                  if (launchDisabled) {
                    return;
                  }
                  if (formValues.type === workflowTypes.ONBOARDING) {
                    return showUpdateModal();
                  }
                  handleSubmit().then(backToResults);
                }}
              >
                Update changes
              </button>
            </Tooltip>

            <WorkflowsCreateUpdateModal
              show={isUpdateModalOpen}
              handleClose={closeUpdateModal}
              onlyUpdateClick={() => {
                closeUpdateModal();
                handleSubmit().then(backToResults);
              }}
              updateAndNotifyClick={() => {
                closeUpdateModal();
                handleSubmit()
                  .then(notifyAllSuppliers)
                  .then(backToResults);
              }}
            />
          </>
        )}
      </div>

      <PanelGroup accordion id="accordion-create-workflow" defaultActiveKey="1">
        <StepInfo {...props} />
        {formValues.type === workflowTypes.ONBOARDING && (
          <StepOnboarding {...props} />
        )}
        {isDraft && <StepSuppliers {...props} />}
        {formValues.type === workflowTypes.ONBOARDING && (
          <StepQuestionnaire {...props} />
        )}
      </PanelGroup>
    </form>
  );
};

const updateWorkflowDebounce = debounce(
  (workflow, dispatch) => dispatch(updateWorkflow(workflow)),
  1000
);

const WorkflowsCreate = reduxForm({
  onChange: (values, dispatch) => {
    if (values && values.status === workflowStatuses.DRAFT) {
      updateWorkflowDebounce(values, dispatch);
    }
  }
})(WorkflowsCreateForm);

export default WorkflowsCreate;
