import get from 'lodash/get';
import React, { Component } from 'react';
import { MomentDate, Tooltip } from 'react-common/components';
import { languages } from 'react-common/constants';
import { Localize } from 'react-common/services';
import {
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  MenuItem
} from 'react-bootstrap';

import ReactToPrint from 'react-to-print';

import { reportTypeToName } from '../../_constants/workflows';

import PaydexModal from '../../_components/PaydexModal/PaydexModal';
import CcrModal from '../../_components/CcrModal/CcrModal';
import RatingsModal from '../../_components/RatingsModal/RatingsModal';

import SupplierDetailsActivity from './components/WorkflowsSupplierDetailsActivity';
import SupplierDetailsCompliance from './components/WorkflowsSupplierDetailsCompliance';
import SupplierDetailsInvite from './components/WorkflowsSupplierDetailsInvite';
import SupplierDetailsLabels from './components/WorkflowsSupplierDetailsLabels';
import SupplierDetailsQuestionnaire from './components/WorkflowsSupplierDetailsQuestionnaire';
import SupplierDetailsRating from './components/WorkflowsSupplierDetailsRating';
import SupplierDetailsReport from './components/WorkflowsSupplierDetailsReport';
import SupplierDetailsReportFiles from './components/WorkflowsSupplierDetailsReportFiles';
import SupplierDetailsSupplierFiles from './components/WorkflowsSupplierDetailsSupplierFiles';

import './WorkflowsSupplierDetails.css';

class WorkflowsSupplierDetails extends Component {
  state = {
    showRatingsModal: false,
    showPaydexModal: false,
    showCcrModal: false
  };

  hideRatingsModal = () => this.setState({ showRatingsModal: false });
  showRatingsModal = () => this.setState({ showRatingsModal: true });

  hidePaydexModal = () => this.setState({ showPaydexModal: false });
  showPaydexModal = () => this.setState({ showPaydexModal: true });

  hideCcrModal = () => this.setState({ showCcrModal: false });
  showCcrModal = () => this.setState({ showCcrModal: true });

  render() {
    const {
      workflow,
      user,
      supplier,
      questionnaire = [],
      downloadAllFiles,
      downloadBirFile,
      downloadFileInput,
      downloadAdditionalFile,
      downloadCfrReport,
      isDownloading,
      uploadingFiles,
      changeSupplierStatus,
      uploadSupplierFile,
      deleteSupplierFile,
      downloadSupplierFile,
      requestBir,
      requestCcr,
      screenSupplier,
      downloadReportFiles
    } = this.props;
    const cfrReports = [];

    supplier.getScreenings().forEach((screening, index) => {
      const reports = get(screening, 'reports', []);

      reports.forEach(report => {
        if (!report || !report.type) {
          return;
        }
        cfrReports.push(
          <MenuItem
            key={report._id}
            onClick={() => downloadCfrReport(screening._id, report.type)}
            disabled={isDownloading}
          >
            <div>CFR Report - {report.type}</div>
            <div className="DropdownDate">
              <MomentDate
                format="DD.MM.YYYY"
                locale={languages[Localize.getLanguage()].uploadcare}
                date={get(screening, 'request.requested_at')}
              />
            </div>
          </MenuItem>
        );
      });
    });

    return (
      <div className="WorkflowsSupplierDetails">
        <div className="WorkflowsSupplierDetails-btn-group">
          <ButtonToolbar>
            <DropdownButton
              pullRight
              className="icon-ic_dots Dots-icon visible-xs"
              id="supplier-details-actions"
              title=""
              noCaret={true}
            >
              <MenuItem eventKey="archive" onClick={changeSupplierStatus}>
                {supplier.isCompleted() ? 'Archive' : 'Unarchive'}
              </MenuItem>
            </DropdownButton>
          </ButtonToolbar>

          <Tooltip
            id={`archive-supplier-${supplier.getId()}-tooltip`}
            text={supplier.isCompleted() ? 'Archive' : 'Unarchive'}
            position="bottom"
          >
            <button
              type="button"
              className="Btn-default-small Btn-default-neutral Workflow-btn-archive hidden-xs"
              onClick={changeSupplierStatus}
            >
              <i className="icon-ic_archive2" />
            </button>
          </Tooltip>

          {/*  PRINT  */}
          <Tooltip text="Print" position="bottom" id="supplier-details-print">
            <ReactToPrint
              trigger={() => (
                <button
                  type="button"
                  className="Btn-default-small Btn-default-neutral Workflow-btn-print hidden-xs"
                >
                  <i className="icon-ic_print" />
                </button>
              )}
              content={() => this.detailsComponent}
            />
          </Tooltip>
          {/*  END PRINT  */}

          {/*  REQUEST BIR BUTTON */}
          {!workflow.isQuestionnaireReport() && (
            <span>
              {/*  DOWNLOADS  */}
              <Dropdown
                className="Btn-default-small Btn-default-success Download-export-btn Download-dropdown hidden-xs"
                id="download-dropdown"
                pullRight
              >
                <Dropdown.Toggle>
                  <Tooltip
                    id={`download-supplier-${supplier.getId()}-tooltip`}
                    text="Download"
                    position="bottom"
                  >
                    <i className="icon-ic_downloads" />
                  </Tooltip>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <MenuItem disabled={isDownloading} onClick={downloadAllFiles}>
                    All files
                  </MenuItem>

                  {!workflow.isCfrReport() && (
                    <MenuItem
                      disabled={isDownloading}
                      onClick={downloadReportFiles}
                    >
                      {reportTypeToName[workflow.getReportType()]} Report
                    </MenuItem>
                  )}

                  {cfrReports}
                </Dropdown.Menu>
              </Dropdown>
              {/*  END DOWNLOADS  */}
            </span>
          )}

          {/*  DOWNLOAD QUESTIONNAIRE BUTTON */}
          {workflow.isQuestionnaireReport() && (
            <button
              className="Btn-default-small Btn-default-success"
              onClick={downloadAllFiles}
              disabled={isDownloading}
            >
              {isDownloading ? 'Downloading...' : 'Download'}
            </button>
          )}
          {/*  END DOWNLOAD QUESTIONNAIRE BUTTON */}
        </div>

        <div
          className="WorkflowsSupplierDetails-content row"
          ref={el => (this.detailsComponent = el)}
        >
          <div className="col-md-12 col-lg-4 WorkflowsSupplierDetails-summary">
            <p className="WorkflowsSupplierDetails-title">Summary</p>
            <div className="WorkflowsSupplierDetails-border-rectangle">
              <SupplierDetailsInvite
                workflow={workflow}
                supplier={supplier}
                showPaydexModal={this.showPaydexModal}
              />

              {supplier.getScore() &&
                !supplier.getReport() && (
                  <SupplierDetailsRating
                    workflow={workflow}
                    supplier={supplier}
                    showRatingsModal={this.showRatingsModal}
                  />
                )}

              {supplier.hasBirFiles() && (
                <SupplierDetailsReportFiles
                  files={supplier.getReportFiles()}
                  downloadBirFile={downloadBirFile}
                  downloadAdditionalFile={downloadAdditionalFile}
                  isDownloading={isDownloading}
                />
              )}

              <SupplierDetailsSupplierFiles
                supplier={supplier}
                isDownloading={isDownloading}
                uploadingFiles={uploadingFiles}
                uploadSupplierFile={uploadSupplierFile}
                deleteSupplierFile={deleteSupplierFile}
                downloadSupplierFile={downloadSupplierFile}
              />

              <SupplierDetailsLabels
                supplier={supplier}
                tagsById={user.getSupplierTagsMap()}
              />
              <SupplierDetailsActivity
                workflow={workflow}
                supplier={supplier}
              />
            </div>
          </div>

          <div
            className={
              'col-md-12 col-lg-8 WorkflowsSupplierDetails-results' +
              (supplier.getBirRequestId() ||
              supplier.hasBirFiles() ||
              questionnaire.length
                ? ''
                : ' no-results')
            }
          >
            <p className="WorkflowsSupplierDetails-title">Results</p>
            {!workflow.isQuestionnaireReport() &&
              !workflow.isCfrReport() && (
                <SupplierDetailsReport
                  workflow={workflow}
                  supplier={supplier}
                  user={user}
                  requestCcr={requestCcr}
                  requestBir={requestBir}
                  showCcrModal={this.showCcrModal}
                  showRatingsModal={this.showRatingsModal}
                  showPaydexModal={this.showPaydexModal}
                  downloadReportFiles={downloadReportFiles}
                />
              )}

            {workflow.hasQuestionnaire() && (
              <SupplierDetailsQuestionnaire
                questionnaire={questionnaire}
                downloadFileInput={downloadFileInput}
                isDownloading={isDownloading}
              />
            )}

            {(user.isComplianceEnabled() || supplier.hasScreening()) && (
              <SupplierDetailsCompliance
                workflow={workflow}
                supplier={supplier}
                user={user}
                downloadCfrReport={downloadCfrReport}
                handleScreenSupplier={screenSupplier}
              />
            )}
          </div>
        </div>

        <RatingsModal
          show={this.state.showRatingsModal}
          handleClose={this.hideRatingsModal}
          workflow={workflow}
        />

        <PaydexModal
          show={this.state.showPaydexModal}
          handleClose={this.hidePaydexModal}
        />

        <CcrModal
          show={this.state.showCcrModal}
          handleClose={this.hideCcrModal}
        />
      </div>
    );
  }
}

export default WorkflowsSupplierDetails;
