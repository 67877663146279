import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'react-common/services';
import posthog from 'posthog-js';

import OnboardingSupplierConfirmation from './OnboardingSupplierConfirmation';
import {
  changePhoneNumber,
  changePhoneNumberFailure
} from '../../../_actions/onboarding';

class OnboardingSupplierConfirmationContainer extends Component {
  state = {
    inputShown: false,
    goBackStep: ''
  };

  showInput = () => this.setState({ inputShown: true });

  hideInput = () => this.setState({ inputShown: false });

  selectGoBackOnboardingStep = goBackStep => this.setState({ goBackStep });

  trackSupplierChangedOnboardingStep = () => {
    const { goBackStep } = this.state;
    const { supplier, workflow } = this.props;
    const lastLogin = Math.floor(Date.now() / 1000);
    if (!window.analytics) {
      console.log(
        'Window.analytics NOT found! -> OnboardingSupplierConfirmationContainer.js'
      );
    }
    if (window.analytics) {
      console.log(
        'Window.analytics found! -> OnboardingSupplierConfirmationContainer.js'
      );
      try {
        window.analytics.identify(supplier.getEmail(), {
          name: supplier.getFullName(),
          email: supplier.getEmail(),
          phone: supplier.getPhone(),
          supplier360LastLogin: lastLogin || 'value not defined'
        });
      } catch (e) {
        console.log(`Catched error on segment - onboarding: ${e}`);
      }
    }
    posthog.identify(supplier.getEmail(), {
      name: supplier.getFullName(),
      email: supplier.getEmail()
    });
    // add timeout to be sure that identify is sent before track event
    setTimeout(() => {
      Segment.track('Supplier has updated information', {
        updatedInformation: goBackStep,
        workflowId: workflow.getId(),
        workflowName: workflow.getTitle(),
        companyName: workflow.getCompanyCommercialName(),
        supplierId: supplier.getId(),
        supplierName: supplier.getFullName(),
        supplierCompany: supplier.getCompanyName(),
        supplierCountry: ['argentina', 'brazil', 'mexico', 'peru'].includes(
          supplier.getCountry()
        )
          ? supplier.getCountry()
          : 'ccs',
        birRequestId: supplier.getBirRequestId(),
        environment: process.env.NODE_ENV
      });
    }, 1000);
  };

  onSubmit = ({ phone }) => {
    const {
      dispatchChangePhoneNumberFailure,
      dispatchChangePhoneNumber,
      workflowId,
      supplierId
    } = this.props;

    if (!phone) {
      return dispatchChangePhoneNumberFailure('Phone is required');
    }

    return dispatchChangePhoneNumber(workflowId, supplierId, phone).then(
      this.hideInput
    );
  };

  render() {
    return (
      <OnboardingSupplierConfirmation
        inputShown={this.state.inputShown}
        goBackStep={this.state.goBackStep}
        showInput={this.showInput}
        hideInput={this.hideInput}
        onSubmit={this.onSubmit}
        selectGoBackOnboardingStep={this.selectGoBackOnboardingStep}
        trackSupplierChangedOnboardingStep={
          this.trackSupplierChangedOnboardingStep
        }
        initialValues={{ phone: this.props.supplier.account.phone }}
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchChangePhoneNumberFailure: changePhoneNumberFailure,
  dispatchChangePhoneNumber: changePhoneNumber
};

export default connect(null, mapDispatchToProps)(
  OnboardingSupplierConfirmationContainer
);
