import React, { Component } from 'react';
import { connect } from 'react-redux';
import { onboardingSteps } from 'react-common/constants';

import OnboardingIntroduction from '../../../_components/Onboarding/OnboardingIntroduction/OnboardingIntroduction';
import { onboardingGetStarted } from '../../../_actions/onboarding';
import { Localize } from 'react-common/services';

class OnboardingSupplierIntroductionContainer extends Component {
  getStartedAction = () => {
    const {
      dispatchOnboardingGetStarted,
      changeOnboardingStep,
      workflowId,
      supplierId
    } = this.props;

    if (supplierId) {
      return dispatchOnboardingGetStarted(workflowId, supplierId);
    }

    // for public onboarding feature, just change step, don't save it
    return changeOnboardingStep(onboardingSteps.CIAL_ACCOUNT);
  };

  render() {
    const { workflow, supplier } = this.props;

    const language = supplier
      ? Localize.getLanguageForCountry(supplier.getCountry())
      : 'en';

    return (
      <OnboardingIntroduction
        workflow={workflow}
        supplier={supplier}
        showPaymentStep={workflow.isSupplierPaying()}
        getStartedAction={this.getStartedAction}
        language={language}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchOnboardingGetStarted: onboardingGetStarted
};

export default connect(
  null,
  mapDispatchToProps
)(OnboardingSupplierIntroductionContainer);
