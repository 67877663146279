import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Breadcrumb,
  Col,
  MenuItem,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  Row
} from 'react-bootstrap';
import { Util } from 'react-common/services';
import { workflowProducts, workflowReportTypes } from 'react-common/constants';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout, createWorkflow } from '../../_actions';

import './Header.css';

const reportTypes = {
  [workflowProducts.SCORE]: 'Score Ratings',
  [workflowReportTypes.BIR]: 'BIR Reports',
  [workflowReportTypes.SHORT_FORM]: 'CKR Reports',
  [workflowReportTypes.SHORT_FORM_PLUS]: 'CKR Plus Reports',
  [workflowReportTypes.RAC]: 'RAC Reports',
  [workflowReportTypes.RAF]: 'SQR Brazil Reports',
  [workflowReportTypes.SQR]: 'SQR Reports',
  [workflowReportTypes.CAR]: 'CAR Reports',
  [workflowReportTypes.CCR]: 'CCR Reports',
  [workflowReportTypes.QUESTIONNAIRE]: 'Questionnaire',
  [workflowReportTypes.HOM_9_ASPECTS]: 'Hom 9 Aspects',
  [workflowReportTypes.HOM_8_ASPECTS]: 'Hom 8 Aspects',
  [workflowReportTypes.HOM_7_ASPECTS]: 'Hom 7 Aspects',
  [workflowReportTypes.HOM_FALLLABELLLA]: 'Hom Fallabella',
  [workflowReportTypes.HOM_FALLLABELLLA_MID]:
    'Hom Fallabella mid-sized companies',
  [workflowReportTypes.CFR]: 'CFR'
};

const UserHeader = () => (
  <MenuItem componentClass="div" className="Dropdown-header Header-with-link">
    <span>My account</span>
    <Link to="/settings/profile" className="Link">
      Edit account
    </Link>
  </MenuItem>
);

const UserContent = ({ user }) => (
  <MenuItem className="Dropdown-content">
    <Row>
      <Col xs={3}>
        {user.getImage() && (
          <img
            className="User-image"
            src={user.getImage()}
            alt={user.getName()}
          />
        )}
        {!user.getImage() && (
          <div className="Colored-circle">
            <var>{Util.getInitials(user.getName())}</var>
          </div>
        )}
      </Col>
      <Col xs={9} className="User-details" notranslate="true">
        <h4>{user.getName()}</h4>
        <p title={user.getEmail()}>{user.getEmail()}</p>
      </Col>
    </Row>
  </MenuItem>
);

const CompanyHeader = () => (
  <MenuItem className="Dropdown-header">
    <span>My company</span>
  </MenuItem>
);

const CompanyContent = ({ user }) => (
  <MenuItem className="Dropdown-content Company-details">
    <Row>
      <Col xs={12}>
        <h4>
          <var>{user.getCompanyName()}</var>
        </h4>
      </Col>
    </Row>
    <Row>
      <Col xs={3}>
        {user.getCompanyLogo() && (
          <img
            className="User-image"
            src={user.getCompanyLogo()}
            alt={user.getCompanyName()}
          />
        )}
        {!user.getCompanyLogo() && (
          <div className="Colored-circle">
            <var>{Util.getInitials(user.getCompanyName())}</var>
          </div>
        )}
      </Col>
      <Col xs={9}>
        <div className="Company-subscription">
          {user.getEnabledProducts().map((product, index) => (
            <div key={index}>
              <p className="Subscription-label">{reportTypes[product.type]}</p>
              <p className="Subscription-value" notranslate="true">
                {product.used} / {product.total}
              </p>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  </MenuItem>
);

class AuthorizedHeader extends Component {
  onCreateWorkflowClick = () => {
    const { dispatchCreateWorkflow, user } = this.props;
    return dispatchCreateWorkflow({}, user);
  };

  doLogout = () => this.props.dispatchLogout();

  render() {
    const {
      user,
      sidebarOpened,
      toggleSidebar,
      headerBreadcrumbs
    } = this.props;

    if (!user) {
      return null;
    }

    const headerTitle = (
      <Breadcrumb>
        {headerBreadcrumbs.map((breadcrumb, index) => {
          const noTranslateAttr = breadcrumb.notranslate && {
            notranslate: 'true'
          };
          if (headerBreadcrumbs.length !== index + 1) {
            return (
              <LinkContainer
                to={breadcrumb.path}
                key={index}
                {...noTranslateAttr}
              >
                <Breadcrumb.Item>{breadcrumb.title}</Breadcrumb.Item>
              </LinkContainer>
            );
          }
          return (
            <Breadcrumb.Item active key={index} {...noTranslateAttr}>
              {breadcrumb.title}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );

    const dropdownHtml = (
      <span>
        <span className="Hamburger-colored-circle">
          <var>{Util.getInitials(user.getName())}</var>
        </span>
        <var>{user.getName()}</var>
      </span>
    );

    const reports = user.getEnabledProducts().map((product, index) => (
      <MenuItem key={product.type}>
        {reportTypes[product.type]}:
        <var>
          {product.used} / {product.total}
        </var>
      </MenuItem>
    ));

    return (
      <Navbar
        className={`Header navbar-inverse col-lg-10 col-sm-9 col-xs-12 ${
          sidebarOpened ? '' : 'Header-wide'
        }`}
        fluid
      >
        <Navbar.Header>
          <div>
            <i
              className="hidden-xs icon-ic_hamburger"
              onClick={toggleSidebar}
            />
            {headerTitle}
          </div>
        </Navbar.Header>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="Header-nav visible-xs">
            <NavDropdown
              eventKey={1}
              title={dropdownHtml}
              id="basic-nav-dropdown"
              ignore="true"
            >
              <MenuItem>
                My Company: <var>{user.getCompanyName()}</var>
              </MenuItem>

              {reports}
              <MenuItem
                href="/settings/profile"
                className="Edit-account-small-header"
              >
                Edit Account
              </MenuItem>
            </NavDropdown>

            {user.isCompanyAdmin() && (
              <NavItem eventKey={2}>
                <button
                  className="Btn-default-small Btn-default-primary Navigation-create-btn-xs"
                  onClick={this.onCreateWorkflowClick}
                >
                  <i className="icon-ic_plus" />
                  <span>New workflow</span>
                </button>
              </NavItem>
            )}

            <NavItem
              eventKey={3}
              href="/home"
              className="Header-navitem no-border"
            >
              Dashboard
            </NavItem>

            <NavItem
              eventKey={4}
              href="/suppliers"
              className="Header-navitem no-border"
            >
              Suppliers
            </NavItem>

            <NavDropdown
              eventKey={5}
              title="Workflows"
              id="basic-nav-dropdown"
              ignore="true"
              className="Header-navitem no-border"
            >
              <MenuItem href="/workflows" className="Edit-account-small-header">
                Active
              </MenuItem>
              <MenuItem
                href="/workflows/draft"
                className="Edit-account-small-header"
              >
                Draft
              </MenuItem>
              <MenuItem
                href="/workflows/completed"
                className="Edit-account-small-header"
              >
                Completed
              </MenuItem>
            </NavDropdown>

            <NavItem
              className="Header-navitem"
              id="logout"
              onClick={this.doLogout}
              eventKey={6}
            >
              Log out
            </NavItem>
          </Nav>

          <Nav className="Header-nav hidden-xs" pullRight>
            <NavDropdown
              pullRight
              title={user.getName()}
              id="user-dropdown"
              notranslate="true"
            >
              <UserHeader />
              <UserContent user={user} />

              <CompanyHeader />
              <CompanyContent user={user} />

              <MenuItem id="logout" onClick={this.doLogout}>
                Log out
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = ({
  user: { data: { user } },
  ui: { headerBreadcrumbs }
}) => ({ user, headerBreadcrumbs });

const mapDispatchToProps = {
  dispatchLogout: logout,
  dispatchCreateWorkflow: createWorkflow
};

AuthorizedHeader.propTypes = {
  user: PropTypes.shape({
    info: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string
    }),
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        company: PropTypes.shape({
          legal_name: PropTypes.string,
          info: PropTypes.shape({
            name: PropTypes.string,
            logo: PropTypes.string
          })
        })
      })
    )
  }),
  sidebarOpened: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  headerBreadcrumbs: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedHeader);
