import { Workflow } from '../_models/workflow';
import { Supplier } from '../_models/supplier';

export default function normalize(workflows) {
  if (!Array.isArray(workflows)) {
    workflows = [workflows];
  }

  workflows = workflows.map(w => {
    const wObj = new Workflow(w);
    wObj.suppliers = w.suppliers.map(s => new Supplier(s));
    return wObj;
  });

  const workflowsById = {};
  const suppliersById = {};

  workflows.forEach(workflow => {
    workflowsById[workflow.getId()] = workflow;
    workflow.getSuppliers().forEach(supplier => {
      supplier.setWorkflow(workflow);
      suppliersById[supplier.getId()] = supplier;
    });
    workflow.suppliers = workflow.getSuppliers().map(s => s.getId());
  });

  return {
    workflows: workflowsById,
    suppliers: suppliersById
  };
}
