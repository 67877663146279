import React, { Component } from 'react';
import { Localize } from 'react-common/services';
import { connect } from 'react-redux';

import OnboardingCover from '../../_components/Onboarding/OnboardingCover/OnboardingCover';
import OnboardingFooter from '../../_components/Onboarding/OnboardingFooter/OnboardingFooter';
import OnboardingHeader from '../../_components/Onboarding/OnboardingHeader/OnboardingHeader';
import OnboardingIntroduction from '../../_components/Onboarding/OnboardingIntroduction/OnboardingIntroduction';
import OnboardingSupplierQuestionnaireContainer from '../OnboardingSupplier/OnboardingSupplierQuestionnaire/OnboardingSupplierQuestionnaireContainer';
import PageNotFound from '../../PageNotFound/PageNotFound';
import OnboardingWorkflowClosed from '../../_components/OnboardingWorkflowClosed/OnboardingWorkflowClosed';
import { getWorkflow } from '../../_actions';
import Loader from 'react-common/components/Loader/Loader';

class OnboardingPreview extends Component {
  componentDidMount() {
    const { dispatchGetWorkflow, workflow, workflowId } = this.props;

    if (!workflow) {
      dispatchGetWorkflow(workflowId);
    }
  }

  render() {
    const {
      showPageNotFound,
      showQuestionnaire,
      showPaymentStep,
      showLoader,
      workflow
    } = this.props;

    if (showLoader) {
      return <Loader loading={true} />;
    }

    if (showPageNotFound) {
      return <PageNotFound className="Onboarding-404" />;
    }

    if (workflow.isClosed()) {
      return <OnboardingWorkflowClosed workflow={workflow} />;
    }

    let onboardingComponent = (
      <OnboardingIntroduction
        workflow={workflow}
        showPaymentStep={showPaymentStep}
      />
    );

    if (showQuestionnaire) {
      onboardingComponent = (
        <OnboardingSupplierQuestionnaireContainer
          showPreview={true}
          {...this.props}
        />
      );
    }

    return (
      <>
        <OnboardingHeader language={Localize.getLanguage()} />

        {!showQuestionnaire && onboardingComponent}

        {showQuestionnaire && (
          <div className="Onboarding-content">
            <OnboardingCover workflow={workflow} />
            <div className="container">
              <div className="card">
                <div className="Onboarding-main">{onboardingComponent}</div>
              </div>
            </div>
          </div>
        )}

        <OnboardingFooter />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workflows } = state;
  const { workflow_id: workflowId, section } = props.match.params;
  const workflow = workflows.getById[workflowId];

  return {
    workflow,
    workflowId,
    showPageNotFound: !workflow && !!workflows.error,
    showLoader: !workflow || workflows.isFetching,
    showQuestionnaire: section === 'questionnaire',
    showPaymentStep: !!(workflow && workflow.payment === 'supplier')
  };
};

const mapDispatchToProps = {
  dispatchGetWorkflow: getWorkflow
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPreview);
