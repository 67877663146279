import includes from 'lodash/includes';
import React, { Component } from 'react';
import {
  FileDescriptor,
  FileUpload,
  SingleModal
} from 'react-common/components';

class WorkflowsSupplierDetailsSupplierFiles extends Component {
  state = {
    showDeleteSupplierFileModal: false,
    supplierFileIdToDelete: ''
  };

  hideDeleteSupplierFileModal = () =>
    this.setState({
      showDeleteSupplierFileModal: false,
      supplierFileIdToDelete: ''
    });

  showDeleteSupplierFileModal = supplierFileIdToDelete =>
    this.setState({
      showDeleteSupplierFileModal: true,
      supplierFileIdToDelete
    });

  render() {
    const {
      supplier,
      isDownloading,
      uploadingFiles,
      uploadSupplierFile,
      deleteSupplierFile,
      downloadSupplierFile
    } = this.props;
    const { showDeleteSupplierFileModal, supplierFileIdToDelete } = this.state;

    return (
      <div className="WorkflowsSupplierDetails-rectangle">
        <div className="WorkflowsSupplierDetails-title-table">
          My Files
          <FileUpload
            buttonClassName="Btn-default-small Btn-default-primary Btn-upload-file pull-right"
            buttonText="Upload"
            onChange={file => uploadSupplierFile(file)}
            isUploading={includes(uploadingFiles, supplier.getId())}
          />
        </div>
        <div className="WorkflowsSupplierDetails-content-table">
          {!supplier.hasFiles() && (
            <div className="Empty-state">You didn't upload any files yet.</div>
          )}
          {supplier
            .getFiles()
            .map(file => (
              <FileDescriptor
                key={file.file_id}
                file={file}
                onClick={downloadSupplierFile(file.file_id, file.name)}
                disabled={isDownloading}
                onDelete={() => this.showDeleteSupplierFileModal(file.file_id)}
              />
            ))}

          <SingleModal
            title="Delete File"
            body="Are you sure you want to delete this file?"
            primaryButtonClassName="Btn-default-danger"
            show={showDeleteSupplierFileModal}
            handleClose={this.hideDeleteSupplierFileModal}
            handleSubmit={() => {
              deleteSupplierFile(supplierFileIdToDelete);
              this.hideDeleteSupplierFileModal();
            }}
          />
        </div>
      </div>
    );
  }
}

export default WorkflowsSupplierDetailsSupplierFiles;
