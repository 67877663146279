import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import './OnboardingIntroduction.css';
import { Util } from 'react-common/services';
import user from '../../../_images/user.svg';
import form from '../../../_images/form.svg';
import card from '../../../_images/card.svg';
import verification from '../../../_images/verification.svg';
import OnboardingCover from '../../../_components/Onboarding/OnboardingCover/OnboardingCover';

const OnboardingIntroduction = ({
  workflow,
  supplier,
  showPaymentStep,
  getStartedAction,
  language
}) => (
  <>
    <div className="Onboarding-content">
      <OnboardingCover workflow={workflow} />
      <div className="container">
        <div className="card">
          <div className="Onboarding-main">
            <div className="Introduction">
              <div className="OnboardingIntroduction">
                <div className="content">
                  <div className="media-left">
                    {workflow.getOwnerCompanyImage() && (
                      <img
                        className="media-object OnboardingCover-logo"
                        src={workflow.getOwnerCompanyImage()}
                        alt={workflow.getCompanyCommercialName()}
                      />
                    )}

                    {!workflow.getOwnerCompanyImage() && (
                      <div className="media-object OnboardingCover-logo OnboardingCover-no-logo">
                        <var>
                          {Util.getInitials(
                            workflow.getCompanyCommercialName()
                          )}
                        </var>
                      </div>
                    )}
                  </div>

                  <div className="OnboardingIntroduction-welcome-message">
                    <var>{workflow.getCompanyCommercialName()}</var> needs to
                    collect some information from you
                    {supplier && <var>, {supplier.getCompanyName()}</var>}
                  </div>
                </div>

                <div className="OnboardingIntroduction-description">
                  {workflow.getWelcomeMessage() && (
                    <p
                      className="OnboardingIntroduction-description-welcome"
                      notranslate="true"
                    >
                      {workflow.getWelcomeMessage()}
                    </p>
                  )}
                </div>

                <div className="button-content">
                  {getStartedAction && (
                    <button
                      className="Btn-default Btn-default-success OnboardingIntroduction-button-next"
                      type="button"
                      onClick={getStartedAction}
                    >
                      Start assessment
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className="container-fluid How-it-works">
        <div className="container How-it-works-content">
          <div className="title">How does it work?</div>

          {language === 'es' && (
            <div className="card text-center">
              <iframe
                title="youtube tutorial"
                width="560"
                height="315"
                src={
                  showPaymentStep
                    ? 'https://www.youtube.com/embed/nndV0kdX7S0'
                    : 'https://www.youtube.com/embed/-m5TvoiDuto'
                }
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}

          <div
            className={
              'col-xs-12 ' + (showPaymentStep ? ' col-md-6' : 'col-md-4')
            }
          >
            <div className={'card ' + (showPaymentStep ? '' : 'no-payment')}>
              <div className="card-content">
                <img src={user} alt="Create account" />
                <div className="card-title">Create an account</div>
              </div>
              <div className="card-text">
                To start your online evaluation process, create an account on
                CIAL Dun & Bradstreet’s MyCIAL platform.
              </div>
            </div>
          </div>

          <div
            className={
              'col-xs-12 ' + (showPaymentStep ? ' col-md-6' : 'col-md-4')
            }
          >
            <div className={'card ' + (showPaymentStep ? '' : 'no-payment')}>
              <div className="card-content">
                <img src={form} alt="Form" />
                <div className="card-title">Provide your data</div>
              </div>
              <div className="card-text">
                Have your financial, legal, and operational data at hand as you
                fill out the form. Your data will be automatically saved as you
                progress.
              </div>
            </div>
          </div>

          {showPaymentStep && (
            <div className="col-xs-12 col-md-6">
              <div className="card">
                <div className="card-content">
                  <img src={card} alt="Payment" />
                  <div className="card-title">Payment</div>
                </div>
                <div className="card-text">
                  Create a payment reference, pay, and then upload your receipt.
                </div>
              </div>
            </div>
          )}

          <div
            className={
              'col-xs-12 ' + (showPaymentStep ? ' col-md-6' : 'col-md-4')
            }
          >
            <div className={'card ' + (showPaymentStep ? '' : 'no-payment')}>
              <div className="card-content">
                <img src={verification} alt="Verification" />
                <div className="card-title">Verification</div>
              </div>
              <div className="card-text">
                A CIAL D&B analyst will review and verify your information, and
                may contact you to confirm certain items.
              </div>
            </div>
          </div>

          <div className="button-content col-xs-12">
            {getStartedAction && (
              <button
                className="Btn-default Btn-default-success OnboardingIntroduction-button-next"
                type="button"
                onClick={getStartedAction}
              >
                Start assessment
              </button>
            )}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className="container How-it-works-content">
        <div className="title">Why is this process important?</div>

        <div className="Section-text">
          Your client <var>{workflow.getCompanyCommercialName()}</var> has
          partnered with CIAL Dun & Bradstreet to collect data on their supplier
          portfolio. CIAL is Latin America’s premier business data and analytics
          provider and is the official partner of the Dun & Bradstreet Worldwide
          Network in the region. Supplier 360 provides a convenient online
          platform in which to share this information with your client.
        </div>

        <div className="button-content col-xs-12">
          <Link to="/contact">
            <button
              className="Btn-default Btn-default-success OnboardingIntroduction-button-next"
              type="button"
            >
              Contact us
            </button>
          </Link>
        </div>
      </div>
    </div>
  </>
);

OnboardingIntroduction.propTypes = {
  workflow: PropTypes.object,
  showPaymentStep: PropTypes.bool,
  getStartedAction: PropTypes.func
};

export default OnboardingIntroduction;
