import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import WorkflowsDetailsResultsContainer from './WorkflowsDetailsResults/WorkflowsDetailsResultsContainer';
import WorkflowsDetailsSuppliersContainer from './WorkflowsDetailsSuppliers/WorkflowsDetailsSuppliersContainer';

import './WorkflowsDetails.css';

const WorkflowsDetails = props => (
  <div className="Workflow-details">
    <Tabs
      id="workflow-details-tabs"
      onSelect={props.handleTabSelect}
      activeKey={props.match.params.section}
    >
      <Tab eventKey="results" title="Results">
        <WorkflowsDetailsResultsContainer {...props} />
      </Tab>
      <Tab eventKey="suppliers" title="Suppliers">
        <WorkflowsDetailsSuppliersContainer {...props} />
      </Tab>
    </Tabs>
  </div>
);

export default WorkflowsDetails;
