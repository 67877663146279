import includes from 'lodash/includes';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import {
  financialStrengthClasses,
  financialStrengthOrder,
  racRiskIndicatorsOrder,
  riskIndicatorsOrder,
  supplierStatuses
} from 'react-common/constants';
import { Checkbox, RadioButton, Tooltip } from 'react-common/components';
import { Util } from 'react-common/services';

import {
  commercialScoreLabels,
  commercialScoreOrder,
  emptyFilters
} from '../../_constants/workflows';
import WorkflowsService from '../../_services/Workflows';
import './SuppliersFilterModal.css';
import uniq from 'lodash/uniq';
import { setSuppliersFilters } from '../../_actions';
import { connect } from 'react-redux';

const STATUS_ALL = 'all';

class SuppliersFilterModal extends Component {
  state = {
    filters: {
      ...emptyFilters,
      updatedAt: new Date()
    },
    showFilterModal: false,
    filterButtonHovering: false
  };

  availableFilters = {
    country: [],
    financialStrength: [],
    industry: [],
    riskIndicator: [],
    commercialScore: [],
    tags: []
  };

  hideFilterModal = () => this.setState({ showFilterModal: false });
  showFilterModal = () => this.setState({ showFilterModal: true });

  componentDidMount() {
    const { suppliers, user, useRacRiskIndicator } = this.props;
    const tagsById = user.getSupplierTagsMap();

    suppliers.forEach(supplier => {
      this.availableFilters.country.push(supplier.getCountry());

      if (supplier.getFinancialStrength()) {
        this.availableFilters.financialStrength.push(
          supplier.getFinancialStrength()
        );
      }

      if (supplier.getIndustry()) {
        this.availableFilters.industry.push(supplier.getIndustry());
      }

      if (supplier.getRacRiskIndicator()) {
        this.availableFilters.riskIndicator.push(
          supplier.getRacRiskIndicator()
        );
      } else if (supplier.getRiskIndicator()) {
        this.availableFilters.riskIndicator.push(supplier.getRiskIndicator());
      }

      if (supplier.getCommercialScore()) {
        this.availableFilters.commercialScore.push(
          WorkflowsService.getCommercialScoreGroup(
            supplier.getCommercialScore()
          )
        );
      }

      this.availableFilters.tags = this.availableFilters.tags.concat(
        supplier.getTags().filter(tag => tagsById[tag])
      );
    });

    this.availableFilters.country = uniq(this.availableFilters.country).sort();
    this.availableFilters.financialStrength = uniq(
      this.availableFilters.financialStrength
    ).sort(Util.sortByArrayIndex(financialStrengthOrder));
    this.availableFilters.industry = uniq(
      this.availableFilters.industry
    ).sort();
    this.availableFilters.riskIndicator = uniq(
      this.availableFilters.riskIndicator
    ).sort(
      Util.sortByArrayIndex(
        useRacRiskIndicator ? racRiskIndicatorsOrder : riskIndicatorsOrder
      )
    );
    this.availableFilters.commercialScore = uniq(
      this.availableFilters.commercialScore
    ).sort(Util.sortByArrayIndex(commercialScoreOrder));
    this.availableFilters.tags = uniq(this.availableFilters.tags).sort(
      (a, b) => tagsById[a] - tagsById[b]
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { activeFilters } = nextProps;

    // put filters from props into state only if they have been updated AFTER the last time the state has been updated
    // we only take filters from props the first time they are updated, after which we trust in the state
    if (
      activeFilters.updatedAt &&
      activeFilters.updatedAt > prevState.filters.updatedAt
    ) {
      return {
        filters: {
          ...activeFilters
        }
      };
    }
    return null;
  }

  onSelect = field => value => {
    const values = this.state.filters[field];
    let newValues = [...values];

    if (value === STATUS_ALL) {
      newValues = [];
    } else if (includes(values, value)) {
      newValues = values.filter(v => v !== value);
    } else {
      newValues = values.concat(value);
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: newValues,
        updatedAt: new Date()
      }
    });
  };

  statusSelect = status =>
    this.setState({
      filters: {
        ...this.state.filters,
        status: status === STATUS_ALL ? undefined : status,
        updatedAt: new Date()
      }
    });

  countrySelect = this.onSelect('country');
  industrySelect = this.onSelect('industry');
  financialStrengthSelect = this.onSelect('financialStrength');
  riskIndicatorSelect = this.onSelect('riskIndicator');
  commercialScoreSelect = this.onSelect('commercialScore');
  tagsSelect = this.onSelect('tags');

  selectAll = field => () => {
    let newValue = [];
    if (
      this.state.filters[field].length !== this.availableFilters[field].length
    ) {
      newValue = [...this.availableFilters[field]];
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: newValue,
        updatedAt: new Date()
      }
    });
  };

  cleanUp = () => {
    const filters = { ...this.state.filters };

    if (!filters.status) {
      delete filters.status;
    }

    if (
      filters.status === supplierStatuses.PENDING ||
      filters.status === supplierStatuses.IN_PROGRESS
    ) {
      filters.financialStrength = [];
      filters.riskIndicator = [];
      filters.industry = [];
      filters.commercialScore = [];
    }

    return filters;
  };

  closeModal = () => {
    const { activeFilters } = this.props;

    if (WorkflowsService.isFilterActive(activeFilters)) {
      this.setState({ filters: { ...activeFilters } });
    } else {
      this.setState({ filters: { ...emptyFilters } });
    }
    this.hideFilterModal();
  };

  renderStatus = () => (
    <>
      <h5 className="Modal-paragraph-title">Status</h5>
      <div className="Modal-status">
        <RadioButton
          name={STATUS_ALL}
          label="All"
          handleChange={this.statusSelect}
          checked={!this.state.filters.status}
        />
        <RadioButton
          name={supplierStatuses.PENDING}
          label="Pending"
          handleChange={this.statusSelect}
          checked={this.state.filters.status === supplierStatuses.PENDING}
        />
        <RadioButton
          name={supplierStatuses.IN_PROGRESS}
          label="In Progress"
          handleChange={this.statusSelect}
          checked={this.state.filters.status === supplierStatuses.IN_PROGRESS}
        />
        <RadioButton
          name={supplierStatuses.COMPLETED}
          label="Completed"
          handleChange={this.statusSelect}
          checked={this.state.filters.status === supplierStatuses.COMPLETED}
        />
        <RadioButton
          name={supplierStatuses.ARCHIVED}
          label="Archived"
          handleChange={this.statusSelect}
          checked={this.state.filters.status === supplierStatuses.ARCHIVED}
        />
      </div>
    </>
  );

  renderCountry = countries => (
    <>
      <h5 className="Modal-paragraph-title">Country</h5>
      <Checkbox
        name={STATUS_ALL}
        label="All"
        handleChange={this.selectAll('country')}
        checked={this.state.filters.country.length === countries.length}
      />
      <div className="row">
        {Util.splitToChunks(countries, 2).map((countries, index) => (
          <div className="col-md-6" key={index}>
            {countries.map((country, index) => (
              <Checkbox
                key={index}
                name={country}
                label={Util.capitalize(country)}
                handleChange={this.countrySelect}
                checked={includes(this.state.filters.country, country)}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );

  renderFinancialStrength = financialStrengths => (
    <>
      <h5 className="Modal-paragraph-title">Financial Strength</h5>
      <Checkbox
        name={STATUS_ALL}
        label="All"
        handleChange={this.selectAll('financialStrength')}
        checked={
          this.state.filters.financialStrength.length ===
          financialStrengths.length
        }
      />

      <div className="row Modal-Financial-strength">
        {Util.splitToChunks(financialStrengths, 3).map(
          (financialStrengths, index) => (
            <div className="col-sm-4" key={index}>
              {financialStrengths.map((financialStrength, index) => (
                <Checkbox
                  key={index}
                  name={financialStrength}
                  label={
                    <div
                      className={`Square-badge Bg-${
                        financialStrengthClasses[financialStrength]
                      }`}
                    >
                      {financialStrength}
                    </div>
                  }
                  handleChange={this.financialStrengthSelect}
                  checked={includes(
                    this.state.filters.financialStrength,
                    financialStrength
                  )}
                />
              ))}
            </div>
          )
        )}
      </div>
    </>
  );

  renderIndustry = industries => (
    <>
      <h5 className="Modal-paragraph-title">Industry</h5>
      <Checkbox
        name={STATUS_ALL}
        label="All"
        handleChange={this.selectAll('industry')}
        checked={this.state.filters.industry.length === industries.length}
      />
      <div className="row">
        {Util.splitToChunks(industries, 2).map((industries, index) => (
          <div className="col-md-6" key={index}>
            {industries.map((industry, index) => (
              <Checkbox
                key={index}
                name={industry}
                label={industry}
                handleChange={this.industrySelect}
                checked={includes(this.state.filters.industry, industry)}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );

  renderRiskIndicator = riskIndicators => (
    <>
      <h5 className="Modal-paragraph-title">Risk Indicator</h5>

      <div className="Modal-Risk-indicator">
        <Checkbox
          name={STATUS_ALL}
          label="All"
          handleChange={this.selectAll('riskIndicator')}
          checked={
            this.state.filters.riskIndicator.length === riskIndicators.length
          }
        />
        {riskIndicators.map((riskIndicator, index) => (
          <Checkbox
            key={index}
            name={riskIndicator}
            label={riskIndicator}
            handleChange={this.riskIndicatorSelect}
            checked={includes(this.state.filters.riskIndicator, riskIndicator)}
          />
        ))}
      </div>
    </>
  );

  renderCommercialScore = commercialScores => (
    <>
      <h5 className="Modal-paragraph-title">Commercial score</h5>
      <Checkbox
        name={STATUS_ALL}
        label="All"
        handleChange={this.selectAll('commercialScore')}
        checked={
          this.state.filters.commercialScore.length === commercialScores.length
        }
      />
      {commercialScores.map((commercialScore, index) => (
        <Checkbox
          key={index}
          name={commercialScore}
          label={commercialScoreLabels[commercialScore]}
          handleChange={this.commercialScoreSelect}
          checked={includes(
            this.state.filters.commercialScore,
            commercialScore
          )}
        />
      ))}
    </>
  );

  renderTags = tags => (
    <>
      <h5 className="Modal-paragraph-title">Tags</h5>
      <Checkbox
        name={STATUS_ALL}
        label="All"
        handleChange={this.selectAll('tags')}
        checked={this.state.filters.tags.length === tags.length}
      />
      <div className="row">
        {Util.splitToChunks(tags, 2).map((tags, index) => (
          <div className="col-md-6" key={index}>
            {tags.map((tag, index) => {
              const selectedTag = this.props.user.getSupplierTagsMap()[tag];
              return (
                <Checkbox
                  key={index}
                  name={tag}
                  label={
                    <div
                      className="Badge"
                      style={{
                        backgroundColor: selectedTag && selectedTag.color
                      }}
                    >
                      {selectedTag && selectedTag.name}
                    </div>
                  }
                  handleChange={this.tagsSelect}
                  checked={includes(this.state.filters.tags, tag)}
                />
              );
            })}
          </div>
        ))}
      </div>
    </>
  );

  applyFilters = filters => {
    const { dispatchSetSuppliersFilters } = this.props;
    return dispatchSetSuppliersFilters(filters);
  };

  componentWillUnmount() {
    // remove filters when this component is unmounted
    this.applyFilters(emptyFilters);
  }

  render() {
    const { activeFilters } = this.props;
    const isFilterActive = WorkflowsService.isFilterActive(activeFilters);

    let filterButtonText = 'Filter';
    if (isFilterActive) {
      filterButtonText = this.state.filterButtonHovering
        ? 'Edit filters'
        : 'Filters applied';
    }
    return (
      <>
        <button
          className={
            'Btn-default-small Btn-default-neutral Btn-filter' +
            (isFilterActive ? ' active' : '')
          }
          onClick={this.showFilterModal}
          onMouseEnter={() => this.setState({ filterButtonHovering: true })}
          onMouseLeave={() => this.setState({ filterButtonHovering: false })}
        >
          {filterButtonText}
        </button>
        {isFilterActive && (
          <Tooltip
            text="Clear filters"
            position="bottom"
            id="remove-filter-tooltip"
          >
            <button
              className="Btn-default-small Btn-default-neutral Btn-remove-filter"
              onClick={() => this.applyFilters(emptyFilters)}
            >
              <i className="icon-ic_close" />
            </button>
          </Tooltip>
        )}
        <Modal
          className="Modal-filter"
          show={this.state.showFilterModal}
          onHide={this.closeModal}
        >
          <Modal.Header>
            <Modal.Title className="ModalTitle">Filter</Modal.Title>
            <i
              className="icon-ic_close pull-right Close-modal-icon"
              onClick={this.closeModal}
            />
          </Modal.Header>
          <Modal.Body>
            {this.renderStatus()}
            {this.availableFilters.country.length > 0 &&
              this.renderCountry(this.availableFilters.country)}

            {this.state.filters.status !== supplierStatuses.PENDING &&
              this.state.filters.status !== supplierStatuses.IN_PROGRESS &&
              this.availableFilters.financialStrength.length > 0 &&
              this.renderFinancialStrength(
                this.availableFilters.financialStrength
              )}

            {this.state.filters.status !== supplierStatuses.PENDING &&
              this.state.filters.status !== supplierStatuses.IN_PROGRESS &&
              this.availableFilters.industry.length > 0 &&
              this.renderIndustry(this.availableFilters.industry)}

            {this.state.filters.status !== supplierStatuses.PENDING &&
              this.state.filters.status !== supplierStatuses.IN_PROGRESS &&
              this.availableFilters.riskIndicator.length > 0 &&
              this.renderRiskIndicator(this.availableFilters.riskIndicator)}

            {this.state.filters.status !== supplierStatuses.PENDING &&
              this.state.filters.status !== supplierStatuses.IN_PROGRESS &&
              this.availableFilters.commercialScore.length > 0 &&
              this.renderCommercialScore(this.availableFilters.commercialScore)}

            {this.availableFilters.tags.length > 0 &&
              this.renderTags(this.availableFilters.tags)}
          </Modal.Body>

          <Modal.Footer>
            <button
              className="Btn-outline-small Btn-outline-danger pull-left"
              onClick={() =>
                this.setState({
                  filters: { ...emptyFilters, updatedAt: new Date() }
                })
              }
            >
              Reset all
            </button>

            <button
              className="Btn-default-small Btn-default-neutral"
              onClick={this.closeModal}
            >
              Cancel
            </button>

            <button
              className="Btn-default-small Btn-default-success"
              onClick={() => {
                this.applyFilters(this.cleanUp());
                this.hideFilterModal();
              }}
            >
              Apply
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { ui: { suppliersFilters } } = state;

  return {
    activeFilters: suppliersFilters
  };
};

const mapDispatchToProps = {
  dispatchSetSuppliersFilters: setSuppliersFilters
};

SuppliersFilterModal.propTypes = {
  suppliers: PropTypes.array,
  user: PropTypes.object,
  useRacRiskIndicator: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(
  SuppliersFilterModal
);
