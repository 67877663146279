import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { isValid as isValidCnpj } from '@fnando/cnpj';

import {
  getBirRequestForSupplier,
  createPaymentReferenceForSupplier,
  submitPaymentProofForSupplier,
  createPaymentReferenceFailure,
  addPaymentProofForSupplier,
  removePaymentProofForSupplier,
  submitOnboardingPaymentProofFailure,
  uploadRfcDocumentForSupplier,
  deleteRfcDocumentForSupplier,
  initBrazilPaymentForBirRequest,
  initOnlinePaymentForBirRequest
} from '../../../_actions/onboarding';

import { taxTypeMapping } from '../../../_constants/onboarding';

import OnboardingSupplierPayment from './OnboardingSupplierPayment';

class OnboardingSupplierPaymentContainer extends Component {
  state = {
    showPaymentReference: false
  };

  componentDidMount() {
    const { dispatchGetBirRequestForSupplier, supplier } = this.props;
    if (!supplier.birRequest) {
      dispatchGetBirRequestForSupplier(supplier);
    }
  }

  createPaymentReference = billingInfo => {
    const {
      dispatchCreatePaymentReferenceFailure,
      dispatchCreatePaymentReferenceForSupplier,
      dispatchInitBrazilPaymentForBirRequest,
      dispatchInitOnlinePaymentForBirRequest,
      paymentRegion,
      supplier
    } = this.props;

    const GENERAL_FIELDS = [
      'company_name',
      'tax_id',
      'tax_id_type',
      'address',
      'postal_code',
      'city',
      'state',
      'phone'
    ];

    const PAYMENT_FIELDS = ['person_name', 'position', 'email', 'method'];

    if (paymentRegion === 'mexico') {
      PAYMENT_FIELDS.push('cfi');
    }

    if (
      !billingInfo ||
      !billingInfo.general ||
      !billingInfo.payment ||
      !GENERAL_FIELDS.every(field => billingInfo.general[field]) ||
      !PAYMENT_FIELDS.every(field => billingInfo.payment[field])
    ) {
      return dispatchCreatePaymentReferenceFailure('All fields are required');
    }

    if (billingInfo.general.tax_id_type === 'rfc') {
      if (!billingInfo.general.tax_id.match(/^[0-9A-Za-z&]{12,13}$/)) {
        return dispatchCreatePaymentReferenceFailure('RFC is not valid');
      }
      if (!supplier.getRfcDocuments().length) {
        return dispatchCreatePaymentReferenceFailure(
          'Please upload RFC document'
        );
      }
    }

    if (
      billingInfo.general.tax_id_type === 'ruc' &&
      !billingInfo.general.tax_id.match(/[0-9]{11}/)
    ) {
      return dispatchCreatePaymentReferenceFailure('RUC is not valid');
    }

    if (
      billingInfo.general.tax_id_type === 'dni' &&
      !billingInfo.general.tax_id.match(/[0-9]{8}/)
    ) {
      return dispatchCreatePaymentReferenceFailure('DNI is not valid');
    }

    if (
      billingInfo.general.tax_id_type === 'cnpj' &&
      !isValidCnpj(billingInfo.general.tax_id, true)
    ) {
      return dispatchCreatePaymentReferenceFailure('CNPJ is not valid');
    }

    billingInfo.general.tax_id = billingInfo.general.tax_id.toUpperCase();

    return dispatchCreatePaymentReferenceForSupplier(
      supplier,
      billingInfo
    ).then(() => {
      if (paymentRegion === 'brazil') {
        return dispatchInitBrazilPaymentForBirRequest(supplier).then(() =>
          window.location.reload()
        );
      }
      if (
        billingInfo.payment.method === 'Credit Card' &&
        paymentRegion === 'mexico'
      ) {
        return dispatchInitOnlinePaymentForBirRequest(supplier).then(
          updatedSupplier => {
            // go to online checkout form
            window.location.href = `${updatedSupplier.getOnlinePaymentUri()}&redirect-url=${encodeURIComponent(
              window.location.href
            )}`;
          }
        );
      }
      return this.setState({ showPaymentReference: true });
    });
  };

  submitPaymentProof = () => {
    const {
      dispatchSubmitPaymentProofForSupplier,
      workflowId,
      supplierId,
      changeOnboardingStep
    } = this.props;

    return dispatchSubmitPaymentProofForSupplier(workflowId, supplierId).then(
      () => changeOnboardingStep('')
    );
  };

  addPaymentProof = file => {
    const {
      dispatchAddPaymentProofForSupplier,
      workflowId,
      supplier
    } = this.props;

    return dispatchAddPaymentProofForSupplier(workflowId, supplier, file);
  };

  removePaymentProof = fileId => {
    const {
      dispatchRemovePaymentProofForSupplier,
      workflowId,
      supplier
    } = this.props;

    return dispatchRemovePaymentProofForSupplier(workflowId, supplier, fileId);
  };

  uploadRfcDocument = rfcDocument => {
    const { dispatchUploadRfcDocument, workflowId, supplier } = this.props;

    return dispatchUploadRfcDocument(workflowId, supplier, rfcDocument);
  };

  deleteRfcDocument = () => {
    const { dispatchDeleteRfcDocument, workflowId, supplier } = this.props;

    return dispatchDeleteRfcDocument(workflowId, supplier);
  };

  render() {
    return (
      <OnboardingSupplierPayment
        showPaymentReference={this.state.showPaymentReference}
        createPaymentReference={this.createPaymentReference}
        addPaymentProof={this.addPaymentProof}
        removePaymentProof={this.removePaymentProof}
        submitPaymentProof={this.submitPaymentProof}
        uploadRfcDocument={this.uploadRfcDocument}
        deleteRfcDocument={this.deleteRfcDocument}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workflows: { uploadingFiles } = {} } = state;
  const { supplier } = props;
  const birRequest = supplier.getBirRequest();
  const paymentRegion = supplier.getPaymentRegion();

  let initialValues = birRequest.billing || {};
  if (!initialValues.general) {
    initialValues.general = {};
  }
  if (!initialValues.general.tax_id_type) {
    initialValues.general.tax_id_type =
      taxTypeMapping[paymentRegion] || 'tax_id';
  }

  if (!initialValues.payment) {
    initialValues.payment = {};
  }

  return {
    initialValues,
    formValues: getFormValues('payment-reference')(state) || {},
    paymentRegion,
    localMarket: supplier.getLocalMarket().toLowerCase(),
    uploadingFiles
  };
};

const mapDispatchToProps = {
  dispatchGetBirRequestForSupplier: getBirRequestForSupplier,
  dispatchCreatePaymentReferenceFailure: createPaymentReferenceFailure,
  dispatchCreatePaymentReferenceForSupplier: createPaymentReferenceForSupplier,
  dispatchSubmitOnboardingPaymentProofFailure: submitOnboardingPaymentProofFailure,
  dispatchAddPaymentProofForSupplier: addPaymentProofForSupplier,
  dispatchRemovePaymentProofForSupplier: removePaymentProofForSupplier,
  dispatchSubmitPaymentProofForSupplier: submitPaymentProofForSupplier,
  dispatchUploadRfcDocument: uploadRfcDocumentForSupplier,
  dispatchDeleteRfcDocument: deleteRfcDocumentForSupplier,
  dispatchInitBrazilPaymentForBirRequest: initBrazilPaymentForBirRequest,
  dispatchInitOnlinePaymentForBirRequest: initOnlinePaymentForBirRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(
  OnboardingSupplierPaymentContainer
);
