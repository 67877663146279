const userConstants = {
  RELOAD_REQUEST: 'RELOAD_REQUEST',
  RELOAD_SUCCESS: 'RELOAD_SUCCESS',
  RELOAD_FAILURE: 'RELOAD_FAILURE',

  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  CHANGE_ACCOUNT_SETTINGS_REQUEST: 'CHANGE_ACCOUNT_SETTINGS_REQUEST',
  CHANGE_ACCOUNT_SETTINGS_SUCCESS: 'CHANGE_ACCOUNT_SETTINGS_SUCCESS',
  CHANGE_ACCOUNT_SETTINGS_FAILURE: 'CHANGE_ACCOUNT_SETTINGS_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  SUPPLIERS_COLUMNS_REQUEST: 'SUPPLIERS_COLUMNS_REQUEST',
  SUPPLIERS_COLUMNS_SUCCESS: 'SUPPLIERS_COLUMNS_SUCCESS',
  SUPPLIERS_COLUMNS_FAILURE: 'SUPPLIERS_COLUMNS_FAILURE',

  SET_SUPPLIER_TAGS_REQUEST: 'SET_SUPPLIER_TAGS_REQUEST',
  SET_SUPPLIER_TAGS_SUCCESS: 'SET_SUPPLIER_TAGS_SUCCESS',
  SET_SUPPLIER_TAGS_FAILURE: 'SET_SUPPLIER_TAGS_FAILURE',

  DELETE_SUPPLIER_TAG_REQUEST: 'DELETE_SUPPLIER_TAG_REQUEST',
  DELETE_SUPPLIER_TAG_SUCCESS: 'DELETE_SUPPLIER_TAG_SUCCESS',
  DELETE_SUPPLIER_TAG_FAILURE: 'DELETE_SUPPLIER_TAG_FAILURE'
};

export default userConstants;
