import React from 'react';
import { Toast } from 'react-common/services';
import SimpleToast from '../_components/Toast/Toast';

class ToastService {
  showInfo(text) {
    Toast.showInfo(<SimpleToast text={text} />);
  }

  showWarning(text) {
    Toast.showWarning(<SimpleToast text={text} />);
  }

  showSuccess(text) {
    Toast.showSuccess(<SimpleToast text={text} />);
  }
}

export default new ToastService();
