import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { UploadcareDialog, Tooltip } from 'react-common/components';
import { Localize, Util } from 'react-common/services';
import { Field } from 'redux-form';

import WorkflowsCreateCover from './WorkflowsCreateCover';

class WorkflowsCreateStepsOnboarding extends Component {
  showOnboardingPreviewPage = () => {
    const { workflowId } = this.props;
    window.open(`/onboarding/${workflowId}/introduction`, '_blank');
  };

  render() {
    const { formValues = {}, change } = this.props;

    return (
      <Panel className="WorkflowsCreate-panel" eventKey="2">
        <Panel.Heading>
          <Panel.Title toggle>
            <span>Customize onboarding</span>
            <Tooltip
              text="Suppliers will see this info"
              position="bottom"
              id="workflow-create-onboarding-tooltip"
            >
              <i className="icon-ic_question Icon-question" />
            </Tooltip>

            <span className="pull-right">
              <Tooltip
                text="Preview how suppliers will see it"
                position="bottom"
                id="preview-introduction"
              >
                <i
                  className="icon-ic_eye Icon-preview"
                  onClick={this.showOnboardingPreviewPage}
                />
              </Tooltip>
              <i className="icon-ic_expand-arrow " />
            </span>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            {formValues.owner &&
              formValues.owner.company && (
                <>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="title">My Company Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="info.company_name"
                          component="input"
                          placeholder="Enter your company name"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>My Company logo</label>
                        <div
                          className="Image-container"
                          style={
                            formValues.owner.company.image && {
                              backgroundImage: `url(${
                                formValues.owner.company.image
                              })`
                            }
                          }
                          onClick={() => this.uploader.openDialog()}
                        >
                          {!formValues.owner.company.image && (
                            <div className="Colored-square">
                              <var>
                                {Util.getInitials(
                                  formValues.info.company_name ||
                                    formValues.owner.company.name
                                )}
                              </var>
                            </div>
                          )}
                          <div className="Image-overlay">
                            <div className="Overlay-text">
                              {(formValues.owner.company.image && 'Change') ||
                                'Upload'}
                            </div>
                          </div>
                          <UploadcareDialog
                            value={formValues.owner.company.image}
                            ref={uploader => (this.uploader = uploader)}
                            onUploadComplete={info =>
                              change('owner.company.image', info.cdnUrl)
                            }
                            language={Localize.getLanguage()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            <hr />
            {formValues.info && (
              <WorkflowsCreateCover
                cover={formValues.info.cover}
                onUploadComplete={info => change('info.cover', info.cdnUrl)}
              />
            )}

            <hr />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="welcome">Welcome message (optional)</label>
                  <Field
                    type="text"
                    className="form-control WorkflowsCreate-message"
                    name="info.welcome_message"
                    component="textarea"
                    placeholder="Write a message welcoming your suppliers to the onboarding process"
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="custom_message">
                    <span>Custom invitation email (optional)</span>
                    <Tooltip
                      text={
                        'Set your message with details in the preferent language.\n' +
                        'Avoid adding a welcome greeting, is automated.\n' +
                        "If a custom invitation isn't needed, CIAL D&B default invitation will be sent."
                      }
                      position="bottom"
                      id="workflow-custom-email-tooltip"
                    >
                      <i className="icon-ic_question Icon-question" />
                    </Tooltip>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="info.email.subject"
                    component="input"
                    placeholder="Subject"
                  />
                  <Field
                    type="text"
                    className="form-control WorkflowsCreate-message"
                    name="info.email.message"
                    component="textarea"
                    placeholder="Write a custom email message for suppliers"
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default WorkflowsCreateStepsOnboarding;
