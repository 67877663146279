const onboardingConstants = {
  GET_WORKFLOW_REQUEST: 'ONBOARDING_GET_WORKFLOW_REQUEST',
  GET_WORKFLOW_SUCCESS: 'ONBOARDING_GET_WORKFLOW_SUCCESS',
  GET_WORKFLOW_FAILURE: 'ONBOARDING_GET_WORKFLOW_FAILURE',

  GET_STARTED_REQUEST: 'ONBOARDING_GET_STARTED_REQUEST',
  GET_STARTED_SUCCESS: 'ONBOARDING_GET_STARTED_SUCCESS',
  GET_STARTED_FAILURE: 'ONBOARDING_GET_STARTED_FAILURE',

  SUBMIT_QUESTIONNAIRE_REQUEST: 'ONBOARDING_SUBMIT_QUESTIONNAIRE_REQUEST',
  SUBMIT_QUESTIONNAIRE_SUCCESS: 'ONBOARDING_SUBMIT_QUESTIONNAIRE_SUCCESS',
  SUBMIT_QUESTIONNAIRE_FAILURE: 'ONBOARDING_SUBMIT_QUESTIONNAIRE_FAILURE',

  SUBMIT_QUESTIONNAIRE_FILES_REQUEST:
    'ONBOARDING_SUBMIT_QUESTIONNAIRE_FILES_REQUEST',
  SUBMIT_QUESTIONNAIRE_FILES_SUCCESS:
    'ONBOARDING_SUBMIT_QUESTIONNAIRE_FILES_SUCCESS',
  SUBMIT_QUESTIONNAIRE_FILES_FAILURE:
    'ONBOARDING_SUBMIT_QUESTIONNAIRE_FILES_FAILURE',

  DELETE_QUESTIONNAIRE_ANSWER_REQUEST:
    'ONBOARDING_DELETE_QUESTIONNAIRE_ANSWER_REQUEST',
  DELETE_QUESTIONNAIRE_ANSWER_SUCCESS:
    'ONBOARDING_DELETE_QUESTIONNAIRE_ANSWER_SUCCESS',
  DELETE_QUESTIONNAIRE_ANSWER_FAILURE:
    'ONBOARDING_DELETE_QUESTIONNAIRE_ANSWER_FAILURE',

  CONNECT_ACCOUNT_REQUEST: 'ONBOARDING_CONNECT_ACCOUNT_REQUEST',
  CONNECT_ACCOUNT_SUCCESS: 'ONBOARDING_CONNECT_ACCOUNT_SUCCESS',
  CONNECT_ACCOUNT_FAILURE: 'ONBOARDING_CONNECT_ACCOUNT_FAILURE',

  CHANGE_PHONE_NUMBER_REQUEST: 'CHANGE_PHONE_NUMBER_REQUEST',
  CHANGE_PHONE_NUMBER_SUCCESS: 'CHANGE_PHONE_NUMBER_SUCCESS',
  CHANGE_PHONE_NUMBER_FAILURE: 'CHANGE_PHONE_NUMBER_FAILURE',

  GET_BIR_REQUEST: 'ONBOARDING_GET_BIR_REQUEST',
  GET_BIR_SUCCESS: 'ONBOARDING_GET_BIR_SUCCESS',
  GET_BIR_FAILURE: 'ONBOARDING_GET_BIR_FAILURE',

  UPDATE_BIR_REQUEST: 'UPDATE_BIR_REQUEST',
  UPDATE_BIR_SUCCESS: 'UPDATE_BIR_SUCCESS',
  UPDATE_BIR_FAILURE: 'UPDATE_BIR_FAILURE',

  SUBMIT_BIR_REQUEST: 'SUBMIT_BIR_REQUEST',
  SUBMIT_BIR_SUCCESS: 'SUBMIT_BIR_SUCCESS',
  SUBMIT_BIR_FAILURE: 'SUBMIT_BIR_FAILURE',

  UPLOAD_BIR_REQUEST_FILE_REQUEST: 'UPLOAD_BIR_REQUEST_FILE_REQUEST',
  UPLOAD_BIR_REQUEST_FILE_SUCCESS: 'UPLOAD_BIR_REQUEST_FILE_SUCCESS',
  UPLOAD_BIR_REQUEST_FILE_FAILURE: 'UPLOAD_BIR_REQUEST_FILE_FAILURE',

  DELETE_BIR_REQUEST_FILE_REQUEST: 'DELETE_BIR_REQUEST_FILE_REQUEST',
  DELETE_BIR_REQUEST_FILE_SUCCESS: 'DELETE_BIR_REQUEST_FILE_SUCCESS',
  DELETE_BIR_REQUEST_FILE_FAILURE: 'DELETE_BIR_REQUEST_FILE_FAILURE',

  CREATE_PAYMENT_REFERENCE_REQUEST:
    'ONBOARDING_CREATE_PAYMENT_REFERENCE_REQUEST',
  CREATE_PAYMENT_REFERENCE_SUCCESS:
    'ONBOARDING_CREATE_PAYMENT_REFERENCE_SUCCESS',
  CREATE_PAYMENT_REFERENCE_FAILURE:
    'ONBOARDING_CREATE_PAYMENT_REFERENCE_FAILURE',

  INIT_ONLINE_PAYMENT_REQUEST: 'ONBOARDING_INIT_ONLINE_PAYMENT_REQUEST',
  INIT_ONLINE_PAYMENT_SUCCESS: 'ONBOARDING_INIT_ONLINE_PAYMENT_SUCCESS',
  INIT_ONLINE_PAYMENT_FAILURE: 'ONBOARDING_INIT_ONLINE_PAYMENT_FAILURE',

  ADD_PAYMENT_PROOF_REQUEST: 'ADD_PAYMENT_PROOF_REQUEST',
  ADD_PAYMENT_PROOF_SUCCESS: 'ADD_PAYMENT_PROOF_SUCCESS',
  ADD_PAYMENT_PROOF_FAILURE: 'ADD_PAYMENT_PROOF_FAILURE',

  REMOVE_PAYMENT_PROOF_REQUEST: 'REMOVE_PAYMENT_PROOF_REQUEST',
  REMOVE_PAYMENT_PROOF_SUCCESS: 'REMOVE_PAYMENT_PROOF_SUCCESS',
  REMOVE_PAYMENT_PROOF_FAILURE: 'REMOVE_PAYMENT_PROOF_FAILURE',

  SUBMIT_PAYMENT_PROOF_REQUEST: 'ONBOARDING_SUBMIT_PAYMENT_PROOF_REQUEST',
  SUBMIT_PAYMENT_PROOF_SUCCESS: 'ONBOARDING_SUBMIT_PAYMENT_PROOF_SUCCESS',
  SUBMIT_PAYMENT_PROOF_FAILURE: 'ONBOARDING_SUBMIT_PAYMENT_PROOF_FAILURE',

  UPLOAD_RFC_DOCUMENT_REQUEST: 'UPLOAD_RFC_DOCUMENT_REQUEST',
  UPLOAD_RFC_DOCUMENT_SUCCESS: 'UPLOAD_RFC_DOCUMENT_SUCCESS',
  UPLOAD_RFC_DOCUMENT_FAILURE: 'UPLOAD_RFC_DOCUMENT_FAILURE',

  DELETE_RFC_DOCUMENT_REQUEST: 'DELETE_RFC_DOCUMENT_REQUEST',
  DELETE_RFC_DOCUMENT_SUCCESS: 'DELETE_RFC_DOCUMENT_SUCCESS',
  DELETE_RFC_DOCUMENT_FAILURE: 'DELETE_RFC_DOCUMENT_FAILURE',

  INIT_BRAZIL_PAYMENT_REQUEST: 'INIT_BRAZIL_PAYMENT_REQUEST',
  INIT_BRAZIL_PAYMENT_SUCCESS: 'INIT_BRAZIL_PAYMENT_SUCCESS',
  INIT_BRAZIL_PAYMENT_FAILURE: 'INIT_BRAZIL_PAYMENT_FAILURE'
};

export const adminMyCialDomains = {
  production: 'https://admin.cialdnb.com/',
  staging: 'http://staging.admin.cialdnb.com/',
  sandbox: 'http://sandbox.admin.cialdnb.com/',
  development: 'http://localhost:4001/'
};

export const appMyCialDomains = {
  production: 'https://my.cialdnb.com/',
  staging: 'http://staging.my.cialdnb.com/',
  sandbox: 'http://sandbox.my.cialdnb.com/',
  development: 'http://localhost:4002/'
};

export const onboardingLinks = {
  terms: {
    en: 'https://www.cialdnb.com/en/terms-of-use/',
    es: 'https://www.cialdnb.com/es/terminos-de-uso/',
    'pt-BR': 'https://www.cialdnb.com/pt-br/termos-de-uso/'
  },
  privacy: {
    en: 'https://www.cialdnb.com/en/privacy-policy/',
    es: 'https://www.cialdnb.com/es/aviso-de-privacidad/',
    'pt-BR': 'https://www.cialdnb.com/pt-br/politica-de-privacidade/'
  }
};

export const ccsCountries = [
  { title: 'anguilla', code: 'AIA' },
  { title: 'antigua and barbuda', code: 'ATG' },
  { title: 'aruba', code: 'ABW' },
  { title: 'the bahamas', code: 'BHS' },
  { title: 'barbados', code: 'BRB' },
  { title: 'belize', code: 'BLZ' },
  { title: 'bermuda', code: 'BMU' },
  { title: 'bonaire', code: 'BES' },
  { title: 'british virgin islands', code: 'VGB' },
  { title: 'cayman islands', code: 'CYM' },
  { title: 'costa rica', code: 'CRI' },
  { title: 'curacao', code: 'CUW' },
  { title: 'dominica', code: 'DMA' },
  { title: 'dominican republic', code: 'DOM' },
  { title: 'el salvador', code: 'SLV' },
  { title: 'grenada', code: 'GRD' },
  { title: 'guatemala', code: 'GTM' },
  { title: 'haiti', code: 'HTI' },
  { title: 'honduras', code: 'HND' },
  { title: 'jamaica', code: 'JAM' },
  { title: 'montserrat', code: 'MSR' },
  { title: 'nicaragua', code: 'NIC' },
  { title: 'panama', code: 'PAN' },
  { title: 'saint kitts and nevis', code: 'KNA' },
  { title: 'saint lucia', code: 'LCA' },
  { title: 'saint vincent and the grenadines', code: 'VCT' },
  { title: 'sint maarten', code: 'SXM' },
  { title: 'trinidad and tobago', code: 'TTO' },
  { title: 'turks and caicos islands', code: 'TCA' },
  { title: 'guyana', code: 'GUY' },
  { title: 'suriname', code: 'SUR' },
  { title: 'venezuela', code: 'VEN' }
];

export const cialCountries = [
  { title: 'mexico', code: 'MEX' },
  { title: 'brazil', code: 'BRA' },
  { title: 'argentina', code: 'ARG' },
  { title: 'paraguay', code: 'PRY' },
  { title: 'uruguay', code: 'URY' },
  { title: 'chile', code: 'CHL' },
  { title: 'bolivia', code: 'BOL' },
  { title: 'bolivia', code: 'BOL' },
  { title: 'ecuador', code: 'ECU' },
  { title: 'peru', code: 'PER' }
];

export const statesMapping = {
  mexico: [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Chiapas',
    'Chihuahua',
    'Ciudad de México',
    'Coahuila de Zaragoza',
    'Colima',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Michoacán de Ocampo',
    'Morelos',
    'México',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz de Ignacio de la Llave',
    'Yucatán',
    'Zacatecas'
  ],
  brazil: [
    'Acre (AC)',
    'Alagoas (AL)',
    'Amapá (AP)',
    'Amazonas (AM)',
    'Bahia (BA)',
    'Ceará (CE)',
    'Distrito Federal (DF)',
    'Espírito Santo (ES)',
    'Goiás (GO)',
    'Maranhão (MA)',
    'Mato Grosso (MT)',
    'Mato Grosso do Sul (MS)',
    'Minas Gerais (MG)',
    'Pará (PA)',
    'Paraíba (PB)',
    'Paraná (PR)',
    'Pernambuco (PE)',
    'Piauí (PI)',
    'Rio de Janeiro (RJ)',
    'Rio Grande do Norte (RN)',
    'Rio Grande do Sul (RS)',
    'Rondônia (RO)',
    'Roraima (RR)',
    'Santa Catarina (SC)',
    'São Paulo (SP)',
    'Sergipe (SE)',
    'Tocantins (TO)'
  ],
  peru: [
    'Amazonas',
    'Apurímac',
    'Arequipa',
    'Ayacucho',
    'Cajamarca',
    'Callao',
    'Cusco',
    'Huancavelica',
    'Huánuco',
    'Ica',
    'Junín',
    'La Libertad',
    'Lambayeque',
    'Lima',
    'Loreto',
    'Madre de Dios',
    'Moquegua',
    'Pasco',
    'Piura',
    'Puno',
    'San Martín',
    'Tacna',
    'Tumbes',
    'Ucayali',
    'Áncash'
  ],
  argentina: [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Ciudad Autónoma de Buenos Aires',
    'Corrientes',
    'Córdoba',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego',
    'Tucumán'
  ]
};

export const taxTypeMapping = {
  argentina: 'cuit',
  mexico: 'rfc',
  peru: 'ruc',
  brazil: 'cnpj'
};

export default onboardingConstants;
