import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { riskIndicators, racRiskIndicators } from "react-common/constants";
import { Localize } from "react-common/services";

import "./StatisticsRiskIndicator.css";

const dataset = {
  labels: [],
  datasets: [
    {
      data: [],
    },
  ],
};

const riskColor = {
  1: "#32AA67",
  2: "#69C568",
  3: "#F9C341",
  4: "#EC665F",
  S: "#0078B0",
  "-": "#BCC9DB",
};

const racRiskColor = {
  1: "#32aa67",
  2: "#69c568",
  3: "#7BDC7A",
  4: "#f9c341",
  5: "#ec665f",
  6: "#B54039",
  "-": "#bcc9db",
};

const legend = {
  display: true,
  position: "left",
  fullWidth: false,
  reverse: false,
  labels: {
    fontColor: "#333333",
    fontSize: 14,
    boxWidth: 15,
    fontFamily: "'Open Sans'",
    padding: 16,
  },
};

const options = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const loadDataset = (data, useRacRatings) => {
  dataset.labels = data.map(
    (stat) =>
      Localize.translate(
        (useRacRatings ? racRiskIndicators : riskIndicators)[stat[0]]
      ) + " (%)"
  );
  dataset.datasets[0].data = data.map((stat) => {
    // Show values between 0 and 1 with first visible digit (i.e. 0.0123 = 0.01, 0.0000567 = 0.00006)
    if (stat[1] < 1) {
      return stat[1].toFixed(1 - Math.floor(Math.log(stat[1]) / Math.log(10)));
    }
    // Show bigger values with 1 decimal place
    return stat[1].toFixed(1);
  });
  dataset.datasets[0].backgroundColor = dataset.datasets[0].hoverBackgroundColor = data.map(
    (stat) => (useRacRatings ? racRiskColor : riskColor)[stat[0]]
  );
};

const loadEmptyDataset = () => {
  dataset.labels = [
    "Minimal risk (%)",
    "Low risk (%)",
    "Slightly greater than the average risk (%)",
    "Meaningful risk (%)",
    "Risk not assigned due to business nature (%)",
    "Unknown risk (%)",
  ].map((riskIndicator) => Localize.translate(riskIndicator));
  dataset.datasets[0].data = [0, 0, 0, 0, 0, 100];
  dataset.datasets[0].backgroundColor = dataset.datasets[0].hoverBackgroundColor = [
    "#32AA67",
    "#69C568",
    "#F9C341",
    "#EC665F",
    "#0078B0",
    "#BCC9DB",
  ];
};

const loadEmptyRacDataset = () => {
  dataset.labels = [
    "Minimum risk (%)",
    "Low risk (%)",
    "Medium risk (%)",
    "Risk above average (%)",
    "Significant risk (%)",
    "High risk (%)",
    "Unknown risk (%)",
  ].map((riskIndicator) => Localize.translate(riskIndicator));
  dataset.datasets[0].data = [0, 0, 0, 0, 0, 0, 100];
  dataset.datasets[0].backgroundColor = dataset.datasets[0].hoverBackgroundColor = [
    "#32aa67",
    "#69c568",
    "#7BDC7A",
    "#f9c341",
    "#ec665f",
    "#B54039",
    "#bcc9db",
  ];
};

const StatisticsRiskIndicator = ({
  data,
  className,
  useRacRatings,
  onClick,
}) => {
  const isClickable = data && data.length && onClick;

  if (data && data.length) {
    loadDataset(data, useRacRatings);
  } else {
    if (useRacRatings) {
      loadEmptyRacDataset();
    } else {
      loadEmptyDataset();
    }
  }

  if (isClickable) {
    options.onHover = (event, chartElement) =>
      (event.target.style.cursor = chartElement[0] ? "pointer" : "default");
  } else {
    delete options.onHover;
  }

  return (
    <div className={className || "col-sm-12 col-lg-6"}>
      <div className="Statistics-content Statistics-content-row">
        <h3 className="Statistics-title">
          {useRacRatings ? "By SQR Risk" : "By Risk Indicator"}
        </h3>
        <div className="row">
          <div className="col-xs-12 StatisticsRiskIndicator-doughnut">
            <Doughnut
              data={dataset}
              legend={legend}
              options={options}
              width={500}
              height={280}
              onElementsClick={(elements) => {
                if (isClickable) {
                  const elementIndex = get(elements, "[0]._index");
                  if (elementIndex !== undefined && data[elementIndex]) {
                    if (data[elementIndex][0] === "-" && useRacRatings) {
                      onClick([
                        "NB4",
                        "N",
                        "NB5",
                        "NB6",
                        "S",
                        "NQ",
                        "BR",
                        "FB",
                        "--",
                      ]);
                      return;
                    }
                    onClick(data[elementIndex][0]);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StatisticsRiskIndicator.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
};

export default StatisticsRiskIndicator;
