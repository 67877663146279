import includes from 'lodash/includes';
import get from 'lodash/get';
import {
  financialStrengthOrder,
  racRiskIndicatorsOrder,
  riskIndicatorsOrder
} from 'react-common/constants';
import { Util } from 'react-common/services';

import workflowsConstants, {
  commercialScoreOrder
} from '../_constants/workflows';
import onboardingConstants from '../_constants/onboarding';
import userConstants from '../_constants/user';
import downloadConstants from '../_constants/download';

let initialWorkflowsState = {
  isFetching: false,
  isSubmitting: false,
  isDownloading: false,
  uploadingFiles: [],
  suppliers: {
    getById: {}
  },
  getById: {},
  statistics: {
    workflows: {},
    suppliers: {},
    isFetching: false
  },
  error: ''
};

export function workflows(state = initialWorkflowsState, action) {
  switch (action.type) {
    case workflowsConstants.GET_ALL_REQUEST:
    case workflowsConstants.GET_REQUEST:
    case workflowsConstants.CREATE_REQUEST:
    case workflowsConstants.ACTIVATE_REQUEST:
    case workflowsConstants.DELETE_DRAFT_REQUEST:
    case workflowsConstants.CLOSE_WORKFLOW_REQUEST:
    case onboardingConstants.GET_WORKFLOW_REQUEST:
    case onboardingConstants.GET_STARTED_REQUEST:
    case onboardingConstants.SUBMIT_PAYMENT_PROOF_REQUEST:
    case onboardingConstants.CONNECT_ACCOUNT_REQUEST:
    case onboardingConstants.GET_BIR_REQUEST:
    case onboardingConstants.SUBMIT_BIR_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case workflowsConstants.UPDATE_REQUEST:
      return {
        ...state,
        isFetching: action.showLoader
      };
    case workflowsConstants.WORKFLOW_STATISTICS_REQUEST:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          isFetching: action.showLoader
        }
      };
    case workflowsConstants.ADD_ATTACHMENT_QUESTION_REQUEST:
    case onboardingConstants.SUBMIT_QUESTIONNAIRE_FILES_REQUEST:
    case onboardingConstants.UPLOAD_BIR_REQUEST_FILE_REQUEST:
    case onboardingConstants.ADD_PAYMENT_PROOF_REQUEST:
    case onboardingConstants.UPLOAD_RFC_DOCUMENT_REQUEST:
    case workflowsConstants.ADD_FILE_SUPPLIER_REQUEST:
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.concat(action.files)
      };
    case workflowsConstants.GET_ALL_FAILURE:
    case workflowsConstants.GET_FAILURE:
    case workflowsConstants.CREATE_FAILURE:
    case workflowsConstants.UPDATE_FAILURE:
    case workflowsConstants.ACTIVATE_FAILURE:
    case workflowsConstants.DELETE_DRAFT_FAILURE:
    case workflowsConstants.CLOSE_WORKFLOW_FAILURE:
    case workflowsConstants.ADD_SUPPLIER_FAILURE:
    case workflowsConstants.ADD_SUPPLIERS_BULK_FAILURE:
    case workflowsConstants.DELETE_SUPPLIER_FAILURE:
    case workflowsConstants.UPDATE_SUPPLIER_FAILURE:
    case workflowsConstants.REQUEST_BIR_REPORT_FAILURE:
    case workflowsConstants.REQUEST_CCR_REPORT_FAILURE:
    case workflowsConstants.CHANGE_SUPPLIER_STATUS_FAILURE:
    case workflowsConstants.SEND_SUPPLIER_REMINDER_FAILURE:
    case workflowsConstants.DELETE_FILE_SUPPLIER_FAILURE:
    case downloadConstants.DOWNLOAD_FAILURE:
    case onboardingConstants.GET_WORKFLOW_FAILURE:
    case onboardingConstants.GET_STARTED_FAILURE:
    case onboardingConstants.SUBMIT_QUESTIONNAIRE_FAILURE:
    case onboardingConstants.DELETE_QUESTIONNAIRE_ANSWER_FAILURE:
    case onboardingConstants.ADD_PAYMENT_PROOF_FAILURE:
    case onboardingConstants.REMOVE_PAYMENT_PROOF_FAILURE:
    case onboardingConstants.UPLOAD_RFC_DOCUMENT_FAILURE:
    case onboardingConstants.DELETE_RFC_DOCUMENT_FAILURE:
    case onboardingConstants.SUBMIT_PAYMENT_PROOF_FAILURE:
    case onboardingConstants.CONNECT_ACCOUNT_FAILURE:
    case onboardingConstants.CHANGE_PHONE_NUMBER_FAILURE:
    case onboardingConstants.DELETE_BIR_REQUEST_FILE_FAILURE:
    case onboardingConstants.UPDATE_BIR_FAILURE:
    case onboardingConstants.SUBMIT_BIR_FAILURE:
    case onboardingConstants.CREATE_PAYMENT_REFERENCE_FAILURE:
    case onboardingConstants.INIT_ONLINE_PAYMENT_FAILURE:
    case onboardingConstants.INIT_BRAZIL_PAYMENT_FAILURE:
      return {
        ...state,
        error: get(action, 'error.error', action.error),
        isFetching: false,
        isSubmitting: false
      };

    case workflowsConstants.SCREEN_SUPPLIER_FAILURE:
      return {
        ...state,
        error:
          action.error && action.error.error
            ? action.error.error
            : action.error,
        isFetching: false,
        isSubmitting: false,
        suppliers: {
          getById: {
            ...state.suppliers.getById,
            [action.supplier._id]: action.supplier
          }
        }
      };
    case onboardingConstants.SUBMIT_QUESTIONNAIRE_FILES_FAILURE:
    case onboardingConstants.ADD_ATTACHMENT_QUESTION_FAILURE:
    case onboardingConstants.UPLOAD_BIR_REQUEST_FILE_FAILURE:
    case workflowsConstants.ADD_FILE_SUPPLIER_FAILURE:
      return {
        ...state,
        error: get(action, 'error.error', action.error),
        isFetching: false,
        uploadingFiles: state.uploadingFiles.filter(
          file => !includes(action.files, file)
        )
      };
    case workflowsConstants.WORKFLOW_STATISTICS_FAILURE:
      return {
        ...state,
        error: get(action, 'error.error', action.error),
        statistics: {
          ...state.statistics,
          isFetching: false
        }
      };
    case workflowsConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        getById: {
          ...state.getById,
          ...action.response.workflows
        },
        suppliers: {
          getById: {
            ...state.suppliers.getById,
            ...action.response.suppliers
          }
        }
      };
    case workflowsConstants.GET_SUCCESS:
    case workflowsConstants.CREATE_SUCCESS:
    case workflowsConstants.UPDATE_SUCCESS:
    case workflowsConstants.ACTIVATE_SUCCESS:
    case workflowsConstants.CLOSE_WORKFLOW_SUCCESS:
    case workflowsConstants.ADD_SUPPLIER_SUCCESS:
    case workflowsConstants.ADD_SUPPLIERS_BULK_SUCCESS:
    case workflowsConstants.DELETE_FILE_SUPPLIER_SUCCESS:
    case workflowsConstants.REQUEST_CCR_REPORT_SUCCESS:
    case workflowsConstants.SCREEN_SUPPLIER_SUCCESS:
    case onboardingConstants.GET_WORKFLOW_SUCCESS:
    case onboardingConstants.GET_STARTED_SUCCESS:
    case onboardingConstants.SUBMIT_QUESTIONNAIRE_SUCCESS:
    case onboardingConstants.DELETE_QUESTIONNAIRE_ANSWER_SUCCESS:
    case onboardingConstants.SUBMIT_PAYMENT_PROOF_SUCCESS:
    case onboardingConstants.CONNECT_ACCOUNT_SUCCESS:
    case onboardingConstants.CHANGE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSubmitting: false,
        getById: {
          ...state.getById,
          ...action.response.workflows
        },
        suppliers: {
          getById: {
            ...state.suppliers.getById,
            ...action.response.suppliers
          }
        },
        error: ''
      };
    case onboardingConstants.SUBMIT_QUESTIONNAIRE_FILES_SUCCESS:
    case workflowsConstants.ADD_ATTACHMENT_QUESTION_SUCCESS:
    case workflowsConstants.ADD_FILE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        uploadingFiles: state.uploadingFiles.filter(
          file => !includes(action.files, file)
        ),
        getById: {
          ...state.getById,
          ...action.response.workflows
        },
        suppliers: {
          getById: {
            ...state.suppliers.getById,
            ...action.response.suppliers
          }
        },
        error: ''
      };

    case workflowsConstants.DELETE_DRAFT_SUCCESS:
      let newState = {
        ...state,
        isFetching: false,
        error: ''
      };
      delete newState.getById[action.workflowId];
      return newState;

    case workflowsConstants.WORKFLOW_STATISTICS_SUCCESS:
      const {
        statistics: { workflows, suppliers },
        activities
      } = action;
      return {
        ...state,
        statistics: {
          workflows: { ...workflows },
          suppliers: {
            status: { ...suppliers.status },
            country: Util.roundSum(Util.countToPercent(suppliers.country, 5)),
            commercialScore: Util.countToPercent(
              suppliers.commercialScore,
              undefined,
              Util.sortByArrayIndex(commercialScoreOrder)
            ),
            financialStrength: Util.roundSum(
              Util.countToPercent(
                suppliers.financialStrength,
                5,
                Util.sortByArrayIndex(financialStrengthOrder)
              )
            ),
            industry: Util.roundSum(Util.countToPercent(suppliers.industry, 3)),
            riskIndicator: Util.countToPercent(
              suppliers.riskIndicator,
              undefined,
              Util.sortByArrayIndex(riskIndicatorsOrder)
            ),
            racRiskIndicator: Util.countToPercent(
              suppliers.racRiskIndicator,
              undefined,
              Util.sortByArrayIndex(racRiskIndicatorsOrder)
            ),
            dnbRating: Util.roundSum(
              Util.countToPercent(
                suppliers.dnbRating,
                5,
                Util.sortDnbRating(financialStrengthOrder, riskIndicatorsOrder)
              )
            )
          },
          activities,
          isFetching: false
        },
        error: ''
      };
    case workflowsConstants.REQUEST_BIR_REPORT_SUCCESS:
      const stateCopy = { ...state };
      stateCopy.suppliers.getById[action.supplier._id] = action.supplier;
      stateCopy.suppliers.getById[action.supplier._id].report = {
        bir_request_id: action.birRequest._id
      };
      return stateCopy;

    case workflowsConstants.DELETE_SUPPLIER_SUCCESS:
      newState = { ...state };
      delete newState.suppliers.getById[action.supplierId];
      newState.getById[action.workflowId].suppliers = newState.getById[
        action.workflowId
      ].suppliers.filter(supplier => supplier !== action.supplierId);
      return newState;

    case workflowsConstants.CHANGE_SUPPLIER_STATUS_SUCCESS:
    case workflowsConstants.UPDATE_SUPPLIER_SUCCESS:
    case workflowsConstants.SEND_SUPPLIER_REMINDER_SUCCESS:
    case workflowsConstants.SET_TAGS_TO_SUPPLIER:
    case onboardingConstants.GET_BIR_SUCCESS:
    case onboardingConstants.REMOVE_PAYMENT_PROOF_SUCCESS:
    case onboardingConstants.DELETE_RFC_DOCUMENT_SUCCESS:
    case onboardingConstants.UPDATE_BIR_SUCCESS:
    case onboardingConstants.SUBMIT_BIR_SUCCESS:
    case onboardingConstants.CREATE_PAYMENT_REFERENCE_SUCCESS:
    case workflowsConstants.SCREEN_SUPPLIER_REQUEST:
    case onboardingConstants.INIT_ONLINE_PAYMENT_SUCCESS:
    case onboardingConstants.INIT_BRAZIL_PAYMENT_SUCCESS:
    case onboardingConstants.DELETE_BIR_REQUEST_FILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        suppliers: {
          getById: {
            ...state.suppliers.getById,
            [action.supplier._id]: action.supplier
          }
        },
        error: ''
      };

    case onboardingConstants.ADD_PAYMENT_PROOF_SUCCESS:
    case onboardingConstants.UPLOAD_RFC_DOCUMENT_SUCCESS:
    case onboardingConstants.UPLOAD_BIR_REQUEST_FILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        suppliers: {
          getById: {
            ...state.suppliers.getById,
            [action.supplier._id]: action.supplier
          }
        },
        uploadingFiles: state.uploadingFiles.filter(
          file => !includes(action.files, file)
        ),
        error: ''
      };

    case userConstants.LOGOUT:
      return {
        ...initialWorkflowsState
      };
    case downloadConstants.DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloading: true
      };
    case downloadConstants.DOWNLOAD_SUCCESS:
      return {
        ...state,
        isDownloading: false
      };
    case onboardingConstants.SUBMIT_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        isSubmitting: true
      };
    //    do not return isFetching: true for these requests otherwise the UX gets screwed-up
    //    TODO - add another type of loader (maybe an overlay loader) for requests like this that do incremental changes
    case workflowsConstants.ADD_SUPPLIER_REQUEST:
    case workflowsConstants.ADD_SUPPLIERS_BULK_REQUEST:
    case workflowsConstants.DELETE_SUPPLIER_REQUEST:
    case workflowsConstants.UPDATE_SUPPLIER_REQUEST:
    case workflowsConstants.REQUEST_BIR_REPORT_REQUEST:
    case workflowsConstants.REQUEST_CCR_REPORT_REQUEST:
    case onboardingConstants.CHANGE_PHONE_NUMBER_REQUEST:
    case onboardingConstants.CHANGE_SUPPLIER_STATUS_REQUEST:
    case onboardingConstants.SEND_SUPPLIER_REMINDER_REQUEST:
    case onboardingConstants.CREATE_PAYMENT_REFERENCE_REQUEST:
    case onboardingConstants.INIT_ONLINE_PAYMENT_REQUEST:
    case onboardingConstants.INIT_BRAZIL_PAYMENT_REQUEST:
    case onboardingConstants.DELETE_QUESTIONNAIRE_ANSWER_REQUEST:
    case onboardingConstants.UPDATE_BIR_REQUEST:
    case onboardingConstants.DELETE_BIR_REQUEST_FILE_REQUEST:
    case onboardingConstants.DELETE_FILE_SUPPLIER_REQUEST:
    case onboardingConstants.REMOVE_PAYMENT_PROOF_REQUEST:
    case onboardingConstants.DELETE_RFC_DOCUMENT_REQUEST:
    default:
      return state;
  }
}
