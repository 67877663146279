import React from 'react';
import { FileDescriptor, FileUpload } from 'react-common/components';

import InternationalArgentinaInfo from './components/InternationalArgentinaInfo';
import InternationalCcsInfo from './components/InternationalCcsInfo';
import InternationalMexicoInfo from './components/InternationalMexicoInfo';
import InternationalPeruInfo from './components/InternationalPeruInfo';

import LocalMexicoInfo from './components/LocalMexicoInfo';
import LocalPeruInfo from './components/LocalPeruInfo';
import LocalCcsInfo from './components/LocalCcsInfo';
import LocalArgentinaInfo from './components/LocalArgentinaInfo';

const bankNameMapping = {
  mexico: 'BBVA Bancomer, S.A.',
  peru: 'BBVA Continental',
  ccs: 'JP Morgan Chase',
  argentina:
    'Industrial and Commercial Bank of China (Argentina) S.A Casa Central'
};

const bankRecipientMapping = {
  mexico: 'Dun & Bradstreet de Mexico, S.A. de C.V.',
  peru: 'Dun & Bradstreet S.A.C',
  ccs: 'Cial D&B Services Inc',
  argentina: 'DUN Y BRADSTREET SA'
};

const OnboardingSupplierPaymentProof = ({
  supplier,
  addPaymentProof,
  removePaymentProof,
  submitPaymentProof,
  formError,
  paymentRegion,
  localMarket,
  uploadingFiles,
  initialValues
}) => (
  <div className="row OnboardingSupplierPaymentProof">
    <div className="col-xs-12 col-sm-12 col-md-6 Payment">
      <h1>Payment</h1>
      {paymentRegion === 'mexico' && <LocalMexicoInfo />}

      {paymentRegion === 'peru' && <LocalPeruInfo />}

      {paymentRegion === 'ccs' && <LocalCcsInfo />}

      {paymentRegion === 'argentina' && <LocalArgentinaInfo />}

      {paymentRegion === 'international' && (
        <>
          {localMarket === 'argentina' && (
            <InternationalArgentinaInfo taxId={initialValues.general.tax_id} />
          )}
          {localMarket === 'mexico' && (
            <InternationalMexicoInfo taxId={initialValues.general.tax_id} />
          )}
          {localMarket === 'ccs' && (
            <InternationalCcsInfo taxId={initialValues.general.tax_id} />
          )}
          {localMarket === 'peru' && (
            <InternationalPeruInfo taxId={initialValues.general.tax_id} />
          )}
        </>
      )}
    </div>

    <div className="col-xs-12 col-sm-12 col-md-6">
      <h5 className="PaymentReference">Payment Reference</h5>
      <p>
        <label>Person who should receive invoice:</label>{' '}
        <var>{supplier.getPaymentPersonName()}</var>
      </p>
      <p>
        <label>Price:</label>{' '}
        <var
        >{`${supplier.getPaymentPrice()} ${supplier.getPaymentCurrency()}`}</var>
      </p>
      <p>
        <label>Bank:</label>{' '}
        <var>
          {bankNameMapping[paymentRegion] || bankNameMapping[localMarket]}
        </var>
      </p>
      <p>
        <label>Recipient:</label>{' '}
        <var>
          {bankRecipientMapping[paymentRegion] ||
            bankRecipientMapping[localMarket]}
        </var>
      </p>
      {paymentRegion === 'mexico' && (
        <>
          <p>
            <label>CIE Account:</label> <var>64771</var>
          </p>
          <p>
            <label>Reference Number:</label>{' '}
            <var>{supplier.getPaymentReference()}</var>
          </p>
          <p>
            <label>CIE Concept:</label>{' '}
            <var>{supplier.getPaymentCompanyName()}</var>
          </p>
        </>
      )}

      <h5 className="PaymentProof">
        Upload payment proof<span notranslate="true">*</span>
      </h5>

      {supplier
        .getPaymentProofs()
        .map(file => (
          <FileDescriptor
            key={file._id}
            file={file}
            onDelete={() => removePaymentProof(file._id)}
          />
        ))}

      <FileUpload
        onChange={addPaymentProof}
        isUploading={uploadingFiles.length > 0}
        fieldName="paymentProof"
        buttonText="Add file"
        buttonClassName="Btn-outline-small Btn-outline-primary"
      />

      {formError && (
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger alert-dismissible" role="alert">
              {formError}
            </div>
          </div>
        </div>
      )}

      <button
        className="Btn-default Btn-default-primary Btn-submit"
        onClick={submitPaymentProof}
        disabled={
          !supplier.getPaymentProofs().length || uploadingFiles.length > 0
        }
      >
        Confirm payment
      </button>
    </div>
  </div>
);

export default OnboardingSupplierPaymentProof;
