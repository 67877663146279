import PropTypes from 'prop-types';
import React from 'react';

import './StatisticsCount.css';

const StatisticsCount = ({ data = {}, title, className, onClick }) => {
  const keys = Object.keys(data);
  const labels = keys.map(key => key.replace(/_|-/g, ' '));

  return (
    <div className={className || 'col-sm-12 col-lg-6'}>
      <div className="Statistics-content">
        <h3 className="Statistics-title">{title}</h3>

        <div className="row">
          {keys.map((key, index) => (
            <div
              key={index}
              className={
                'col-xs-4' +
                (onClick ? ' Statistics-clickable' : '') +
                (index !== keys.length - 1 ? ' Statistics-divider' : '')
              }
              onClick={() => onClick && onClick(key)}
            >
              <div className="Statistics-number" notranslate="true">
                {data[key]}
              </div>
              <div className="Statistics-state">{labels[index]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

StatisticsCount.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string
};

export default StatisticsCount;
