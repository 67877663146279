import React from 'react';
import { Field, reduxForm } from 'redux-form';

const WorkflowsSuppliersAddBulkForm = ({ handleSubmit, workflow }) => {
  let placeholder =
    'Company Name; Full Name; Contact Emails; Country; Tax ID; Full address; SIC; Phone';

  if (workflow.isCfrReport()) {
    placeholder += '; D-U-N-S Number; Shareholders';
  } else if (workflow.isInvestigating()) {
    placeholder += '; D-U-N-S Number';
  }

  return (
    <form onSubmit={handleSubmit} className="WorkflowsSuppliersAddBulk">
      <p>
        Add multiple suppliers in batch form. Copy and paste values separated by
        semi-colon (;) into the box below to bulk upload (one company per line).<br />
        Suppliers should match this format: <b>{placeholder}</b>
      </p>
      <Field
        type="text"
        name="suppliersData"
        className="form-control"
        component="textarea"
        placeholder={placeholder}
      />
      <button
        className="Btn-default-small Btn-default-primary BulkAdd-btn"
        type="submit"
      >
        Bulk add
      </button>
    </form>
  );
};

const WorkflowsSuppliersAddBulk = reduxForm({
  form: 'add-suppliers-bulk'
})(WorkflowsSuppliersAddBulkForm);

export default WorkflowsSuppliersAddBulk;
