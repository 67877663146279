import { Enums } from 'react-common/services';
import enumsConstants from '../_constants/enums';

function getAllEnumsRequest() {
  return {
    type: enumsConstants.GET_ALL_REQUEST
  };
}

function getAllEnumsSuccess(enums) {
  return {
    type: enumsConstants.GET_ALL_SUCCESS,
    enums
  };
}

function getAllEnumsFailure(error) {
  return {
    type: enumsConstants.GET_ALL_FAILURE,
    error
  };
}

export function getEnums() {
  return dispatch => {
    dispatch(getAllEnumsRequest());
    return Enums.getEnums()
      .then(json => dispatch(getAllEnumsSuccess(json)))
      .catch(err => dispatch(getAllEnumsFailure(err)));
  };
}
