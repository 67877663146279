import get from 'lodash/get';
import merge from 'lodash/merge';
import {
  workflowProducts,
  workflowReportTypes,
  workflowTypes,
  workflowPayment,
  workflowStatuses
} from 'react-common/constants';

export class Workflow {
  constructor(workflow = {}) {
    merge(this, workflow);
  }

  getId() {
    return this._id;
  }

  getTitle() {
    return get(this, 'info.title');
  }

  getWelcomeMessage() {
    return get(this, 'info.welcome_message');
  }

  getDescription() {
    return get(this, 'info.description');
  }

  getCover() {
    return get(this, 'info.cover');
  }

  getCompanyName() {
    return get(this, 'info.company_name');
  }

  getCompanyCommercialName() {
    return this.getCompanyName() || this.getOwnerCompanyName();
  }

  getType() {
    return this.type;
  }

  isOnboarding() {
    return this.getType() === workflowTypes.ONBOARDING;
  }

  isInvestigating() {
    return this.getType() === workflowTypes.INVESTIGATING;
  }

  getReportType() {
    return this.report_type;
  }

  isScoreProduct() {
    return this.getProduct() === workflowProducts.SCORE;
  }

  isReportProduct() {
    return this.getProduct() === workflowProducts.REPORT;
  }

  isBirReport() {
    return (
      this.isReportProduct() && this.getReportType() === workflowReportTypes.BIR
    );
  }

  isCkrReport() {
    return (
      this.isReportProduct() &&
      (this.getReportType() === workflowReportTypes.SHORT_FORM ||
        this.getReportType() === workflowReportTypes.SHORT_FORM_PLUS)
    );
  }

  isRacReport() {
    return (
      this.isReportProduct() && this.getReportType() === workflowReportTypes.RAC
    );
  }

  isRafReport() {
    return (
      this.isReportProduct() && this.getReportType() === workflowReportTypes.RAF
    );
  }

  isRafOrRacReport() {
    return this.isRafReport() || this.isRacReport();
  }

  isSqrReport() {
    return (
      this.isReportProduct() && this.getReportType() === workflowReportTypes.SQR
    );
  }

  isQuestionnaireReport() {
    return (
      this.isReportProduct() &&
      this.getReportType() === workflowReportTypes.QUESTIONNAIRE
    );
  }

  isCcrReport() {
    return (
      this.isReportProduct() && this.getReportType() === workflowReportTypes.CCR
    );
  }

  isCfrReport() {
    return (
      this.isReportProduct() && this.getReportType() === workflowReportTypes.CFR
    );
  }

  isHomFalabellaReport() {
    return (
      this.isReportProduct() &&
      (this.getReportType() === workflowReportTypes.HOM_FALLLABELLLA ||
        this.getReportType() === workflowReportTypes.HOM_FALLLABELLLA_MID)
    );
  }

  getStatus() {
    return this.status;
  }

  isActive() {
    return this.getStatus() === workflowStatuses.ACTIVE;
  }

  isDraft() {
    return this.getStatus() === workflowStatuses.DRAFT;
  }

  isClosed() {
    return this.getStatus() === workflowStatuses.CLOSED;
  }

  getProduct() {
    return this.product;
  }

  getPayment() {
    return this.payment;
  }

  isClientPaying() {
    return this.getPayment() === workflowPayment.CLIENT;
  }

  isSupplierPaying() {
    return this.getPayment() === workflowPayment.SUPPLIER;
  }

  getPricePerSupplier() {
    return this.price_per_supplier;
  }

  getQuestionnaire() {
    return this.questionnaire;
  }

  hasQuestionnaire() {
    const questionnaire = this.getQuestionnaire();
    return questionnaire && questionnaire.length > 0;
  }

  getSuppliers() {
    return this.suppliers || [];
  }

  getLaunchedAt() {
    return this.launched_at;
  }

  getClosedAt() {
    return this.closed_at;
  }

  getOwner() {
    return this.owner;
  }

  getOwnerCompanyName() {
    return get(this, 'owner.company.name');
  }

  getOwnerCompanyImage() {
    return get(this, 'owner.company.image');
  }
}
