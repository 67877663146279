import React, { Component } from 'react';
import { Panel, PanelGroup } from 'react-bootstrap';
import { DunsAbbr, MomentDate, Tooltip } from 'react-common/components';
import {
  financialStrengthClasses,
  racRiskIndicatorsClasses,
  languages
} from 'react-common/constants';
import { Localize } from 'react-common/services';

import {
  ccrScoreClasses,
  paydexGroupClasses,
  INFORMATION_UNAVAILABLE,
  reportTypeToName
} from '../../../_constants/workflows';
import WorkflowsService from '../../../_services/Workflows';

class WorkflowsSupplierDetailsReport extends Component {
  render() {
    const {
      workflow,
      supplier,
      user,
      requestBir,
      requestCcr,
      showCcrModal,
      showRatingsModal,
      showPaydexModal,
      downloadReportFiles
    } = this.props;
    const ccrProduct = user.getCcrProduct();
    const ccrProductEnabled = !!ccrProduct.enabled;
    const ccrLimitReached = ccrProduct.used >= ccrProduct.total;
    const reportName = reportTypeToName[workflow.getReportType()];

    const reportNotRequested =
      !supplier.getBirRequestId() && supplier.isCompleted();
    const reportPending =
      (supplier.getBirRequestId() || !supplier.isCompleted()) &&
      !supplier.hasBirFiles();
    const reportDelivered =
      supplier.getBirRequestId() &&
      supplier.isCompleted() &&
      supplier.hasBirFiles();

    const racRiskIndicatorClass = supplier.getRacRiskIndicator()
      ? racRiskIndicatorsClasses[supplier.getRacRiskIndicator()] ||
        racRiskIndicatorsClasses['-']
      : '';

    return (
      <PanelGroup
        id="supplier-details-report-accordion"
        accordion
        defaultActiveKey="1"
      >
        <Panel
          className="WorkflowsCreate-panel Screen-panel Bir-report-group"
          eventKey="1"
        >
          <Panel.Heading>
            <Panel.Title toggle>
              <div className="WorkflowsSupplierDetails-title-table no-border">
                {reportNotRequested && (
                  <>
                    <span className="WorkflowsSupplierDetails-title-table-heading">
                      {reportName} Report
                    </span>
                    <span className="WorkflowsSupplierDetails-status-label">
                      Not requested
                    </span>
                  </>
                )}
                {reportPending && (
                  <>
                    <span className="WorkflowsSupplierDetails-title-table-heading">
                      {reportName} Report
                    </span>
                    <span className="WorkflowsSupplierDetails-status-label">
                      Pending
                    </span>
                  </>
                )}

                {reportDelivered && (
                  <>
                    <div>
                      <div className="WorkflowsSupplierDetails-title-table-heading">
                        {reportName} Report
                      </div>
                      <div className="WorkflowsSupplierDetails-last-update ScreenCheckDate">
                        Last Updated:&nbsp;
                        {supplier.getDeliveryDate() ? (
                          <MomentDate
                            format="MMMM DD, YYYY"
                            locale={
                              languages[Localize.getLanguage()].uploadcare
                            }
                            date={supplier.getDeliveryDate()}
                            timezone="UTC"
                          />
                        ) : (
                          INFORMATION_UNAVAILABLE
                        )}
                      </div>
                    </div>

                    <Tooltip
                      text={`Download full ${reportName} report`}
                      position="bottom"
                      id="supplier-details-ccr"
                      className="Title-tooltip"
                    >
                      <button
                        className="Btn-default-small Btn-default-primary Btn-download-report"
                        onClick={e => {
                          e.stopPropagation();
                          downloadReportFiles();
                        }}
                      >
                        Download
                      </button>
                    </Tooltip>
                  </>
                )}
              </div>
              <span className="pull-right">
                <i className="icon-ic_expand-arrow" />
              </span>
            </Panel.Title>
          </Panel.Heading>

          <Panel.Collapse>
            <Panel.Body>
              <div className="row">
                <div className="WorkflowsSupplierDetails-results-data">
                  <div className="WorkflowsSupplierDetails-content-table Bir-content-table">
                    <div className="row">
                      {reportNotRequested && (
                        <div className="Not-requested-state">
                          <p className="text-center WorkflowsSupplierDetails-bir-alert">
                            You haven’t requested a {reportName} for this
                            company yet. <br />
                            Requesting a {reportName} gives you access to the
                            full company data.
                          </p>
                          <div>
                            <button
                              className="Btn-default-small Btn-default-primary"
                              onClick={requestBir}
                            >
                              Request {reportName}
                            </button>
                          </div>
                        </div>
                      )}
                      {reportPending && (
                        <p className="text-center WorkflowsSupplierDetails-bir-alert">
                          {reportName} investigation is in progress by our
                          analysts. <br />
                          As soon as the investigation is completed, you’ll get
                          a notification.
                        </p>
                      )}

                      {reportDelivered && (
                        <>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="WorkflowsSupplierDetails-label">
                              Company Name
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getCompanyName() ||
                                INFORMATION_UNAVAILABLE}
                            </div>

                            <div className="WorkflowsSupplierDetails-label">
                              TAX ID
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getTaxId() || INFORMATION_UNAVAILABLE}
                            </div>

                            {!workflow.isCkrReport() &&
                              !workflow.isSqrReport() &&
                              !workflow.isCcrReport() && (
                                <>
                                  <div className="WorkflowsSupplierDetails-label">
                                    D&B Rating
                                    <i
                                      className="icon-ic_question"
                                      onClick={showRatingsModal}
                                    />
                                  </div>
                                  {supplier.getDnbRating() && (
                                    <div
                                      className={`WorkflowsSupplierDetails-rating ${supplier.getFinancialStrength() &&
                                        'Square-badge Bg-' +
                                          financialStrengthClasses[
                                            supplier.getFinancialStrength()
                                          ]}`}
                                      notranslate="true"
                                    >
                                      {supplier.getDnbRating()}
                                    </div>
                                  )}
                                  {!supplier.getDnbRating() && (
                                    <div
                                      className="WorkflowsSupplierDetails-info-text"
                                      notranslate="true"
                                    >
                                      {INFORMATION_UNAVAILABLE}
                                    </div>
                                  )}
                                </>
                              )}

                            <div className="WorkflowsSupplierDetails-label">
                              Records
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getNumberOfSuits() ||
                                INFORMATION_UNAVAILABLE}
                            </div>

                            {!workflow.isRafOrRacReport() &&
                              !workflow.isSqrReport() && (
                                <>
                                  <div className="WorkflowsSupplierDetails-label">
                                    EMMA Score
                                  </div>
                                  <div
                                    className="WorkflowsSupplierDetails-info-text"
                                    notranslate="true"
                                  >
                                    {supplier.getEmmaScore() ||
                                      INFORMATION_UNAVAILABLE}
                                  </div>

                                  <div className="WorkflowsSupplierDetails-label">
                                    Paydex
                                    <i
                                      className="icon-ic_question"
                                      onClick={showPaydexModal}
                                    />
                                  </div>
                                  {supplier.getPaydex() ? (
                                    <div
                                      className={
                                        'WorkflowsSupplierDetails-rating Square-badge Bg-' +
                                        paydexGroupClasses[
                                          WorkflowsService.getPaydexGroup(
                                            supplier.getPaydex()
                                          )
                                        ]
                                      }
                                    >
                                      {WorkflowsService.isValidPaydex(
                                        supplier.getPaydex()
                                      )
                                        ? supplier.getPaydex()
                                        : 'UN'}
                                    </div>
                                  ) : (
                                    <div
                                      className="WorkflowsSupplierDetails-info-text"
                                      notranslate="true"
                                    >
                                      {INFORMATION_UNAVAILABLE}
                                    </div>
                                  )}
                                </>
                              )}

                            {workflow.isSqrReport() && (
                              <>
                                <div className="WorkflowsSupplierDetails-label">
                                  SQR Risk
                                </div>
                                {supplier.getRacRiskIndicator() && (
                                  <div
                                    className={`WorkflowsSupplierDetails-rating Square-badge Bg-${racRiskIndicatorClass}`}
                                    notranslate="true"
                                  >
                                    {supplier.getRacRiskIndicator()}
                                  </div>
                                )}
                                {!supplier.getRacRiskIndicator() && (
                                  <div
                                    className="WorkflowsSupplierDetails-info-text"
                                    notranslate="true"
                                  >
                                    {INFORMATION_UNAVAILABLE}
                                  </div>
                                )}
                              </>
                            )}

                            {workflow.isCkrReport() && (
                              <>
                                <div className="WorkflowsSupplierDetails-label">
                                  Commercial Score
                                </div>
                                <div
                                  className="WorkflowsSupplierDetails-info-text"
                                  notranslate="true"
                                >
                                  {supplier.getCommercialScore() ||
                                    INFORMATION_UNAVAILABLE}
                                </div>
                              </>
                            )}

                            {(ccrProductEnabled ||
                              supplier.getCcrScore() ||
                              supplier.isCcrRequested()) && (
                              <>
                                <div className="WorkflowsSupplierDetails-label">
                                  CCR Score
                                  <i
                                    className="icon-ic_question"
                                    onClick={showCcrModal}
                                  />
                                </div>

                                <div className="WorkflowsSupplierDetails-info-text WorkflowsSupplierDetails-ccr-score">
                                  {supplier.getCcrScore() && (
                                    <div
                                      className={`WorkflowsSupplierDetails-rating Square-badge Bg-${
                                        ccrScoreClasses[supplier.getCcrScore()]
                                      }`}
                                      notranslate="true"
                                    >
                                      {supplier.getCcrScore()}
                                    </div>
                                  )}

                                  {!supplier.getCcrScore() &&
                                    supplier.isCcrRequested() && (
                                      <span className="Badge Bg-grey">
                                        Pending
                                      </span>
                                    )}

                                  {ccrProductEnabled &&
                                    !workflow.isCcrReport() &&
                                    !supplier.isCcrRequested() && (
                                      <Tooltip
                                        text={
                                          ccrLimitReached
                                            ? 'You reached product limit. Please contact support for upgrade.'
                                            : ''
                                        }
                                        position="bottom"
                                        id="supplier-details-ccr"
                                      >
                                        <button
                                          className="Btn-default-small Btn-default-primary hidden-xs"
                                          onClick={requestCcr}
                                          disabled={ccrLimitReached}
                                        >
                                          Request CCR
                                        </button>
                                      </Tooltip>
                                    )}
                                </div>
                              </>
                            )}
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-4">
                            {!workflow.isCcrReport() && (
                              <>
                                <div className="WorkflowsSupplierDetails-label">
                                  Balance sheet date
                                </div>
                                <div
                                  className="WorkflowsSupplierDetails-info-text"
                                  notranslate="true"
                                >
                                  {supplier.getBalanceSheetDateDate() ? (
                                    <MomentDate
                                      format="MMMM DD, YYYY"
                                      locale={
                                        languages[Localize.getLanguage()]
                                          .uploadcare
                                      }
                                      date={supplier.getBalanceSheetDateDate()}
                                      timezone="UTC"
                                    />
                                  ) : (
                                    INFORMATION_UNAVAILABLE
                                  )}
                                </div>
                              </>
                            )}

                            <div className="WorkflowsSupplierDetails-label">
                              Phone
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getPhones().join(', ') ||
                                INFORMATION_UNAVAILABLE}
                            </div>

                            <div className="WorkflowsSupplierDetails-label">
                              Email
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getAllEmails().join(', ') ||
                                INFORMATION_UNAVAILABLE}
                            </div>

                            <div className="WorkflowsSupplierDetails-label">
                              Industry
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getIndustry() ||
                                INFORMATION_UNAVAILABLE}
                            </div>

                            <div className="WorkflowsSupplierDetails-label">
                              <DunsAbbr /> Number
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getDunsNumber() ||
                                INFORMATION_UNAVAILABLE}
                            </div>

                            <div className="WorkflowsSupplierDetails-label">
                              SIC
                            </div>
                            <div
                              className="WorkflowsSupplierDetails-info-text"
                              notranslate="true"
                            >
                              {supplier.getSic() || INFORMATION_UNAVAILABLE}
                            </div>

                            {!workflow.isCcrReport() && (
                              <>
                                <div className="WorkflowsSupplierDetails-label">
                                  Shareholders
                                </div>

                                <div
                                  className="WorkflowsSupplierDetails-info-text"
                                  notranslate="true"
                                >
                                  {supplier.getShareholders().join(', ') ||
                                    INFORMATION_UNAVAILABLE}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </PanelGroup>
    );
  }
}

export default WorkflowsSupplierDetailsReport;
