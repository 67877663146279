import React, { Component } from 'react';
import { ComponentWithLoader } from 'react-common/components';
import { connect } from 'react-redux';

import {
  addSupplier,
  addSupplierFailure,
  addSuppliersBulk,
  addSuppliersBulkFailure,
  getEnums,
  getWorkflow,
  updateHeaderBreadcrumbs
} from '../../_actions';
import WorkflowsService from '../../_services/Workflows';

import PageNotFound from '../../PageNotFound/PageNotFound';
import WorkflowsSuppliersAdd from './WorkflowsSuppliersAdd';

import './WorkflowsSuppliersAdd.css';

class WorkflowsSuppliersAddContainer extends Component {
  componentDidMount() {
    const {
      dispatchGetWorkflow,
      dispatchGetEnums,
      dispatchUpdateHeaderBreadcrumbs,
      workflow,
      workflowId,
      countries,
      headerTitles
    } = this.props;

    if (!workflow) {
      dispatchGetWorkflow(workflowId);
    }

    if (!countries) {
      dispatchGetEnums();
    }

    dispatchUpdateHeaderBreadcrumbs(headerTitles);
  }

  componentDidUpdate(prevProps) {
    const { dispatchUpdateHeaderBreadcrumbs, headerTitles } = this.props;

    if (headerTitles.length !== prevProps.headerTitles.length) {
      dispatchUpdateHeaderBreadcrumbs(headerTitles);
    }
  }

  addSupplierSubmit = supplier => {
    const {
      dispatchAddSupplier,
      dispatchAddSupplierFailure,
      workflowId,
      workflow
    } = this.props;
    if (!WorkflowsService.isSupplierValid(supplier, workflow)) {
      return dispatchAddSupplierFailure({
        error:
          'Please fill in required fields (*) and make sure all emails are valid.'
      });
    }

    if (
      supplier.invite.tax_id &&
      !WorkflowsService.isTaxIdValidForCountry(
        supplier.invite.tax_id,
        supplier.invite.country
      )
    ) {
      return dispatchAddSupplierFailure({
        error: 'Please fix TAX ID. Only valid CNPJ is allowed.'
      });
    }

    return dispatchAddSupplier(workflowId, supplier);
  };

  addBulkSubmit = ({ suppliersData }) => {
    const {
      dispatchAddSuppliersBulk,
      dispatchAddSuppliersBulkFailure,
      workflowId,
      workflow,
      countries
    } = this.props;

    if (!suppliersData) {
      return;
    }

    const { suppliers, errors } = WorkflowsService.parseSuppliersBulk(
      suppliersData,
      countries,
      workflow
    );

    if (errors.length) {
      return dispatchAddSuppliersBulkFailure({ error: errors });
    }

    return dispatchAddSuppliersBulk(workflowId, suppliers);
  };

  render() {
    const { showPageNotFound, showLoader } = this.props;
    if (showPageNotFound) {
      return <PageNotFound />;
    }
    return (
      <ComponentWithLoader showLoader={showLoader}>
        <WorkflowsSuppliersAdd
          addSupplierSubmit={this.addSupplierSubmit}
          addBulkSubmit={this.addBulkSubmit}
          {...this.props}
        />
      </ComponentWithLoader>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    workflows,
    enums: { items: { countries } },
    user: { data: { user } }
  } = state;
  const { error, isFetching } = workflows;
  const { workflow_id: workflowId } = props.match.params;
  const workflow = workflows.getById[workflowId];
  let headerTitles = [];
  let suppliers = workflow
    ? workflow.suppliers.map(
        supplierId => workflows.suppliers.getById[supplierId]
      )
    : [];

  if (workflow) {
    const category = WorkflowsService.getWorkflowCategoryForStatus(
      workflow.status
    );
    headerTitles = [
      {
        title: category.headerTitle,
        path: category.path
      },
      {
        title: workflow.info.title,
        path: `/workflows/${workflow._id}/suppliers`,
        notranslate: true
      },
      {
        title: 'Add Suppliers'
      }
    ];
  }

  return {
    workflow,
    workflowId,
    suppliers,
    user,
    headerTitles,
    countries,
    showPageNotFound: (!workflow && !isFetching) || !user.isCompanyAdmin(),
    showLoader: !workflow || isFetching,
    error
  };
};

const mapDispatchToProps = {
  dispatchGetWorkflow: getWorkflow,
  dispatchGetEnums: getEnums,
  dispatchUpdateHeaderBreadcrumbs: updateHeaderBreadcrumbs,
  dispatchAddSupplierFailure: addSupplierFailure,
  dispatchAddSupplier: addSupplier,
  dispatchAddSuppliersBulkFailure: addSuppliersBulkFailure,
  dispatchAddSuppliersBulk: addSuppliersBulk
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsSuppliersAddContainer
);
