import PropTypes from 'prop-types';
import React from 'react';
import { languages } from 'react-common/constants';

import supplierlogo from '../../../_images/logo_side-menu.svg';
import { Dropdown, MenuItem } from 'react-bootstrap';

import './OnboardingHeader.css';

const OnboardingHeader = ({ language, changeLanguage }) => (
  <div className="Onboarding-header">
    <img src={supplierlogo} alt="Supplier360" />

    {changeLanguage && (
      <Dropdown
        id="language-selector"
        className="Onboarding-language"
        onSelect={changeLanguage}
        pullRight
      >
        <Dropdown.Toggle className="form-control Dropdown-form-control">
          {languages[language].full}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(languages)
            .filter(key => languages[key].enabled)
            .map(key => (
              <MenuItem eventKey={key} key={key}>
                {languages[key].full}
              </MenuItem>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    )}
  </div>
);

OnboardingHeader.propTypes = {
  language: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func
};

export default OnboardingHeader;
