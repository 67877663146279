import React, { Component } from 'react';
import { MomentDate } from 'react-common/components';
import { languages, statusUpdateTypeToText } from 'react-common/constants';
import { Localize } from 'react-common/services';

import StatusModal from '../../../_components/StatusModal/StatusModal';

class WorkflowsSupplierDetailsActivity extends Component {
  state = {
    showStatusModal: false
  };

  hideStatusModal = () => this.setState({ showStatusModal: false });
  showStatusModal = () => this.setState({ showStatusModal: true });

  render() {
    const { workflow, supplier } = this.props;

    return (
      <div className="WorkflowsSupplierDetails-rectangle">
        <div className="WorkflowsSupplierDetails-title-table">
          Activity
          <i className="icon-ic_question" onClick={this.showStatusModal} />
        </div>
        <div className="WorkflowsSupplierDetails-content-table">
          <ul className="WorkflowsSupplierDetails-connected-list">
            {supplier.getStatusUpdates().map((status, index) => (
              <li key={index}>
                <span className="WorkflowsSupplierDetails-status-name">
                  {statusUpdateTypeToText[status.name]}
                </span>{' '}
                <span className="WorkflowsSupplierDetails-status-date">
                  <MomentDate
                    format="MMM DD, YYYY"
                    locale={languages[Localize.getLanguage()].uploadcare}
                    date={status.created_at}
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>

        <StatusModal
          show={this.state.showStatusModal}
          handleClose={this.hideStatusModal}
          workflow={workflow}
        />
      </div>
    );
  }
}

export default WorkflowsSupplierDetailsActivity;
