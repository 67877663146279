import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import {
  closeWorkflow,
  cloneWorkflow,
  getStatistics,
  setSuppliersFilters
} from '../../../_actions';
import { emptyFilters } from '../../../_constants/workflows';

import WorkflowsDetailsResults from './WorkflowsDetailsResults';

class WorkflowsDetailsResultsContainer extends Component {
  state = {
    isModalOpen: false
  };

  hideModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  showModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  handleCloseWorkflow = () => {
    const { dispatchCloseWorkflow, workflowId } = this.props;
    dispatchCloseWorkflow(workflowId);
  };

  handleCloneWorkflow = workflow => {
    const { dispatchCloneWorkflow } = this.props;
    return dispatchCloneWorkflow(workflow);
  };

  componentDidMount() {
    const { dispatchGetStatistics, workflowId } = this.props;
    dispatchGetStatistics(workflowId, true);
  }

  onStatusClick = status => {
    const { dispatchPush, dispatchSetSuppliersFilters } = this.props;
    dispatchSetSuppliersFilters({ ...emptyFilters, status });
    dispatchPush('suppliers');
  };

  onStatisticsClick = field => value => {
    const { dispatchPush, dispatchSetSuppliersFilters } = this.props;
    dispatchSetSuppliersFilters({
      ...emptyFilters,
      [field]: Array.isArray(value) ? value : [value]
    });
    dispatchPush('suppliers');
  };

  render() {
    return (
      <WorkflowsDetailsResults
        isModalOpen={this.state.isModalOpen}
        hideModal={this.hideModal}
        showModal={this.showModal}
        handleCloseWorkflow={this.handleCloseWorkflow}
        handleCloneWorkflow={this.handleCloneWorkflow}
        onStatusClick={this.onStatusClick}
        onStatisticsClick={this.onStatisticsClick}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => {
  const { workflows: { statistics }, user: { data: { user } } } = state;

  return {
    statistics,
    user
  };
};

const mapDispatchToProps = {
  dispatchCloseWorkflow: closeWorkflow,
  dispatchCloneWorkflow: cloneWorkflow,
  dispatchGetStatistics: getStatistics,
  dispatchSetSuppliersFilters: setSuppliersFilters,
  dispatchPush: push
};

export default connect(mapStateToProps, mapDispatchToProps)(
  WorkflowsDetailsResultsContainer
);
