import get from 'lodash/get';
import has from 'lodash/has';
import last from 'lodash/last';
import merge from 'lodash/merge';
import sum from 'lodash/sum';
import { supplierStatuses } from 'react-common/constants';

import { ccsCountries } from '../_constants/onboarding';
import { Workflow } from './workflow';

export class Supplier {
  constructor(supplier = {}) {
    merge(this, supplier);
  }

  getId() {
    return this._id;
  }

  getStatus() {
    return this.status;
  }

  isPending() {
    return this.getStatus() === supplierStatuses.PENDING;
  }

  isInProgress() {
    return this.getStatus() === supplierStatuses.IN_PROGRESS;
  }

  isCompleted() {
    return this.getStatus() === supplierStatuses.COMPLETED;
  }

  isArchived() {
    return this.getStatus() === supplierStatuses.ARCHIVED;
  }

  getOnboardingStep() {
    return this.onboarding_step;
  }

  getStatusUpdates() {
    return this.status_updates || [];
  }

  getLastStatusUpdate() {
    return last(this.getStatusUpdates());
  }

  getAnswers() {
    return this.answers;
  }

  getReminders() {
    return this.reminders || [];
  }

  getLatestReminder() {
    return last(this.getReminders());
  }

  getCompanyName() {
    return get(this, 'report.company_name') || get(this, 'invite.company_name');
  }

  getCountry() {
    return get(this, 'invite.country', '');
  }

  getFullName() {
    return get(this, 'invite.full_name');
  }

  getEmail() {
    return get(this, 'invite.email');
  }

  getSecondaryEmails() {
    return get(this, 'invite.secondary_emails');
  }

  getInviteEmails() {
    const emails = [];
    if (this.getEmail()) {
      emails.push(this.getEmail());
    }
    return emails.concat(this.getSecondaryEmails() || []);
  }

  getAllEmails() {
    if (get(this, 'report.emails', []).length > 0) {
      return get(this, 'report.emails');
    }
    return (has(this, 'invite.email')
      ? [get(this, 'invite.email')]
      : []
    ).concat(get(this, 'invite.secondary_emails', []));
  }

  getAddress() {
    return get(this, 'report.address') || get(this, 'invite.address');
  }

  getSic() {
    return (
      get(this, 'report.sic') ||
      get(this, 'score.sic') ||
      get(this, 'invite.sic')
    );
  }

  getTaxId() {
    return get(this, 'report.tax_id') || get(this, 'invite.tax_id');
  }

  getPhone() {
    return get(this, 'invite.phone');
  }

  getPhones() {
    return get(this, 'report.phones', []).length > 0
      ? get(this, 'report.phones')
      : [get(this, 'invite.phone')];
  }

  getBirRequestId() {
    return get(this, 'report.bir_request_id');
  }

  getBirRequest() {
    return get(this, 'birRequest', {});
  }

  getCcrScore() {
    return get(this, 'report.ccr_score');
  }

  isCcrRequested() {
    return !!get(this, 'ccr.requested_at');
  }

  getCcrRequestedDate() {
    return get(this, 'ccr.requested_at');
  }

  getCcrDeliveredDate() {
    return get(this, 'ccr.delivered_at');
  }

  getQuestionnaireStartedDate() {
    return get(this, 'questionnaire.started_at');
  }

  getQuestionnaireCompletedDate() {
    return get(this, 'questionnaire.completed_at');
  }

  getIndustry() {
    return get(this, 'report.industry') || get(this, 'score.industry');
  }

  getDunsNumber() {
    return get(this, 'report.duns_number') || get(this, 'invite.duns_number');
  }

  getFinancialStrength() {
    return (
      get(this, 'report.financial_strength') ||
      get(this, 'score.financial_strength')
    );
  }

  getEmmaScore() {
    return get(this, 'report.emma_score');
  }

  getPaydex() {
    return get(this, 'report.paydex') || get(this, 'score.paydex');
  }

  getRiskIndicator() {
    return (
      get(this, 'report.risk_indicator') || get(this, 'score.risk_indicator')
    );
  }

  getRacRiskIndicator() {
    return get(this, 'report.rac_risk_indicator');
  }

  getReportDnbRating() {
    return get(this, 'report.dnb_rating');
  }

  getDnbRating() {
    const financialStrength = this.getFinancialStrength();
    const riskIndicator = this.getRiskIndicator();
    const racRiskIndicator = this.getRacRiskIndicator();
    const dnbReportRating = this.getReportDnbRating();

    return (
      dnbReportRating ||
      (financialStrength &&
        riskIndicator &&
        financialStrength + riskIndicator) ||
      (financialStrength &&
        racRiskIndicator &&
        financialStrength + racRiskIndicator)
    );
  }

  getCommercialScore() {
    return get(this, 'report.commercial_score');
  }

  getNumberOfSuits() {
    return get(this, 'report.number_of_suits');
  }

  getDeliveryDate() {
    return (
      get(this, 'report.delivery_date') || get(this, 'score.delivery_date')
    );
  }

  getBalanceSheetDateDate() {
    return (
      get(this, 'report.balance_sheet_date') ||
      get(this, 'score.balance_sheet_date')
    );
  }

  getWebsite() {
    return get(this, 'report.website') || get(this, 'score.website');
  }

  getShareholders() {
    return (
      get(this, 'report.shareholders') || get(this, 'invite.shareholders') || []
    );
  }

  getScore() {
    return this.score;
  }

  getReport() {
    return this.report;
  }

  getReportFiles() {
    return get(this, 'report.files');
  }

  getBirFiles() {
    return get(this, 'report.files.bir');
  }

  getAdditionalFiles() {
    return get(this, 'report.files.additional');
  }

  hasBirFiles() {
    return get(this, 'report.files.bir', []).length > 0;
  }

  hasQuestionnaireFiles() {
    return (this.getAnswers() || []).length > 0;
  }

  hasAnyFilesForDownload() {
    return this.hasBirFiles() || this.hasQuestionnaireFiles();
  }

  getAccountId() {
    return get(this, 'account.account_id');
  }

  hasFiles() {
    return get(this, 'files', []).length > 0;
  }

  getFiles() {
    return get(this, 'files', []);
  }

  getTags() {
    return get(this, 'tags', []);
  }

  hasTags() {
    return this.getTags().length > 0;
  }

  getPaymentRegion() {
    const country = this.getCountry();
    if (['argentina', 'mexico', 'peru', 'brazil'].includes(country)) {
      return country;
    }
    if (!!ccsCountries.find(c => c.title === country)) {
      return 'ccs';
    }
    return 'international';
  }

  getBirRequestFiles() {
    return get(this, 'birRequest.files._files', []);
  }

  getRfcDocuments() {
    const files = this.getBirRequestFiles();

    return files.filter(file => file.type === 'rfc_document');
  }

  getPaymentProofs() {
    const files = this.getBirRequestFiles();

    return files.filter(file => file.type === 'payment_proof');
  }

  getPaymentPersonName() {
    return get(this, 'birRequest.billing.payment.person_name', '');
  }

  getPaymentPrice() {
    return get(this, 'birRequest.billing.payment.price.full_price', '');
  }

  getPaymentCurrency() {
    return get(this, 'birRequest.billing.payment.price.currency', '');
  }

  getPaymentReference() {
    return get(this, 'birRequest.billing.payment.reference', '');
  }

  getPaymentCompanyName() {
    return get(this, 'birRequest.billing.general.company_name', '');
  }

  getScreenings() {
    return get(this, 'compliance', []);
  }

  hasScreening() {
    return this.getScreenings().length > 0;
  }

  getLastScreening() {
    return last(this.getScreenings());
  }

  isScreeningInProgress() {
    const screening = this.getLastScreening();
    return screening && !has(screening, 'data');
  }

  isScreeningForBrazil() {
    const screening = this.getLastScreening();
    return get(screening, 'request.company_country') === 'BR';
  }

  companyNumberOfSanctionLists() {
    const screening = this.getLastScreening();
    if (!screening) {
      return 0;
    }

    return sum(
      get(screening, 'data.company_screenings', []).map(
        companyScreening => get(companyScreening, 'sanction_lists', []).length
      )
    );
  }

  companySanctionListsFound() {
    return this.companyNumberOfSanctionLists() > 0;
  }

  companyNumberOfAdverseMedia() {
    const screening = this.getLastScreening();
    if (!screening) {
      return 0;
    }

    return sum(
      get(screening, 'data.company_screenings', []).map(
        companyScreening => get(companyScreening, 'adverse_media', []).length
      )
    );
  }

  companyAdverseMediaFound() {
    return this.companyNumberOfAdverseMedia() > 0;
  }

  companyNumberOfAssociatedIndividuals() {
    const screening = this.getLastScreening();
    if (!screening) {
      return 0;
    }

    return (
      get(screening, 'data.shareholders', []).length +
      get(screening, 'data.beneficial_owners', []).length
    );
  }

  companyNumberOfCriminalActions() {
    const screening = this.getLastScreening();
    if (!screening) {
      return 0;
    }

    return get(screening, 'data.legal_actions', []).length;
  }

  companyCriminalActionsFound() {
    return this.companyNumberOfCriminalActions() > 0;
  }

  companyOwnershipReportAvailable() {
    return this.companyNumberOfAssociatedIndividuals() > 0;
  }

  companyNumberOfAssociatedCompanies() {
    const screening = this.getLastScreening();
    if (!screening) {
      return 0;
    }

    let numberOfCompanies = 0;
    ['subsidiaries', 'branches'].forEach(field => {
      if (has(screening, `data.${field}`)) {
        numberOfCompanies++;
      }
    });

    ['subsidiaries', 'branches'].forEach(
      field => (numberOfCompanies += get(screening, `data.${field}`, []).length)
    );

    return numberOfCompanies;
  }

  companyFamilyTreeReportAvailable() {
    return this.companyNumberOfAssociatedCompanies() > 0;
  }

  companyResultsFound() {
    return (
      this.companySanctionListsFound() ||
      this.companyAdverseMediaFound() ||
      this.companyOwnershipReportAvailable() ||
      this.companyFamilyTreeReportAvailable()
    );
  }

  getComplianceRequestShareholders() {
    return get(this.getLastScreening(), 'request.shareholders', []);
  }

  getComplianceShareholderScreenings(index) {
    return get(
      this.getLastScreening(),
      `data.shareholders_screenings[${index}]`,
      []
    );
  }

  shareholderNumberOfSanctionLists(index) {
    const shareholderScreenings = this.getComplianceShareholderScreenings(
      index
    );
    if (!shareholderScreenings || !shareholderScreenings.length) {
      return 0;
    }

    return sum(
      shareholderScreenings.map(
        shareholderScreening =>
          get(shareholderScreening, 'sanction_lists', []).length
      )
    );
  }

  shareholderSanctionListsFound(index) {
    return this.shareholderNumberOfSanctionLists(index) > 0;
  }

  shareholderNumberOfPeps(index) {
    const shareholderScreenings = this.getComplianceShareholderScreenings(
      index
    );
    if (!shareholderScreenings || !shareholderScreenings.length) {
      return 0;
    }

    return shareholderScreenings.filter(
      shareholderScreening => shareholderScreening.pep
    ).length;
  }

  shareholderPepsFound(index) {
    return this.shareholderNumberOfPeps(index) > 0;
  }

  shareholderNumberOfAdverseMedia(index) {
    const shareholderScreenings = this.getComplianceShareholderScreenings(
      index
    );
    if (!shareholderScreenings || !shareholderScreenings.length) {
      return 0;
    }

    return sum(
      shareholderScreenings.map(
        shareholderScreening =>
          get(shareholderScreening, 'adverse_media', []).length
      )
    );
  }

  shareholderAdverseMediaFound(index) {
    return this.shareholderNumberOfAdverseMedia(index) > 0;
  }

  shareholderResultsFound(index) {
    return (
      this.shareholderSanctionListsFound(index) ||
      this.shareholderPepsFound(index) ||
      this.shareholderAdverseMediaFound(index)
    );
  }

  isMatch() {
    return (
      this.companyResultsFound() ||
      this.getComplianceRequestShareholders().some((shareholder, index) =>
        this.shareholderResultsFound(index)
      )
    );
  }

  getOnlinePaymentUri() {
    return get(this, 'birRequest.billing.online_payment.uri', '');
  }

  getLocalMarket() {
    return get(this, 'birRequest.billing.payment.local_market', '');
  }

  setWorkflow(workflow) {
    const copyWorkflow = { ...workflow };
    delete copyWorkflow.suppliers;
    this.workflow = new Workflow(copyWorkflow);
  }

  getWorkflow() {
    return this.workflow;
  }

  getWorkflowId() {
    return get(this, 'workflow._id', '');
  }

  getWorkflowTitle() {
    return get(this, 'workflow.info.title', '');
  }
}
