import React from 'react';
import {
  ComponentWithLoader,
  MomentDate,
  SingleModal,
  Tooltip
} from 'react-common/components';
import { languages } from 'react-common/constants';
import { Localize } from 'react-common/services';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import StatisticsCount from '../../../_components/Statistics/StatisticsCount/StatisticsCount';
import Statistics from '../../../_components/Statistics/Statistics';
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './WorkflowsDetailsResults.css';

const WorkflowsDetailsResults = ({
  isModalOpen,
  workflow,
  statistics,
  handleCloseWorkflow,
  handleCloneWorkflow,
  hideModal,
  showModal,
  showStatisticsLoader,
  onStatusClick,
  onStatisticsClick,
  user
}) => (
  <div>
    {workflow.isActive() && (
      <div className="WorkflowsDetailsResults-btn-group pull-right">
        <ButtonToolbar>
          <DropdownButton
            pullRight
            className="icon-ic_dots Dots-icon"
            id="workflow-create-actions"
            title=""
            noCaret={true}
          >
            {user.isCompanyAdmin() && (
              <MenuItem
                eventKey="clone"
                onClick={() => handleCloneWorkflow(workflow)}
              >
                Clone
              </MenuItem>
            )}
            <LinkContainer
              to={`/onboarding/${workflow.getId()}/introduction`}
              target="_blank"
            >
              <MenuItem eventKey="preview">Preview</MenuItem>
            </LinkContainer>
            {user.isCompanyAdmin() && (
              <>
                <LinkContainer to={`/workflows/${workflow.getId()}/edit`}>
                  <MenuItem eventKey="edit">Edit</MenuItem>
                </LinkContainer>
                <MenuItem eventKey="launch" onClick={showModal}>
                  Close
                </MenuItem>
              </>
            )}
          </DropdownButton>
        </ButtonToolbar>

        {user.isCompanyAdmin() && (
          <Tooltip
            text="Clone to New Draft"
            position="bottom"
            id={`clone-workflow-tooltip`}
          >
            <button
              type="button"
              className="Btn-default-small Btn-default-neutral WorkflowActive-btn-clone"
              onClick={() => handleCloneWorkflow(workflow)}
            >
              <i className="icon-ic_clone" />
            </button>
          </Tooltip>
        )}

        {workflow.isOnboarding() && (
          <Link
            to={`/onboarding/${workflow.getId()}/introduction`}
            target="_blank"
          >
            <Tooltip
              text="Preview"
              position="bottom"
              id={`preview-workflow-tooltip`}
            >
              <button
                type="button"
                className="Btn-default-small Btn-default-neutral WorkflowActive-btn-preview"
              >
                <i className="icon-ic_eye" />
              </button>
            </Tooltip>
          </Link>
        )}

        {user.isCompanyAdmin() && (
          <>
            <Link to={`/workflows/${workflow.getId()}/edit`}>
              <button
                type="button"
                className="Btn-default-small Btn-outline-primary WorkflowActive-btn-edit"
              >
                Edit
              </button>
            </Link>

            <button
              className="Btn-outline-small Btn-outline-danger WorkflowActive-btn-close"
              onClick={showModal}
            >
              Close
            </button>

            <SingleModal
              title="Close Workflow"
              body={
                workflow.isOnboarding()
                  ? 'Once you close your workflow, all ongoing onboarding processes are going to be completed. Are you sure you are ready to proceed?'
                  : "Once you close your workflow, you'll still be able to see the results but you won't be able to add new suppliers. Are you sure you are ready to proceed?"
              }
              buttonText="Close"
              primaryButtonClassName="Btn-default-primary"
              show={isModalOpen}
              handleClose={hideModal}
              handleSubmit={handleCloseWorkflow}
            />
          </>
        )}
      </div>
    )}

    <ComponentWithLoader showLoader={showStatisticsLoader}>
      <div className="clearfix" />
      <div className="row Workflow-detailsResults">
        <div className="col-lg-6">
          <div className="WorkflowsDetailsResults-content">
            <h3 className="Results-title">Workflow</h3>
            <div className="row">
              <div className="col-xs-6 Vertical-divider">
                <div className="Results-number" notranslate="true">
                  <MomentDate
                    format="MMMM DD, YYYY"
                    locale={languages[Localize.getLanguage()].uploadcare}
                    date={workflow.getLaunchedAt()}
                  />
                </div>
                <div className="Results-state">Launched</div>
              </div>
              <div className="col-xs-6">
                <div className="Results-number" notranslate="true">
                  <Moment diff={workflow.getLaunchedAt()} unit="days">
                    {new Date()}
                  </Moment>
                </div>
                <div className="Results-state">days active</div>
              </div>
            </div>
          </div>
        </div>

        <StatisticsCount
          className="col-sm-12 col-lg-6"
          data={statistics.suppliers.status}
          title="Suppliers"
          onClick={onStatusClick}
        />

        <Statistics
          statistics={statistics}
          workflow={workflow}
          onClick={onStatisticsClick}
          user={user}
        />
      </div>
    </ComponentWithLoader>
  </div>
);

export default WorkflowsDetailsResults;
