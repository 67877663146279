import React from 'react';
import PropTypes from 'prop-types';

import StatisticsRecentActivityItem from './StatisticsRecentActivityItem/StatisticsRecentActivityItem';

import './StatisticsRecentActivity.css';

const StatisticsRecentActivity = ({ className, activities = [] }) => (
  <div className={className || 'col-sm-12 col-md-6'}>
    <div className="Statistics-content Statistics-content-row">
      <h3 className="Statistics-title">Recent Activity</h3>

      <div className="RecentActivity-container">
        {!activities.length && <div>No activities.</div>}
        {activities.map((activity, index) => (
          <StatisticsRecentActivityItem
            activity={activity}
            key={activity._id}
          />
        ))}
      </div>
    </div>
  </div>
);

StatisticsRecentActivity.propTypes = {
  className: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.object)
};

export default StatisticsRecentActivity;
