import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { MomentDate } from 'react-common/components';
import {
  languages,
  workflowReportTypes,
  workflowStatuses
} from 'react-common/constants';
import { Localize } from 'react-common/services';

import './WorkflowsListItem.css';

class WorkflowsListItem extends Component {
  state = {
    hovering: false
  };

  status = {
    [workflowStatuses.ACTIVE]: {
      field: 'launched_at',
      title: 'Launched'
    },
    [workflowStatuses.DRAFT]: {
      field: 'created_at',
      title: 'Created'
    },
    [workflowStatuses.CLOSED]: {
      field: 'closed_at',
      title: 'Closed'
    }
  };

  reportTypeLabels = {
    [workflowReportTypes.BIR]: 'BIR',
    [workflowReportTypes.SHORT_FORM]: 'CKR',
    [workflowReportTypes.SHORT_FORM_PLUS]: 'CKR Plus',
    [workflowReportTypes.RAC]: 'RAC',
    [workflowReportTypes.CAR]: 'CAR',
    [workflowReportTypes.RAF]: 'SQR Brazil',
    [workflowReportTypes.SQR]: 'SQR',
    [workflowReportTypes.CCR]: 'CCR',
    [workflowReportTypes.QUESTIONNAIRE]: 'Questionnaire',
    [workflowReportTypes.HOM_9_ASPECTS]: 'Hom 9 Aspects',
    [workflowReportTypes.HOM_8_ASPECTS]: 'Hom 8 Aspects',
    [workflowReportTypes.HOM_7_ASPECTS]: 'Hom 7 Aspects',
    [workflowReportTypes.HOM_FALLLABELLLA]: 'Hom Fallabella',
    [workflowReportTypes.HOM_FALLLABELLLA_MID]:
      'Hom Fallabella mid-sized companies',
    [workflowReportTypes.CFR]: 'CFR'
  };

  render() {
    const { workflow, suppliersById, onClickHandler } = this.props;
    return (
      <Row
        className="WorkflowsListItem"
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <div
          className="col-md-4 hidden-xs hidden-sm WorkflowsListItem-image-container"
          onClick={() => onClickHandler(workflow)}
        >
          {!!workflow.info.cover && (
            <img
              className="img-responsive WorkflowsListItem-image"
              src={workflow.info.cover}
              alt="Workflow cover"
            />
          )}
          {!workflow.info.cover && (
            <div className="WorkflowsListItem-image WorkflowsListItem-no-cover" />
          )}
        </div>

        <div className="col-xs-12 col-md-8 WorkflowsListItem-content-group">
          <div className="WorkflowsListItem-content WorkflowsListItem-content-main">
            <div className="Workflow-list-item-item">
              <div
                onClick={() => onClickHandler(workflow)}
                className="Workflow-list-item-var Workflow-list-item-var-title"
              >
                {(workflow.info.title && <var>{workflow.info.title}</var>) ||
                  'New workflow'}
              </div>
            </div>

            <div className="Workflow-list-item-item">
              <div className="Workflow-list-item-var Workflow-list-item-description">
                <var>{workflow.info.description}</var>
              </div>
            </div>
          </div>
          <div className="WorkflowsListItem-content">
            <div className="Workflow-list-item-item">
              <div className="Workflow-list-item-label">
                {this.status[workflow.status].title}
              </div>
              <div className="Workflow-list-item-var">
                <var>
                  <MomentDate
                    format="MMMM DD, YYYY"
                    locale={languages[Localize.getLanguage()].uploadcare}
                    date={workflow[this.status[workflow.status].field]}
                  />
                </var>
              </div>
            </div>

            <div className="Workflow-list-item-item">
              <div className="Workflow-list-item-label">
                Completed Suppliers
              </div>
              <div className="Workflow-list-item-var" notranslate="true">
                {
                  workflow
                    .getSuppliers()
                    .map(supplierId => suppliersById[supplierId])
                    .filter(supplier => supplier.isCompleted()).length
                }{' '}
                / {workflow.getSuppliers().length}
              </div>
            </div>

            <div className="Workflow-list-item-item">
              <div className="Workflow-list-item-label">Report type</div>
              <div className="Workflow-list-item-var">
                {this.reportTypeLabels[workflow.getReportType()]}
              </div>
            </div>

            <div className="Workflow-list-item-item">
              <div className="Workflow-list-item-label">Type</div>
              <div className="Workflow-list-item-var">
                {workflow.isOnboarding()
                  ? 'Onboarding invitation'
                  : 'CIAL investigation'}
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}

export default WorkflowsListItem;
