import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Tooltip } from 'react-common/components';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import './TagsManagementDropdown.css';

const TAG_COLORS = [
  '#69C568',
  '#15cdc4',
  '#006898',
  '#4eb1cb',
  '#717ecd',
  '#9c5fb5',
  '#ffc341',
  '#e1a357',
  '#d06079',
  '#963156',
  '#bc4c05',
  '#ec6660',
  '#b54039',
  '#728d96'
];

class TagItem extends Component {
  state = {
    isColorSelectorOpen: false
  };

  onToggle = (isOpen, event, { source }) => {
    if (source === 'rootClose') {
      this.setState({ isColorSelectorOpen: false });
    }
    if (source === 'click' && !this.state.isColorSelectorOpen) {
      this.setState({ isColorSelectorOpen: true });
    }
  };

  onUpdateDebounce = debounce(
    tag =>
      this.props
        .onUpdate(tag._id, tag)
        .then(() => this.setState({ isColorSelectorOpen: false })),
    500
  );

  render() {
    const { tag, onUpdate, onDelete } = this.props;

    return (
      <div className="Tags-row" key={tag._id}>
        <Dropdown
          id={`TagDropdown-${tag._id}`}
          className="TagsColorDropdown"
          onToggle={this.onToggle}
          open={this.state.isColorSelectorOpen}
        >
          <Dropdown.Toggle>
            <i
              className="Tags-color-selector"
              style={{ backgroundColor: tag.color }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="TagsColorDropdown-Menu">
            <div className="Tags-color-container">
              {TAG_COLORS.map((color, index) => (
                <Fragment key={index}>
                  <div
                    className="Badge"
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      onUpdate(tag._id, { ...tag, color });
                      this.setState({ isColorSelectorOpen: false });
                    }}
                  >
                    Label
                  </div>
                  {index > 0 && index % 2 === 1 && <br />}
                </Fragment>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Field
          type="text"
          component="input"
          className="form-control"
          name={tag._id}
          onChange={event =>
            this.onUpdateDebounce({ ...tag, name: event.target.value })
          }
        />

        <Tooltip
          id={`tags-delete-${tag._id}`}
          position="bottom"
          text="Remove label"
        >
          <i
            className="icon-ic_close Tags-delete-btn"
            onClick={() => onDelete(tag._id)}
          />
        </Tooltip>
      </div>
    );
  }
}

class TagsManagementDropdown extends Component {
  state = {
    dropdownOpen: false,
    newTags: []
  };

  onToggle = (isOpen, event, { source }) => {
    if (source === 'rootClose') {
      this.setState({ dropdownOpen: false });
    }
    if (source === 'click' && !this.state.dropdownOpen) {
      this.setState({ dropdownOpen: true });
    }
  };

  render() {
    const { tags, onCreate, onUpdate, onDelete } = this.props;

    return (
      <Dropdown
        id="tags-management"
        className="TagsManagementDropdown"
        onToggle={this.onToggle}
        open={this.state.dropdownOpen}
      >
        <Dropdown.Toggle>
          <i className="icon-ic_dots" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="TagsManagementDropdown-Menu">
          <div className="Tags-Header">Manage labels</div>
          {tags.map(tag => (
            <TagItem
              key={tag._id}
              tag={tag}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
          <button
            type="button"
            className="Tags-create-btn icon-ic_plus"
            onClick={onCreate}
          >
            Create new label
          </button>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

TagsManagementDropdown.propTypes = {
  tags: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  initialValues: props.tags.reduce((acc, cur) => {
    acc[cur._id] = cur.name;
    return acc;
  }, {})
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'tags',
    enableReinitialize: true
  })(TagsManagementDropdown)
);
