import get from 'lodash/get';
import React from 'react';

const WorkflowsSupplierDetailsLabels = ({ supplier, tagsById }) => (
  <div className="WorkflowsSupplierDetails-rectangle">
    <div className="WorkflowsSupplierDetails-title-table">Labels</div>
    <div className="WorkflowsSupplierDetails-content-table WorkflowsSupplierDetails-labels">
      {!supplier.hasTags() && (
        <div className="Empty-state">You didn't add any labels yet.</div>
      )}
      {supplier.hasTags() &&
        supplier
          .getTags()
          .filter(tag => tagsById[tag])
          .map(tag => (
            <div
              key={tag}
              className="Badge"
              style={{ backgroundColor: get(tagsById[tag], 'color') }}
            >
              {get(tagsById[tag], 'name')}
            </div>
          ))}
    </div>
  </div>
);

export default WorkflowsSupplierDetailsLabels;
