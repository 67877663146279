import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControl } from 'react-bootstrap';
import FieldValidationMessage from '../FieldValidationMessage/FieldValidationMessage';
import './TextField.css';

const FieldComponent = ({ id, input, placeholder, meta, disabled }) => (
  <>
    <FormControl
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      {...input}
    />
    <FieldValidationMessage {...meta} />
  </>
);

const TextField = ({ name, placeholder, label, validate, disabled }) => (
  <div className="form-group">
    <label
      className="control-label control-label--fullWidth"
      htmlFor={`text-field-${name}`}
    >
      {label}
    </label>
    <Field
      id={`text-field-${name}`}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      component={FieldComponent}
      validate={validate}
    />
  </div>
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

export default TextField;
