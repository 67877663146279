import PropTypes from 'prop-types';
import React from 'react';
import './StatisticsFinancialStrength.css';
import { financialStrengthClasses } from 'react-common/constants';

const EMPTY_STATE = [
  ['5A', 0],
  ['4A', 0],
  ['3A', 0],
  ['2A', 0],
  ['1A', 0],
  ['other', 0]
];

const StatisticsFinancialStrength = ({ data, className, onClick }) => {
  // set empty state
  if (!data || !data.length) {
    data = EMPTY_STATE;
  }

  const isClickable = rating =>
    data !== EMPTY_STATE && rating !== 'other' && onClick;

  return (
    <div className={className || 'col-sm-6 col-lg-3'}>
      <div className='Statistics-content Statistics-content-row'>
        <h3 className='Statistics-title'>By Financial Strength</h3>

        {data.map(([rating, percent], index) => {
          const colorClass = financialStrengthClasses[rating] || 'grey';

          return (
            <div
              key={index}
              className={
                'row StatisticsFinancialStrength' +
                (isClickable(rating) ? ' clickable' : '')
              }
              onClick={() => isClickable(rating) && onClick(rating)}
            >
              <div
                className={`StatisticsFinancialStrength-square Border-${colorClass}`}
              >
                <div
                  className={
                    `StatisticsFinancialStrength-square-content C-${colorClass}` +
                    (rating === 'other'
                      ? ' StatisticsFinancialStrength-square-content-other'
                      : '')
                  }
                >
                  {rating === 'other' ? 'Other' : <var>{rating}</var>}
                </div>
              </div>

              <div className='StatisticsFinancialStrength-inline-squares-wrapper'>
                {percent !== 0 && (
                  <div
                    className={`StatisticsFinancialStrength-square-inline Bg-${colorClass} Border-${colorClass}`}
                    style={{ width: `${percent}%` }}
                  />
                )}
                {percent !== 100 && (
                  <div
                    className='StatisticsFinancialStrength-square-inline-background'
                    style={{ width: 95 - percent + '%' }}
                  />
                )}
              </div>

              <div
                className='StatisticsFinancialStrength-percentage'
                notranslate='true'
              >
                {data !== EMPTY_STATE && percent === 0
                  ? '<1%'
                  : Math.round(percent) + '%'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

StatisticsFinancialStrength.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string
};

export default StatisticsFinancialStrength;
