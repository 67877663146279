const clientPlaybooks = {
  en: 103401,
  es: 104608,
  'pt-BR': 104609
};

const supplierPlaybooks = {
  en: 104084,
  es: 104606,
  'pt-BR': 104607
};

class DriftService {
  activatePlaybook(interactionId) {
    if (window.drift && window.drift.api) {
      window.drift.api.startInteraction({ interactionId });
    }
  }

  activateClientPlaybook(language) {
    this.activatePlaybook(clientPlaybooks[language]);
  }

  activateSupplierPlaybook(language) {
    this.activatePlaybook(supplierPlaybooks[language]);
  }
}

export default new DriftService();
