import PropTypes from 'prop-types';
import React from 'react';

import './Toast.css';

const SimpleToast = ({ text }) => (
  <div className="Toast-content">
    <i className="icon-ic_toast-info" />
    <span>{text}</span>
  </div>
);

SimpleToast.propTypes = {
  text: PropTypes.string.isRequired
};

export default SimpleToast;
